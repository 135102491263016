import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const claimDivitMilesSlice = createSlice({
  name: 'claimDivitMiles',
  initialState: {
    fetchOrder: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.fetchOrder = AsyncState.reset()
      state.claim = AsyncState.reset()
    },
    fetchOrderStart: (state) => {
      state.fetchOrder = AsyncState.start()
    },
    fetchOrderSuccess: (state, action) => {
      state.fetchOrder = AsyncState.success(action.payload)
    },
    fetchOrderFailed: (state, action) => {
      state.fetchOrder = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = claimDivitMilesSlice

export default claimDivitMilesSlice.reducer

import { put } from 'redux-saga/effects'

import { fetchBuyMilesOrder, fetchPayNowOrder } from '@/redux/order/orderSaga'

import { getError } from '../utils/error'
import { actions as claimDivitMilesActions } from './claimDivitMilesSlice'

export function* fetchOrder({ payload }) {
  try {
    yield put(claimDivitMilesActions.fetchOrderStart())
    const { orderId } = payload
    const data = yield fetchPayNowOrder({ orderId })
    yield put(claimDivitMilesActions.fetchOrderSuccess(data))
  } catch (e) {
    yield put(claimDivitMilesActions.fetchOrderFailed(getError(e)))
  }
}

export function* fetchTopupOrder({ payload }) {
  try {
    yield put(claimDivitMilesActions.fetchOrderStart())
    const { orderId } = payload
    const data = yield fetchBuyMilesOrder({ orderId })
    yield put(claimDivitMilesActions.fetchOrderSuccess(data))
  } catch (e) {
    yield put(claimDivitMilesActions.fetchOrderFailed(getError(e)))
  }
}

export default 0

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popover,
} from '@mui/material'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import MenuItem from '@/components/layout/Sidebar/MenuItem'

export const AccordionStyled = classNames(`
shadow-none border-none p-0 before:hidden
`)

export const MuiAccordionSummary = classNames(`[&>div]:([&>div]:w-full)`)

const AccordionMenu = ({
  icon,
  name,
  childrenArr,
  active,
  isNarrowed,
  selectedItem,
  setSelectedItem,
  keyItem,
  expanded,
  onCloseMenu,
  hasMilesService,
  hasPaynowService,
}) => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    onCloseMenu()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      {!isNarrowed ? (
        <Accordion
          className={AccordionStyled}
          defaultExpanded={!!active}
          expanded={expanded}
          onChange={() => {
            if (setSelectedItem) {
              setSelectedItem(selectedItem !== keyItem ? keyItem : '')
            }
          }}
          elevation={0}
          sx={{
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary className={MuiAccordionSummary}>
            <MenuItem
              icon={icon}
              active={!!active}
              name={name}
              isNarrowed={isNarrowed}
            />
          </AccordionSummary>

          <AccordionDetails>
            {childrenArr &&
              childrenArr.map((item) => {
                console.log('item', item)
                if (
                  !item?.service ||
                  (item?.service === 'miles' && hasMilesService) ||
                  (item?.service === 'paynow' && hasPaynowService)
                )
                  return (
                    <Link to={item.path} key={item.key} onClick={onCloseMenu}>
                      <MenuItem
                        icon=""
                        active={location.pathname === item.path}
                        name={item.title}
                      />
                    </Link>
                  )
                return null
              })}
          </AccordionDetails>
        </Accordion>
      ) : (
        <MenuItem
          icon={icon}
          active={!!active}
          name={name}
          isNarrowed={isNarrowed}
          onClick={handleClick}
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {childrenArr &&
          childrenArr.map((item) => (
            <Link to={item.path} key={item.key}>
              <MenuItem
                icon=""
                active={location.pathname === item.path}
                name={item.title}
              />
            </Link>
          ))}
      </Popover>
    </>
  )
}

export default AccordionMenu

import React from 'react'
import { useSelector } from 'react-redux'

import LoadingModal from '@/components/LoadingModal'

// Loading modal that connected redux
const GlobalLoadingModal = () => {
  const loading = useSelector((state) => state.app.loading)
  return <LoadingModal loading={loading} />
}

export default GlobalLoadingModal

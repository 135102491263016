import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import { ReactComponent as MenuBtnSvg } from '@/assets/header/menu-btn.svg'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import { StoreKeys } from '@/constants/storage'
import { matchExactPath } from '@/utils/Route'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`

const Panel = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Wrapper = styled.div`
  width: 100%;
  height: 3.55rem;
  top: 0;
  left: 0;
  box-shadow: 0 1px 1px #eee;
  background-color: white;
  z-index: 1000;
  position: fixed;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.lg}px`}) {
    position: absolute;
  }
`

const CreatePaymentButton = styled(Button)`
  font-size: 0.6rem;
  padding: 7px 16px;
`

const Login = styled.div`
  font-size: 0.8rem;
  font-weight: 800;
  cursor: pointer;
`

const SignupButton = styled(Button)`
  font-size: 0.8rem;
  padding: 10px 36px;
`

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location
  const hasToken = !!localStorage.getItem(StoreKeys.TOKEN)
  const isDisableCreatePayment = matchExactPath(pathname, '/create-payment')

  const isShowCreatePayment =
    matchExactPath(pathname, '/create-payment') ||
    matchExactPath(pathname, '/fps') ||
    matchExactPath(pathname, '/claim-miles') ||
    matchExactPath(pathname, '/change-password')

  const isHideMenu =
    matchExactPath(pathname, '/') ||
    matchExactPath(pathname, '/signup') ||
    matchExactPath(pathname, '/signup/success') ||
    matchExactPath(pathname, '/forgot-password')

  const isShowSignupLogin = matchExactPath(pathname, '/')

  const onClickCreatePayment = () => {
    if (
      matchExactPath(pathname, '/change-password') ||
      matchExactPath(pathname, '/claim-miles')
    ) {
      history.push('/create-payment')
      return
    }

    dispatch({
      type: 'modal/openModal',
      payload: {
        title: 'hey wait!',
        content:
          'are you sure you want to leave this page without completing your order?',
        actions: [
          {
            key: 'leave',
            title: 'yes, i want to leave',
            action: () => {
              history.push('/create-payment')
            },
          },
          {
            key: 'cancel',
            title: 'no, i want to stay',
          },
        ],
      },
    })
  }

  const onClickMenu = () => {
    dispatch({ type: 'app/showSideBar' })
  }

  const onClickLogo = () => {
    history.push('/home')
  }

  const onClickLogin = () => {
    history.push('/login')
  }

  const onClickSignup = () => {
    history.push('/signup')
  }

  return (
    <Wrapper>
      <Container>
        <Icon
          onClick={onClickLogo}
          renderImage={() => <DivitSvg />}
          width="3.73rem"
          height="1.33rem"
          align="center"
        />
        <Panel>
          {isShowCreatePayment && (
            <CreatePaymentButton
              onClick={onClickCreatePayment}
              disabled={isDisableCreatePayment}
            >
              create payment
            </CreatePaymentButton>
          )}
          <Spacer width="8px" />
          {!isHideMenu && (
            <Icon
              onClick={onClickMenu}
              renderImage={() => <MenuBtnSvg />}
              width="2.66rem"
              height="2.66rem"
            />
          )}
          {isShowSignupLogin && !hasToken && (
            <>
              <Login onClick={onClickLogin}>login</Login>
              <Spacer width="2rem" />
              <SignupButton onClick={onClickSignup}>
                create account
              </SignupButton>
            </>
          )}
        </Panel>
      </Container>
    </Wrapper>
  )
}

export default Header

import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import DivitFastAPI from '@/services/apiFast'

import {
  checkFpsIsAvailable,
  fetchProfileServices,
} from '../profile/profileSaga'
import { getError } from '../utils/error'
import { actions as paynowFastFpsActions } from './paynowFastFpsSlice'

export function* fetchOrder({ payload }) {
  try {
    yield put(paynowFastFpsActions.fetchOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      DivitFastAPI.get(`paynow/fast/orders/${orderId}`)
    )
    yield put({ type: 'order/getOrderSuccess', payload: data.data })
    yield put(paynowFastFpsActions.fetchOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.fetchOrderFailed(getError(e)))
  }
}

export function* generateFpsLink({ payload }) {
  try {
    yield put(paynowFastFpsActions.generateFpsLinkStart())
    yield fetchProfileServices()
    yield checkFpsIsAvailable()
    const { orderId, instalmentId } = payload
    const { data } = yield call(() =>
      DivitFastAPI.post('paynow/payments', {
        orderID: orderId,
        instalmentID: instalmentId,
        instrumentCode: 'fps',
      })
    )
    yield put(paynowFastFpsActions.generateFpsLinkSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.generateFpsLinkFailed(getError(e)))
  }
}

export function* fpsRefreshOrder({ payload }) {
  try {
    yield put(paynowFastFpsActions.refreshOrderStart())
    const { orderId } = payload
    const { data } = yield call(() =>
      // DivitFastAPI.get(`paynow/fast/orders/${orderId}/status`)
      DivitFastAPI.get(`paynow/fast/orders/${orderId}`)
    )
    yield put(paynowFastFpsActions.refreshOrderSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.refreshOrderFailed(getError(e)))
  }
}

export function* requestShareEmail({ payload }) {
  try {
    yield put(paynowFastFpsActions.requestShareEmailStart())
    const { requestID, milesForReward } = payload
    const { data } = yield call(() =>
      DivitAPI.post(`/paynow/payments/request/${requestID}/message`, {
        milesForReward,
      })
    )
    yield put(paynowFastFpsActions.requestShareEmailSuccess(data.data))
  } catch (e) {
    yield put(paynowFastFpsActions.requestShareEmailFailed(getError(e)))
  }
}

export default null

import QRCodeStyling from 'qr-code-styling'
import React, { useEffect, useRef, useState } from 'react'

import DivitQrImage from '@/assets/base64/divit-qrimage'
import DivitQrImageTest from '@/assets/base64/divit-qrimage-test'
import FpsQrImage from '@/assets/base64/fps-qrimage'
import FpsQrImageTest from '@/assets/base64/fps-qrimage-test'

// https://qr-code-styling.com/
// image encode by https://yoksel.github.io/url-encoder/

const imageSets = {
  none: {
    production: {
      image: '',
      imageSize: 1,
      margin: 0,
    },
    test: {
      image: '',
      imageSize: 1,
      margin: 0,
    },
  },
  divit: {
    production: {
      image: DivitQrImage,
      imageSize: 0.4,
      margin: 4,
    },
    test: {
      image: DivitQrImageTest,
      imageSize: 0.5,
      margin: 4,
    },
  },
  fps: {
    production: {
      image: FpsQrImage,
      imageSize: 0.3,
      margin: 4,
    },
    test: {
      image: FpsQrImageTest,
      imageSize: 0.4,
      margin: 4,
    },
  },
}

export default function QRCode({
  style,
  className,
  children,
  type = 'divit', // divit, fps, none
  size = 256,
  data = '',
  backgroundColor = '#FFF',
  color = '#000',
  isTest = true,
}) {
  const imageSet = imageSets[type]
  const image = imageSet[isTest ? 'test' : 'production']

  const options = {
    type: 'svg',
    width: size,
    height: size,
    data,
    image: image.image,
    margin: 0,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'Q',
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: image.imageSize,
      margin: image.margin,
    },
    backgroundOptions: {
      color: backgroundColor,
    },
    dotsOptions: {
      color,
      type: 'sqaure',
    },
    cornersSqaureOptions: {
      color,
      type: 'sqaure',
    },
    cornersDotOptions: {
      color,
      type: 'square',
    },
  }

  const [qrCode] = useState(() => new QRCodeStyling(options))
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current && data) {
      qrCode.append(ref.current)
    }
  }, [qrCode, ref])

  useEffect(() => {
    if (!qrCode) return
    qrCode.update(options)
  }, [qrCode, data])

  return (
    <div style={style} className={className} ref={ref}>
      {!data && children}
    </div>
  )
}

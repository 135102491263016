import colors from '../colors'

const cathay = {
  bubble: {
    background: colors.divitDarkPurple,
    text: colors.white,
  },
  ticket: {
    primary: colors.cathayGreen,
    secondary: colors.cathayGreen, // to-do
  },
  backButton: {
    text: colors.cathayGreen,
  },
}

export default cathay

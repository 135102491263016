/* eslint-disable no-alert */
import axios from 'axios'
import moment from 'moment'

let store = null
export const setApiStore = (s) => {
  store = s
}

let currentToken = localStorage.getItem('token')

const DivitAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: currentToken ? { Authorization: `Bearer ${currentToken}` } : {},
  timeout: 30000,
})

// delegated instance to handle refresh token
const RefreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Divit-Site-ID': 'business',
  },
  timeout: 30000,
  withCredentials: true,
})

export const getAuthToken = () => currentToken

export const setAuthHeader = (token) => {
  currentToken = token
  DivitAPI.defaults.headers.Authorization = `Bearer ${token}`
  RefreshInstance.defaults.headers.Authorization = `Bearer ${token}`
}

export const unsetAuthHeader = () => {
  currentToken = ''
  delete DivitAPI.defaults.headers.Authorization
  delete RefreshInstance.defaults.headers.Authorization
}

// How to test token expire cases:
// 1. go to backend project divit-api
// 2. generateJWT with expireTime 0
// 3. comment refreshToken conditions to test
// 4. set expired token in browser localstorage

// eslint-disable-next-line consistent-return
const refreshToken = async () => {
  try {
    const {
      data: { token, expire },
    } = await RefreshInstance.get('auth/refresh')
    if (token) {
      setAuthHeader(token)
      localStorage.setItem('token', token)
      localStorage.setItem('expire', expire)
      store.dispatch({ type: 'profile/setToken', payload: { token } })
      return token
    }
    throw new Error('no token')
  } catch (e) {
    // handle token expired
    store.dispatch({ type: 'tokenExpiredRedirect' })
    throw e
  }
}

// refresh token before request
DivitAPI.interceptors.request.use(async (request) => {
  const expire = localStorage.getItem('expire')
  if (moment().isAfter(moment(expire))) {
    try {
      const token = await refreshToken()
      // update on-hold request
      request.headers.Authorization = `Bearer ${token}`
    } catch (err) {
      return Promise.reject(err)
    }
  }
  return request
})

// refresh token and retry after server response token expired
DivitAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error
    // TODO: handle 2002 and "token contains an invalid number of segments"
    if (
      response?.data?.code === 2002 &&
      response?.data?.message === 'Token is expired'
    ) {
      try {
        const token = await refreshToken()
        // update current request, otherwise dead loop
        response.config.headers.Authorization = `Bearer ${token}`
        return DivitAPI.request(response.config)
      } catch (e) {
        return Promise.reject(e)
      }
    }
    if (response?.data?.code === 2005) {
      store.dispatch({ type: 'tokenExpiredRedirect' })
    }
    throw error
  }
)

export default DivitAPI

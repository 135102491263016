import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import { ReactComponent as FpsSvg } from '@/assets/payment/fps.svg'
import Icon from '@/components/Icon'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.88rem;
  text-align: center;
  padding-top: 0.77rem;
  margin: 0 8px;
`

const PoweredByDivit = () => (
  <Container>
    <Icon renderImage={() => <FpsSvg />} width="1.778rem" height="1.667rem" />
    <Text>by</Text>
    <Icon renderImage={() => <DivitSvg />} width="3.7rem" height="1.33rem" />
  </Container>
)

export default PoweredByDivit

import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import Button from '@/components/common/Button'
import UploadFileInputField from '@/components/common/UploadFileInputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import LoadingModal from '@/components/LoadingModal'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import misc from '@/constants/types'
import { promptActionModal } from '@/redux/modal/modalActions'
import scrollToTop from '@/utils/scrollToTop'

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const DownloadTemplate = styled.div`
  cursor: pointer;
  color: #fc3;
`

const UploadButton = styled(Button)`
  width: 100%;
  font-size: 0.89rem;
`

const schema = Yup.object().shape({
  files: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'FILE_SIZE',
      'uploaded file is too big',
      (value) => !value || !value?.[0] || value?.[0]?.size <= 4096 * 1024
    )
    .test(
      'FILE_FORMAT',
      'uploaded file has unsupported format',
      (value) =>
        !value ||
        !value?.[0] ||
        value?.[0]?.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        value?.[0]?.type === 'application/vnd.ms-excel'
    ),
})

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const SendInvoicesInBatch = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')

  const { batchOrder } = useSelector((state) => state.batchOrder)
  const { error, isError, isLoading, isSuccess } = batchOrder

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    if (error) {
      setErrorMessage(error)
    } else {
      setErrorMessage(null)
    }
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      // save promo code for default next one
      dispatch(
        promptActionModal({
          title: 'sent',
          content: 'invoices have been processed successfully',
          actionText: 'view records',
          closeText: 'close',
          actionFn: () => {
            history.push('/invoices')
          },
          closable: true,
        })
      )
      reset()
      dispatch({ type: 'batchOrder/reset' })
    }
    if (isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [isError, isSuccess])

  const onClickUpload = handleSubmit(async (data) => {
    setErrorMessage('')

    const payload = new FormData()
    payload.append('file', data.files[0])
    payload.append('source', misc.SOURCE)

    dispatch({
      type: 'batchOrder/batchOrder',
      payload,
    })
  })

  const onClickDownload = async () => {
    dispatch({
      type: 'batchOrder/downloadTemplate',
    })
  }

  return (
    <PageContainer>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>batch sending invoices</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>
          please upload the invoice information with this{' '}
          <DownloadTemplate onClick={onClickDownload}>
            template file
          </DownloadTemplate>
        </SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {errorMessage && (
          <>
            <ErrorMessageLine errorMessage={errorMessage} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Form>
          <FormRow>
            <FormColumn>
              <UploadFileInputField
                id="files"
                {...register('files')}
                placeholder={intl.formatMessage({
                  id: 'upload.excel',
                })}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx,.xls"'
                error={getErrorMessage(errors.files)}
              />
            </FormColumn>
          </FormRow>
          <Spacer height={globalStyles.px.xs} />
          <UploadButton type="bw" onClick={onClickUpload} disabled={isLoading}>
            {intl.formatMessage({ id: 'button.upload' })}
          </UploadButton>
          <LoadingModal loading={isLoading || false} />
        </Form>
      </PageContent>
    </PageContainer>
  )
}

export default SendInvoicesInBatch

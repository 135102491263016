import React from "react"

const PenComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4 20.5h4L18.5 10a2.829 2.829 0 0 0-4-4L4 16.5v4ZM13.5 7l4 4"
    />
  </svg>
)
export default PenComponent

// helpers

export const saveString = (key, str) => {
  localStorage.setItem(key, str)
}

export const getString = (key) => localStorage.getItem(key)

export const setSplitString = (key, arr) => {
  localStorage.setItem(key, arr.join('|'))
}

export const getSplitString = (key) => {
  const strArray = localStorage.getItem(key)
  return strArray ? strArray.split('|') : []
}

export const saveJSON = (key, json) => {
  const jsonStr = JSON.stringify(json)
  localStorage.setItem(key, jsonStr)
}

export const getJSON = (key, defaultValue) => {
  const item = localStorage.getItem(key)
  return JSON.parse(item) || defaultValue
}

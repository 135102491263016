import 'react-day-picker/dist/style.css'

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import styled from 'styled-components'

import { ReactComponent as YellowTickSvg } from '@/assets/common/yellow-tick.svg'
import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'

const Content = styled.div`
  width: 100%;
  max-width: 480px;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 1.111rem;
  text-align: center;
`

const List = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#FFFAEA' : 'transparent')};
  padding: 0.888rem 1.333rem;
  cursor: pointer;
`

const ItemText = styled.div`
  font-size: 0.888rem;
  font-weight: 500;
`

const DayPickerContainer = styled.div``

const DayPickerLabel = styled.div`
  margin: 0 auto;
  font-size: 0.778rem;
  padding: 0 1.333rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const CancelButton = styled(Button)``

const ApplyButton = styled(Button)``

export const OPTIONS = [
  {
    key: '1_day',
    title: '1 day',
    getValue: () => ({
      selectedDate: moment().add(1, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '3_days',
    title: '3 days',
    getValue: () => ({
      selectedDate: moment().add(3, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '7_days',
    title: '7 days',
    getValue: () => ({
      selectedDate: moment().add(7, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '14_days',
    title: '14 days',
    getValue: () => ({
      selectedDate: moment().add(14, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '30_days',
    title: '30 days',
    getValue: () => ({
      selectedDate: moment().add(30, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '60_days',
    title: '60 days',
    getValue: () => ({
      selectedDate: moment().add(60, 'days').endOf('day').toDate(),
    })
  },
  {
    key: '90_days',
    title: '90 days',
    getValue: () => ({
      selectedDate: moment().add(90, 'days').endOf('day').toDate(),
    })
  },
  {
    key: 'custom_date',
    title: 'custom',
    getValue: () => false,
  },
]

const getDefaultCustomDate = () => ({
  selectedDate: moment().add(3, 'days').endOf('day').toDate(),
})

const getOption = (dateValue) => OPTIONS.find((o) => o.key === dateValue.key)

const formatWeekdayName = (weekDay) => {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  return days[moment(weekDay).day()]
}

const SingleDateSelectModal = React.forwardRef(({ isOpen, dateValue, onChange, onClose }, ref) => {
  // const containerRef = useRef(ref)
  const [activeOption, setActiveOption] = useState(() => getOption(dateValue))
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  const [customDate, setCustomDate] = useState(() => getDefaultCustomDate().selectedDate)

  useEffect(() => {
    if (isOpen) {
      setActiveOption(getOption(dateValue))
    }
  }, [isOpen, dateValue])

  useEffect(() => {
    if (activeOption.key === 'custom_date') {
      setIsShowCalendar(true)
    } else {
      setIsShowCalendar(false)
    }
  }, [activeOption])

  const onClickOption = (option) => {
    setActiveOption(option)
  }

  const onClickApply = () => {
    const selected = activeOption.getValue()
    if (isShowCalendar) {
      const defaultDate = getDefaultCustomDate()
      const selectedDate = customDate ?? defaultDate.selectedDate
      onChange({
        key: 'custom_date',
        selectedDate,
      })
    } else if (selected) {
      onChange({ key: activeOption.key, ...selected })
    }
  }

  const toD = (v) => (v ? moment(v).format('LLL') : '')
  const customDateLabel = customDate
    ? `${toD(customDate)}`
    : 'please select a date'

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>specify a date</Title>
        <Spacer height={globalStyles.px.md} />
        <List>
          {OPTIONS.map((option) => (
            <Item
              key={option.key}
              isActive={option.key === activeOption.key}
              onClick={() => onClickOption(option)}
            >
              <ItemText>{option.title}</ItemText>
              {option.key === activeOption.key && (
                <Icon
                  renderImage={() => <YellowTickSvg />}
                  width="0.833rem"
                  height="0.656rem"
                />
              )}
            </Item>
          ))}
        </List>
        {isShowCalendar && (
          <DayPickerContainer>
            <Spacer height={globalStyles.px.md} />
            <DayPickerLabel>{customDateLabel}</DayPickerLabel>
            <Spacer height={globalStyles.px.md} />
            <DayPicker
              mode="single"
              fromDate={moment().add(1, 'day').toDate()}
              selected={customDate}
              onSelect={setCustomDate}
              formatters={{ formatWeekdayName }}
            />
          </DayPickerContainer>
        )}
        <Spacer height={globalStyles.px.lg2} />
        <Buttons>
          <CancelButton type="bw" bordered onClick={onClose}>
            cancel
          </CancelButton>
          <ApplyButton type="bw" onClick={onClickApply}>
            apply
          </ApplyButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
      </Content>
    </BottomSheet>
  )
})

export default SingleDateSelectModal

import axios from 'axios'

import * as SessionStorage from '@/utils/sessionStorage'

// http client for fast checkout
// token will be load from sessionStorage

const currentToken = SessionStorage.getString('token')

const DivitFastAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: currentToken ? { Authorization: `Bearer ${currentToken}` } : {},
  timeout: 30000,
})

const setAuthHeader = (jwt) => {
  SessionStorage.saveString('token', jwt)
  DivitFastAPI.defaults.headers.Authorization = `Bearer ${jwt}`
}

DivitFastAPI.setAuthHeader = setAuthHeader

export default DivitFastAPI

import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'

const Wrapper = styled.div`
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

const Container = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
`

const Bg = styled.div`
  position: absolute;
  width: ${({ total }) => `${(1 / total) * 100}%`};
  height: 100%;
  top: 0;
  left: ${({ total, activeIndex }) => `${(activeIndex / total) * 100}%`};
  border-bottom: 3px solid #ffcc33;
  transition: left 0.3s;
`

const SelectorTab = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 0;

  &:hover {
    cursor: pointer;
  }
`

const TabIcon = styled(Icon)`
  & svg path {
    fill: ${({ isActive }) => (isActive ? '#FFCC33' : '#979797')};
  }
`

const TabLabel = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  color: ${({ isActive }) => (isActive ? '#1C1C1C' : '#979797')};
  margin-left: 0.44rem;
`

const SelectorTabs = ({ defaultTab, tabs, onChange }) => {
  const defaultTabIndex = tabs.findIndex((t) => t.key === defaultTab)
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const onSelectTab = (index) => {
    setActiveTabIndex(index)
    onChange?.(tabs[index])
  }

  return (
    <Wrapper>
      <Container>
        <Bg total={tabs.length} activeIndex={activeTabIndex} />
        {tabs.map((tab, i) => (
          <SelectorTab key={i} onClick={() => onSelectTab(i)}>
            {tab.Icon && (
              <TabIcon
                isActive={i === activeTabIndex}
                renderImage={() => <tab.Icon />}
                width="0.88rem"
                height="0.88rem"
              />
            )}
            <TabLabel isActive={i === activeTabIndex}>{tab.label}</TabLabel>
          </SelectorTab>
        ))}
      </Container>
    </Wrapper>
  )
}

export default SelectorTabs

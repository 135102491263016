import React from 'react'
import styled from 'styled-components'

import { ReactComponent as EmailSvg } from '@/assets/share/email.svg'
import { ReactComponent as OtherOptionIconSvg } from '@/assets/share/other-option-icon.svg'
import { ReactComponent as SmsSvg } from '@/assets/share/sms.svg'
import { ReactComponent as WhatsappSvg } from '@/assets/share/whatsapp.svg'
import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import * as Share from '@/utils/share'

const Content = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  text-align: center;
`

const SubTitle = styled.div`
  margin-top: 0.44rem;
  font-size: 0.667rem;
  color: #979797;
  text-align: center;
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`

const Item = styled.div`
  margin: 0 0.66rem;
  cursor: pointer;
`

const ShareButton = styled(Button)`
  width: 100%;
  background-color: white;
  border: 1px solid #dedede;
  font-size: 0.88rem;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    margin-left: 8px;
  }
`

const ShareModal = ({ isOpen, shareInfo, onClose, onCleanup }) => {
  const onLeave = () => {
    onCleanup()
  }

  const onClickShare = (type) => {
    if (type === 'sms') {
      Share.smsShare(shareInfo)
    } else if (type === 'email') {
      Share.emailShare(shareInfo)
    } else if (type === 'whatsapp') {
      Share.whatsappShare(shareInfo)
    } else if (type === 'browser') {
      Share.browserShare(shareInfo)
    }
  }

  if (!shareInfo) return null

  const { tel, email } = shareInfo
  const medias = Share.getShareMedias({ tel, email })

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave()}
    >
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>share</Title>
        <SubTitle>share information for manual pay</SubTitle>
        <Spacer height={globalStyles.px.md} />
        <List>
          {medias.indexOf('whatsapp') >= 0 && (
            <Item onClick={() => onClickShare('whatsapp')}>
              <Icon
                renderImage={() => <WhatsappSvg />}
                width="4.44rem"
                height="4.44rem"
              />
            </Item>
          )}
          {medias.indexOf('email') >= 0 && (
            <Item onClick={() => onClickShare('email')}>
              <Icon
                renderImage={() => <EmailSvg />}
                width="4.44rem"
                height="4.44rem"
              />
            </Item>
          )}
          {medias.indexOf('sms') >= 0 && (
            <Item onClick={() => onClickShare('sms')}>
              <Icon
                renderImage={() => <SmsSvg />}
                width="4.44rem"
                height="4.44rem"
              />
            </Item>
          )}
        </List>
        {medias.indexOf('browser') >= 0 && (
          <>
            <Spacer height={globalStyles.px.lg} />
            <Spacer height={globalStyles.px.lg} />
            <ShareButton onClick={() => onClickShare('browser')}>
              <Icon
                renderImage={() => <OtherOptionIconSvg />}
                width="1.33rem"
                height="1.33rem"
              />
              <span>more option</span>
            </ShareButton>
          </>
        )}
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </Content>
    </BottomSheet>
  )
}

export default ShareModal

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessSvg } from '@/assets/payment/success.svg'
import PoweredByDivit from '@/components/common/PoweredByDivit'
import Icon from '@/components/Icon'
import LoadingScreen from '@/components/LoadingScreen'
import LoadingSpinner from '@/components/LoadingSpinner'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName from '@/components/merchants/MerchantName'
import MilesValue from '@/components/miles/MilesValue'
import PageContent from '@/components/Page/PageContent'
import QRCode from '@/components/QRCode'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import * as DivitMiles from '@/utils/DivitMiles'
import { isTestEnv } from '@/utils/env'
import * as Order from '@/utils/Order'
import * as SessionStorage from '@/utils/sessionStorage'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #13cc93;
  text-align: center;
`

const Header = styled.div``

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

const TitleContainer = styled.div`
  width: 12rem;
  text-align: center;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    width: 100%;
  }
`

const SuccessText = styled.div`
  font-size: 2.22rem;
  font-weight: 600;
  line-height: 1.1em;
  color: white;
`

const MerchantInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MerchantLogoContainer = styled.div`
  width: 4.44rem;
  height: 4.44rem;
`

const MerchantDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.33rem;
`

const MerchantNameStyled = styled(MerchantName)`
  font-weight: 600;
`

const EarnedText = styled.div`
  font-size: 0.77rem;
`

const FromMerchantText = styled.div`
  font-size: 0.77rem;
`

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
`

const QRRow = styled.div`
  display: flex;
  align-items: center;
`

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0.66rem;
  background-color: white;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 1.77rem;
`

const QRCodeStyled = styled(QRCode)`
  width: 160px;
  height: 160px;
`

const Footer = styled.div`
  background-color: white;
`

const HR = styled.div`
  width: 100%;
  height: 1px;
  background: #eee;
`

const PaymentSuccess = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  // find created order in session
  const { orderID: orderId } = SessionStorage.getJSON('created_order')

  const { fetchOrder } = useSelector((s) => s.claimDivitMiles)
  const { data: order } = fetchOrder

  useEffect(() => () => dispatch({ type: 'claimDivitMiles/reset' }), [])

  // get order when mount
  useEffect(() => {
    // check only have token in session storage can access
    if (!orderId || !SessionStorage.getString('token')) {
      history.replace('/home')
      return
    }

    dispatch({
      type: 'claimDivitMiles/fetchOrder',
      payload: { orderId },
    })
  }, [])

  // order is not paid
  useEffect(() => {
    if (order && Order.isNew(order)) {
      history.replace('/fps')
    }
  }, [order])

  if (!order) return <LoadingScreen loading />
  if (order.orderID !== orderId) return <div />

  const milesEarned = DivitMiles.getMilesEarned(order.milesTransactions)

  return (
    <Container>
      <Header>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.lg2} />
          <Icon
            renderImage={() => <SuccessSvg />}
            width="1.7rem"
            height="1.7rem"
          />
          <Spacer height={globalStyles.px.xs} />
          <SuccessText>payment success!</SuccessText>
          <Spacer height={globalStyles.px.lg} />
        </PageContent>
      </Header>
      <Content>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.lg2} />
          {milesEarned > 0 && (
            <>
              <MerchantInfo>
                <MerchantLogoContainer>
                  <MerchantLogo
                    merchantID={order.merchantID}
                    width="4.44rem"
                    height="4.44rem"
                  />
                </MerchantLogoContainer>
                <MerchantDetails>
                  <EarnedText>you&#39;ve earned</EarnedText>
                  <Spacer height="6px" />
                  <MilesValue
                    fontSize="1.77rem"
                    miles={milesEarned}
                    isSigned={false}
                  />
                  <Spacer height="6px" />
                  <FromMerchantText>
                    from <MerchantNameStyled merchantID={order.merchantID} />
                  </FromMerchantText>
                </MerchantDetails>
              </MerchantInfo>
              <Spacer height={globalStyles.px.lg} />
              <HR />
              <Spacer height={globalStyles.px.lg} />
              <TitleContainer>
                <Title>scan to claim rewards</Title>
              </TitleContainer>
              <Spacer height={globalStyles.px.md} />
              <QRRow>
                <QRCodeContainer>
                  <QRCodeStyled
                    type="divit"
                    size={160}
                    data={`${process.env.REACT_APP_CONSUMER_WEB_URL}/profile/miles`}
                    isTest={isTestEnv}
                  >
                    <LoadingSpinner />
                  </QRCodeStyled>
                </QRCodeContainer>
              </QRRow>
            </>
          )}
        </PageContent>
      </Content>
      <Footer>
        <Spacer height={globalStyles.px.lg2} />
        <PoweredByDivit />
        <Spacer height={globalStyles.px.lg2} />
      </Footer>
    </Container>
  )
}

export default PaymentSuccess

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ClearIcon from '@mui/icons-material/Clear'
import { InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

const InputNumber = (props) => {
  const {
    name,
    control,
    errors,
    label,
    variant = 'outlined',
    isRequired,
    fullWidth = true,
    onChange,
    multiline,
    inputProps,
    size,
    onClear,
    disabled,
    placeholder,
    startContent,
    endContent,
    defaultValue,
    thousandSeparator = ',',
    decimalScale = 0, // set to 2 to have 2 digits after decimal only
    sx,
  } = props

  const [inputHover, setInputHover] = useState(false)

  const onChangeSelect = (e, field) => {
    field.onChange(e.target.value.replaceAll(',', ''))
    if (onChange) {
      onChange(e, field)
    }
  }

  const handleClear = (field) => {
    field.onChange('')

    if (onClear) {
      onClear(field)
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          {...field}
          sx={sx}
          thousandSeparator={thousandSeparator}
          size={size}
          decimalScale={decimalScale}
          required={isRequired}
          customInput={TextField}
          fullWidth={fullWidth}
          onMouseEnter={() => setInputHover(true)}
          onMouseLeave={() => setInputHover(false)}
          onChange={(e) => onChangeSelect(e, field)}
          placeholder={placeholder}
          name={name}
          label={label}
          defaultValue={defaultValue}
          variant={variant}
          error={errors ? !!errors[name] : undefined}
          helperText={errors ? errors[name]?.message : ''}
          multiline={multiline}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              ...inputProps,
            },
            startAdornment: (
              <>
                <InputAdornment position="start">{startContent}</InputAdornment>
              </>
            ),
            endAdornment: endContent ? (
              <>
                <InputAdornment position="end">{endContent}</InputAdornment>
              </>
            ) : (
              <>
                {inputProps &&
                  inputProps.endAdornment &&
                  inputProps.endAdornment}
                {inputHover && field.value && (
                  <InputAdornment position="end" className="cursor-pointer">
                    <div
                      className="hover:bg-zinc-100 p-1 rounded-full"
                      onClick={() => handleClear(field)}
                    >
                      <ClearIcon fontSize="small" color="inherit" />
                    </div>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default InputNumber

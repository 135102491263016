// general page actions, use with redux pageInit

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import scrollToTop from '@/utils/scrollToTop'

const getReduxStateName = (sagaActionName) => sagaActionName.split('/')[1]

const usePage = ({
  initAction,
  skipInit,
  isBlocking = true,
  disableErrorScroll,
}) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(isBlocking && !skipInit)
  const [errorMessage, setErrorMessage] = useState('')

  const initState = useSelector(
    (s) => s.pageInit[getReduxStateName(initAction.type)]
  )

  function init() {
    dispatch(initAction)
  }

  function updateErrorMessage(errMsg) {
    if (errMsg && !disableErrorScroll) {
      scrollToTop(true)
    }
    setErrorMessage(errMsg)
  }

  useEffect(() => {
    if (!skipInit) {
      init()
    }
    return () => dispatch({ type: 'pageInit/reset' })
  }, [])

  // when init action start, stop local loading
  useEffect(() => {
    if (initState.isLoading || initState.isSuccess || initState.isError) {
      setIsLoading(false)
    }
  }, [initState])

  return {
    retry: init,
    isInitSuccess: initState.isSuccess,
    isInitLoading: isBlocking && (isLoading || initState.isLoading),
    isInitError: initState.isError,
    errorMessage,
    updateErrorMessage,
  }
}

export default usePage

import 'yup-phone-lite'

import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { Decimal } from 'decimal.js'
import { isUndefined, parseInt } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as ExpandIconSvg } from '@/assets/common/down-arrow.svg'
import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import MilesBg from '@/assets/landing/bg_travel.jpg'
import CitiSvg from '@/assets/landing/color_fps_divit_citi.png'
import Button from '@/components/common/Button'
import InputError from '@/components/common/InputError'
import Row from '@/components/common/Row'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import InputNumber from '@/components/form/InputNumber'
import TagLabel from '@/components/form/TagLabel'
import Icon from '@/components/Icon'
import PenComponent from '@/components/icons/pen'
import PersonIcon from '@/components/icons/person'
import LoadingModal from '@/components/LoadingModal'
import PageContent from '@/components/Page/PageContent'
import ContentVer2 from '@/components/Page/PageContentV2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { profileSelector } from '@/redux/profile/profileSelector'
import { FormattedMiles, FormattedPrice, Round } from '@/utils/Price'
import scrollToTop from '@/utils/scrollToTop'
import { invalidNameValue } from '@/utils/validation'

const SubLogo = styled.img`
  max-width: 279px !important;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.xs}px`}) {
    max-width: 379px !important;
  }
`

const RowContent = classNames(
  'flex items-center justify-between flex-wrap sm:flex-nowrap'
)

const RowContentEmail = classNames('flex items-center flex-wrap gap-1')

const RowContentTitle = classNames('w-full sm:w-6/12 body-large text-xl')

const RowContentValueContainer = classNames(
  'w-full sm:w-6/12 flex gap-2 items-center sm:justify-center'
)

const RowContentInputContainer = classNames(
  'w-full sm:w-6/12 flex gap-2 items-center sm:justify-end'
)

const RowContentValue = classNames(
  'text-display-value sm:w-11/12 flex sm:justify-end font-bold'
)

const RowContentSuffix = classNames('sm:w-1/12 text-xl')

const RowRight = styled(Row)`
  text-align: right;
  padding-bottom: 0.5rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const InputAmount = styled(InputNumber)``

const ResetButton = styled(Button)`
  padding: 4px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
  width: 100%;
`

const CreateButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
  width: 100%;
`

const AmountContent = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.sm}px`}) {
    width: ${({ inputWidth }) => inputWidth};
  }
`

const ShowDetailsDiv = styled.div`
  cursor: pointer;
  display: flex;
`

const DetailsPane = styled.div`
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 15px;
`

const Banner = styled.div`
  padding: 1.5rem;
  height: 250px;
  border: 0px none transparent;
  background-image: url(${MilesBg});
  background-repeat: no-repeat;
  background-position: center 38%;
  background-size: 100% auto;
  margin-bottom: -3rem;
`

const defaultValues = {
  firstName: '',
  lastName: '',
  miles: '',
  email: '',
  recipients: [],
  amount: '',
  milesForReward: 0,
  reference: '',
}

const nameSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .when('lastName', {
        is: (lastName) => !lastName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    lastName: Yup.string()
      .when('firstName', {
        is: (firstName) => !firstName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  },
  ['firstName', 'lastName']
)

const schema = Yup.object()
  .shape({
    miles: Yup.number()
      .typeError('miles is required')
      .positive('miles must be positive')
      .required('miles is required'),
    email: Yup.string()
      .trim('')
      .when('tel', {
        is: (tel) => !tel,
        then: Yup.string()
          .email('invalid email format')
          .required('either mobile or email is required'),
      }),
    recipients: Yup.array()
      .of(Yup.string().email())
      .test({
        message: 'recipient is required',
        test: (arr) => arr.length > 0,
      }),
    amount: Yup.number()
      .typeError('amount must be number')
      .positive('amount must be positive')
      .required('amount is required'),
    reference: Yup.string()
      .required('merchant ref# is required')
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  })
  .concat(nameSchema)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const DivitPromotions = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [emails, setEmails] = useState([])
  const [sellRate, setSellRate] = useState(0)
  const [showCalc, setShowCalc] = useState(false)

  const profile = useSelector(profileSelector)
  const { createPayment } = useSelector((s) => s.createPayment)
  const rateHKDDVM = useSelector((s) => s.lookup?.rateHKDDVM)
  const tokenRateHKDDVM = useSelector((s) => s.lookup?.tokenRateHKDDVM)

  const {
    reset,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      firstName: `customer_${moment().unix()}`,
      reference: `${moment().unix()}`,
    },
    resolver: yupResolver(schema),
  })

  const [inputWidth, setInputWidth] = useState('110px')

  const orderAmount = watch('amount')

  const onClickReset = () => {
    setEmails([])
    reset()
  }

  const onClickCreate = handleSubmit((data) => {
    dispatch({
      type: 'createPayment/createDirectMilesPayment',
      payload: data,
    })
  })

  useEffect(() => {
    onClickReset()
  }, [])

  useEffect(() => {
    if (!profile) return
    if (profile?.abbreviation !== 'DIVITMARKET') {
      history.push('/')
    }
    dispatch({
      type: 'divitMiles/fetchMerchantMilesBooks',
      payload: {},
    })
  }, [profile])

  useEffect(() => {
    if (createPayment.isSuccess) {
      window.location.href = `${createPayment.data?.redirectURI}&token=${createPayment.data?.token}`
    }
    if (createPayment.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [createPayment])

  useEffect(() => () => dispatch({ type: 'createPayment/reset' }), [])

  useEffect(() => {
    if (!rateHKDDVM && !tokenRateHKDDVM) return
    if (!tokenRateHKDDVM || tokenRateHKDDVM?.sell === 0) {
      setSellRate(rateHKDDVM?.sell)
    } else {
      setSellRate(tokenRateHKDDVM?.sell)
    }
  }, [tokenRateHKDDVM, rateHKDDVM])

  const onChangeMiles = (recip, val = 0) => {
    if (!isUndefined(val) && recip.length !== 0) {
      setValue('email', recip?.[0] || '')
      setValue('recipients', recip || [])
      setValue(
        'amount',
        Round(Decimal(val).mul(sellRate).toNumber(), 2) * recip.length
      )
    }
  }

  const onClickShowDetails = () => setShowCalc(!showCalc)
  const loading = createPayment.isLoading

  return (
    <>
      <Banner>
        <div className="text-[26px] font-bold mb-2">divit promotions</div>
        <SubLogo src={CitiSvg} alt="" />
      </Banner>
      <PageContent>
        <ContentVer2 hasPadding>
          <div className={RowContent}>
            {createPayment.error && (
              <>
                <ErrorMessageLine errorMessage={createPayment.error} />
                <Spacer height={globalStyles.px.xs} />
              </>
            )}
          </div>
          <div className={RowContent}>
            <div className={RowContentTitle}>mile reward per recipient</div>
            <div className={RowContentInputContainer}>
              <AmountContent inputWidth={inputWidth}>
                <InputAmount
                  name="miles"
                  variant="standard"
                  placeholder="0"
                  type="price"
                  control={control}
                  onChange={(e) => {
                    const milesVal = e.target.value.replaceAll(',', '') || 0
                    onChangeMiles(emails, parseInt(milesVal))
                    setInputWidth(() =>
                      milesVal > 1
                        ? `${110 + 19 * milesVal.toString().length}px`
                        : '110px'
                    )
                  }}
                  startContent={<PenComponent />}
                  endContent={
                    <span className="text-black text-body-large">miles</span>
                  }
                  sx={{
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#000',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#000',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontSize: '32px',
                      fontWeight: 700,
                      lineHeight: '53.12px',
                    },
                  }}
                />
              </AmountContent>
            </div>
          </div>
          {errors.miles && (
            <RowRight>
              <InputError error={getErrorMessage(errors.miles)} />
            </RowRight>
          )}
          <div className={RowContentEmail}>
            <div className={RowContentTitle}>email</div>
            <div className="w-full">
              <TagLabel
                control={control}
                options={emails}
                type="email"
                placeholder="recipient(s)"
                name="emails"
                onChange={(_emails) => {
                  const milesVal = getValues('miles') || 0
                  setEmails(_emails)
                  onChangeMiles(_emails, milesVal)
                }}
                label=""
              />
            </div>
          </div>
          {errors.recipients && (
            <Row>
              <InputError error={getErrorMessage(errors.recipients)} />
            </Row>
          )}
          <div className={RowContent}>
            <ShowDetailsDiv
              className={RowContentTitle}
              onClick={onClickShowDetails}
            >
              order total{' '}
              <Icon
                renderImage={() => <ExpandIconSvg />}
                width="1.111rem"
                height="1.111rem"
              />
            </ShowDetailsDiv>
            <div className={RowContentValueContainer}>
              <div className={RowContentValue}>
                {FormattedPrice('', orderAmount * 100)}
              </div>
              <div className={RowContentSuffix}>HKD</div>
            </div>
          </div>

          {showCalc && (
            <>
              <Spacer height="0.5rem" />
              <DetailsPane>
                <div className={RowContent}>
                  <div className={RowContentTitle}>
                    number of recipient{emails.length > 1 ? 's' : ''}
                  </div>
                  <div className={RowContentValueContainer}>
                    <div className={RowContentValue}>{emails.length}</div>
                    <div className={RowContentSuffix}>
                      <PersonIcon />
                    </div>
                  </div>
                </div>
                <div className={RowContent}>
                  <div className={RowContentTitle}>total miles purchased</div>
                  <div className={RowContentValueContainer}>
                    <div className={RowContentValue}>
                      {FormattedMiles(getValues('miles') * 100 * emails.length)}
                    </div>
                    <div className={RowContentSuffix}>miles</div>
                  </div>
                </div>
                <div className={RowContent}>
                  <div className={RowContentTitle}>cost per mile</div>
                  <div className={RowContentValueContainer}>
                    <div className={RowContentValue}>{sellRate}</div>
                    <div className={RowContentSuffix}>HKD</div>
                  </div>
                </div>
              </DetailsPane>
              <Spacer height="0.5rem" />
            </>
          )}
          <div className={RowContent}>
            {errors.amount && (
              <RowRight>
                <InputError error={getErrorMessage(errors.amount)} />
              </RowRight>
            )}
          </div>
          <div className="flex">
            <Buttons className="w-full">
              <ResetButton
                type="bw"
                bordered
                onClick={onClickReset}
                style={{ paddingTop: '8px', paddingBottom: '7px' }}
              >
                reset
              </ResetButton>
              <CreateButton
                type="main"
                onClick={onClickCreate}
                style={{ paddingTop: '10px', paddingBottom: '5px' }}
              >
                <DivitSvg
                  style={{ marginBottom: '10px', marginRight: '5px' }}
                />{' '}
                checkout
              </CreateButton>
            </Buttons>
          </div>
        </ContentVer2>
        <LoadingModal loading={loading} />
      </PageContent>
    </>
  )
}

export default DivitPromotions

import { call, put } from 'redux-saga/effects'

import misc from '@/constants/types'
import { fetchPayNowOrder } from '@/redux/order/orderSaga'
import DivitAPI from '@/services/api'
import DivitFastAPI from '@/services/apiFast'
import * as SessionStorage from '@/utils/sessionStorage'

import { getError } from '../utils/error'
import { actions as createPaymentActions } from './createPaymentSlice'

export function* createDirectMilesPayment({ payload }) {
  try {
    yield put(createPaymentActions.createPaymentStart())

    // create new order
    const newOrder = {
      customer: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: (payload.email || '').toLowerCase(),
        language: 'en',
      },
      order: {
        merchantRef: payload.reference,
        milesForReward: payload.milesForReward,
        totalForReward: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        totalAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        nonrefundableAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        refundableAmount: { amount: 0, currency: 'HKD' },
        promoCode: '',
        webhookSuccess: window.location.href,
        webhookFailure: window.location.href,
        webhookEvents: 'NA',
        orderItems: [
          {
            itemType: 'miles',
            itemData: {
              recipients: payload.recipients,
              milesPerRecipient: payload.miles,
            },
          },
        ],
        messageContent: '',
        notifyWhenPaid: false,
        source: misc.SOURCE,
      },
    }
    const createOrderRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/fast', newOrder)
    )
    yield put(
      createPaymentActions.createPaymentSuccess(createOrderRespData.data.data)
    )
  } catch (err) {
    yield put(createPaymentActions.createPaymentFailed(getError(err)))
  }
}

export function* createPayment({ payload }) {
  try {
    yield put(createPaymentActions.createPaymentStart())

    // create new order
    const newOrder = {
      customer: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: (payload.email || '').toLowerCase(),
        countryCode: payload.countryCode,
        tel: payload.tel,
        language: 'en',
      },
      order: {
        merchantRef: payload.reference,
        // TODO: uncomment the following code
        // milesForReward: payload.milesForReward,
        milesForReward: 0,
        totalForReward: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        totalAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        nonrefundableAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        refundableAmount: { amount: 0, currency: 'HKD' },
        // TODO: uncomment the following code
        // promoCode: payload.promoCode,
        promoCode: '',
        webhookSuccess: 'NA',
        webhookFailure: 'NA',
        webhookEvents: 'NA',
        orderItems: [],
        messageContent: '',
        notifyWhenPaid: false,
        source: misc.SOURCE,
      },
    }
    const createOrderRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/fast', newOrder)
    )
    const { orderID, token } = createOrderRespData.data.data

    DivitFastAPI.setAuthHeader(token)

    // get order
    const payNowOrder = yield fetchPayNowOrder({ orderId: orderID })

    // save details for future user
    SessionStorage.saveJSON('created_order_customer', newOrder.customer)
    SessionStorage.saveString('created_order_promo_code', payload.promoCode)

    yield put(createPaymentActions.createPaymentSuccess(payNowOrder.data))
  } catch (err) {
    yield put(createPaymentActions.createPaymentFailed(getError(err)))
  }
}

export default null

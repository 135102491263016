import 'yup-phone-lite'

import { yupResolver } from '@hookform/resolvers/yup'
import { Decimal } from 'decimal.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as BackSpaceSvg } from '@/assets/common/backspace.svg'
import { ReactComponent as EmailSvg } from '@/assets/common/email.svg'
import { ReactComponent as PhoneSvg } from '@/assets/common/mobile.svg'
import Button from '@/components/common/Button'
import ExpandableInputField from '@/components/common/ExpandableInputField'
import InputError from '@/components/common/InputError'
import InputField from '@/components/common/InputField'
import Row from '@/components/common/Row'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import MilesSlider from '@/components/miles/MilesSlider'
import MilesEarnLabel from '@/components/MilesEarnLabel'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import {
  profileConfigSettings,
  roleSelector,
} from '@/redux/profile/profileSelector'
import * as CampaignUtils from '@/utils/Campaign'
import * as LocalStorage from '@/utils/localstorage'
import { FormattedPercentage, FormattedPrice, Round } from '@/utils/Price'
import scrollToTop from '@/utils/scrollToTop'
import { invalidNameValue } from '@/utils/validation'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.8rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Form = styled.div`
  background: white;
  padding: 1.33rem;
  padding-top: 0.5rem;
  border-radius: 16px;
`

const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 3rem;
  text-align: right;
  border: none;
  width: 100%;
  flex: unset;
`
const AmountSuffix = styled.div`
  max-width: 45px;
`
const BackSpace = styled(Icon)`
  fill: #979797;
`
const Currency = styled.div``
const RowRight = styled(Row)`
  text-align: right;
  padding-bottom: 0.5rem;
`
const ReferenceNumber = styled(ExpandableInputField)`
  & > div:first-child {
    & div {
      margin-left: auto;
    }
  }
`

const EarnMilesContainer = styled.div``

const EarnMilesButton = styled.div`
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  margin-bottom: 0.33rem;
  width: fit-content;
`
const EarnMilesContent = styled(MilesEarnLabel)``

const MilesCostText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.778rem;
  color: #979797;
  padding: 0 1.333rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`

const ResetButton = styled(Button)``

const CreateButton = styled(Button)``

const defaultValues = {
  firstName: '',
  lastName: '',
  countryCode: '852',
  tel: '',
  email: '',
  amount: '',
  milesForReward: 0,
  promoCode: '',
  reference: '',
}

const nameSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .when('lastName', {
        is: (lastName) => !lastName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    lastName: Yup.string()
      .when('firstName', {
        is: (firstName) => !firstName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  },
  ['firstName', 'lastName']
)

const contactSchema = Yup.object().shape(
  {
    email: Yup.string()
      .trim('')
      .when('tel', {
        is: (tel) => !tel,
        then: Yup.string()
          .email('invalid email format')
          .required('either mobile or email is required'),
      }),
    countryCode: Yup.string().required(),
    tel: Yup.string().when('email', {
      is: (email) => !email,
      then: Yup.string()
        .phone('HK', 'invalid phone number format')
        .required('either mobile or email is required'),
    }),
  },
  ['tel', 'email']
)

const schema = Yup.object()
  .shape({
    amount: Yup.number()
      .typeError('amount must be number')
      .positive('amount must be positive')
      .required('amount is required'),
    reference: Yup.string()
      .required('merchant ref# is required')
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    promoCode: Yup.string().test(
      'should not contain special character(s)',
      'should not contain special character(s)',
      (v) => !invalidNameValue(v)
    ),
  })
  .concat(nameSchema)
  .concat(contactSchema)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const CreatePayment = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  // const [campaignOptions, setCampaignOptions] = useState([])
  const [activeTab, setActiveTab] = useState('mobile')
  // const [campaignOptionIndex, setCampaignOptionIndex] = useState(0)

  // const campaigns = useSelector((s) => s.profile.campaigns)
  const { createPayment } = useSelector((s) => s.createPayment)
  // const payNowRate = useSelector((s) => s.profile.payNowRate)
  // const rateHKDDVM = useSelector((s) => s.lookup.rateHKDDVM)
  // const role = useSelector(roleSelector)
  const configs = useSelector(profileConfigSettings)

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      firstName: `customer_${moment().unix()}`,
      reference: `${moment().unix()}`,
    },
    resolver: yupResolver(schema),
  })

  const orderAmount = watch('amount')
  const promoCode = watch('promoCode')
  const reference = watch('reference')

  // const campaign = campaigns.find((o) => o.promoCode === promoCode)

  // const setDefaultCampaign = () => {
  //   if (!configs || !campaigns) return
  //   const options = campaigns
  //     .filter(
  //       (c) =>
  //         c.status === 'active' &&
  //         (configs.campaignList?.indexOf(c.promoCode) >= 0 ||
  //           !configs.campaignList)
  //     )
  //     .map((c) => ({ label: c.title, value: c.promoCode }))
  //   setCampaignOptions(options)

  //   if (configs.defaultCampaign) {
  //     setValue('promoCode', configs.defaultCampaign)
  //     setCampaignOptionIndex(
  //       options.length -
  //         1 -
  //         options.findIndex((c) => c.value === configs.defaultCampaign)
  //     )
  //   } else {
  //     const code = options[options.length - 1]?.value || ''
  //     setValue('promoCode', code)
  //   }
  // }

  // useEffect(() => {
  //   setDefaultCampaign()
  // }, [campaigns, configs])

  useEffect(() => {
    if (createPayment.isSuccess) {
      // save promo code for default next one
      LocalStorage.saveString('default_promo_code', promoCode)
      history.push('/fps')
    }
    if (createPayment.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [createPayment])

  // useEffect(() => {
  //   if (campaign) {
  //     const milesForReward = CampaignUtils.getRewardMiles(
  //       campaign,
  //       parseFloat(orderAmount)
  //     )
  //     setValue('milesForReward', milesForReward)
  //   }
  // }, [orderAmount, campaign])

  useEffect(() => () => dispatch({ type: 'createPayment/reset' }), [])

  const onClickReset = () => {
    reset()
    // setDefaultCampaign()
  }

  const onClickCreate = handleSubmit((data) => {
    dispatch({ type: 'createPayment/createPayment', payload: data })
  })

  // const onChangeMilesSlider = (value) => {
  //   let index = campaignOptions.length - value - 1
  //   if (index < 0) index = 0
  //   if (index >= campaignOptions.length) index = campaignOptions.length - 1

  //   setCampaignOptionIndex(value)
  //   setValue('promoCode', campaignOptions[index].value)
  // }

  // const milesReward = campaign
  //   ? CampaignUtils.getRewardMiles(campaign, parseFloat(orderAmount))
  //   : 0

  // TODO: uncomment the following code
  // const mileCost = campaign
  //   ? Round(Decimal(milesReward).mul(rateHKDDVM?.sell).toNumber(), 2)
  //   : 0
  // const mileCost = 0

  // const mileCostPercentage = campaign
  //   ? FormattedPercentage((mileCost / parseFloat(orderAmount)) * 100)
  //   : 0
  // const totalCost = campaign
  //   ? Decimal(payNowRate.feeFixedAmount)
  //       .add(
  //         Decimal(parseFloat(orderAmount)).mul(payNowRate.feeInPercentage / 100)
  //       )
  //       .add(mileCost)
  //       .toNumber()
  //   : 0
  // const totalCostPercentage = campaign
  //   ? FormattedPercentage((totalCost / parseFloat(orderAmount)) * 100)
  //   : 0

  // const isDisplayMilesCost = !!campaign && orderAmount > 0
  const loading = createPayment.isLoading

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>create payment</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>please enter information below</SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {createPayment.error && (
          <>
            <ErrorMessageLine errorMessage={createPayment.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Row>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Amount
                allowNegativeValue={false}
                decimalScale={2}
                disableAbbreviations
                placeholder="0.00"
                value={field.value}
                onValueChange={(value, name) => {
                  field.onChange(value)
                }}
                inputMode="numeric"
                maxLength={10}
              />
            )}
          />
          <AmountSuffix>
            <BackSpace
              renderImage={() => <BackSpaceSvg />}
              width="1.111rem"
              height="1.111rem"
              onClick={() => {
                setValue('amount', '')
              }}
            />
            <Currency>hkd</Currency>
          </AmountSuffix>
        </Row>
        {errors.amount && (
          <RowRight>
            <InputError error={getErrorMessage(errors.amount)} />
          </RowRight>
        )}
        <Row>
          <ReferenceNumber
            title={`merchant ref # ${reference}`}
            {...register('reference')}
            placeholder="merchant reference #"
            error={getErrorMessage(errors.reference)}
            maxLength={10}
          />
        </Row>
        <Spacer height={globalStyles.px.xs} />
        <Form>
          <SelectorTabs
            defaultTab={activeTab}
            tabs={[
              { key: 'mobile', label: 'mobile', Icon: PhoneSvg },
              { key: 'email', label: 'email', Icon: EmailSvg },
            ]}
            onChange={(tab) => setActiveTab(tab.key)}
          />
          <Tab isActive={activeTab === 'mobile'}>
            <Row>
              <InputField
                style={{ width: 90, flex: 'none' }}
                onChange={() => null}
                value="852"
                hasError={getErrorMessage(errors.tel)}
                tabIndex="-1"
              />
              <InputField
                placeholder="mobile number"
                {...register('tel')}
                hasError={getErrorMessage(errors.tel)}
                inputMode="tel"
              />
            </Row>
            <Row>
              <InputError error={getErrorMessage(errors.tel)} />
            </Row>
          </Tab>
          <Tab isActive={activeTab === 'email'}>
            <InputField
              title=""
              placeholder="email"
              {...register('email')}
              error={getErrorMessage(errors.email)}
              inputMode="email"
            />
          </Tab>
          {/* TODO: uncomment the following code */}
          {/* <Spacer height={globalStyles.px.xs} />
          <EarnMilesContainer>
            <center>
              <EarnMilesButton type="bw" bordered>
                <EarnMilesContent miles={(milesReward || 0) * 100} />
              </EarnMilesButton>
            </center>
            <Spacer height="0.889rem" />
            {campaignOptions.length > 1 && (
              <MilesSlider
                defaultValue={campaignOptionIndex}
                max={campaignOptions.length - 1}
                value={campaignOptionIndex}
                onChange={onChangeMilesSlider}
              />
            )}
          </EarnMilesContainer> */}

          {/* TODO: uncomment the following code */}
          {/* {(role === 'admin' || !configs.hideCost) && isDisplayMilesCost && (
            <>
              <Spacer height={globalStyles.px.xs} />
              <MilesCostText>
                <div>miles cost</div>
                <div>
                  {FormattedPrice('HKD', mileCost * 100)} ({mileCostPercentage}
                  %)
                </div>
              </MilesCostText>
              <Spacer height={globalStyles.px.xs} />
              <MilesCostText>
                <div>total cost</div>
                <div>
                  {FormattedPrice('HKD', totalCost * 100)} (
                  {totalCostPercentage}%)
                </div>
              </MilesCostText>
            </>
          )} */}
        </Form>
        <Spacer height={globalStyles.px.xs} />
        <Buttons>
          <ResetButton type="bw" bordered onClick={onClickReset}>
            reset
          </ResetButton>
          <CreateButton type="main" onClick={onClickCreate}>
            create
          </CreateButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default CreatePayment

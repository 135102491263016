import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as SuccessSvg } from '@/assets/payment/success.svg'
import Button from '@/components/common/Button'
import PoweredByDivit from '@/components/common/PoweredByDivit'
import Icon from '@/components/Icon'
import LoadingScreen from '@/components/LoadingScreen'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MilesValue from '@/components/miles/MilesValue'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import {
  roleSelector,
  tokenBookBalanceSelector,
} from '@/redux/profile/profileSelector'
import * as DivitMiles from '@/utils/DivitMiles'
import * as Order from '@/utils/Order'
import * as SessionStorage from '@/utils/sessionStorage'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #13cc93;
  text-align: center;
`

const Header = styled.div``

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

const SuccessText = styled.div`
  font-size: 2.22rem;
  font-weight: 600;
  line-height: 1.1em;
  color: white;
`

const MerchantInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MerchantLogoContainer = styled.div`
  width: 4.44rem;
  height: 4.44rem;
`

const MerchantDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.33rem;
`

const EarnedText = styled.div`
  font-size: 0.77rem;
`

const FromMerchantText = styled.div`
  font-size: 0.77rem;
`

const BalanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BalanceTitle = styled.div`
  font-size: 0.77rem;
`
const Balance = styled.div``
const HomeButton = styled(Button)`
  width: 80%;
  font-size: 0.89rem;
`
const Footer = styled.div`
  background-color: white;
`

const TopupSuccess = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const role = useSelector(roleSelector)
  const balance = useSelector(tokenBookBalanceSelector)

  useEffect(() => {
    if (role === 'admin') {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
  }, [role])

  // find created order in session
  const { orderID: orderId } = SessionStorage.getJSON('created_order')

  const { fetchOrder } = useSelector((s) => s.claimDivitMiles)
  const { data: order } = fetchOrder

  useEffect(() => () => dispatch({ type: 'claimDivitMiles/reset' }), [])

  // get order when mount
  useEffect(() => {
    // check only have token in session storage can access
    if (!orderId || !SessionStorage.getString('token')) {
      history.replace('/home')
      return
    }

    dispatch({
      type: 'claimDivitMiles/fetchTopupOrder',
      payload: { orderId },
    })
  }, [])

  // order is not paid
  useEffect(() => {
    if (order && Order.isNew(order)) {
      history.replace('/fps')
    }
  }, [order])

  const onClickHome = () => {
    history.push('/')
  }

  if (!order) return <LoadingScreen loading />
  if (order.orderID !== orderId) return <div />

  const milesEarned = DivitMiles.getMilesEarned(order.milesTransactions)

  return (
    <Container>
      <Header>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.lg2} />
          <Icon
            renderImage={() => <SuccessSvg />}
            width="1.7rem"
            height="1.7rem"
          />
          <Spacer height={globalStyles.px.xs} />
          <SuccessText>payment success!</SuccessText>
          <Spacer height={globalStyles.px.lg} />
        </PageContent>
      </Header>
      <Content>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.lg2} />
          <MerchantInfo>
            <MerchantLogoContainer>
              <MerchantLogo
                merchantID={order.merchantID}
                width="4.44rem"
                height="4.44rem"
              />
            </MerchantLogoContainer>
            <MerchantDetails>
              <EarnedText>you&#39;ve purchased</EarnedText>
              <Spacer height="6px" />
              <MilesValue
                fontSize="1.77rem"
                miles={milesEarned}
                isSigned={false}
              />
              <Spacer height="6px" />
              <FromMerchantText>
                from <b>Divit</b>
              </FromMerchantText>
            </MerchantDetails>
          </MerchantInfo>
          <Spacer height={globalStyles.px.lg} />
          <BalanceContainer>
            <BalanceTitle>current balance is:&nbsp;</BalanceTitle>
            <Balance>
              <MilesValue
                fontSize="1.2rem"
                miles={balance.balance * 100}
                isSigned={false}
              />
            </Balance>
          </BalanceContainer>
          <Spacer height={globalStyles.px.footer} />
        </PageContent>
      </Content>
      <Footer>
        <HomeButton type="bw" bordered onClick={onClickHome}>
          home
        </HomeButton>
        <Spacer height={globalStyles.px.lg2} />
        <PoweredByDivit />
        <Spacer height={globalStyles.px.lg2} />
      </Footer>
    </Container>
  )
}

export default TopupSuccess

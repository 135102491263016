import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const requestPaymentSlice = createSlice({
  name: 'requestPayment',
  initialState: {
    requestPayment: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.requestPayment = AsyncState.reset()
    },
    requestPaymentStart: (state) => {
      state.requestPayment = AsyncState.start()
    },
    requestPaymentSuccess: (state, action) => {
      state.requestPayment = AsyncState.success(action.payload)
    },
    requestPaymentFailed: (state, action) => {
      state.requestPayment = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = requestPaymentSlice

export default requestPaymentSlice.reducer

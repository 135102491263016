import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as ExpandSvg } from '@/assets/history/expand.svg'
import { ReactComponent as LockSvg } from '@/assets/user/lock.svg'
import { ReactComponent as UserSvg } from '@/assets/user/user.svg'
import EditUserModal from '@/components/EditUserModal'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import InviteUserModal from '@/components/InviteUserModal'
import LoadingModal from '@/components/LoadingModal'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import useModal from '@/hooks/useModal'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const ActionHeader = styled.div`
  display: flex;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const TitleGroup = styled.div``

const Action = styled.div`
  display: flex;
  margin-left: auto;
`

const ActionContent = styled.div`
  display: flex;
  font-size: 0.77rem;
  margin-top: 1.5rem;
  margin-left: auto;
  border: 2px solid #dedede;
  background: transparent;
  padding: 0.3rem 1.33rem;
  border-radius: 32px;
  height: 2rem;
`

const ActionIcon = styled(Icon)`
  margin-left: 0.5rem;
`

const LockIcon = styled(Icon)`
  margin-right: 0.5rem;
  stroke: red;
`

const Content = styled.div`
  background: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const UserList = styled.div``

const ItemHeader = styled.div`
  padding: 0 1.333rem;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
`

const Item = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`

const Name = styled.div`
  display: flex;
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const LockedName = styled.div`
  color: #aaa;
`

const Email = styled.div`
  font-size: 0.75rem;
  color: #aaa;
`

const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`

const NoOne = styled.div`
  font-size: 0.77rem;
  text-align: center;
  margin-top: 1.33rem;
`

const Role = styled.div`
  font-size: 0.66rem;
  border: 2px solid #ffcc33;
  background: transparent;
  padding: 0.15rem 0.6rem;
  border-radius: 16px;
  color: #ffcc33;
`

const SetupTeam = () => {
  const dispatch = useDispatch()
  const inviteUserModal = useModal()
  const editUserModal = useModal()
  const { inviteUser, getUserList, editUser } = useSelector((s) => s.setupTeam)
  const { contactID } = useSelector((s) => s.profile.profile)
  const [user, setUser] = useState(null)
  const [hasLockedUser, setHasLockedUser] = useState(false)
  const [activeTab, setActiveTab] = useState('activeuser')

  useEffect(() => {
    dispatch({
      type: 'setupTeam/getUserList',
    })

    return () => dispatch({ type: 'setupTeam/reset' })
  }, [])

  useEffect(() => {
    if (inviteUser.status === 'success') inviteUserModal.onCloseModal()
  }, [inviteUser])

  useEffect(() => {
    if (user) {
      editUserModal.openModal()
    }
  }, [user])

  const onClickSendInvitation = async (data) => {
    dispatch({
      type: 'setupTeam/inviteUser',
      payload: {
        ...data,
        source: 'business',
      },
    })
  }

  const onClickInviteUserButton = () => inviteUserModal.openModal()

  const onClickEditUser = (u) => {
    if (contactID === u.contactID) {
      return
    }
    setUser(u)
  }
  const onCloseEditUser = () => {
    setUser(null)
    editUserModal.onCloseModal()
  }
  const onClickUpdateUser = (data) => {
    if (user) {
      dispatch({
        type: 'setupTeam/updateUser',
        payload: data,
      })
    }
  }

  useEffect(() => {
    if (editUser.status === 'success') onCloseEditUser()
  }, [editUser])

  const isUserLocked = (u) =>
    u?.user?.status === 'locked' ||
    u?.user?.status === 'inactive' ||
    u?.status === 'inactive'

  useEffect(() => {
    const data = getUserList?.data || []
    setHasLockedUser(false)
    if (data.length > 0) {
      data.forEach((u) => {
        if (isUserLocked(u)) {
          setHasLockedUser(true)
        }
      })
    }
  }, [getUserList])

  if (!getUserList?.data) return <></>

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <ActionHeader>
          <TitleGroup>
            <Title>setup team</Title>
            <Spacer height={globalStyles.px.xs} />
            <SubTitle>invite someone to join your team</SubTitle>
          </TitleGroup>
          <Action onClick={onClickInviteUserButton}>
            <ActionContent>
              invite{' '}
              <ActionIcon
                renderImage={() => <ExpandSvg />}
                widht="1.111rem"
                height="1.111rem"
              />
            </ActionContent>
          </Action>
        </ActionHeader>
        <Spacer height={globalStyles.px.xs} />

        {inviteUser?.inviteUser?.error && (
          <>
            <ErrorMessageLine errorMessage={getUserList?.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <SelectorTabs
          defaultTab={activeTab}
          tabs={[
            {
              key: 'activeuser',
              label: 'active user',
              Icon: UserSvg,
            },
            { key: 'lockeduser', label: 'locked user', Icon: LockSvg },
          ]}
          onChange={(tab) => setActiveTab(tab.key)}
        />
        <Content>
          <Tab isActive={activeTab === 'activeuser'}>
            <UserList>
              {getUserList?.data &&
                getUserList?.data
                  ?.filter(
                    (u) =>
                      u?.user?.userRole !== 'partnerapi' && !isUserLocked(u)
                  )
                  ?.map((u) => (
                    <ItemHeader
                      key={u.contactID}
                      onClick={() => onClickEditUser(u)}
                    >
                      <Item>
                        <NameWrapper>
                          <Name>
                            {u?.firstName} {u?.lastName} &nbsp;{' '}
                            {u.contactID === contactID ? (
                              <LockedName>(You)</LockedName>
                            ) : (
                              ''
                            )}
                          </Name>
                          {u?.user?.userRole === 'partneradmin' && (
                            <Role>admin</Role>
                          )}
                        </NameWrapper>
                        <Email>{u?.email}</Email>
                      </Item>
                    </ItemHeader>
                  ))}
            </UserList>
          </Tab>
          <Tab isActive={activeTab === 'lockeduser'}>
            <UserList>
              {hasLockedUser &&
                getUserList?.data &&
                getUserList?.data
                  ?.filter(
                    (u) => u?.user?.userRole !== 'partnerapi' && isUserLocked(u)
                  )
                  ?.map((u) => (
                    <ItemHeader
                      key={u.userID}
                      onClick={() => onClickEditUser(u)}
                    >
                      <Item>
                        <NameWrapper>
                          <Name>
                            <LockIcon
                              renderImage={() => <LockSvg />}
                              widht="1.111rem"
                              height="1.111rem"
                            />
                            {u?.firstName} {u?.lastName} &nbsp;{' '}
                            {u.contactID === contactID ? (
                              <LockedName>(You)</LockedName>
                            ) : (
                              ''
                            )}
                          </Name>
                          {u?.user?.userRole === 'partneradmin' && (
                            <Role>admin</Role>
                          )}
                        </NameWrapper>
                        <Email>{u?.email}</Email>
                      </Item>
                    </ItemHeader>
                  ))}
              {!hasLockedUser && <NoOne>{`no one's here`}</NoOne>}
            </UserList>
          </Tab>
          <Spacer height={globalStyles.px.lg} />
        </Content>

        <InviteUserModal
          isOpen={inviteUserModal.isOpen}
          onClose={inviteUserModal.onCloseModal}
          onCleanup={inviteUserModal.onCleanUpModal}
          onClickSendInvitation={onClickSendInvitation}
          isLoading={inviteUser?.isLoading}
          error={inviteUser?.error}
        />
        <EditUserModal
          user={user}
          isOpen={editUserModal.isOpen}
          onClose={onCloseEditUser}
          onCleanup={editUserModal.onCleanUpModal}
          onClickUpdate={onClickUpdateUser}
          isLoading={editUser?.isLoading}
          error={editUser?.error}
        />
        <LoadingModal loading={getUserList?.isLoading} />
        <Spacer height={globalStyles.px.lg2} />
      </PageContent>
    </Container>
  )
}

export default SetupTeam

import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'

import { setApiStore } from '@/services/api'

import appReducer from './app/appSlice'
import authReducer from './auth/authSlice'
import divitMilesSlice from './divitMiles/divitMilesSlice'
import flashMessageSlice from './flashMessage/flashMessageSlice'
import lookupSlice from './lookup/lookupSlice'
import modalReducer from './modal/modalSlice'
import orderReducer from './order/orderSlice'
import batchOrderReducer from './pages/batchOrderSlice'
import batchSendMilesReducer from './pages/batchSendMilesSlice'
import changePasswordReducer from './pages/changePasswordSlice'
import claimDivitMilesReducer from './pages/claimDivitMilesSlice'
import createPaymentReducer from './pages/createPaymentSlice'
import invoiceRecordReducer from './pages/invoiceRecordSlice'
import milesTransactionsReducer from './pages/milesTransactionsSlice'
import pageInitReducer from './pages/pageInitSlice'
import paynowFastFpsReducer from './pages/paynowFastFpsSlice'
import requestPaymentReducer from './pages/requestPaymentSlice'
import resetPasswordReducer from './pages/resetPasswordSlice'
import sendMilesReducer from './pages/sendMilesSlice'
import setupTeamReducer from './pages/setupTeamSlice'
import topupMilesReducer from './pages/topupMilesSlice'
import transactionHistoryReducer from './pages/transactionHistorySlice'
import pinReducer from './pin/pinSlice'
import profileReducer from './profile/profileSlice'
import saga from './saga'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger()
const middleware = [
  thunk,
  sagaMiddleware,
  process.env.REACT_APP_ENV !== 'prod' ? loggerMiddleware : null,
  routerMiddleware(history),
].filter((m) => m)

const store = configureStore({
  reducer: {
    router: connectRouter(history),
    app: appReducer,
    modal: modalReducer,
    auth: authReducer,
    order: orderReducer,
    profile: profileReducer,
    pin: pinReducer,
    flashMessage: flashMessageSlice,
    lookup: lookupSlice,
    divitMiles: divitMilesSlice,
    // pages
    pageInit: pageInitReducer,
    createPayment: createPaymentReducer,
    requestPayment: requestPaymentReducer,
    paynowFastFps: paynowFastFpsReducer,
    claimDivitMiles: claimDivitMilesReducer,
    invoiceRecord: invoiceRecordReducer,
    topupMiles: topupMilesReducer,
    sendMiles: sendMilesReducer,
    milesTransactions: milesTransactionsReducer,
    transactionHistory: transactionHistoryReducer,
    changePassword: changePasswordReducer,
    resetPassword: resetPasswordReducer,
    setupTeam: setupTeamReducer,
    batchOrder: batchOrderReducer,
    batchSendMiles: batchSendMilesReducer,
  },
  middleware,
})

sagaMiddleware.run(saga)

setApiStore(store)

export default store

import React, { useEffect, useState } from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as CheckedSvg } from '@/assets/history/checked.svg'
import { ReactComponent as UnCheckSvg } from '@/assets/history/uncheck.svg'
import Button from '@/components/common/Button'
import SelectField from '@/components/common/SelectField'
import Icon from '@/components/Icon'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import Toggle from '@/components/ui/Toggle'
import globalStyles from '@/constants/globalStyles'
import { promptConfirmModal } from '@/redux/modal/modalActions'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const TitleGroup = styled.div``

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
`

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SelectDefaultContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ContainerText = styled.div`
  flex: 1;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.778rem;
`

const Form = styled.div`
  background: white;
  padding: 1.33rem;
  border-radius: 16px;
`
const CcEmail = styled(ReactMultiEmail)`
  display: ${(props) => props.shown};
  margin-top: 0.5rem;
  font-size: 0.78rem;
  background: transparent;
  padding: 0.778rem 1.333rem;
  &.empty > span[data-placeholder] {
    color: #989898;
  }
  &.focused {
    border-color: #ffcc33;
  }
  & > span[data-placeholder] {
    position: unset;
    padding: 0;
  }
  & > input {
    background: transparent;
    padding: 0 0rem !important;
  }
`
const RemoveEmail = styled.span``

const ConfigSection = styled.div``
const ConfigOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
`

const Question = styled.div`
  font-size: 0.75rem;
  padding-top: 0.33rem;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding: 0.889rem 0;
  cursor: pointer;
`

const ItemContent = styled.div`
  display: flex;
`

const DefaultSwitchContent = styled.div`
  display: flex;
  margin-right: 1.33rem;
`

const ItemName = styled.div`
  font-size: 0.778rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const SaveButton = styled(Button)``

const CampaignOption = ({
  data,
  isSelected,
  isDefault,
  onClickSelect,
  onClickDefault,
}) => {
  const SelectIcon = isSelected ? CheckedSvg : UnCheckSvg
  const DefaultIcon = isDefault ? CheckedSvg : UnCheckSvg

  const onClickSelectItem = () => {
    onClickSelect(data, false)
  }

  const onClickDefaultItem = () => {
    onClickDefault(data)
  }

  return (
    <ListItem>
      <ItemContent onClick={onClickSelectItem}>
        <Icon
          renderImage={() => <SelectIcon />}
          widht="1.111rem"
          height="1.111rem"
        />
        <Spacer width={globalStyles.px.xs} />
        <ItemName>{data.label}</ItemName>
      </ItemContent>
      <DefaultSwitchContent onClick={onClickDefaultItem}>
        <Icon
          renderImage={() => <DefaultIcon />}
          widht="1.111rem"
          height="1.111rem"
        />
      </DefaultSwitchContent>
    </ListItem>
  )
}

const Settings = () => {
  const dispatch = useDispatch()

  const [campaignOptions, setCampaignOptions] = useState([])
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [defaultItem, setDefaultItem] = useState(null)
  const [hideCost, setHideCost] = useState(false)
  const [invoiceExpiry, setInvoiceExpiry] = useState('7_days')
  const [defaultCcList, setDefaultCcList] = useState([])

  const profile = useSelector((s) => s.profile)
  const campaigns = useSelector((s) => s.profile.campaigns)
  const configs = useSelector(
    (s) => s.profile.profile?.settings?.configSettings || {}
  )

  const items = {
    hideCost: {
      key: 'hideCost',
      setFn: setHideCost,
    },
  }
  useEffect(
    () => () => dispatch({ type: 'profile/patchProfileConfigReset' }),
    []
  )
  useEffect(() => {
    const options = campaigns
      .filter((c) => c.status === 'active')
      .map((c) => ({ label: c.title, value: c.promoCode }))
    setCampaignOptions(options)
  }, [campaigns])

  useEffect(() => {
    if (!configs) return

    if (configs.invoiceExpiry) {
      setInvoiceExpiry(configs.invoiceExpiry)
    }
    if (configs.defaultCcList) {
      setDefaultCcList(configs.defaultCcList)
    }
    if (configs.defaultCampaign) {
      setDefaultItem(configs.defaultCampaign)
    }
    if ((configs.campaignList || []).length > 0) {
      setSelectedItems(configs.campaignList)
      if (configs.campaignList.length === campaignOptions.length) {
        setIsSelectedAll(true)
      } else {
        setIsSelectedAll(false)
      }
    }
    if (configs.hideCost) {
      setHideCost(true)
    }
  }, [configs])

  useEffect(() => {
    if (profile.isSuccess) {
      dispatch(
        promptConfirmModal({
          title: 'update',
          content: 'settings has been updated successfully',
          okText: 'done',
          closable: false,
        })
      )
    }
  }, [profile])

  const onToggleSelectAll = () => {
    const newState = !isSelectedAll
    setIsSelectedAll(newState)
    if (newState) {
      setSelectedItems(campaignOptions.map((c) => c.value))
    } else {
      setSelectedItems([defaultItem])
    }
  }

  const onClickItem = (item, force) => {
    let newState = []
    const index = selectedItems.indexOf(item.value)
    if (index >= 0) {
      if (!force) newState = selectedItems.filter((x) => x !== item.value)
      else newState = selectedItems
    } else {
      newState = selectedItems.concat(item.value)
    }
    if (newState.length === campaignOptions.length) {
      setIsSelectedAll(true)
    } else {
      setIsSelectedAll(false)
    }
    setSelectedItems(newState)
  }

  const onClickDefault = (item) => {
    setDefaultItem(item.value)
    onClickItem(item, true)
  }

  const onClickToggle =
    ({ key, setFn }) =>
    (v) => {
      setFn(v)
    }

  const onClickSave = () => {
    const settings = {
      defaultCampaign: defaultItem,
      campaignList: selectedItems,
      invoiceExpiry,
      hideCost,
      defaultCcList,
    }
    const merchantID = profile?.profile?.merchantID
    const branchID = profile?.profile?.branchID

    dispatch({
      type: 'patchProfileConfig',
      payload: { merchantID, branchID, settings },
    })
  }

  const SelectIcon = isSelectedAll ? CheckedSvg : UnCheckSvg

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <TitleGroup>
          <Title>settings</Title>
        </TitleGroup>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>configure the invoice payment order</SubTitle>
        <Spacer height={globalStyles.px.xs} />
        <Form>
          <ConfigSection>
            <Question>default invoice expiry period:</Question>
            <SelectField
              value={invoiceExpiry}
              options={[
                { label: `1 day`, value: '1_day' },
                { label: `3 days`, value: '3_days' },
                { label: `7 days`, value: '7_days' },
                { label: `14 days`, value: '14_days' },
                { label: `30 days`, value: '30_days' },
                { label: `60 days`, value: '60_days' },
                { label: `90 days`, value: '90_days' },
              ]}
              onChange={(v) => setInvoiceExpiry(v)}
            />
          </ConfigSection>
          <ConfigSection>
            <Question>payment request will be cc to:</Question>
            <CcEmail
              placeholder="default cc email to"
              emails={defaultCcList}
              onChange={(_emails) => {
                setDefaultCcList(_emails)
              }}
              getLabel={(email, index, removeEmail) => (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <RemoveEmail
                    data-tag-handle
                    onClick={() => removeEmail(index)}
                  >
                    ×
                  </RemoveEmail>
                </div>
              )}
            />
          </ConfigSection>
        </Form>
        <Spacer height={globalStyles.px.xs} />
        {/* TODO: uncomment the following code */}
        {/* <SubTitle>configure your reward options</SubTitle>
        <Spacer height={globalStyles.px.xs} />
        <Form>
          <ConfigSection>
            <Controls>
              <SelectAllContainer onClick={onToggleSelectAll}>
                <Icon
                  renderImage={() => <SelectIcon />}
                  widht="1.111rem"
                  height="1.111rem"
                />
                <Spacer width="16px" />
                <ContainerText>
                  {isSelectedAll ? 'only default' : 'all available'}
                </ContainerText>
              </SelectAllContainer>
              <SelectDefaultContainer>
                <ContainerText>select default</ContainerText>
              </SelectDefaultContainer>
            </Controls>
            {campaignOptions &&
              campaignOptions?.map((campaign) => (
                <CampaignOption
                  key={campaign.value}
                  data={campaign}
                  isSelected={selectedItems.indexOf(campaign.value) >= 0}
                  isDefault={defaultItem === campaign.value}
                  onClickSelect={onClickItem}
                  onClickDefault={onClickDefault}
                />
              ))}
          </ConfigSection>
          <ConfigSection>
            <ConfigOption>
              <Question>hide reward cost?</Question>
              <Toggle
                checked={hideCost}
                onChange={onClickToggle(items.hideCost)}
              />
            </ConfigOption>
          </ConfigSection>
        </Form>
        <Spacer height={globalStyles.px.xs} /> */}
        <Buttons>
          <SaveButton type="main" onClick={onClickSave}>
            save
          </SaveButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
    </Container>
  )
}

export default Settings

import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as FacebookLoginIconSvg } from '@/assets/socials/signin/facebook.svg'
import { ReactComponent as GoogleLoginIconSvg } from '@/assets/socials/signin/google.svg'
import Spacer from '@/components/Spacer'

import LoginFacebook from './LoginFacebook'
import LoginGoogle from './LoginGoogle'

const SocialMedia = styled.div``

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Google = styled.div`
  flex: 1;
  height: 2.66rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 0.77rem;
  font-weight: 600;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);

  &:hover {
    cursor: pointer;
  }
`

const GoogleLoginIcon = styled(GoogleLoginIconSvg)`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.88rem;
`

const Facebook = styled.div`
  flex: 1;
  height: 2.66rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 0.77rem;
  font-weight: 600;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);

  &:hover {
    cursor: pointer;
  }
`

const FacebookLoginIcon = styled(FacebookLoginIconSvg)`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.88rem;
`

const SocialMediaAuth = ({
  isShowGoogle,
  isShowFacebook,
  onClickGoogleLogin,
  onGoogleSuccess,
  onGoogleFailure,
  onClickFacebookLogin,
  onFacebookSuccess,
  onFacebookFailure,
}) => {
  const intl = useIntl()

  const doClickGoogleLogin = (onClick) => () => {
    const result = onClickGoogleLogin ? onClickGoogleLogin() : true
    if (result) {
      onClick()
    }
  }

  const doClickFacebookLogin = (onClick) => () => {
    const result = onClickFacebookLogin ? onClickFacebookLogin() : true
    if (result) {
      onClick()
    }
  }

  return (
    <SocialMedia>
      <Row>
        {isShowFacebook && (
          <LoginFacebook
            onSuccess={onFacebookSuccess}
            onFailure={onFacebookFailure}
            render={({ onClick }) => (
              <Facebook type="stretch" onClick={doClickFacebookLogin(onClick)}>
                <FacebookLoginIcon />
                <span>
                  {intl.formatMessage({ id: 'login.signin.facebook.title' })}
                </span>
              </Facebook>
            )}
          />
        )}
        {isShowFacebook && isShowGoogle && <Spacer width="0.88rem" />}
        {isShowGoogle && (
          <LoginGoogle
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            render={({ onClick }) => (
              <Google type="stretch" onClick={doClickGoogleLogin(onClick)}>
                <GoogleLoginIcon />
                <span>
                  {intl.formatMessage({ id: 'login.signin.google.title' })}
                </span>
              </Google>
            )}
          />
        )}
      </Row>
    </SocialMedia>
  )
}

SocialMediaAuth.propTypes = {
  onClickGoogleLogin: PropTypes.func,
  onGoogleSuccess: PropTypes.func.isRequired,
  onGoogleFailure: PropTypes.func.isRequired,
  onClickFacebookLogin: PropTypes.func,
  onFacebookSuccess: PropTypes.func.isRequired,
  onFacebookFailure: PropTypes.func.isRequired,
}

SocialMediaAuth.defaultProps = {
  onClickGoogleLogin: () => {},
  onClickFacebookLogin: () => {},
}

export default SocialMediaAuth

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const ChangePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    changePassword: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.changePassword = AsyncState.reset()
    },
    changePasswordStart: (state) => {
      state.changePassword = AsyncState.start()
    },
    changePasswordSuccess: (state, action) => {
      state.changePassword = AsyncState.success(action.payload)
    },
    changePasswordFailed: (state, action) => {
      state.changePassword = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = ChangePasswordSlice

export default ChangePasswordSlice.reducer

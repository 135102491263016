import React from 'react'

const HomeMiniOutlineIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} {...props}>
    <path
      fill=''
      fillRule='evenodd'
      d='M9.765 2.93c-.082.053-1.846 1.796-3.921 3.875-4.156 4.163-3.888 3.866-3.802 4.209.073.288.112.301.925.313l.696.01.012 2.857c.014 3.184.001 3.003.251 3.489.17.33.604.758.941.926.465.233.23.223 5.133.223s4.668.01 5.133-.223c.337-.168.771-.596.941-.926.25-.486.237-.305.251-3.491l.012-2.859h.635c.717 0 .806-.021.947-.229.127-.187.112-.36-.049-.559-.226-.281-7.582-7.603-7.693-7.658-.153-.076-.244-.067-.412.043m3.402 4.287 3.15 3.15h-.334c-.361 0-.499.048-.589.206-.035.062-.047.716-.061 3.26l-.016 3.184-.082.166a1.39 1.39 0 0 1-.594.577c-.186.088-.21.09-.914.101l-.723.011-.013-2.444c-.011-2.155-.019-2.466-.067-2.628-.196-.651-.701-1.219-1.289-1.449-.328-.129-.569-.151-1.643-.151-1.17 0-1.389.027-1.788.223-.354.174-.776.593-.949.944-.244.495-.232.346-.246 3.061l-.013 2.444-.723-.011c-.704-.011-.728-.013-.914-.101a1.39 1.39 0 0 1-.594-.577l-.082-.166-.016-3.184c-.014-2.544-.026-3.198-.061-3.26-.09-.158-.228-.206-.589-.206h-.334l3.15-3.15A427.316 427.316 0 0 1 10 4.067c.009 0 1.434 1.417 3.167 3.15m-2.094 4.987c.098.021.236.067.308.104.174.088.416.342.519.544l.083.165.01 2.425.009 2.425H7.998l.009-2.425.01-2.425.083-.165c.098-.193.341-.453.502-.537.233-.122.424-.143 1.371-.145.568-.002.992.011 1.1.034'
    />
  </svg>
)

export default HomeMiniOutlineIcon

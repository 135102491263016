// src/assets/styles/theme.tsx

import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Red Hat Display',
      '-apple-system',
      'Noto Sans',
      'Helvetica Neue',
      'Helvetica',
      'Nimbus Sans L',
      'Arial',
      'Liberation Sans',
      'PingFang SC',
      'Hiragino Sans GB',
      'Noto Sans CJK SC',
      'Source Han Sans SC',
      'Source Han Sans CN',
      'Microsoft YaHei',
      'Wenquanyi Micro Hei',
      'WenQuanYi Zen Hei',
      'ST Heiti',
      'SimHei',
      'WenQuanYi Zen Hei Sharp',
      'sans-serif',
    ].join(','),
  },

  shape: {
    borderRadius: 5,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPaginationItem-root': {
            fontWeight: 500,
            '&.Mui-selected': {
              background: '#FFCC3326',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 'inherit',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
              margin: 0,
              minHeight: 'inherit',
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0',
          boxShadow: 'inset 0 0.5rem 10px #fafafa',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontSize: '1rem',
          fontWeight: 700,
          textTransform: 'lowercase',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            '&:hover': {
              backgroundColor: 'rgb(255 251 235)',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#FFF7E8',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-expanded': {
            marginTop: '0px',
            marginBottom: '0px',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
              margin: 0,
              minHeight: 'inherit',
            },
          },
        },
      },
    },
  },
})

export default theme

import React from 'react'
import styled from 'styled-components'

import { ReactComponent as PinErrorSvg } from '@/assets/pin/pin-error.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
  text-align: center;
`

const PinError = () => {
  const title = 'you’ve been logged out'
  const subTitle =
    'as you’ve entered the wrong PIN for 5 times, log in again to resume session'

  return (
    <Container>
      <Icon
        renderImage={() => <PinErrorSvg />}
        width="1.74rme"
        height="1.5rem"
      />
      <Spacer height="0.889rem" />
      <Title>{title}</Title>
      <Spacer height="0.889rem" />
      <SubTitle>{subTitle}</SubTitle>
    </Container>
  )
}

export default PinError

import { useDebounceEffect } from 'ahooks'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as ExpandIconSvg } from '@/assets/common/down-arrow.svg'
import Button from '@/components/common/Button'
import DateSelectField, {
  getDefaultDateValue,
} from '@/components/common/DateSelectField'
import Row from '@/components/common/Row'
import SearchInputField from '@/components/common/SearchInputField'
import EditInvoiceOrderModal from '@/components/EditInvoiceOrderModal'
import EmptyResult from '@/components/EmptyResult'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingSpinner from '@/components/LoadingSpinner'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import useModal from '@/hooks/useModal'
import { FormattedPrice } from '@/utils/Price'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Content = styled.div`
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`

const ErrorContainer = styled.div`
  padding: 0 1.333rem;
`

const Filters = styled.div`
  padding: 0.889rem 1.333rem;
`

const LoadingContainer = styled.div``

const Footer = styled.div`
  width: 100%;
  height: 1.333rem;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const RecordList = styled.div`
  background-color: white;
  padding: 0 1.333rem;
`

const Record = styled.div`
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`
const InvoiceDate = styled.div``
const LastUpdateDate = styled.div`
  text-align: right;
`

const MerchantRefLabel = styled.span`
  color: #ccc;
`
const MerchantRef = styled.div`
  margin-top: 0.889rem;
  font-size: 0.889rem;
  font-weight: 600;
`
const InvoiceTotal = styled.div`
  margin-top: 0.889rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: right;
`
const Customer = styled.div`
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #888;
`
const Tel = styled.div`
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #888;
  text-align: right;
`
const FooterRow = styled(Row)`
  font-size: 0.6rem;
  color: #ccc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
const MessageContent = styled.div`
  font-size: 0.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: auto;
  max-height: 200px;

  > pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
  }
`
const TotalCount = styled.div`
  font-size: 0.6rem;
  color: #888;
  padding: 0.5rem;
  text-align: right;
`
const LoadMoreButton = styled(Button)``

const ActiveStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #ffcc33;
  background: transparent;
  padding: 0.15rem 0.6rem;
  border-radius: 16px;
  color: #ffcc33;
  flex: 0;
  cursor: pointer;

  svg {
    path {
      stroke: #ffcc33 !important;
    }
  }
`
const ExpireStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #ddd;
  background: transparent;
  padding: 0.15rem 0.6rem;
  border-radius: 16px;
  color: #ddd;
  flex: 0;
  cursor: pointer;

  svg {
    path {
      stroke: #ddd !important;
    }
  }
`
const ExpandArrow = styled(Icon).attrs({
  renderImage: () => <ExpandIconSvg />,
})``
const CompleteStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #00cc00;
  background: transparent;
  padding: 0.15rem 0.6rem;
  border-radius: 16px;
  color: #00cc00;
  flex: 0;
`
const getStatus = (orderID, status, expiredAt, onClickOrder) => {
  if (status === 'completed') {
    return <CompleteStatus>paid</CompleteStatus>
  }
  if (status === 'cancelled') {
    return <ExpireStatus>{status}</ExpireStatus>
  }
  if (status === 'new' || status === 'active') {
    return (
      <ActiveStatus onClick={() => onClickOrder(orderID, expiredAt)}>
        <Row>
          {status} <ExpandArrow />
        </Row>
      </ActiveStatus>
    )
  }
  return (
    <ExpireStatus onClick={() => onClickOrder(orderID, expiredAt)}>
      <Row>
        {status} <ExpandArrow />
      </Row>
    </ExpireStatus>
  )
}

const InvoiceRecordList = ({ items, onClickOrder }) =>
  items && (
    <RecordList>
      {items.map((order) => {
        const amount = FormattedPrice(
          order.orderAmount?.currency,
          order.orderAmount?.amount
        )
        return (
          <Record key={order.orderID}>
            <Row>
              <MerchantRef>
                <MerchantRefLabel>Ref: </MerchantRefLabel> {order.merchantRef}
              </MerchantRef>
              <InvoiceTotal>{amount}</InvoiceTotal>
            </Row>
            <Row>
              <Customer>{`${order.customer?.customerInfo?.firstName} ${order.customer?.customerInfo?.lastName} <${order.customer?.customerInfo?.email}>`}</Customer>
              <Tel>tel: {order.customer?.customerInfo?.tel}</Tel>
            </Row>
            {order.metadata?.messageContent && (
              <Row>
                <MessageContent>
                  <pre>{order.metadata?.messageContent}</pre>
                </MessageContent>
              </Row>
            )}
            <FooterRow>
              <InvoiceDate>
                created at: {moment(order.createdAt).calendar()}
              </InvoiceDate>
              <LastUpdateDate>
                last updated: {moment(order.updatedAt).calendar()}
              </LastUpdateDate>
              {getStatus(
                order.orderID,
                order.status,
                (order.metadata?.expiredAt || 0) > moment().unix()
                  ? order.metadata?.expiredAt
                  : 0,
                onClickOrder
              )}
            </FooterRow>
          </Record>
        )
      })}
    </RecordList>
  )

const InvoiceRecords = () => {
  const editOrderModal = useModal()
  const dispatch = useDispatch()

  const { getInvoiceRecordList, totalCount, invoiceOrder } = useSelector(
    (s) => s.invoiceRecord
  )

  const { data: invoiceList, isLoading } = getInvoiceRecordList

  const [dateValue, setDateValue] = useState(() => getDefaultDateValue())
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(0)
  const [orderID, setOrderID] = useState(null)
  const [expiredAt, setExpiredAt] = useState(0)

  const searchRecord = (p) => {
    const keyword = searchText.trim()
    dispatch({
      type: 'invoiceRecord/getInvoiceRecordList',
      payload: {
        fromDate: dateValue.fromDate,
        toDate: dateValue.toDate,
        keyword,
        page: p,
      },
    })
  }
  const onClickLoadMore = () => {
    if (totalCount > (page + 1) * 10) {
      setPage(page + 1)
    }
  }
  const onClickEditOrder = (oid, exp) => {
    setOrderID(oid)
    setExpiredAt(exp)
  }
  const onCloseEditOrder = () => {
    setOrderID(null)
    setExpiredAt(0)
    editOrderModal.onCloseModal()
  }
  const onClickCancelOrder = (data) => {
    dispatch({
      type: 'invoiceRecord/cancelOrder',
      payload: data,
    })
  }
  const onClickExtendOrder = (data) => {
    dispatch({
      type: 'invoiceRecord/extendOrder',
      payload: data,
    })
  }

  useEffect(() => {
    if (orderID) {
      editOrderModal.openModal()
    }
  }, [orderID])

  useEffect(() => {
    if (invoiceOrder.status === 'success') {
      onCloseEditOrder()
      searchRecord(page)
    }
  }, [invoiceOrder])

  useDebounceEffect(() => {
    setPage(0)
    searchRecord(0)
  }, [dateValue, searchText])

  useDebounceEffect(() => {
    if (page === 0) return
    searchRecord(page)
  }, [page])

  const isEmptyResult = !isLoading && invoiceList?.length === 0
  const loadMore = !isLoading && totalCount > (page + 1) * 10

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>invoice records</Title>
        <Spacer height={globalStyles.px.lg1} />
        <Container>
          <Content>
            {getInvoiceRecordList.error && (
              <ErrorContainer>
                <Spacer height={globalStyles.px.xs} />
                <ErrorMessageLine errorMessage={getInvoiceRecordList.error} />
              </ErrorContainer>
            )}
            <Filters>
              <DateSelectField
                value={dateValue}
                onChange={setDateValue}
                disabled={isLoading}
              />
              <Spacer height={globalStyles.px.xs} />
              <SearchInputField
                value={searchText}
                placeholder="search"
                onClickSuffix={() => setSearchText('')}
                onChange={(e) => setSearchText(e.target.value)}
                disabled={isLoading}
              />
            </Filters>
            {isLoading && (
              <LoadingContainer>
                <Spacer height={globalStyles.px.xs} />
                <LoadingSpinner />
              </LoadingContainer>
            )}
            {isEmptyResult && <EmptyResult />}
            {!isEmptyResult && (
              <InvoiceRecordList
                items={invoiceList}
                onClickOrder={onClickEditOrder}
              />
            )}
          </Content>
          <Footer />
          {invoiceList && invoiceList?.length > 0 && (
            <>
              <Row>
                <Spacer />
                <TotalCount>{`items ${invoiceList.length}/${totalCount}`}</TotalCount>
              </Row>
              <Row>
                {loadMore && (
                  <LoadMoreButton type="bw" onClick={onClickLoadMore}>
                    more...
                  </LoadMoreButton>
                )}
              </Row>
            </>
          )}
        </Container>

        <EditInvoiceOrderModal
          orderID={orderID}
          expiredAt={expiredAt}
          isOpen={editOrderModal.isOpen}
          onClose={onCloseEditOrder}
          onCleanup={editOrderModal.onCleanUpModal}
          onClickExtend={onClickExtendOrder}
          onClickCancel={onClickCancelOrder}
          isLoading={invoiceOrder?.isLoading}
          error={invoiceOrder?.error}
        />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
    </Container>
  )
}

export default InvoiceRecords

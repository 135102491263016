export default {
  px: {
    header: '3.555rem', // 64px
    footer: '3.555rem', // 64px
    lg2: '2.222rem', // 40px
    lg: '1.778rem', // 32px
    md: '1.333rem', // 24px
    xs: '0.889rem', // 16px
  },
}

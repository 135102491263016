import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as BackSpaceSvg } from '@/assets/common/backspace.svg'
import Button from '@/components/common/Button'
import InputError from '@/components/common/InputError'
import Row from '@/components/common/Row'
import SelectField from '@/components/common/SelectField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import MilesValue from '@/components/miles/MilesValue'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import {
  profileConfigSettings,
  roleSelector,
  tokenBooksSelector,
} from '@/redux/profile/profileSelector'
import scrollToTop from '@/utils/scrollToTop'
import * as SessionStorage from '@/utils/sessionStorage'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
`
const BookOptionItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
const BookOptionItemTitle = styled.div`
  margin-left: 10px;
  color: #888;
`
const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 3rem;
  text-align: right;
  border: none;
  width: 100%;
  flex: unset;
`
const AmountSuffix = styled.div`
  max-width: 45px;
`
const BackSpace = styled(Icon)`
  fill: #979797;
`
const Currency = styled.div``
const RowRight = styled(Row)`
  text-align: right;
  padding-bottom: 0.5rem;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const FieldWrapper = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const FieldTitle = styled.div`
  font-size: 0.77rem;
  margin-bottom: 0.44rem;
`
const CcEmail = styled(ReactMultiEmail)`
  display: ${(props) => props.shown};
  margin-top: 0.5rem;
  font-size: 0.78rem;
  background: transparent;
  padding: 0.778rem 1.333rem;
  &.empty > span[data-placeholder] {
    color: #989898;
  }
  & > span[data-placeholder] {
    position: unset;
    padding: 0;
  }
  & > input {
    background: transparent;
    padding: 0 0rem !important;
  }
  &:hover {
    border-color: #f7ce55;
  }
  &.focused {
    border-color: #f7ce55;
  }
`

const MessageContent = styled.textarea`
  background-color: transparent;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 8px;
  padding: 0.778rem 1.333rem;
  outline: none;
  margin-bottom: 0.44rem;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`
const CenterContent = styled.div`
  text-align: center;
`

const ResetButton = styled(Button)``

const PurchaseButton = styled(Button)``

const SendButton = styled(Button)``

const RemoveEmail = styled.span``

const defaultValues = {
  miles: '',
  ccEmail: '',
  messageContent: '',
  bookID: '',
}

const schema = Yup.object().shape(
  {
    miles: Yup.number()
      .typeError('miles is required')
      .positive('miles must be positive')
      .required('miles is required'),
    messageContent: Yup.string(),
    ccEmail: Yup.string(),
    bookID: Yup.string(),
  },
  ['miles', 'messageContent', 'ccEmail', 'bookID']
)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const TopupMiles = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [emails, setEmails] = useState([])
  const [paynow, setPaynow] = useState()
  const [currentBook, setCurrentBook] = useState({})

  const { topupMiles } = useSelector((s) => s.topupMiles)
  const { books } = useSelector(tokenBooksSelector)
  const { profile } = useSelector((s) => s.profile)
  const configs = useSelector(profileConfigSettings)
  const role = useSelector(roleSelector)

  const {
    reset,
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (role && role !== 'admin') {
      history.replace('/home')
    }
    if (role) {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
  }, [role])

  useEffect(() => {
    if (books && profile) {
      if (books.length > 0) {
        setValue('bookID', books[0].bookID)
        setCurrentBook(books[0])
      }
      setValue('merchantID', profile.merchantID)
    }
  }, [books, profile])

  useEffect(() => {
    if (topupMiles.isSuccess) {
      if (paynow) {
        SessionStorage.saveJSON('created_order_customer', {
          email: profile.email,
          tel: profile.tel,
        })
        const { miles } = getValues()
        history.push('/fps?buymiles=1', { purchaseMiles: miles })
      } else {
        dispatch(
          promptConfirmModal({
            title: 'sent',
            content: 'invoice has been delivered successfully',
            okText: 'done',
            closable: false,
          })
        )
        setPaynow(null)
        reset()
      }
    }
    if (topupMiles.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [topupMiles])

  useEffect(() => () => dispatch({ type: 'topupMiles/reset' }), [])
  useEffect(() => {
    if (configs && configs.defaultCcList && configs.defaultCcList.length > 0) {
      setEmails(configs.defaultCcList)
    }
  }, [configs])

  const onClickReset = () => {
    reset()
  }

  const onClickPurchase = handleSubmit((data) => {
    setPaynow(true)
    dispatch({
      type: 'topupMiles/topupMiles',
      payload: { ...data, emails: [...emails] },
    })
  })

  const onClickSend = handleSubmit((data) => {
    setPaynow(false)
    dispatch({
      type: 'topupMiles/topupMilesSend',
      payload: { ...data, emails: [...emails] },
    })
  })

  const setTokenBook = (bookID) => {
    setCurrentBook(books.filter((b) => b.bookID === bookID)[0])
    setValue('bookID', bookID)
  }

  const loading = topupMiles.isLoading

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>buy divit miles</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>
          {books && books.length > 1 && (
            <>
              select miles account:&nbsp;&nbsp;
              <SelectField
                name="bookID"
                value={currentBook?.bookID}
                options={books.map((b) => ({
                  label: (
                    <BookOptionItem>
                      <MilesValue
                        fontSize="1rem"
                        miles={b.balance.amount}
                        isSigned={false}
                      />
                      <BookOptionItemTitle>{b.bookTitle}</BookOptionItemTitle>
                    </BookOptionItem>
                  ),
                  value: b.bookID,
                }))}
                onChange={(v) => setTokenBook(v)}
              />
            </>
          )}
          {books && books.length === 1 && currentBook?.balance && (
            <>
              current balance:&nbsp;&nbsp;
              <MilesValue
                fontSize="1rem"
                miles={currentBook?.balance?.amount}
                isSigned={false}
              />
            </>
          )}
        </SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {topupMiles.error && (
          <>
            <ErrorMessageLine errorMessage={topupMiles.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Row>
          <Controller
            name="miles"
            control={control}
            render={({ field }) => (
              <Amount
                allowNegativeValue={false}
                decimalScale={0}
                disableAbbreviations
                placeholder="0"
                value={field.value}
                onValueChange={(value, name) => {
                  field.onChange(value)
                }}
                inputMode="numeric"
                maxLength={10}
              />
            )}
          />
          <AmountSuffix>
            <BackSpace
              renderImage={() => <BackSpaceSvg />}
              width="1.111rem"
              height="1.111rem"
              onClick={() => {
                setValue('miles', '')
              }}
            />
            <Currency>miles</Currency>
          </AmountSuffix>
        </Row>
        <Row>
          <FieldWrapper>
            <FieldTitle>remarks</FieldTitle>
            <MessageContent {...register('messageContent')} rows={4} />
          </FieldWrapper>
        </Row>
        <Row>
          <FieldWrapper>
            <FieldTitle>send invoice to</FieldTitle>
            <CcEmail
              name="ccEmail"
              placeholder="email(s)"
              emails={emails}
              shown="flex"
              onChange={(_emails) => {
                setEmails(_emails)
              }}
              getLabel={(email, index, removeEmail) => (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <RemoveEmail
                    data-tag-handle
                    onClick={() => removeEmail(index)}
                  >
                    ×
                  </RemoveEmail>
                </div>
              )}
            />
          </FieldWrapper>
        </Row>
        {errors.miles && (
          <RowRight>
            <InputError error={getErrorMessage(errors.miles)} />
          </RowRight>
        )}
        <Spacer height={globalStyles.px.xs} />
        <Buttons>
          <ResetButton type="bw" bordered onClick={onClickReset}>
            reset
          </ResetButton>
          <PurchaseButton type="main" onClick={onClickPurchase}>
            pay now
          </PurchaseButton>
        </Buttons>
        <Spacer height={globalStyles.px.xs} />
        <CenterContent>or</CenterContent>
        <Spacer height={globalStyles.px.xs} />
        <Buttons>
          <SendButton type="main" onClick={onClickSend}>
            send invoice
          </SendButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default TopupMiles

import React from 'react'
import styled from 'styled-components'

import { ReactComponent as BackBtnSvg } from '@/assets/common/back-btn.svg'
import Icon from '@/components/Icon'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.33rem;
`

const BackButton = styled.div`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  font-size: 0.78rem;
  color: black;
  padding: 0.77rem;
  background-color: #fafafa;
  border-radius: 8px;
  cursor: pointer;
`

const Content = styled.div`
  margin-left: 0.5rem;
`

const Button = ({ className, children, onClick }) => (
  <BackButton className={className} onClick={onClick}>
    <Icon renderImage={() => <BackBtnSvg />} width="1.11rem" height="1.11rem" />
    {children && <Content>{children}</Content>}
  </BackButton>
)

export const BackButtonContainer = (props) => (
  <Container>
    <Button {...props} />
  </Container>
)

export default Button

import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import { downloadFile } from '@/utils/download'

import { getError, getErrorMessage } from '../utils/error'
import { actions as batchOrderActions } from './batchOrderSlice'

export function* batchUploadOrder({ payload }) {
  try {
    yield put(batchOrderActions.batchOrderUploadStart())
  
    const batchInvoicesRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/send/batch', payload, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    )

    yield put(batchOrderActions.batchOrderUploadSuccess(batchInvoicesRespData.data))
  } catch (err) {
    yield put(batchOrderActions.batchOrderUploadFailed(getError(err)))
  }
}

export function* downloadBatchInvoiceTemplate() {
  try {
    yield put(batchOrderActions.downloadBatchInvoiceTemplateStart())
    yield call(() =>
      downloadFile({
        customFetch: (url) =>
          DivitAPI.get(url, {
            responseType: "blob",
          }).then((resp) => resp.data),
        url: '/paynow/orders/send/batch/template/batch_invoices_template', 
        filename:'batch_invoices_template.xlsx'
      })
    )
    yield put(
      batchOrderActions.downloadBatchInvoiceTemplateSuccess({})
    )
  } catch (e) {
    yield put(
      batchOrderActions.downloadBatchInvoiceTemplateFailed(
        getErrorMessage(e)
      )
    )
  }
}

export default null

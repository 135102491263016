export const ERROR_CODE_FPS_UNAVAILABLE = 900000

const errorActions = {
  KYC: 'KYC',
}

const errors = [
  {
    codes: [6704],
    message: 'invaild identity document, please check again',
    action: errorActions.KYC,
  },
]

export const getError = ({ code }) =>
  errors.find((e) => e.codes.indexOf(code) >= 0)

export default 0

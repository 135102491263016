import { useEffect, useRef, useState } from 'react'
import { useSpringRef, useTransition } from 'react-spring'

const transLeftConfig = {
  from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
}
const transRightConfig = {
  from: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: 'translate3d(100%,0,0)' },
}

const usePageTransition = () => {
  const [direction, setDirection] = useState(['right'])

  const [pageIndex, setPageIndex] = useState(0)
  const pageIndexRef = useRef(pageIndex)

  const transRef = useSpringRef()

  const transitions = useTransition(pageIndex, {
    ref: transRef,
    keys: null,
    ...(direction[0] === 'left' ? transLeftConfig : transRightConfig),
  })

  useEffect(() => {
    if (pageIndex <= pageIndexRef.current) {
      setDirection(['left'])
    } else {
      setDirection(['right'])
    }
    pageIndexRef.current = pageIndex
  }, [pageIndex])

  useEffect(() => {
    transRef.start()
  }, [direction])

  return {
    setPageIndex,
    transitions,
  }
}

export default usePageTransition

import * as routerActions from 'connected-react-router'

import { t } from '@/locales/format'
import { promptActionModal } from '@/redux/modal/modalActions'
import { startSetupPinFlow } from '@/redux/pin/pinActions'
import { fetchProfile } from '@/redux/profile/profileActions'
import { roleSelector, sessionSelector } from '@/redux/profile/profileSelector'

const promptSetupAccount = (dispatch, { content }) =>
  new Promise((resolve) => {
    dispatch(
      promptActionModal({
        title: t('common.heywait'),
        content,
        actionText: t('profile.complete.confirm'),
        actionFn: () => {
          resolve({ result: 'ok' })
        },
        closeText: t('common.logout'),
        closeFn: () => {
          resolve({ result: 'logout' })
        },
      })
    )
  })

export const setupAccount = ({ isPrompt, isRedirect = true }) => async (
  dispatch,
  getState
) => {
  const session = sessionSelector(getState())
  const role = roleSelector(getState())

  const { hasPin } = session
  const isAdmin = role === 'admin'
  const validatedPin = isAdmin ? hasPin : true
  const isCompleted = validatedPin

  if (isCompleted) {
    if (isRedirect) {
      dispatch({ type: 'redirectReturnUrlOrHome' })
    }
    return
  }

  if (isPrompt) {
    let content = ''

    if (!validatedPin) {
      content = t('profile.completepin.ask')
    }

    const { result } = await dispatch(async (d) =>
      promptSetupAccount(d, { content })
    )

    if (result === 'logout') {
      dispatch({ type: 'logout' })
      setTimeout(() => dispatch(routerActions.replace('/'), 500))
      return
    }
  }

  if (!validatedPin) {
    await dispatch(startSetupPinFlow())
  }

  // refetch profile
  await fetchProfile()

  dispatch(setupAccount({ isPrompt: false }))
}

export default 0

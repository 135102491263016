const colors = {
  white: '#FFFFFF',
  black: '#000000',
  unnamed1: '#E23B3B',
  unnamed2: '#707070',
  backgroundGrey: '#F4F4F4',
  borderGrey: '#D3D3D3',
  divitLightGrey: '#CCCCCC',
  divitDarkGrey: '#333333',
  divitLightBlue: '#0099FF',
  divitLightPurple: '#990099',
  divitDarkPurple: '#660099',
  divitRed: '#CC0000',
  divitOrange: '#FF6633',
  divitYellow: '#FFCC33',
  cathayGreen: '#23555A',
  hkexpressPurple: '#702F8E', // #702F8E, #6E2D91
  hkexpressRed: '#EB1E23',
  asiamilesYellow: '#FFD700',
  paymentGreen: '#2CC271',
}

export default colors

// src/components/common/MenuItem.tsx
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'

// MilesIconOutline is not standard svg that is not work by putting text color, we need to use dynamic styling just
// for this case
export const MenuItemLink = classNames('w-full')
export const MenuItemIcon = classNames(
  `text-xl flex [&>*]:mx-auto w-[25px] ml-1`
)
export const MenuItemText = classNames(`text-xl font-semibold`)
export const SubmenuBullet = classNames(`text-right w-[20px] mr-4 ml-1`)
export const SubItemText = classNames(`text-base font-semibold`)

const ItemLink = styled.div`
  &:hover {
    background-color: ${(props) =>
      props.icon ? 'rgba(255, 247, 232, 1)' : 'rgba(255, 247, 232, 0.38)'};
  }
`

const BlockIcon = styled(Icon)`
  > svg path {
    fill: ${(props) => (props.active ? '#ffcc33' : '')};
  }
`

const MenuItem = (props) => {
  const { icon, active, name, isNarrowed, customMenuContainerClasses } = props
  return (
    <ItemLink className={MenuItemLink} icon={icon} active={active}>
      {icon && (
        <div
          className={`flex px-4 py-3 items-center gap-4 ${customMenuContainerClasses}`}
          style={active ? { backgroundColor: 'rgba(255, 247, 232, 1)' } : {}}
        >
          <div className={MenuItemIcon}>
            <BlockIcon
              renderImage={icon}
              width="24px"
              height="24px"
              active={active}
            />
          </div>
          {!isNarrowed && <div className={MenuItemText}>{name}</div>}
        </div>
      )}
      {!icon && (
        <div
          className="flex px-4 py-3 items-center gap-4"
          style={active ? { backgroundColor: 'rgba(255, 247, 232, 0.38)' } : {}}
        >
          <div className={SubmenuBullet}>
            <FiberManualRecordIcon
              sx={
                active
                  ? { height: '6px', width: '6px', color: '#ffcc33' }
                  : { height: '6px', width: '6px' }
              }
            />
          </div>
          {!isNarrowed && <div className={SubItemText}>{name}</div>}
        </div>
      )}
    </ItemLink>
  )
}

export default MenuItem

import React from 'react'
import styled from 'styled-components'

import { ReactComponent as EmptyResultIconSvg } from '@/assets/common/empty-result.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const SubTitle = styled.div`
  font-size: 0.778rem;
`

const EmptyResult = ({ title, subtitle }) => (
  <Container>
    <Icon
      renderImage={() => <EmptyResultIconSvg />}
      width="1.778rem"
      height="1.778rem"
    />
    <Spacer height="8px" />
    <Title>{title || 'no results found'}</Title>
    <Spacer height="4px" />
    <SubTitle>{subtitle || 'please try another keyword'}</SubTitle>
  </Container>
)

export default EmptyResult

import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import * as Yup from 'yup'

import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import InputField from '@/components/common/InputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { invalidNameValue } from '@/utils/validation'

const Content = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  text-align: center;
`

const SubTitle = styled.div`
  margin-top: 0.44rem;
  font-size: 0.667rem;
  color: #979797;
  text-align: center;
`

const Form = styled.div`
  padding: 0 1.33rem;
`

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const InviteButton = styled(Button)`
  width: 100%;
  font-size: 0.88rem;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
`

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('required')
    .test(
      'should not contain special character(s)',
      'should not contain special character(s)',
      (v) => !invalidNameValue(v)
    ),
  lastName: Yup.string()
    .notRequired()
    .test(
      'should not contain special character(s)',
      'should not contain special character(s)',
      (v) => !invalidNameValue(v)
    ),
  email: Yup.string().email('invalid email format').required('required'),
})

const InviteUserModal = ({
  isOpen,
  onClose,
  onCleanup,
  onClickSendInvitation,
  error,
}) => {
  const intl = useIntl()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onLeave = () => {
    reset()
    onCleanup()
  }

  const onClickInvite = handleSubmit((data) => {
    onClickSendInvitation({ ...data })
  })

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave()}
    >
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>invite user</Title>
        <SubTitle>please enter information below</SubTitle>
        <Spacer height={globalStyles.px.md} />
        <Form>
          {error && (
            <>
              <ErrorMessageLine errorMessage={error} />
              <Spacer height={globalStyles.px.xs} />
            </>
          )}
          <FormRow>
            <FormColumn>
              <InputField
                id="firstName"
                data-testid="invite.firstName"
                title=""
                {...register('firstName')}
                placeholder={`${intl.formatMessage({
                  id: 'common.firstname',
                })} *`}
                error={getErrorMessage(errors.firstName)}
              />
            </FormColumn>
            <FormColumn>
              <InputField
                id="lastName"
                data-testid="invite.lastName"
                title=""
                {...register('lastName')}
                placeholder={`${intl.formatMessage({
                  id: 'common.lastname',
                })}`}
                error={getErrorMessage(errors.lastName)}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <InputField
                type="email"
                id="email"
                data-testid="invite.email"
                title=""
                {...register('email')}
                placeholder={`${intl.formatMessage({
                  id: 'common.email',
                })} *`}
                error={getErrorMessage(errors.email)}
              />
            </FormColumn>
          </FormRow>
          <Spacer height={globalStyles.px.xs} />
          <InviteButton type="bw" onClick={onClickInvite}>
            {intl.formatMessage({ id: 'invite.merchant.send' })}
          </InviteButton>
          <Spacer height={globalStyles.px.lg} />
          <Spacer height={globalStyles.px.lg} />
        </Form>
      </Content>
    </BottomSheet>
  )
}

export default InviteUserModal

import { useCallback, useState } from 'react'

const useModal = () => {
  const [modalInfo, setModalInfo] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback((info) => {
    setModalInfo(info)
    setIsOpen(true)
  }, [])

  const onCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onCleanUpModal = useCallback(() => {
    setModalInfo(null)
  }, [])

  return {
    isOpen,
    modalInfo,
    openModal,
    onCloseModal,
    onCleanUpModal,
  }
}

export default useModal

import { Link, useLocation } from 'react-router-dom'

import AccordionMenu from './AccordionMenu'
import MenuItem from './MenuItem'

const Menu = (props) => {
  const {
    icon,
    title,
    route,
    childrenArr,
    narrowed,
    hasMilesService,
    hasPaynowService,
  } = props

  const location = useLocation()

  if (childrenArr && childrenArr.length > 0) {
    return (
      <AccordionMenu
        icon={icon}
        name={title}
        childrenArr={childrenArr}
        active={location.pathname.startsWith(route)}
        isNarrowed={narrowed}
        hasMilesService={hasMilesService}
        hasPaynowService={hasPaynowService}
      />
    )
  }

  return (
    <Link to={route}>
      <MenuItem
        icon={icon}
        active={location.pathname === route}
        name={title}
        isNarrowed={narrowed}
      />
    </Link>
  )
}

export default Menu

import React, { createContext, useContext, useState } from 'react'
import { ThemeProvider as StyledProvider } from 'styled-components'

import themes from '@/themes'

const ThemeContext = createContext({
  changeTheme: (theme) => theme,
  currentThemeName: '',
  currentTheme: {},
})

const ThemeProvider = (props) => {
  const defaultThemeName = 'divit'
  const [currentThemeName, setThemeName] = useState(defaultThemeName)
  const [currentTheme, setTheme] = useState(themes[defaultThemeName])

  const changeTheme = (themeName) => {
    setThemeName(themeName)
    setTheme(themes[themeName])
  }

  return (
    <StyledProvider
      theme={themes[currentThemeName] || themes[defaultThemeName]}
    >
      <ThemeContext.Provider
        value={{ changeTheme, currentThemeName, currentTheme }}
        {...props}
      />
    </StyledProvider>
  )
}

const useTheme = () => useContext(ThemeContext)

export { ThemeProvider, useTheme }

/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { MenuItemPaths } from '@/constants/paths'

import HomeMiniOutlineIcon from './icons/homeMini'

const BreadcrumbHomeIcon = classNames(
  `text-gray-500 hover:text-amber-300 [font-size:20px]`
)
const BreadcrumbRoot = classNames(`text-body-large pl-6 py-5`)
const BreadcrumbText = classNames(
  `font-normal hover:text-amber-300 text-black [font-size:16px]`
)

const pathArr = []

const pathArrMap = (arr) => {
  arr.map((el) => {
    pathArr.push(el)
    if (el.children) {
      pathArrMap(el.children)
    }
  })
}

pathArrMap(MenuItemPaths)

const Breadcrumbs = ({ path }) => {
  const { pathname } = useLocation()
  const { id } = useParams()

  const renderPath = (pathobj) =>
    pathobj.map((item) => (
      <Link to={item.children ? '' : item.path || ''} key={item.id}>
        <p className={BreadcrumbText}>{item.label}</p>
      </Link>
    ))

  const pathnames = pathname.split('/').filter(Boolean)

  const renderPathName = () => {
    const newPathName = cloneDeep(pathnames)

    return newPathName.map((name, index) => {
      if (name === id) {
        return
      }

      // Format pathname
      const formatName =
        name
          ?.toLowerCase()
          .replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace('-', ' ').replace('_', '')
          )
          ?.toLowerCase() || ''

      const routeTo = `/:lang/${newPathName.slice(0, index + 1).join('/')}`
      const isLast = () => {
        if (id && newPathName.includes(id)) {
          return index === newPathName.length - 2
        }
        return index === newPathName.length - 1
      }

      const findPath = MenuItemPaths.filter((path) => routeTo === path.path)

      const patchHasChild = () => {
        if (
          findPath.length > 0 &&
          findPath[0].children &&
          findPath[0].children?.length > 0
        ) {
          return true
        }
        return false
      }

      // const labelTranslation =
      //   pathArr.filter((path) => routeTo === path.path).length > 0 &&
      //   pathArr.filter((path) => routeTo === path.path)[0].label

      return isLast() ? (
        <Typography key={name} style={{ fontSize: '16px' }}>
          {formatName}
        </Typography>
      ) : (
        <Link key={name} to={patchHasChild() ? '' : routeTo}>
          <p className={BreadcrumbText}>{formatName}</p>
        </Link>
      )
    })
  }

  return (
    <MuiBreadcrumbs
      className={BreadcrumbRoot}
      maxItems={window.innerWidth < 640 ? 2 : 4}
      aria-label="breadcrumb"
      separator={<KeyboardArrowRightIcon fontSize="small" />}
    >
      <Link to="/">
        <HomeMiniOutlineIcon className={BreadcrumbHomeIcon} />
      </Link>
      {!path && renderPathName()}
      {path && renderPath(path)}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const TransactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState: {
    getTransactionDailyList: AsyncState.create(),
    getTransactionDailyDetails: AsyncState.create(),
    searchTransactionDailyDetails: AsyncState.create(),
    getTransactionDailyReport: AsyncState.create(),
    downloadTransactionDailyReport: AsyncState.create(),
  },
  reducers: {
    clearTransactionDailyDetails: (state) => {
      state.getTransactionDailyDetails = AsyncState.reset()
    },
    clearSearchTransactionDailyDetails: (state) => {
      state.searchTransactionDailyDetails = AsyncState.reset()
    },
    reset: (state) => {
      state.getTransactionHistory = AsyncState.reset()
      state.getTransactionDailyDetails = AsyncState.create()
      state.searchTransactionDailyDetails = AsyncState.create()
      state.getTransactionDailyReport = AsyncState.create()
      state.downloadTransactionDailyReport = AsyncState.create()
    },
    getTransactionDailyListStart: (state) => {
      state.getTransactionDailyList = AsyncState.start()
    },
    getTransactionDailyListSuccess: (state, action) => {
      state.getTransactionDailyList = AsyncState.success(action.payload)
    },
    getTransactionDailyListFailed: (state, action) => {
      state.getTransactionDailyList = AsyncState.failed(action.payload)
    },
    getTransactionDailyDetailsStart: (state) => {
      state.getTransactionDailyDetails = AsyncState.start()
    },
    getTransactionDailyDetailsSuccess: (state, action) => {
      state.getTransactionDailyDetails = AsyncState.success(action.payload)
    },
    getTransactionDailyDetailsFailed: (state, action) => {
      state.getTransactionDailyDetails = AsyncState.failed(action.payload)
    },
    searchTransactionDailyDetailsStart: (state) => {
      state.searchTransactionDailyDetails = AsyncState.start()
    },
    searchTransactionDailyDetailsSuccess: (state, action) => {
      state.searchTransactionDailyDetails = AsyncState.success(action.payload)
    },
    searchTransactionDailyDetailsFailed: (state, action) => {
      state.searchTransactionDailyDetails = AsyncState.failed(action.payload)
    },
    getTransactionDailyReportStart: (state) => {
      state.getTransactionDailyReport = AsyncState.start()
    },
    getTransactionDailyReportSuccess: (state, action) => {
      state.getTransactionDailyReport = AsyncState.success(action.payload)
    },
    getTransactionDailyReportFailed: (state, action) => {
      state.getTransactionDailyReport = AsyncState.failed(action.payload)
    },
    downloadTransactionDailyReportStart: (state) => {
      state.downloadTransactionDailyReport = AsyncState.start()
    },
    downloadTransactionDailyReportSuccess: (state, action) => {
      state.downloadTransactionDailyReport = AsyncState.success(action.payload)
    },
    downloadTransactionDailyReportFailed: (state, action) => {
      state.downloadTransactionDailyReport = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = TransactionHistorySlice

export default TransactionHistorySlice.reducer

import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import Button from '@/components/common/Button'
import PasswordInputField from '@/components/common/PasswordInputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import PasswordValidation from '@/components/PasswordValidation'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import usePasswordValidation from '@/hooks/usePasswordValidation'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Form = styled.div`
  background: white;
  padding: 1.33rem;
  border-radius: 16px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const CancelButton = styled(Button)``

const SaveButton = styled(Button)``

const defaultValues = {
  oldPwd: '',
  newPwd: '',
  confirmNewPwd: '',
}

const schema = Yup.object({
  oldPwd: Yup.string().required('required'),
  newPwd: Yup.string().required('required'),
  confirmNewPwd: Yup.string()
    .oneOf([Yup.ref('newPwd')], 'confirm password do not match')
    .required('required'),
})

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const ChangePassword = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const { passwordValidation } = usePasswordValidation(password)

  const { changePassword } = useSelector((s) => s.changePassword)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  useEffect(() => {
    if (changePassword.isSuccess) {
      dispatch({
        type: 'modal/openModal',
        payload: {
          content: 'password changed successfully',
          actions: [
            { key: 'ok', title: 'ok', action: () => history.push('/home') },
          ],
        },
      })
    }
  }, [changePassword])

  useEffect(() => () => dispatch({ type: 'changePassword/reset' }), [])

  const onClickCancel = () => history.goBack()

  const onClickSave = handleSubmit((data) => {
    if (!passwordValidation.valid) {
      setError('newPwd', {
        type: 'custom',
        message: 'new password does not meet the policy requirements',
      })
      return
    }
    const { newPwd, oldPwd } = data
    dispatch({
      type: 'changePassword/changePassword',
      payload: { newPwd, oldPwd },
    })
  })

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>change password</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>please enter information below</SubTitle>
        <Spacer height={globalStyles.px.lg1} />
        {changePassword.error && (
          <>
            <ErrorMessageLine errorMessage={changePassword.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Form>
          <PasswordInputField
            title="current password"
            placeholder="enter current password"
            {...register('oldPwd')}
            error={getErrorMessage(errors.oldPwd)}
          />
          <Spacer height={globalStyles.px.lg} />
          <PasswordInputField
            title="new password"
            placeholder="enter new password"
            {...register('newPwd')}
            onChange={(e) => setPassword(e.target.value)}
            error={getErrorMessage(errors.newPwd)}
          />
          {password && (
            <PasswordValidation
              strengthScore={passwordValidation.strengthScore}
              hasSufficientStrength={passwordValidation.validStrength}
              hasSufficientLength={passwordValidation.validLength}
              hasNumber={passwordValidation.validNumber}
              hasLowercase={passwordValidation.validLowercase}
              hasUppercase={passwordValidation.validUppercase}
              hasSymbol={passwordValidation.validSymbol}
              ignored={passwordValidation.ignored}
            />
          )}
          <Spacer height={globalStyles.px.lg} />
          <PasswordInputField
            title="verify password"
            placeholder="re-enter new password"
            {...register('confirmNewPwd')}
            error={getErrorMessage(errors.confirmNewPwd)}
          />
        </Form>
        <Spacer height={globalStyles.px.lg} />
        <Buttons>
          <CancelButton type="bw" bordered onClick={onClickCancel}>
            cancel
          </CancelButton>
          <SaveButton type="bw" onClick={onClickSave}>
            save
          </SaveButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg2} />
      </PageContent>
    </Container>
  )
}

export default ChangePassword

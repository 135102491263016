import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 16px;
  margin: 1rem;
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 2px 1px -2px rgba(0, 0, 0, 0.2);
  padding: ${({ hasPadding }) => hasPadding && '1rem 1rem 2.5rem 1rem'};
`

export default Content

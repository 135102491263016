import moment from 'moment'
import { call, delay, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import { downloadFilesAsZip } from '@/utils/download'

import { getErrorMessage } from '../utils/error'
import { actions as transactionHistoryActions } from './transactionHistorySlice'

const REPORT_DATE_FORMAT = 'YYYY-MM-DD'

// include today
export function* getTransactionDailyList({ payload }) {
  try {
    const { fromDate, toDate } = payload
    const params = {
      fromDate: moment(fromDate).format(REPORT_DATE_FORMAT),
      toDate: moment(toDate).add(1, 'days').format(REPORT_DATE_FORMAT),
    }

    yield put(transactionHistoryActions.getTransactionDailyListStart())
    const { data } = yield call(() =>
      DivitAPI.get('/reporting/paynow/transaction/summary', { params })
    )
    yield put(
      transactionHistoryActions.getTransactionDailyListSuccess(data.data)
    )
  } catch (e) {
    yield put(
      transactionHistoryActions.getTransactionDailyListFailed(
        getErrorMessage(e)
      )
    )
  }
}

export function* getTransactionDailyDetails({ payload }) {
  try {
    const { date } = payload
    const params = {
      fromDate: moment(date).format(REPORT_DATE_FORMAT),
      toDate: moment(date).add(1, 'day').format(REPORT_DATE_FORMAT),
      page: 0,
      pageSize: 50,
    }
    yield put(transactionHistoryActions.getTransactionDailyDetailsStart())
    yield delay(200)
    const { data } = yield call(() =>
      DivitAPI.get('/reporting/paynow/transaction', { params })
    )
    yield put(
      transactionHistoryActions.getTransactionDailyDetailsSuccess(data.data)
    )
  } catch (e) {
    yield put(
      transactionHistoryActions.getTransactionDailyDetailsFailed(
        getErrorMessage(e)
      )
    )
  }
}

// search keyword, ignore date
export function* searchTransactionDailyDetails({ payload }) {
  try {
    const { keyword } = payload
    const params = {
      keyword,
      fromDate: moment().subtract(1, 'days').format(REPORT_DATE_FORMAT),
      toDate: moment().format(REPORT_DATE_FORMAT),
      page: 0,
      pageSize: 50,
    }
    yield put(transactionHistoryActions.searchTransactionDailyDetailsStart())
    const { data } = yield call(() =>
      DivitAPI.get('/reporting/paynow/transaction', { params })
    )
    yield put(
      transactionHistoryActions.searchTransactionDailyDetailsSuccess(data.data)
    )
  } catch (e) {
    yield put(
      transactionHistoryActions.searchTransactionDailyDetailsFailed(
        getErrorMessage(e)
      )
    )
  }
}

export function* getTransactionDailyReport({ payload }) {
  try {
    const { fromDate, toDate } = payload
    const params = {
      fromDate: moment(fromDate).format(REPORT_DATE_FORMAT),
      toDate: moment(toDate).add(1, 'days').format(REPORT_DATE_FORMAT),
      page: 0,
      pageSize: 90,
    }
    yield put(transactionHistoryActions.getTransactionDailyReportStart())
    const { data } = yield call(() =>
      DivitAPI.get('/reporting/paynow/daily/report', { params })
    )
    yield put(
      transactionHistoryActions.getTransactionDailyReportSuccess(data.data)
    )
  } catch (e) {
    yield put(
      transactionHistoryActions.getTransactionDailyReportFailed(
        getErrorMessage(e)
      )
    )
  }
}

export function* downloadTransactionDailyReport({ payload }) {
  try {
    const { filenames } = payload
    yield put(transactionHistoryActions.downloadTransactionDailyReportStart())
    const files = filenames.map(({ sourceFilename, distFilename }) => ({
      filename: distFilename,
      url: `/reporting/paynow/daily/report/${sourceFilename}`,
    }))
    yield call(() =>
      downloadFilesAsZip({
        customFetch: (url) =>
          DivitAPI.get(url, { responseType: 'blob' }).then((resp) => resp.data),
        files,
      })
    )
    yield put(
      transactionHistoryActions.downloadTransactionDailyReportSuccess({})
    )
  } catch (e) {
    yield put(
      transactionHistoryActions.downloadTransactionDailyReportFailed(
        getErrorMessage(e)
      )
    )
  }
}

export default null

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as LockSvg } from '@/assets/user/lock.svg'
import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import Toggle from '@/components/ui/Toggle'
import globalStyles from '@/constants/globalStyles'

const Content = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  text-align: center;
`

const SubTitle = styled.div`
  margin-top: 0.44rem;
  font-size: 0.667rem;
  color: #979797;
  text-align: center;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`

const LockIcon = styled(Icon)`
  margin-left: 0.5rem;
  stroke: red;
`

const Name = styled.div``

const Question = styled.li`
  font-size: 0.75rem;
  list-style-type: disc;
`

const LockedName = styled.div`
  color: #aaa;
`

const Email = styled.div`
  font-size: 0.75rem;
  color: #aaa;
`

const Form = styled.div`
  padding: 0 1.33rem;
`

const UpdateButton = styled(Button)`
  width: 100%;
  font-size: 0.88rem;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
`

const EditUserModal = ({
  user,
  isOpen,
  onClose,
  onCleanup,
  onClickUpdate,
  error,
}) => {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLocked, setIsLocked] = useState(false)

  const onLeave = () => {
    onCleanup()
  }

  const items = {
    admin: {
      key: 'admin',
      setFn: setIsAdmin,
    },
    lock: {
      key: 'lock',
      setFn: setIsLocked,
    },
  }

  const isUserLocked =
    user?.user?.status === 'locked' ||
    user?.user?.status === 'inactive' ||
    user?.status === 'inactive' ||
    user?.status === 'suspended'

  useEffect(() => {
    if (user) {
      setIsLocked(isUserLocked)
      setIsAdmin(user?.user?.userRole === 'partneradmin')
    }
  }, [user])

  const onClickToggle =
    ({ key, setFn }) =>
    (v) => {
      setFn(v)
    }

  const onClickUpdateUser = () => {
    onClickUpdate({
      contactID: user.contactID,
      status: isLocked ? 'locked' : undefined,
      role: isAdmin ? 'partneradmin' : 'partneragent',
    })
  }

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave()}
    >
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>update user</Title>
        <SubTitle>update user status and role</SubTitle>
        <Spacer height={globalStyles.px.md} />
        <Form>
          {error && (
            <>
              <ErrorMessageLine errorMessage={error} />
              <Spacer height={globalStyles.px.xs} />
            </>
          )}
          <Item>
            <Name>
              {isUserLocked && (
                <LockedName>
                  {user?.firstName} {user?.lastName}
                </LockedName>
              )}
              {!isUserLocked && (
                <>
                  {user?.firstName} {user?.lastName}
                </>
              )}
              <Email>{user?.email}</Email>
            </Name>
            {(user?.user?.status === 'locked' ||
              user?.user?.status === 'inactive' ||
              user?.status === 'inactive') && (
              <LockIcon
                renderImage={() => <LockSvg />}
                widht="1.111rem"
                height="1.111rem"
              />
            )}
          </Item>
          <Item>
            <Question>is admin user?</Question>
            <Toggle checked={isAdmin} onChange={onClickToggle(items.admin)} />
          </Item>
          <Item>
            <Question>lock user account?</Question>
            <Toggle checked={isLocked} onChange={onClickToggle(items.lock)} />
          </Item>
          <Spacer height={globalStyles.px.xs} />

          <UpdateButton type="bw" onClick={onClickUpdateUser}>
            <span>update</span>
          </UpdateButton>
          <Spacer height={globalStyles.px.lg} />
          <Spacer height={globalStyles.px.lg} />
        </Form>
      </Content>
    </BottomSheet>
  )
}

export default EditUserModal

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const defaultPageData = { data: [], total: 0 }

const MilesTransactionsSlice = createSlice({
  name: 'milesTransactions',
  initialState: {
    milesTransactions: defaultPageData,
    getMilesTransactions: AsyncState.create(),
    getMilesTransactionWeeklyReport: AsyncState.create(),
    downloadMilesTransactionWeeklyReport: AsyncState.create(),
  },
  reducers: {
    insertMilesTransactions: (state, action) => {
      const { data, total } = action.payload
      const list = state.milesTransactions.data
      state.milesTransactions.data = list.concat(data)
      state.milesTransactions.total = total
    },
    reset: (state) => {
      state.milesTransactions = defaultPageData
      state.getMilesTransactions = AsyncState.reset()
      state.getMilesTransactionWeeklyReport = AsyncState.create()
      state.downloadMilesTransactionWeeklyReport = AsyncState.create()
    },
    getMilesTransactionsStart: (state) => {
      state.milesTransactions = defaultPageData
      state.getMilesTransactions = AsyncState.start()
    },
    getMilesTransactionsSuccess: (state, action) => {
      const { data, total } = action.payload
      state.milesTransactions = { data, total }
      state.getMilesTransactions = AsyncState.success(action.payload)
    },
    getMilesTransactionsFailed: (state, action) => {
      state.milesTransactions = defaultPageData
      state.getMilesTransactions = AsyncState.failed(action.payload)
    },
    getMilesTransactionWeeklyReportStart: (state) => {
      state.getMilesTransactionWeeklyReport = AsyncState.start()
    },
    getMilesTransactionWeeklyReportSuccess: (state, action) => {
      state.getMilesTransactionWeeklyReport = AsyncState.success(action.payload)
    },
    getMilesTransactionWeeklyReportFailed: (state, action) => {
      state.getMilesTransactionWeeklyReport = AsyncState.failed(action.payload)
    },
    downloadMilesTransactionWeeklyReportStart: (state) => {
      state.downloadMilesTransactionWeeklyReport = AsyncState.start()
    },
    downloadMilesTransactionWeeklyReportSuccess: (state, action) => {
      state.downloadMilesTransactionWeeklyReport = AsyncState.success(action.payload)
    },
    downloadMilesTransactionWeeklyReportFailed: (state, action) => {
      state.downloadMilesTransactionWeeklyReport = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = MilesTransactionsSlice

export default MilesTransactionsSlice.reducer

import React from 'react'
import styled from 'styled-components'

import { ReactComponent as DivitMilesSvg } from '@/assets/miles/divit-miles-logo.svg'
import { ReactComponent as DivitMilesBWBorderedSvg } from '@/assets/miles/divit-miles-logo-bw-bordered.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import { FormattedMiles } from '@/utils/Price'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize};
  padding-top: 0.1em;
`

const MilesValue = ({ miles, fontSize = '0.77rem', isBW, isSigned = true }) => {
  const LogoSvg = isBW ? DivitMilesBWBorderedSvg : DivitMilesSvg
  const isNegative = miles < 0

  return (
    <Container>
      {isSigned && (
        <>
          <Text>{isNegative ? '-' : '+'}</Text>
          <Spacer width="8px" />
        </>
      )}
      <Icon
        renderImage={() => <LogoSvg />}
        width={fontSize}
        height={fontSize}
      />
      <Spacer width="8px" />
      <Text fontSize={fontSize}>{FormattedMiles(Math.abs(miles))}</Text>
    </Container>
  )
}

export default MilesValue

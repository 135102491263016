import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const createPaymentSlice = createSlice({
  name: 'createPayment',
  initialState: {
    createPayment: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.createPayment = AsyncState.reset()
    },
    createPaymentStart: (state) => {
      state.createPayment = AsyncState.start()
    },
    createPaymentSuccess: (state, action) => {
      state.createPayment = AsyncState.success(action.payload)
    },
    createPaymentFailed: (state, action) => {
      state.createPayment = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = createPaymentSlice

export default createPaymentSlice.reducer

import { useMemoizedFn } from 'ahooks'
import React, { useRef } from 'react'
import styled from 'styled-components'

import InputError from './InputError'

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 8px;
  padding: 0.778rem 1.333rem;
  outline: none;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

export const InputInner = styled.input`
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 0.78rem;

  ::placeholder {
    color: #989898;
  }
`

export const Prefix = styled.div`
  font-size: 0.78rem;
  margin-right: 0.444rem;
`

export const Suffix = styled.div`
  font-size: 0.78rem;
  margin-left: 0.444rem;
`

export const Input = React.forwardRef(
  (
    { prefix, suffix, onClickPrefix, onClickSuffix, error, hasError, ...props },
    ref
  ) => (
    <InputWrapper error={error || hasError}>
      {prefix && <Prefix onClick={onClickPrefix}>{prefix}</Prefix>}
      <InputInner ref={ref} autoCapitalize="none" {...props} />
      {suffix && <Suffix onClick={onClickSuffix}>{suffix}</Suffix>}
    </InputWrapper>
  )
)

const InputField = React.forwardRef(
  (
    {
      style,
      className,
      hasTitle = true,
      title,
      prefix,
      suffix,
      onClickPrefix = () => {},
      onClickSuffix = () => {},
      disabled,
      hasError,
      error,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null)

    const onClickField = useMemoizedFn(() => {
      inputRef.current?.focus()
    })

    return (
      <Field
        style={style}
        className={className}
        disabled={disabled}
        onClick={!disabled ? onClickField : null}
      >
        {hasTitle && <Title>{title}</Title>}
        <Input
          ref={(node) => {
            ref?.(node)
            inputRef.current = node
          }}
          prefix={prefix}
          suffix={suffix}
          onClickPrefix={onClickPrefix}
          onClickSuffix={onClickSuffix}
          hasError={hasError}
          error={error}
          {...props}
        />
        <InputError error={error} />
      </Field>
    )
  }
)

export default InputField

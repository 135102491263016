import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const pageInitSlice = createSlice({
  name: 'pageInit',
  initialState: {
    initApp: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.initApp = AsyncState.reset()
    },
    initAppStart: (state) => {
      state.initApp = AsyncState.start()
    },
    initAppSuccess: (state, action) => {
      state.initApp = AsyncState.success(action.payload)
    },
    initAppFailed: (state, action) => {
      state.initApp = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = pageInitSlice

export default pageInitSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const topupMilesSlice = createSlice({
  name: 'topupMiles',
  initialState: {
    topupMiles: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.topupMiles = AsyncState.reset()
    },
    topupMilesStart: (state) => {
      state.topupMiles = AsyncState.start()
    },
    topupMilesSuccess: (state, action) => {
      state.topupMiles = AsyncState.success(action.payload)
    },
    topupMilesFailed: (state, action) => {
      state.topupMiles = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = topupMilesSlice

export default topupMilesSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const paynowSlice = createSlice({
  name: 'paynowFastFps',
  initialState: {
    fetchOrder: AsyncState.create(),
    generateFpsLink: AsyncState.create(),
    refreshOrder: AsyncState.create(),
    requestShareEmail: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.fetchOrder = AsyncState.create()
      state.generateFpsLink = AsyncState.reset()
      state.refreshOrder = AsyncState.create()
      state.requestShareEmail = AsyncState.create()
    },
    fetchOrderStart: (state) => {
      state.fetchOrder = AsyncState.start()
    },
    fetchOrderSuccess: (state, action) => {
      state.fetchOrder = AsyncState.success(action.payload)
    },
    fetchOrderFailed: (state, action) => {
      state.fetchOrder = AsyncState.failed(action.payload)
    },
    generateFpsLinkStart: (state) => {
      state.generateFpsLink = AsyncState.start()
    },
    generateFpsLinkSuccess: (state, action) => {
      state.generateFpsLink = AsyncState.success(action.payload)
    },
    generateFpsLinkFailed: (state, action) => {
      state.generateFpsLink = AsyncState.failed(action.payload)
    },
    refreshOrderStart: (state) => {
      state.refreshOrder = AsyncState.start()
    },
    refreshOrderSuccess: (state, action) => {
      state.refreshOrder = AsyncState.success(action.payload)
    },
    refreshOrderFailed: (state, action) => {
      state.refreshOrder = AsyncState.failed(action.payload)
    },
    requestShareEmailStart: (state) => {
      state.requestShareEmail = AsyncState.start()
    },
    requestShareEmailSuccess: (state, action) => {
      state.requestShareEmail = AsyncState.success(action.payload)
    },
    requestShareEmailFailed: (state, action) => {
      state.requestShareEmail = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = paynowSlice

export default paynowSlice.reducer

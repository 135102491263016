import { all, call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { actions as lookupActions } from './lookupSlice'

export function* fetchMerchantList({ merchantId }) {
  const { data: merchants } = yield call(async () =>
    DivitAPI.get(`/merchants/v2/${merchantId}`)
  )
  yield put(lookupActions.getMerchantListSuccess([merchants.data]))
}

export function* fetchRateHKDDVM() {
  const { data: rateData } = yield call(async () =>
    DivitAPI.get(`/miles/rates/merchants/HKD/DVM`)
  )
  yield put(lookupActions.getRateHKDDVMSuccess(rateData.data))
}

export function* fetchTokenRateHKDDVM() {
  const { data: rateData } = yield call(async () =>
    DivitAPI.get(`/miles/rates/token/HKD/DVM`)
  )
  yield put(lookupActions.getTokenRateHKDDVMSuccess(rateData.data))
}

export function* fetchLookups() {
  yield all([fetchRateHKDDVM(), fetchTokenRateHKDDVM()])
}

export default 0

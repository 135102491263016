import React from 'react'
import styled from 'styled-components'

export const HRWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.78rem;

  > span {
    margin: 0 1.33rem;
  }
`

export const Line = styled.div`
  display: block;
  width: 48px;
  height: 1px;
  background-color: #f0f0f0;
`

const LineHR = ({ children }) => (
  <HRWrapper>
    <Line />
    <span>{children}</span>
    <Line />
  </HRWrapper>
)

export default LineHR

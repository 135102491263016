import html2canvas from 'html2canvas'

export const downloadImage = (dataURI, filename) => {
  const element = document.createElement('a')
  element.href = dataURI
  element.download = filename || `divit-qrcode-${Date.now()}.jpg`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const addDataToCanvas = (
  canvas,
  { merchantName, merchantRef, paymentAmount }
) => {
  const lineStartPosY = 40
  const lineHeight = 28
  const lineMargin = 10
  // copy dom canvas
  const temp = document.createElement('canvas')
  temp.width = canvas.width
  temp.height = canvas.height
  const tempCtx = temp.getContext('2d')
  tempCtx.drawImage(canvas, 0, 0)
  // new canvas for save image
  const newCanvas = document.createElement('canvas')
  newCanvas.width = temp.width
  newCanvas.height = temp.height + lineStartPosY + (lineHeight + lineMargin) * 2
  const newCanvasCtx = newCanvas.getContext('2d')
  const newWidth = newCanvas.width
  const newHeight = newCanvas.height
  // draw bg of text
  newCanvasCtx.fillStyle = 'white'
  newCanvasCtx.fillRect(0, 0, newWidth, newHeight)
  // draw html image
  newCanvasCtx.drawImage(temp, 0, lineStartPosY + (lineHeight + lineMargin) * 2)
  // draw text
  newCanvasCtx.font = '24px Arial'
  newCanvasCtx.fillStyle = '#1c1c1c'
  newCanvasCtx.textAlign = 'center'
  newCanvasCtx.fillText(
    merchantName,
    newWidth / 2,
    lineStartPosY + (lineHeight + lineMargin) * 0
  )
  newCanvasCtx.fillText(
    merchantRef,
    newWidth / 2,
    lineStartPosY + (lineHeight + lineMargin) * 1
  )
  newCanvasCtx.fillText(
    paymentAmount,
    newWidth / 2,
    lineStartPosY + (lineHeight + lineMargin) * 2
  )
  return newCanvas
}

export const getHtmlToImageDataURI = async (
  domElement,
  { merchantName, merchantRef, paymentAmount }
) => {
  window.scrollTo(0, 0)
  const canvas = await html2canvas(domElement)
  const newCanvas = addDataToCanvas(canvas, {
    merchantName,
    merchantRef,
    paymentAmount,
  })
  return newCanvas.toDataURL('image/jpeg')
}

export const downloadHtmlToImage = async (
  domElement,
  { merchantName, merchantRef, paymentAmount },
  filename = `divit-payment-${Date.now()}.jpg`
) => {
  const dataURI = await getHtmlToImageDataURI(domElement, {
    merchantName,
    merchantRef,
    paymentAmount,
  })
  downloadImage(dataURI, filename)
}

export default null

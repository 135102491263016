import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const SetupTeamSlice = createSlice({
  name: 'setupTeam',
  initialState: {
    getUserList: AsyncState.create(),
    inviteUser: AsyncState.create(),
    editUser: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.getUserList = AsyncState.reset()
    },
    getUserListStart: (state) => {
      state.getUserList = AsyncState.start()
    },
    getUserListSuccess: (state, action) => {
      state.getUserList = AsyncState.success(action.payload)
    },
    getUserListFailed: (state, action) => {
      state.getUserList = AsyncState.failed(action.payload)
    },
    inviteUserStart: (state) => {
      state.inviteUser = AsyncState.start()
    },
    inviteUserSuccess: (state, action) => {
      state.inviteUser = AsyncState.success(action.payload)
    },
    inviteUserFailed: (state, action) => {
      state.inviteUser = AsyncState.failed(action.payload)
    },
    editUserStart: (state) => {
      state.editUser = AsyncState.start()
    },
    editUserSuccess: (state, action) => {
      state.editUser = AsyncState.success(action.payload)
    },
    editUserFailed: (state, action) => {
      state.editUser = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = SetupTeamSlice

export default SetupTeamSlice.reducer

import jwtDecode from 'jwt-decode'

export const decode = (token) => ({
  header: jwtDecode(token, { header: true }),
  payload: jwtDecode(token),
})

export const getRole = (token) => {
  const d = decode(token)
  return d.payload.scope
}

export const getLoginType = (token) => {
  const d = decode(token)
  return d.payload.oauth || 'pwd'
}

export const isRoleAvailable = (token) => {
  const roles = getRole(token)
  const arr = Array.isArray(roles) ? roles : roles.split(',')
  return arr.indexOf('partneradmin') >= 0 || arr.indexOf('partneragent') >= 0
}

export default null

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as CheckedSvg } from '@/assets/history/checked.svg'
import { ReactComponent as UnCheckSvg } from '@/assets/history/uncheck.svg'
import { ReactComponent as DownloadSvg } from '@/assets/share/download-icon.svg'
import DateSelectField, {
  getDefaultDateValue,
} from '@/components/common/DateSelectField'
import EmptyResult from '@/components/EmptyResult'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import LoadingSpinner from '@/components/LoadingSpinner'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { profileSelector } from '@/redux/profile/profileSelector'

const Container = styled.div``

const Content = styled.div`
  background-color: white;
`

const ErrorContainer = styled.div`
  padding: 0 1.333rem;
`

const Filters = styled.div`
  background-color: white;
  padding: 0.889rem 1.333rem;
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.333rem;
  background-color: white;
`

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ContainerText = styled.div`
  flex: 1;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.778rem;
`

const LoadingContainer = styled.div`
  background-color: white;
`

const List = styled.div`
  background-color: white;
  padding: 0 1.333rem;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  padding: 0.889rem 0;
  cursor: pointer;
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemName = styled.div`
  font-size: 0.778rem;
`

const ItemDate = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const Footer = styled.div`
  width: 100%;
  height: 1.333rem;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const getFilenameByReportItem = (reportItem) => {
  const date = moment(reportItem.reportDate).format('YYYYMMDD')
  const ext = reportItem.filename.split('.')[1]
  return `weekly_miles_report_${date}.${ext}`
}

const TransactionHistoryListItem = ({ data, isSelected, onClickSelect }) => {
  const date = moment(data.reportDate).format('YYYY-MM-DD')
  const SelectIcon = isSelected ? CheckedSvg : UnCheckSvg

  const onClickSelectItem = () => {
    onClickSelect(data)
  }

  return (
    <ListItem onClick={onClickSelectItem}>
      <Icon
        renderImage={() => <SelectIcon />}
        widht="1.111rem"
        height="1.111rem"
      />
      <Spacer width={globalStyles.px.xs} />
      <ItemContent>
        <ItemName>{getFilenameByReportItem(data)}</ItemName>
        <Spacer height="4px" />
        <ItemDate>{date}</ItemDate>
      </ItemContent>
    </ListItem>
  )
}

const TransactionDailyList = () => {
  const dispatch = useDispatch()

  const {
    getMilesTransactionWeeklyReport,
    downloadMilesTransactionWeeklyReport,
  } = useSelector((s) => s.milesTransactions)
  const profile = useSelector(profileSelector)
  const { isLoading: isDownloading } = downloadMilesTransactionWeeklyReport
  const { data: reportList, isLoading } = getMilesTransactionWeeklyReport

  const [dateValue, setDateValue] = useState(() => getDefaultDateValue())
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    dispatch({
      type: 'milesTransactions/getMilesTransactionWeeklyReport',
      payload: {
        fromDate: dateValue.fromDate,
        toDate: dateValue.toDate,
        merchantID: profile?.merchantID,
      },
    })
  }, [dateValue])

  const onToggleSelectAll = () => {
    const newState = !isSelectedAll
    setIsSelectedAll(newState)
    if (newState) {
      setSelectedItems(reportList)
    } else {
      setSelectedItems([])
    }
  }

  const onClickItem = (item) => {
    let newState = []
    const index = selectedItems.indexOf(item)
    if (index >= 0) {
      newState = selectedItems.filter((x) => x !== item)
    } else {
      newState = selectedItems.concat(item)
    }
    if (newState.length === reportList.length) {
      setIsSelectedAll(true)
    } else {
      setIsSelectedAll(false)
    }
    setSelectedItems(newState)
  }

  const onDownload = async () => {
    if (selectedItems.length <= 0) return
    const filenames = selectedItems.map((item) => ({
      sourceFilename: item.filename,
      distFilename: getFilenameByReportItem(item),
    }))
    dispatch({
      type: 'milesTransactions/downloadMilesTransactionWeeklyReport',
      payload: { filenames },
    })
  }

  const SelectIcon = isSelectedAll ? CheckedSvg : UnCheckSvg
  const isEmptyResult = !isLoading && reportList?.length === 0
  const canDownload = selectedItems?.length > 0

  return (
    <Container>
      <Content>
        <Filters>
          <DateSelectField value={dateValue} onChange={setDateValue} />
        </Filters>
        {getMilesTransactionWeeklyReport.error && (
          <ErrorContainer>
            <Spacer height={globalStyles.px.xs} />
            <ErrorMessageLine
              errorMessage={getMilesTransactionWeeklyReport.error}
            />
          </ErrorContainer>
        )}
        <Controls>
          <SelectAllContainer onClick={onToggleSelectAll}>
            <Icon
              renderImage={() => <SelectIcon />}
              widht="1.111rem"
              height="1.111rem"
            />
            <Spacer width="16px" />
            <ContainerText>
              {isSelectedAll ? 'deselect all' : 'select all'}
            </ContainerText>
          </SelectAllContainer>
          {canDownload && (
            <DownloadContainer onClick={onDownload}>
              <Icon
                renderImage={() => <DownloadSvg />}
                width="0.859rem"
                height="0.859rem"
              />
              <Spacer width="8px" />
              <ContainerText>download</ContainerText>
            </DownloadContainer>
          )}
        </Controls>
        {isLoading && (
          <LoadingContainer>
            <Spacer height={globalStyles.px.xs} />
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {isEmptyResult && <EmptyResult />}
        <List>
          {!isLoading &&
            reportList?.map((reportItem) => (
              <TransactionHistoryListItem
                key={reportItem.reportDate}
                data={reportItem}
                isSelected={selectedItems.indexOf(reportItem) >= 0}
                onClickSelect={onClickItem}
              />
            ))}
        </List>
      </Content>
      <Footer />
      <LoadingModal loadingText="downloading..." loading={isDownloading} />
    </Container>
  )
}

export default TransactionDailyList

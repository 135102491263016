import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token'),
    expire: localStorage.getItem('expire'),
    email: null,
    password: null,
    sendingOTP: false,
    sentOTP: false,
    sendOTPError: null,
    verifyingOTP: false,
    verified: false,
    verifyOTPError: null,
    verifyEmailDone: false,
    resetPasswordSendDone: false,
    verifyResetPasswordDone: false,
    resetPasswordDone: false,
    merchantSignupDone: false,
    logoutDone: false,
    loading: false,
    done: false,
    error: null,
  },
  reducers: {
    signUpStart: (state, action) => {
      const {
        payload: { email, password },
      } = action
      state.email = email
      state.password = password
      state.loading = true
      state.done = false
      state.error = null
    },
    signUpSuccess: (state) => {
      state.loading = false
      state.done = true
    },
    signUpFailed: (state, action) => {
      state.loading = false
      state.done = true
      state.error = action.payload
    },
    merchantSignupClear: (state) => {
      state.merchantSignupDone = false
      state.loading = false
      state.done = false
      state.error = null
    },
    merchantSignupStart: (state) => {
      state.merchantSignupDone = false
      state.loading = true
      state.done = false
      state.error = null
    },
    merchantSignupSuccess: (state) => {
      state.merchantSignupDone = true
      state.loading = false
      state.done = true
    },
    merchantSignupFailed: (state, action) => {
      state.merchantSignupDone = false
      state.loading = false
      state.done = true
      state.error = action.payload
    },
    sendOTPStart: (state) => {
      state.sendingOTP = true
      state.sentOTP = false
      state.sendOTPError = null
    },
    sendOTPSuccess: (state) => {
      state.sendingOTP = false
      state.sentOTP = true
    },
    sendOTPFailed: (state, action) => {
      state.sendingOTP = false
      state.sendOTPError = action.payload
    },
    verifyOTPStart: (state) => {
      state.verifyingOTP = true
      state.verifyOTPError = null
    },
    verifyOTPSuccess: (state) => {
      state.verifyingOTP = false
      state.verified = true
    },
    verifyOTPFailed: (state, action) => {
      state.verifyingOTP = false
      state.verifyOTPError = action.payload
    },
    clearSignUp: (state) => {
      state.email = null
      state.password = null
      state.sendingOTP = false
      state.sentOTP = false
      state.sendOTPError = null
      state.verifyingOTP = false
      state.verified = false
      state.verifyOTPError = null
      state.loading = false
      state.done = false
      state.error = null
    },
    loginStart: (state) => {
      state.loading = true
      state.error = null
    },
    loginSuccess: (state, action) => {
      const {
        payload: { token, expire },
      } = action
      state.token = token
      state.expire = expire
      state.loading = false
      state.error = null
    },
    loginFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    loginClear: (state) => {
      state.loading = false
      state.error = null
    },
    logoutClear: (state) => {
      state.logoutDone = false
    },
    logout: (state) => {
      state.token = null
      state.expire = null
      state.logoutDone = true
    },
    verifyEmailClear: (state) => {
      state.loading = false
      state.error = null
      state.verifyEmailDone = false
    },
    verifyEmailStart: (state) => {
      state.loading = true
      state.verifyEmailDone = false
    },
    verifyEmailSuccess: (state) => {
      state.loading = false
      state.verifyEmailDone = true
    },
    verifyEmailFailed: (state, action) => {
      state.loading = false
      state.verifyEmailDone = false
      state.error = action.payload
    },
    resetPasswordSendClear: (state) => {
      state.loading = false
      state.error = null
      state.resetPasswordSendDone = false
    },
    resetPasswordSendStart: (state) => {
      state.loading = true
      state.resetPasswordSendDone = false
    },
    resetPasswordSendSuccess: (state) => {
      state.loading = false
      state.resetPasswordSendDone = true
    },
    resetPasswordSendFailed: (state, action) => {
      state.loading = false
      state.resetPasswordSendDone = false
      state.error = action.payload
    },
    verifyResetPasswordClear: (state) => {
      state.loading = false
      state.error = null
      state.verifyResetPasswordDone = false
    },
    verifyResetPasswordStart: (state) => {
      state.loading = true
      state.verifyResetPasswordDone = false
    },
    verifyResetPasswordSuccess: (state) => {
      state.loading = false
      state.verifyResetPasswordDone = true
    },
    verifyResetPasswordFailed: (state, action) => {
      state.loading = false
      state.verifyResetPasswordDone = false
      state.error = action.payload
    },
    resetPasswordClear: (state) => {
      state.loading = false
      state.error = null
      state.resetPasswordDone = false
    },
    resetPasswordStart: (state) => {
      state.loading = true
      state.resetPasswordDone = false
    },
    resetPasswordSuccess: (state) => {
      state.loading = false
      state.resetPasswordDone = true
    },
    resetPasswordFailed: (state, action) => {
      state.loading = false
      state.resetPasswordDone = false
      state.error = action.payload
    },
  },
})

export const {
  signUpStart,
  signUpSuccess,
  signUpFailed,
  sendOTPStart,
  sendOTPSuccess,
  sendOTPFailed,
  verifyOTPStart,
  verifyOTPSuccess,
  verifyOTPFailed,
  clearSignUp,
  loginStart,
  loginSuccess,
  loginFailed,
  logout,
} = authSlice.actions

export default authSlice.reducer

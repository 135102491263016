import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as NotFoundSvg } from '@/assets/common/not-found-icon.svg'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import { useAuth } from '@/contexts/authContext'
import colors from '@/themes/colors'

const Container = styled(PageContainer)`
  background-color: #fff;
  padding: 1.25rem 1.875rem;
  text-align: center;
`

const NotFoundIcon = styled(NotFoundSvg)`
  width: 8.675rem;
  height: auto;
`

const Title = styled.div`
  margin-top: 1.875rem;
  color: ${colors.divitLightBlue};
  font-size: 1.625rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
`

const Message = styled.div`
  margin-top: 1.875rem;
  font-size: 0.875rem;

  span {
    color: ${colors.divitOrange};
    text-decoration: underline;
  }
`

const NotFound = () => {
  const intl = useIntl()
  const history = useHistory()
  const { isLoggedIn } = useAuth()

  const goLogin = (e) => {
    e.preventDefault()

    if (isLoggedIn) {
      history.push('/home')
    } else {
      history.push('/')
    }
  }

  return (
    <Container>
      <PageContent hasPadding>
        <NotFoundIcon />
        <Title>
          {intl.formatMessage(
            {
              id: 'notfound.title',
            },
            {
              br: <br />,
            }
          )}
        </Title>
        <Message onClick={goLogin}>
          {intl.formatMessage(
            {
              id: 'notfound.message.homepage',
            },
            {
              highlight: (
                <span>
                  {intl.formatMessage({
                    id: 'notfound.message.homepage.highlight',
                  })}
                </span>
              ),
            }
          )}
        </Message>
      </PageContent>
    </Container>
  )
}

export default NotFound

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Divider } from '@mui/material'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import { ReactComponent as DivitNoneTextSvg } from '@/assets/divit-miles-icon.svg'
import { ReactComponent as LogoutSvg } from '@/assets/menu/menu-logout-icon.svg'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import MerchantName from '@/components/merchants/MerchantName'
import Spacer from '@/components/Spacer'
import { MENU_ITEMS, MORE_MENU_ITEMS } from '@/constants/menuItem'
import { useAuth } from '@/contexts/authContext'
import { profileSelector } from '@/redux/profile/profileSelector'

import Menu from './layout/Sidebar/Menu'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isNarrowed ? '' : '241px')};
  height: 100%;
  background-color: white;
  transition: margin 0.2s ease-in-out;
`

const Content = styled.div`
  flex: 1;
`

const MenuContainer = styled.div``

const Footer = styled.div`
  background-color: #f5f6f7;
  padding: 16px 24px;
`

const ProfileContainer = styled.div`
  width: 100%;
`

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileName = styled.div`
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 6px;
`

const ProfileRole = styled.div`
  font-size: 12px;
  color: black;
  font-weight: bold;
`

const LogoutButton = styled(Button)`
  border: ${(props) => (props.isNarrowed ? '' : '1px solid black')};
  background: white;
  padding: 10px 8px;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  position: relative;
`

const HeaderLogoContainer = styled.div`
  background-color: #ffcc33;
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
`

const RightBtnBox = styled.div`
  position: absolute;
  right: -16px;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
`

const ArrowIconBox = styled.div`
  background-color: white;
  border-radius: 9999px;
  width: 32px;
  height: 32px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
`

const SideBar = ({ show }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isLoggedIn, logout } = useAuth()
  const [hasMilesService, setHasMilesSerivce] = useState(false)
  const [hasPaynowService, setHasPaynowService] = useState(false)
  const [isNarrowed, setIsNarrowed] = useState(false)
  const [isDivitMarket, setIsDivitMarket] = useState(false)

  const profile = useSelector(profileSelector)

  useEffect(() => {
    setHasMilesSerivce(
      (profile?.subscribedServices || '').indexOf('miles') >= 0
    )
    setHasPaynowService(
      (profile?.subscribedServices || '').indexOf('paynow') >= 0
    )
    setIsDivitMarket(profile?.merchantID === 'DIVITMARKET')
  }, [profile])

  const { firstName, lastName } = profile
  const name = `${firstName || ''} ${lastName || ''}`.trim()
  const merchantID = profile?.merchantID

  const onClickLogout = () => {
    dispatch({ type: 'app/hideSideBar' })
    logout()
  }

  if (!isLoggedIn) return null

  const SidebarCx = classNames(
    'transition-[margin-left] ease-in-out duration-500 fixed lg:static flex flex-col top-0 bottom-0 left-0 z-40 bg-white ml-0 border-r border-gray-200',
    {
      'min-w-[74px] w-[74px] lg:transition-[width] ease-in-out duration-500':
        isNarrowed,
      'w-[260px]': isNarrowed,
      '': show,
      'ml-[-260px] lg:ml-0': !show,
    }
  )

  return (
    <div className={SidebarCx}>
      {/* <Background onClick={onClickClose} /> */}
      <Container isNarrowed={isNarrowed}>
        <Content>
          <HeaderLogoContainer
            className="cursor-pointer"
            onClick={() => history.push('/home')}
          >
            {isNarrowed ? (
              <Icon renderImage={() => <DivitNoneTextSvg />} />
            ) : (
              <Icon
                renderImage={() => <DivitSvg />}
                align="left"
                width="3.74rem"
                height="1.33rem"
              />
            )}
            <RightBtnBox>
              <ArrowIconBox onClick={() => setIsNarrowed(!isNarrowed)}>
                {isNarrowed ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardArrowLeftIcon />
                )}
              </ArrowIconBox>
            </RightBtnBox>
          </HeaderLogoContainer>
          <MenuContainer>
            {MENU_ITEMS.map((item) => {
              if (
                !item?.only ||
                (isDivitMarket && item?.only === 'DIVITMARKET')
              )
                return (
                  <Menu
                    icon={() => <item.Icon />}
                    key={item.key}
                    title={item.title}
                    route={item.path}
                    childrenArr={item.children}
                    narrowed={isNarrowed}
                    hasMilesService={hasMilesService}
                    hasPaynowService={hasPaynowService}
                  />
                )
              return null
            })}
          </MenuContainer>
          <div className="px-6 py-2">
            <Divider />
          </div>
          <MenuContainer>
            {MORE_MENU_ITEMS.map((item) => (
              <Menu
                icon={() => <item.Icon />}
                key={item.key}
                title={item.title}
                route={item.path}
                childrenArr={item.children}
                narrowed={isNarrowed}
              />
            ))}
          </MenuContainer>
        </Content>
        <Footer>
          <ProfileContainer>
            <ProfileDetails>
              {!isNarrowed && (
                <>
                  <ProfileName>{name}</ProfileName>
                  <ProfileRole>
                    <MerchantName merchantID={merchantID} />
                  </ProfileRole>
                  <Spacer height="0.68rem" />
                </>
              )}
              <LogoutButton isNarrowed={isNarrowed} onClick={onClickLogout}>
                <div className="absolute top-1">
                  <Icon renderImage={() => <LogoutSvg />} />
                </div>{' '}
                {isNarrowed ? '' : 'log out'}
              </LogoutButton>
            </ProfileDetails>
          </ProfileContainer>
        </Footer>
      </Container>
    </div>
  )
}

export default SideBar

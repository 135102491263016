import styled from 'styled-components'

const themes = {
  main: {
    backgroundColor: '#FFCC33',
    color: '#1C1C1C',
    borderColor: '#FFCC33',
  },
  bw: {
    backgroundColor: '#1C1C1C',
    color: 'white',
    borderColor: '#DEDEDE',
  },
}

const Button = styled.button`
  background-color: ${({ type, bordered }) =>
    bordered ? 'transparent' : themes[type].backgroundColor};
  color: ${({ type, bordered }) =>
    bordered ? themes[type].backgroundColor : themes[type].color};
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: ${({ size }) => size || '0.88rem'};
  width: ${({ type }) => (type === 'stretch' ? '100%' : 'auto')};
  border: ${({ type, bordered }) =>
    bordered ? `1px solid ${themes[type].borderColor}` : 'none'};
  border-radius: 2rem;
  padding: 1rem 0;
  letter-spacing: 0.2px;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 0.2s;

  &:active:enabled,
  &:hover:enabled {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.2;
  }
`

Button.defaultProps = {
  type: 'main',
}

export default Button

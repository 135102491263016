import styled from 'styled-components'

const Content = styled.div`
  flex: 1;
  position: relative;
  box-sizing: border-box;

  padding-left: ${({ hasPadding }) => hasPadding && 'calc(100% / 24)'};
  padding-right: ${({ hasPadding }) => hasPadding && 'calc(100% / 24)'};
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.xs}px`}) {
    min-width: 360px;
  }
`

export default Content

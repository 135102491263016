import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as GreenTickSvg } from '@/assets/common/green-tick.svg'
import Icon from '@/components/Icon'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'

const Container = styled.div``

const PasswordStrengthMeterContainer = styled.div`
  width: 100%;
  margin: 0.889rem 0 0 0;
`

const ValidationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.67rem;
  color: ${({ fulfilled }) => (fulfilled ? '#00b76f' : '#1c1c1c')};
  opacity: ${({ fulfilled }) => (fulfilled ? '1' : '0.5')};

  svg {
    border: ${({ fulfilled }) =>
      fulfilled ? '1px solid #3ca96a' : '1px solid #1c1c1c'};
    color: ${({ fulfilled }) => (fulfilled ? '#3ca96a' : '#1c1c1c')};
  }
`

const TickIcon = styled(Icon).attrs({
  renderImage: () => <GreenTickSvg />,
})`
  margin: 0 0.44rem;
  height: 0.44rem;
  padding-right: 0.22rem;

  svg {
    border: 0px solid transparent;
    path {
      stroke: ${({ fulfilled }) =>
        fulfilled ? '#13CC93' : '#1c1c1c'} !important;
    }
  }
`

const PasswordValidation = ({
  hasNumber,
  hasUppercase,
  hasLowercase,
  hasSufficientLength,
  hasSymbol,
  strengthScore,
  ignored,
}) => {
  const intl = useIntl()

  return (
    <Container>
      <PasswordStrengthMeterContainer>
        <PasswordStrengthMeter score={strengthScore} />
      </PasswordStrengthMeterContainer>
      {!ignored && (
        <ValidationContainer>
          <Row fulfilled={hasSufficientLength}>
            <TickIcon fulfilled={hasSufficientLength} />
            <div>
              {intl.formatMessage({
                id: 'password.valid.length8',
              })}
            </div>
          </Row>
          <Row fulfilled={hasNumber}>
            <TickIcon fulfilled={hasNumber} />
            <div>
              {intl.formatMessage({
                id: 'password.valid.number',
              })}
            </div>
          </Row>
          <Row fulfilled={hasLowercase}>
            <TickIcon fulfilled={hasLowercase} />
            <div>
              {intl.formatMessage({
                id: 'password.valid.lowercase',
              })}
            </div>
          </Row>
          <Row fulfilled={hasUppercase}>
            <TickIcon fulfilled={hasUppercase} />
            <div>
              {intl.formatMessage({
                id: 'password.valid.uppercase',
              })}
            </div>
          </Row>
          <Row fulfilled={hasSymbol}>
            <TickIcon fulfilled={hasSymbol} />
            <div>
              {intl.formatMessage({
                id: 'password.valid.symbol',
              })}
            </div>
          </Row>
        </ValidationContainer>
      )}
    </Container>
  )
}

PasswordValidation.propTypes = {
  hasNumber: PropTypes.bool.isRequired,
  hasSufficientLength: PropTypes.bool.isRequired,
  hasLowercase: PropTypes.bool.isRequired,
  hasUppercase: PropTypes.bool.isRequired,
  strengthScore: PropTypes.number.isRequired,
  ignored: PropTypes.bool.isRequired,
}

export default PasswordValidation

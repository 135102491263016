import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

const Button = styled.div``

const FastClickButton = ({ onPress, ...rest }) => {
  if (isMobileOnly) {
    return <Button onTouchStart={onPress} {...rest} />
  }
  return <Button onClick={onPress} {...rest} />
}

export default FastClickButton

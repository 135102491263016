import colors from '../colors'

const divit = {
  bubble: {
    background: colors.divitDarkPurple,
    text: colors.white,
  },
  ticket: {
    primary: colors.divitLightBlue,
    secondary: colors.divitOrange,
  },
  backButton: {
    text: colors.divitLightBlue,
  },
}

export default divit

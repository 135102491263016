import styled from 'styled-components'

export default styled.div`
  color: #0a9dfd;
  cursor: pointer;
  font-size: 0.78rem;

  &:hover {
    color: #048bf1;
  }
`

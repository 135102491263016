import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { BackButtonContainer } from '@/components/common/BackButton'
import Button from '@/components/common/Button'
import InputField from '@/components/common/InputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const ActionButton = styled(Button)`
  width: 100%;
  font-size: 0.89rem;
`

const schema = Yup.object().shape({
  email: Yup.string().email('invalid email format').required('required'),
})

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const ForgotPassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useAuth()
  const location = useLocation()
  const { executeRecaptcha } = useCaptcha()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [errorMessage, setErrorMessage] = useState('')

  const { forgotPassword } = useSelector((state) => state.resetPassword)

  useEffect(() => {
    dispatch({ type: 'resetPassword/reset' })
  }, [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (token) {
      history.replace('/home')
    }
    const email = location.state?.email || ''
    setValue('email', email)
  }, [])

  useEffect(() => {
    if (forgotPassword.isError) {
      setErrorMessage(
        intl.formatMessage({ id: 'error.action.forgot.password' })
      )
    } else {
      setErrorMessage(null)
    }
  }, [forgotPassword])

  const onClickBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const onClickSubmit = handleSubmit(async (data) => {
    setErrorMessage('')

    const { email } = data

    let recapResp = ''
    try {
      recapResp = await executeRecaptcha('FORGOT_PWD')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'resetPassword/forgotPassword',
      payload: {
        email,
        source: 'business',
        recapResp,
      },
    })
  })

  return (
    <PageContainer>
      <PageContent hasPadding>
        <BackButtonContainer onClick={onClickBack} />
        <Spacer height={globalStyles.px.lg2} />
        <Title>forgot password?</Title>
        <Spacer height={globalStyles.px.xs} />
        {!forgotPassword.isSuccess && (
          <>
            <SubTitle>enter your email to reset your password</SubTitle>
            <Spacer height="0.65rem" />
            {errorMessage && (
              <>
                <ErrorMessageLine errorMessage={errorMessage} />
                <Spacer height={globalStyles.px.xs} />
              </>
            )}
            <Form>
              <FormRow>
                <FormColumn>
                  <InputField
                    type="email"
                    id="email"
                    data-testid="forgot-password.email"
                    title="email"
                    {...register('email')}
                    placeholder="divit@gmail.com"
                    error={getErrorMessage(errors.email)}
                  />
                </FormColumn>
              </FormRow>
              <Spacer height={globalStyles.px.lg} />
              <ActionButton type="bw" onClick={onClickSubmit}>
                submit
              </ActionButton>
            </Form>
          </>
        )}
        {forgotPassword.isSuccess && (
          <>
            <SubTitle>
              {`the reset password notice will be sent to ${getValues(
                'email'
              )}`}
            </SubTitle>
            <Spacer height={globalStyles.px.lg} />
            <ActionButton type="bw" onClick={onClickBack}>
              login
            </ActionButton>
          </>
        )}
        <Spacer height="2.22rem" />
      </PageContent>
    </PageContainer>
  )
}

export default (props) => (
  <CaptchaProvider>
    <ForgotPassword {...props} />
  </CaptchaProvider>
)

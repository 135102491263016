import moment from 'moment'
import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import { downloadFile } from '@/utils/download'

import { getError, getErrorMessage } from '../utils/error'
import { actions as batchSendMilesActions } from './batchSendMilesSlice'

export function* batchGenerate({ payload }) {
  try {
    const { merchantID } = payload
    yield put(batchSendMilesActions.batchSendMilesStart())
    const batchSendMilesRespData = yield call(() =>
      DivitAPI.post(
        `/miles/merchants/${merchantID}/issue/batch/v2/generate`,
        payload,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )
    )

    yield put(
      batchSendMilesActions.batchSendMilesSuccess(batchSendMilesRespData.data)
    )
  } catch (err) {
    yield put(batchSendMilesActions.batchSendMilesFailed(getError(err)))
  }
}

export function* batchSendMiles({ payload }) {
  try {
    yield put(batchSendMilesActions.batchSendMilesStart())
    const batchSendMilesRespData = yield call(() =>
      DivitAPI.post(
        `/miles/merchants/${payload.get('merchantID')}/issue/batch/v2`,
        payload,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
    )

    yield put(
      batchSendMilesActions.batchSendMilesSuccess(batchSendMilesRespData.data)
    )
  } catch (err) {
    yield put(batchSendMilesActions.batchSendMilesFailed(getError(err)))
  }
}

// export function* batchSendAndConvertMiles({ payload }) {
//   try {
//     yield put(batchSendMilesActions.batchSendMilesStart())
//     const batchSendMilesRespData = yield call(() =>
//       DivitAPI.post(
//         `/miles/merchants/${payload.get('merchantID')}/issue/batch/convert`,
//         payload,
//         {
//           headers: {
//             'content-type': 'multipart/form-data',
//           },
//         }
//       )
//     )

//     yield put(
//       batchSendMilesActions.batchSendMilesSuccess(batchSendMilesRespData.data)
//     )
//   } catch (err) {
//     yield put(batchSendMilesActions.batchSendMilesFailed(getError(err)))
//   }
// }

export function* downloadBatchSendMilesTemplate({ payload }) {
  try {
    yield put(batchSendMilesActions.downloadBatchSendMilesTemplateStart())
    yield call(() =>
      downloadFile({
        customFetch: (url) =>
          DivitAPI.get(url, {
            responseType: 'blob',
          }).then((resp) => resp.data),
        url: payload.directConvertMode
          ? '/miles/batch/template/issueandconvertmiles'
          : '/miles/batch/template/issuemilesmemberv2',
        filename: payload.directConvertMode
          ? 'batch_issue_and_convert_template.xlsx'
          : 'batch_issue_miles_template_member_v2.xlsx',
      })
    )
    yield put(batchSendMilesActions.downloadBatchSendMilesTemplateSuccess({}))
  } catch (e) {
    yield put(
      batchSendMilesActions.downloadBatchSendMilesTemplateFailed(
        getErrorMessage(e)
      )
    )
  }
}

export function* fetchBatchSendMilesRecords({ payload }) {
  try {
    yield put(batchSendMilesActions.fetchBatchSendMilesStart())
    const params = {
      merchantID: payload.merchantID,
    }
    const batchSendMilesRespData = yield call(() =>
      DivitAPI.get('miles/batch', { params })
    )
    yield put(
      batchSendMilesActions.fetchBatchSendMilesSuccess(
        batchSendMilesRespData.data
      )
    )
  } catch (err) {
    yield put(batchSendMilesActions.fetchBatchSendMilesFailed(getError(err)))
  }
}

export function* downloadBatchSendMilesResult({ payload }) {
  try {
    yield put(batchSendMilesActions.downloadBatchSendMilesResultStart())
    yield call(() =>
      downloadFile({
        customFetch: (url) =>
          DivitAPI.get(url, {
            responseType: 'blob',
          }).then((resp) => resp.data),
        url: `miles/batch/${payload.processID}/result`,
        filename: `batch_issue_miles_${moment().unix()}.${
          payload.contentType === 'text/csv' ? 'csv' : 'xlsx'
        }`,
      })
    )
    yield put(batchSendMilesActions.downloadBatchSendMilesResultSuccess({}))
  } catch (e) {
    yield put(
      batchSendMilesActions.downloadBatchSendMilesResultFailed(
        getErrorMessage(e)
      )
    )
  }
}

export default null

import { call, put } from 'redux-saga/effects'

import misc from '@/constants/types'
import { fetchFastPayNowOrder } from '@/redux/order/orderSaga'
import DivitAPI from '@/services/api'
import DivitFastAPI from '@/services/apiFast'

import { getError } from '../utils/error'
import { actions as topupMilesActions } from './topupMilesSlice'

export function* topupMiles({ payload }) {
  try {
    yield put(topupMilesActions.topupMilesStart())
    const requestOrderRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/topup', {
        miles: payload.miles,
        currency: 'HKD',
        messageContent: payload.messageContent,
        emails: [...payload.emails],
        source: misc.SOURCE,
        bookID: payload.bookID,
      })
    )
    const { orderID, token } = requestOrderRespData.data.data

    DivitFastAPI.setAuthHeader(token)

    // get order
    const payNowOrder = yield fetchFastPayNowOrder({ orderId: orderID })

    yield put(topupMilesActions.topupMilesSuccess(payNowOrder.data))
  } catch (err) {
    yield put(topupMilesActions.topupMilesFailed(getError(err)))
  }
}

export function* topupMilesSend({ payload }) {
  try {
    yield put(topupMilesActions.topupMilesStart())
    const requestOrderRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/topup/send', {
        miles: payload.miles,
        currency: 'HKD',
        messageContent: payload.messageContent,
        emails: [...payload.emails],
        source: misc.SOURCE,
        bookID: payload.bookID,
      })
    )
    yield put(topupMilesActions.topupMilesSuccess(requestOrderRespData.data))
  } catch (err) {
    yield put(topupMilesActions.topupMilesFailed(getError(err)))
  }
}

export default null

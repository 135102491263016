import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { merchantsSelector } from '@/redux/lookup/lookupSelector'

const Wrap = styled.span``

export const getMerchantName = (merchants, merchantID) => {
  const merchant = merchants.find((m) => m.merchantID === merchantID)
  if (merchant) return merchant.merchantName || merchant.name || ''
  return ''
}

export const getMerchant = (merchants, merchantID) =>
  merchants.find((m) => m.merchantID === merchantID)

const MerchantName = ({ className, merchantID }) => {
  const merchants = useSelector(merchantsSelector)
  const merchantName = getMerchantName(merchants, merchantID)
  return <Wrap className={className}>{merchantName}</Wrap>
}

export default MerchantName

import { ReactComponent as DivitPromotionsIconSvg } from '@/assets/menu/menu-divit-promotion-2.svg'
import { ReactComponent as MilesSvg } from '@/assets/menu/menu-miles.svg'
import { ReactComponent as LinkSocialIconSvg } from '@/assets/menu/menu-person-icon-2.svg'
import { ReactComponent as CreatePaymentIconSvg } from '@/assets/menu/menu-qr-code.svg'
import { ReactComponent as SettingsIconSvg } from '@/assets/menu/menu-settings.svg'
import { ReactComponent as SetupTeamIconSvg } from '@/assets/menu/menu-team-setup.svg'

const MENU_ITEMS = [
  {
    key: 'separator-0',
    title: 'payment',
    admin: false,
    Icon: CreatePaymentIconSvg,
    service: 'paynow',
    path: '/payment',
    children: [
      {
        key: 'create_payment',
        title: 'create payment',
        path: '/payment/create-payment',
        admin: false,
        service: 'paynow',
      },
      {
        key: 'send_payment_request',
        title: 'send invoice',
        path: '/payment/request-payment',
        admin: false,
        service: 'paynow',
      },
      {
        key: 'send_invoices',
        title: 'batch invoices',
        path: '/payment/batch-invoices',
        admin: false,
        service: 'paynow',
      },
      {
        key: 'invoice_records',
        title: 'manage invoice',
        path: '/payment/invoices',
        admin: false,
      },
      {
        key: 'transaction_history',
        title: 'payment history',
        path: '/payment/transactions',
        admin: false,
      },
    ],
  },

  /* hidden sidebar miles menu items */

  // {
  //   key: 'separator-1',
  //   title: 'miles',
  //   admin: true,
  //   Icon: MilesSvg,
  //   service: 'miles',
  //   path: '/miles',
  //   children: [
  //     {
  //       key: 'topup_miles',
  //       title: 'buy divit miles',
  //       path: '/miles/buy-miles',
  //       admin: true,
  //       service: 'miles',
  //     },
  //     {
  //       key: 'send_miles',
  //       title: 'reward miles',
  //       path: '/miles/send-miles',
  //       admin: true,
  //       service: 'miles',
  //     },
  //     {
  //       key: 'batch_send_miles',
  //       title: 'reward batch miles',
  //       path: '/miles/batch-send-miles',
  //       admin: true,
  //       service: 'miles',
  //     },
  //     {
  //       key: 'miles_transactions',
  //       title: 'miles history',
  //       path: '/miles/miles-transactions',
  //       admin: true,
  //     },
  //   ],
  // },

  {
    key: 'divit_promotion',
    title: 'divit promotions',
    Icon: DivitPromotionsIconSvg,
    path: '/divit-promotions',
    admin: true,
    only: 'DIVITMARKET',
  },
].filter((m) => m)

const MORE_MENU_ITEMS = [
  {
    key: 'change_password',
    title: 'change password',
    Icon: LinkSocialIconSvg,
    path: '/change-password',
    admin: false,
  },
  false && {
    key: 'link_social',
    title: 'link social account',
    Icon: LinkSocialIconSvg,
    path: '/link-social',
  },
  {
    key: 'setup_team',
    title: 'team setup',
    Icon: SetupTeamIconSvg,
    path: '/setup-team',
    admin: true,
  },
  {
    key: 'settings',
    title: 'settings',
    Icon: SettingsIconSvg,
    path: '/settings',
    admin: true,
  },
].filter((m) => m)

export { MORE_MENU_ITEMS, MENU_ITEMS }

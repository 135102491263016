import { takeEvery } from 'redux-saga/effects'

import * as authSaga from './auth/authSaga'
import * as divitMilesSaga from './divitMiles/divitMilesSaga'
import * as gtmSaga from './gtm/gtmSaga'
import * as batchOrderSaga from './pages/batchOrderSaga'
import * as batchSendMilesSaga from './pages/batchSendMilesSaga'
import * as changePasswordSaga from './pages/changePasswordSaga'
import * as claimDivitMilesSaga from './pages/claimDivitMilesSaga'
import * as createPaymentSaga from './pages/createPaymentSaga'
import * as invoiceRecordSaga from './pages/invoiceRecordSaga'
import * as milesTransactionsSaga from './pages/milesTransactionsSaga'
import * as pageInitSaga from './pages/pageInitSaga'
import * as paynowFastFpsSaga from './pages/paynowFastFpsSaga'
import * as requestPaymentSaga from './pages/requestPaymentSaga'
import * as resetPasswordSaga from './pages/resetPasswordSaga'
import * as sendMilesSaga from './pages/sendMilesSaga'
import * as setupTeamSaga from './pages/setupTeamSaga'
import * as topupMilesSaga from './pages/topupMilesSaga'
import * as transactionHistorySaga from './pages/transactionHistorySaga'
import * as pinSaga from './pin/pinSaga'
import * as profileSaga from './profile/profileSaga'

export default function* rootSaga() {
  // auth
  yield takeEvery('login', authSaga.login)
  yield takeEvery('loginRedirect', authSaga.loginRedirect)
  yield takeEvery('verifyProfile', authSaga.verifyProfile)
  yield takeEvery('logout', authSaga.logout)
  yield takeEvery('merchantSignup', authSaga.merchantSignup)
  yield takeEvery('signUp', authSaga.signUp)
  yield takeEvery('clearSignUp', authSaga.clearSignUp)
  yield takeEvery('tokenExpiredRedirect', authSaga.tokenExpiredRedirect)
  yield takeEvery('redirectReturnUrlOrHome', authSaga.redirectReturnUrlOrHome)

  // profile
  yield takeEvery('fetchProfile', profileSaga.fetchProfile)
  yield takeEvery('patchProfile', profileSaga.patchProfile)
  yield takeEvery('patchProfileConfig', profileSaga.patchProfileConfig)
  // yield takeEvery('fetchReferralLink', profileSaga.fetchCorporateReferralLink)
  yield takeEvery(
    'divitMiles/fetchMerchantMilesBooks',
    divitMilesSaga.fetchMerchantMilesBooks
  )

  // pin
  yield takeEvery('pin/authPin', pinSaga.authPin)
  yield takeEvery('pin/setupPin', pinSaga.setupPin)
  yield takeEvery('pin/validatePin', pinSaga.validatePin)
  yield takeEvery('pin/forgotPin', pinSaga.forgotPin)
  yield takeEvery('pin/validatePinOTP', pinSaga.validatePinOTP)

  // page initialize
  yield takeEvery('pageInit/initApp', pageInitSaga.initApp)

  // create payment
  yield takeEvery(
    'createPayment/createPayment',
    createPaymentSaga.createPayment
  )
  yield takeEvery(
    'createPayment/createDirectMilesPayment',
    createPaymentSaga.createDirectMilesPayment
  )

  // request payment
  yield takeEvery(
    'requestPayment/requestPayment',
    requestPaymentSaga.requestPayment
  )

  // miles
  yield takeEvery('topupMiles/topupMiles', topupMilesSaga.topupMiles)
  yield takeEvery('topupMiles/topupMilesSend', topupMilesSaga.topupMilesSend)
  yield takeEvery('sendMiles/sendMiles', sendMilesSaga.sendMiles)
  yield takeEvery(
    'batchSendMiles/batchGenerateTokens',
    batchSendMilesSaga.batchGenerate
  )
  yield takeEvery(
    'batchSendMiles/batchSendMiles',
    batchSendMilesSaga.batchSendMiles
  )
  // yield takeEvery(
  //   'batchSendMiles/batchSendAndConvertMiles',
  //   batchSendMilesSaga.batchSendAndConvertMiles
  // )
  yield takeEvery(
    'batchSendMiles/downloadBatchSendMilesTemplate',
    batchSendMilesSaga.downloadBatchSendMilesTemplate
  )
  yield takeEvery(
    'batchSendMiles/fetchBatchResults',
    batchSendMilesSaga.fetchBatchSendMilesRecords
  )
  yield takeEvery(
    'batchSendMiles/downloadBatchSendMilesResult',
    batchSendMilesSaga.downloadBatchSendMilesResult
  )
  yield takeEvery(
    'milesTransactions/getMilesTransactions',
    milesTransactionsSaga.getMilesTransactions
  )
  yield takeEvery(
    'milesTransactions/getMilesTransactionWeeklyReport',
    milesTransactionsSaga.getMilesTransactionWeeklyReport
  )
  yield takeEvery(
    'milesTransactions/downloadMilesTransactionWeeklyReport',
    milesTransactionsSaga.downloadMilesTransactionWeeklyReport
  )

  // batch order
  yield takeEvery('batchOrder/batchOrder', batchOrderSaga.batchUploadOrder)
  yield takeEvery(
    'batchOrder/downloadTemplate',
    batchOrderSaga.downloadBatchInvoiceTemplate
  )

  // paynow fast
  yield takeEvery('paynowFastFps/fetchOrder', paynowFastFpsSaga.fetchOrder)
  yield takeEvery(
    'paynowFastFps/generateFpsLink',
    paynowFastFpsSaga.generateFpsLink
  )
  yield takeEvery(
    'paynowFastFps/fpsRefreshOrder',
    paynowFastFpsSaga.fpsRefreshOrder
  )
  yield takeEvery(
    'paynowFastFps/requestShareEmail',
    paynowFastFpsSaga.requestShareEmail
  )

  // transaction history
  yield takeEvery(
    'transactionHistory/getTransactionDailyList',
    transactionHistorySaga.getTransactionDailyList
  )
  yield takeEvery(
    'transactionHistory/getTransactionDailyDetails',
    transactionHistorySaga.getTransactionDailyDetails
  )
  yield takeEvery(
    'transactionHistory/searchTransactionDailyDetails',
    transactionHistorySaga.searchTransactionDailyDetails
  )
  yield takeEvery(
    'transactionHistory/getTransactionDailyReport',
    transactionHistorySaga.getTransactionDailyReport
  )
  yield takeEvery(
    'transactionHistory/downloadTransactionDailyReport',
    transactionHistorySaga.downloadTransactionDailyReport
  )

  // invoice records
  yield takeEvery(
    'invoiceRecord/getInvoiceRecordList',
    invoiceRecordSaga.getInvoiceRecordList
  )
  yield takeEvery('invoiceRecord/cancelOrder', invoiceRecordSaga.cancelOrder)
  yield takeEvery('invoiceRecord/extendOrder', invoiceRecordSaga.extendOrder)

  // change password
  yield takeEvery(
    'changePassword/changePassword',
    changePasswordSaga.changePassword
  )

  // reset password
  yield takeEvery(
    'resetPassword/forgotPassword',
    resetPasswordSaga.forgotPassword
  )
  yield takeEvery('resetPassword/verifyToken', resetPasswordSaga.verifyToken)
  yield takeEvery(
    'resetPassword/resetPassword',
    resetPasswordSaga.resetPassword
  )

  // invite user
  yield takeEvery('setupTeam/inviteUser', setupTeamSaga.inviteUser)
  yield takeEvery('setupTeam/getUserList', setupTeamSaga.getUserList)
  yield takeEvery('setupTeam/updateUser', setupTeamSaga.updateUser)

  // claim divit miles
  yield takeEvery('claimDivitMiles/fetchOrder', claimDivitMilesSaga.fetchOrder)
  yield takeEvery(
    'claimDivitMiles/fetchTopupOrder',
    claimDivitMilesSaga.fetchTopupOrder
  )

  // gtm
  yield takeEvery('gtm/sendEvent', gtmSaga.sendEvent)
}

import moment from 'moment'
import qs from 'qs'
import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'
import { actions as invoiceRecordActions } from './invoiceRecordSlice'

const REPORT_DATE_FORMAT = 'YYYY-MM-DD'

export function* cancelOrder({ payload }) {
  try {
    const { orderID } = payload
    yield put(invoiceRecordActions.updateInvoiceOrderStart())
    yield call(() => DivitAPI.delete(`/paynow/orders/${orderID}`))
    yield put(invoiceRecordActions.updateInvoiceOrderSuccess())
  } catch (e) {
    yield put(invoiceRecordActions.updateInvoiceOrderFailed(getErrorMessage(e)))
  }
}

export function* extendOrder({ payload }) {
  try {
    const { orderID, expiryDate } = payload
    yield put(invoiceRecordActions.updateInvoiceOrderStart())
    yield call(() => DivitAPI.patch(`/paynow/orders/extend`, { orderID, expiryDate }))
    yield put(invoiceRecordActions.updateInvoiceOrderSuccess())
  } catch (e) {
    yield put(invoiceRecordActions.updateInvoiceOrderFailed(getErrorMessage(e)))
  }
}

// include today
export function* getInvoiceRecordList({ payload }) {
  try {
    const { keyword, fromDate, toDate, page } = payload
    const params = {
      fromDate: moment(fromDate).format(REPORT_DATE_FORMAT),
      toDate: moment(toDate).add(1, 'days').format(REPORT_DATE_FORMAT),
      page,
      pageSize: 10,
      sentInvoice: true,
    }
    const headers = {
      keyword,
    }
    yield put(invoiceRecordActions.getInvoiceRecordListStart(page))
    const { data } = yield call(() =>
      DivitAPI.get('/paynow/orders', {  
        headers: {
          "x-divit-filters": qs.stringify(headers),
        },
        params })
    )
    yield put(
      invoiceRecordActions.getInvoiceRecordListSuccess(data)
    )
  } catch (e) {
    yield put(
      invoiceRecordActions.getInvoiceRecordListFailed(
        getErrorMessage(e)
      )
    )
  }
}

export default null

import { useDebounceEffect } from 'ahooks'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as ExpandSvg } from '@/assets/history/expand.svg'
import { ReactComponent as UnexpandSvg } from '@/assets/history/unexpand.svg'
import DateSelectField, {
  getDefaultDateValue,
} from '@/components/common/DateSelectField'
import SearchInputField from '@/components/common/SearchInputField'
import EmptyResult from '@/components/EmptyResult'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingSpinner from '@/components/LoadingSpinner'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { FormattedPrice } from '@/utils/Price'

const Container = styled.div``

const Content = styled.div`
  background-color: white;
`

const ErrorContainer = styled.div`
  padding: 0 1.333rem;
`

const Filters = styled.div`
  padding: 0.889rem 1.333rem;
`

const LoadingContainer = styled.div``

const List = styled.div``

const Footer = styled.div`
  width: 100%;
  height: 1.333rem;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ListItem = styled.div``

const ItemHeader = styled.div`
  background-color: #fafafa;
  padding: 0 1.333rem;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
`

const ItemDate = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`

const TotalDailySales = styled.div`
  font-size: 0.889rem;
`

const TotalAmount = styled.div`
  font-size: 1rem;
  font-weight: 600;
`

const TxnList = styled.div`
  background-color: white;
  padding: 0 1.333rem;
`

const TxnItem = styled.div`
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`

const TxnRef = styled.div`
  font-size: 0.778rem;
`

const TxnAmount = styled.div`
  font-size: 0.778rem;
  font-weight: 600;
`

const TxnDate = styled.div`
  color: #979797;
  font-size: 0.667rem;
`

const TxnDayItem = styled.div`
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }
`

const TxnDay = styled.div`
  margin-top: 0.889rem;
  font-size: 0.889rem;
  font-weight: 600;
`

const TransactionHistoryListItem = ({
  data,
  items,
  isExpanded = true,
  isLoading,
  onClickExpand,
}) => {
  const date = moment(data.transactionDate).format('LLL')
  const orderAmount = data.totalOrderAmount
  const amount = FormattedPrice(orderAmount.currency, orderAmount.amount)
  const ExpandIcon = isExpanded ? UnexpandSvg : ExpandSvg

  const onClickHeader = () => {
    onClickExpand(data)
  }

  return (
    <ListItem>
      <ItemHeader onClick={onClickHeader}>
        <Spacer height={globalStyles.px.xs} />
        <Row>
          <ItemDate>{date}</ItemDate>
          <Icon
            renderImage={() => <ExpandIcon />}
            widht="1.111rem"
            height="1.111rem"
          />
        </Row>
        <Spacer height="8px" />
        <Row>
          <TotalDailySales>total daily sales</TotalDailySales>
          <TotalAmount>{amount}</TotalAmount>
        </Row>
        <Spacer height={globalStyles.px.xs} />
      </ItemHeader>
      {isExpanded && isLoading && (
        <>
          <Spacer height={globalStyles.px.xs} />
          <LoadingSpinner />
        </>
      )}
      {isExpanded && !isLoading && (
        <TxnList>
          {items?.map((item) => (
            <TransactionItem key={item.transactionDate} item={item} />
          ))}
        </TxnList>
      )}
    </ListItem>
  )
}

const TransactionListByDay = ({ items }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    const groups = items.reduce((obj, txn) => {
      const day = moment(txn.transactionDate).format('YYYY-MM-DD')
      obj[day] = obj[day] || []
      obj[day].push(txn)
      return obj
    }, {})
    setList(groups)
  }, [items])

  return (
    <TxnList>
      {Object.keys(list).map((day) => {
        const txns = list[day]
        return (
          <TxnDayItem key={day}>
            <TxnDay>{day}</TxnDay>
            {txns.map((item) => (
              <TransactionItem key={item.transactionDate} item={item} />
            ))}
          </TxnDayItem>
        )
      })}
    </TxnList>
  )
}

const TransactionItem = ({ item }) => (
  <TxnItem>
    <Spacer height={globalStyles.px.xs} />
    <Row>
      <TxnRef>{`#${item.merchantRef}`}</TxnRef>
      <TxnAmount>
        {FormattedPrice(item.orderAmount.currency, item.orderAmount.amount)}
      </TxnAmount>
    </Row>
    <Spacer height="4px" />
    <Row>
      <TxnDate>
        order created at {moment(item.transactionDate).format('HH:mm')}
      </TxnDate>
    </Row>
    <Spacer height={globalStyles.px.xs} />
  </TxnItem>
)

const TransactionDailyList = () => {
  const dispatch = useDispatch()

  const {
    getTransactionDailyList,
    getTransactionDailyDetails,
    searchTransactionDailyDetails,
  } = useSelector((s) => s.transactionHistory)
  const { data: dailyList, isLoading } = getTransactionDailyList
  const {
    data: dailyDetailsList,
    isLoading: isDailyDetailsListLoading,
  } = getTransactionDailyDetails
  const {
    data: searchResults,
    isLoading: isSearching,
  } = searchTransactionDailyDetails

  const [dateValue, setDateValue] = useState(() => getDefaultDateValue())
  const [searchText, setSearchText] = useState('')
  const [expandedId, setExpandedId] = useState('')
  const [isSearchMode, setIsSearchMode] = useState(false)

  useEffect(() => {
    dispatch({
      type: 'transactionHistory/getTransactionDailyList',
      payload: {
        fromDate: dateValue.fromDate,
        toDate: dateValue.toDate,
      },
    })
  }, [dateValue])

  useEffect(() => {
    if (expandedId) {
      const item = dailyList.find((t) => t.transactionDate === expandedId)
      dispatch({
        type: 'transactionHistory/getTransactionDailyDetails',
        payload: {
          date: item.transactionDate,
        },
      })
    }
  }, [expandedId])

  useDebounceEffect(() => {
    const keyword = searchText.trim()
    if (searchText !== '') {
      setIsSearchMode(true)
      dispatch({
        type: 'transactionHistory/searchTransactionDailyDetails',
        payload: {
          keyword,
        },
      })
    } else {
      setIsSearchMode(false)
      dispatch({
        type: 'transactionHistory/clearSearchTransactionDailyDetails',
      })
    }
  }, [searchText])

  const onClickExpandDailyItem = (item) => {
    dispatch({ type: 'transactionHistory/clearTransactionDailyDetails' })
    if (expandedId === item.transactionDate) {
      setExpandedId('')
    } else {
      setExpandedId(item.transactionDate)
    }
  }

  const isEmptyResult =
    (!isLoading && dailyList?.length === 0) ||
    (isSearchMode && !isSearching && searchResults?.length === 0)

  return (
    <Container>
      <Content>
        {getTransactionDailyList.error && (
          <ErrorContainer>
            <Spacer height={globalStyles.px.xs} />
            <ErrorMessageLine errorMessage={getTransactionDailyList.error} />
          </ErrorContainer>
        )}
        <Filters>
          <DateSelectField
            value={dateValue}
            onChange={setDateValue}
            disabled={isSearchMode}
          />
          <Spacer height={globalStyles.px.xs} />
          <SearchInputField
            value={searchText}
            placeholder="search"
            onClickSuffix={() => setSearchText('')}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Filters>
        {(isLoading || isSearching) && (
          <LoadingContainer>
            <Spacer height={globalStyles.px.xs} />
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {isEmptyResult && <EmptyResult />}
        {isSearchMode && searchResults && (
          <TransactionListByDay items={searchResults} />
        )}
        <List>
          {!isLoading &&
            !isSearchMode &&
            dailyList?.map((dailyItem) => (
              <div key={dailyItem.transactionDate}>
                <TransactionHistoryListItem
                  data={dailyItem}
                  items={dailyDetailsList}
                  isLoading={isDailyDetailsListLoading}
                  isExpanded={dailyItem.transactionDate === expandedId}
                  onClickExpand={onClickExpandDailyItem}
                />
                <Spacer height={globalStyles.px.xs} />
              </div>
            ))}
        </List>
      </Content>
      <Footer />
    </Container>
  )
}

export default TransactionDailyList

export const getFpsDetails = (payment) => {
  try {
    const { paidCurrency, paidAmount, appLink, webLink, metadata } = payment
    const { FPSID, Payee, PaymentRefID } = JSON.parse(metadata)
    return {
      paidCurrency,
      paidAmount,
      fpsID: FPSID,
      payee: Payee,
      paymentRefID: PaymentRefID,
      appLink,
      webLink,
    }
  } catch (err) {
    console.error(err)
    return null
  }
}

export default 0

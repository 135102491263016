import { actions as modalActions } from './modalSlice'

export const promptConfirmModal = ({
  title = '',
  content = '',
  okText = '',
  closable = true,
}) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title,
        content,
        actions: [
          {
            key: 'ok',
            title: okText || 'ok',
            action: () => {
              resolve({ isOk: true })
            },
          },
          closable && {
            key: 'cancel',
            title: 'cancel',
            action: () => {
              resolve({ isOk: false })
            },
          },
        ].filter((s) => s),
        closable,
      })
    )
  })

export const promptActionModal = ({
  title = '',
  content = '',
  actionText = '',
  closeText = '',
  actionFn,
  closeFn,
  closable = true,
}) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title,
        content,
        actions: [
          {
            key: 'ok',
            title: actionText || 'ok',
            action:
              actionFn ||
              (() => {
                resolve({ isOk: true })
              }),
          },
          closable && {
            key: 'close',
            title: closeText || 'close',
            action:
              closeFn ||
              (() => {
                resolve({ isOk: false })
              }),
          },
        ].filter((s) => s),
        closable,
      })
    )
  })

export const promptOptionsModal = ({
  title = '',
  content = '',
  actionFns,
  closable = true,
}) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(
      modalActions.openModal({
        title,
        content,
        actions: actionFns || [],
        closable,
      })
    )
  })

export default 0

import * as Pagination from '@/utils/pagination'
import { sleep } from '@/utils/promises'

import { fetchMilesTransactionsAsync } from './milesTransactionsSaga'

export const fetchMilesTransactionsNextPage = ({
  merchantID,
  bookID,
  fromDate,
  toDate,
}) => async (dispatch, getState) => {
  await sleep(1000)

  const state = getState()
  const { milesTransactions } = state.milesTransactions
  const { data: list, total } = milesTransactions

  const hasMore = Pagination.hasMore(list, total)
  if (!hasMore) return

  const pageNo = Pagination.getNextPageNo(list)
  const { data } = await fetchMilesTransactionsAsync({
    merchantID,
    bookID,
    fromDate,
    toDate,
    page: pageNo,
    pageSize: 25,
  })
  dispatch({
    type: 'milesTransactions/insertMilesTransactions',
    payload: { total: data.count, data: data.data },
  })
}

export default 0

import { call, put } from 'redux-saga/effects'

import misc from '@/constants/types'
import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as requestPaymentActions } from './requestPaymentSlice'

export function* requestPayment({ payload }) {
  try {
    yield put(requestPaymentActions.requestPaymentStart())

    // request new order
    const newOrder = {
      customer: {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: (payload.email || '').toLowerCase(),
        countryCode: payload.countryCode,
        tel: payload.tel,
        language: 'en',
      },
      order: {
        merchantRef: payload.reference,
        // TODO: uncomment the following code
        // milesForReward: payload.milesForReward,
        milesForReward: 0,
        totalForReward: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        totalAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        nonrefundableAmount: {
          amount: parseFloat(payload.amount) * 100,
          currency: 'HKD',
        },
        refundableAmount: { amount: 0, currency: 'HKD' },
        // TODO: uncomment the following code
        // promoCode: payload.promoCode,
        promoCode: '',
        webhookSuccess: 'NA',
        webhookFailure: 'NA',
        webhookEvents: 'NA',
        expiredAt: payload.expiredAt,
        messageContent: payload.messageContent,
        notifyWhenPaid: payload.notifyWhenPaid,
        orderItems: [],
        source: misc.SOURCE,
      },
    }
    if (payload.ccEmail.length > 0) {
      newOrder.ccEmailList = payload.ccEmail
    }
    const requestOrderRespData = yield call(() =>
      DivitAPI.post('/paynow/orders/send', newOrder)
    )

    yield put(
      requestPaymentActions.requestPaymentSuccess(requestOrderRespData.data)
    )
  } catch (err) {
    yield put(requestPaymentActions.requestPaymentFailed(getError(err)))
  }
}

export default null

import React from 'react'
import styled from 'styled-components'

export const Button = styled.button`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: ${({ size }) => size || '1rem'};
  width: ${({ type }) => (type === 'stretch' ? '100%' : 'auto')};
  border-radius: 1.25rem;
  border: none;
  padding: 0.88rem 1rem;
  white-space: nowrap;
  cursor: pointer;
  background-color: #ffcc33;
  color: #1c1c1c;

  &:disabled {
    opacity: 50%;
  }
`

export const Input = styled.input`
  width: calc(100% - 1.35rem - 2px);
  height: 2.67rem;
  border: 1px solid
    ${({ error, theme }) => (error ? theme.message.error : 'transparent')};
  border-radius: 0.44rem;
  padding: 0 0.78rem;
  font-size: 0.78rem;
  outline: none;

  &:hover {
    border-color: #ccc;
  }

  &:active,
  &:focus {
    border-color: #f7ce55;
  }

  ::placeholder {
    color: ${({ theme }) => theme.placeholder};
  }
`

export const Label = styled.label`
  font-size: 0.78rem;
  margin-bottom: 0.56rem;
  display: inline-block;

  color: ${({ error, theme }) => (error ? theme.message.error : 'unset')};
`

export const HRWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.78rem;

  > span {
    margin: 0 1.33rem;
  }
`

export const Line = styled.div`
  display: block;
  width: 48px;
  height: 1px;
  background-color: #f0f0f0;
`

export const LineHR = ({ children }) => (
  <HRWrapper>
    <Line />
    <span>{children}</span>
    <Line />
  </HRWrapper>
)

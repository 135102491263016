import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const getToken = () => localStorage.getItem('token', '')

const getLastLoginTs = () =>
  parseInt(localStorage.getItem('last_login_ts', Date.now()), 10)

const initialState = {
  token: getToken(),
  lastLoginTs: getLastLoginTs(),
  role: '',
  profile: null,
  campaigns: [],
  payNowRate: null,
  purchaseStatus: null,
  divitMiles: [],
  divitMilesMemberships: [],
  divitMilesTranscations: [],
  merchantBooks: [],
  campaignMissions: [],
  services: [],
  referralLink: null,
  loading: false,
  patching: false,
  error: null,
  ...AsyncState.create(),
}

const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token
    },
    setLastLoginTs: (state, action) => {
      state.lastLoginTs = action.payload.lastLoginTs
    },
    reset: () => initialState,
    getRoleSuccess: (state, action) => {
      state.role = action.payload
    },
    getProfileStart: (state) => {
      state.loading = true
      state.error = null
    },
    getProfileSuccess: (state, action) => {
      state.profile = action.payload
      state.loading = false
      state.error = null
    },
    getProfileFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getCorporateReferralLinkStart: (state, action) => {
      state.loading = true
      state.error = null
    },
    getCorporateReferralLinkSuccess: (state, action) => {
      state.referralLink = action.payload
      state.loading = false
      state.error = null
    },
    getCorporateReferralLinkFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getCampaignsSuccess: (state, action) => {
      state.campaigns = action.payload
    },
    getPayNowRateSuccess: (state, action) => {
      state.payNowRate = action.payload
    },
    patchProfileStart: (state) => {
      state.patching = true
      state.error = null
    },
    patchProfileSuccess: (state) => {
      state.patching = false
      state.error = null
    },
    patchProfileFailed: (state, action) => {
      state.patching = false
      state.error = action.payload
    },
    patchProfileConfigReset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.patching = false
      state.error = null
    },
    patchProfileConfigStart: (state) => {
      state.isLoading = true
      state.isSuccess = false
      state.isError = false
      state.patching = true
      state.error = null
    },
    patchProfileConfigSuccess: (state) => {
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
      state.patching = false
      state.error = null
    },
    patchProfileConfigFailed: (state, action) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = true
      state.patching = false
      state.error = action.payload
    },
    updateSocialMediaLinkage: (state, action) => {
      state.profile.socialMediaLinkage = action.payload
    },
    getPurchaseStatusSuccess: (state, action) => {
      state.purchaseStatus = action.payload
    },
    getDivitMilesSuccess: (state, action) => {
      state.divitMiles = action.payload
    },
    getDivitMilesTransactionsSuccess: (state, action) => {
      state.divitMilesTranscations = action.payload
    },
    getDivitMilesMembershipSuccess: (state, action) => {
      state.divitMilesMemberships = action.payload
    },
    getCampaignMissions: (state, action) => {
      state.campaignMissions = action.payload
    },
    addMilesLinkage: (state, action) => {
      const linkage = state.milesLinkage.concat([action.payload])
      state.milesLinkage = Array.from(new Set(linkage))
    },
    resetMilesLinkage: (state) => {
      state.milesLinkage = []
    },
    getProfileServicesSuccess: (state, action) => {
      state.services = action.payload
    },
  },
})

export const { getProfileStart, getProfileSuccess, getProfileFailed } =
  ProfileSlice.actions

export default ProfileSlice.reducer

/* eslint-disable import/prefer-default-export */
export const Regex = {
    email:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phoneNumber:
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    signatureKey: /^(?=.*[a-z])(?=.*[A-Z])(?=.{12,})/,
    onlyNumber: /^[1-9][0-9]*$/,
    numberDecimal: /^\d*\.?\d*$/,
    numberDecimal2Digit: /^(\d*\.{0,1}\d{0,2}$)/,
    pinNumber: /^[0-9]{1,6}$/,
    password:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
}

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Login from './pages/auth/Login'
// import Signup from './pages/auth/Signup'
// import SignupSuccess from './pages/auth/SignupSuccess'
import ChangePassword from './pages/ChangePassword'
import ClaimMiles from './pages/ClaimMiles'
import CreatePayment from './pages/CreatePayment'
import DirectMiles from './pages/DirectMilesPayment'
import DivitPromotions from './pages/DivitPromotions'
import Error from './pages/Error'
import ForgotPassword from './pages/ForgotPassword'
import Fps from './pages/Fps'
import Home from './pages/Home'
import InvoiceRecords from './pages/InvoiceRecords'
import Landing from './pages/Landing'
import MilesTransactions from './pages/MilesTransactions'
import NotFound from './pages/NotFound'
import RequestPayment from './pages/RequestPayment'
import SendInvoicesInBatch from './pages/SendInvoicesInBatch'
import SendMiles from './pages/SendMiles'
import SendMilesInBatch from './pages/SendMilesInBatch'
import SetPasswordByToken from './pages/SetPasswordByToken'
import Settings from './pages/Settings'
import SetupTeam from './pages/SetupTeam'
import TopupMiles from './pages/TopupMiles'
import TopupSuccess from './pages/TopupSuccess'
import TransactionHistory from './pages/TransactionHistory'
import PrivateRoute from './PrivateRoute'

const TestError = () => {
  const a = {}
  return <div>{a.b.c}</div>
}
const RedirectToNewSignup = () => {
  window.location.replace(`${process.env.REACT_APP_ADMIN_PORTAL_URL}/en/signup`)
}

export const routes = {
  root: {
    name: 'root',
    path: '/',
    component: Landing,
    private: false,
    exact: true,
  },
  testError: {
    name: 'test error',
    path: '/test-error',
    component: TestError,
    private: false,
    exact: true,
  },
  error: {
    name: 'error',
    path: '/error',
    component: Error,
    private: false,
    exact: true,
  },
  login: {
    name: 'login',
    path: '/login',
    component: Login,
    private: false,
    exact: true,
  },
  signup: {
    name: 'signup',
    path: '/signup',
    component: RedirectToNewSignup,
    private: false,
    exact: true,
  },
  // signupSuccess: {
  //   name: 'signup success',
  //   path: '/signup/success',
  //   component: SignupSuccess,
  //   private: false,
  //   exact: true,
  // },
  home: {
    name: 'home',
    path: '/home',
    component: Home,
    private: true,
    exact: true,
  },
  createPayment: {
    name: 'create payment',
    path: '/payment/create-payment',
    component: CreatePayment,
    private: true,
    exact: true,
    service: 'paynow',
  },
  requestPayment: {
    name: 'send invoice',
    path: '/payment/request-payment',
    component: RequestPayment,
    private: true,
    exact: true,
    service: 'paynow',
  },
  sendInvoicesInBatch: {
    name: 'send invoices in batch',
    path: '/payment/batch-invoices',
    component: SendInvoicesInBatch,
    private: true,
    exact: true,
    service: 'paynow',
  },
  Fps: {
    name: 'fps',
    path: '/fps',
    component: Fps,
    private: true,
    exact: true,
  },
  claimMiles: {
    name: 'claim miles',
    path: '/claim-miles',
    component: ClaimMiles,
    private: true,
    exact: true,
  },
  transactionHistory: {
    name: 'transaction history',
    path: '/payment/transactions',
    component: TransactionHistory,
    private: true,
    exact: true,
  },
  invoiceHistory: {
    name: 'invoice records',
    path: '/payment/invoices',
    component: InvoiceRecords,
    private: true,
    exact: true,
  },
  directMiles: {
    name: 'direct miles',
    path: '/direct-miles',
    component: DirectMiles,
    private: true,
    exact: true,
    service: 'paynow',
  },
  divitPromotions: {
    name: 'divit promotion',
    path: '/divit-promotions',
    component: DivitPromotions,
    private: true,
    exact: true,
    service: 'paynow',
  },
  topupMiles: {
    name: 'buy miles',
    path: '/miles/buy-miles',
    component: TopupMiles,
    private: true,
    exact: true,
    service: 'miles',
  },
  topupMilesSuccess: {
    name: 'buy miles success',
    path: '/buy-miles-success',
    component: TopupSuccess,
    private: true,
    exact: true,
  },
  sendMiles: {
    name: 'send miles',
    path: '/miles/send-miles',
    component: SendMiles,
    private: true,
    exact: true,
    service: 'miles',
  },
  sendMilesInBatch: {
    name: 'batch send miles',
    path: '/miles/batch-send-miles',
    component: SendMilesInBatch,
    private: true,
    exact: true,
    service: 'miles',
  },
  milesTransactions: {
    name: 'miles transactions',
    path: '/miles/miles-transactions',
    component: MilesTransactions,
    private: true,
    exact: true,
  },
  setupTeam: {
    name: 'setup team',
    path: '/setup-team',
    component: SetupTeam,
    private: true,
    exact: true,
  },
  settings: {
    name: 'settings',
    path: '/settings',
    component: Settings,
    private: true,
    exact: true,
  },
  changePassword: {
    name: 'change password',
    path: '/change-password',
    component: ChangePassword,
    private: true,
    exact: true,
  },
  setPassword: {
    name: 'set password',
    path: ['/verify/resetpwd/:token', '/set-password/:token'],
    component: SetPasswordByToken,
    private: false,
    exact: true,
  },
  forgotPassword: {
    name: 'forgot password',
    path: '/forgot-password',
    component: ForgotPassword,
    private: false,
    exact: true,
  },
  notFund: {
    name: 'Not Found',
    path: '/not-found',
    component: NotFound,
    private: false,
    exact: true,
  },
}

const Router = () => (
  <Switch>
    {Object.values(routes).map((r) => {
      if (r.private) {
        return (
          <PrivateRoute
            key={r.name}
            path={r.path}
            component={r.component}
            exact={r.exact}
            service={r.service}
          />
        )
      }
      return (
        <Route
          key={r.name}
          path={r.path}
          component={r.component}
          exact={r.exact}
        />
      )
    })}
    <Redirect to="/home" />
  </Switch>
)

export default Router

import React from 'react'
import styled from 'styled-components'

import { ReactComponent as SpinnerSvg } from '@/assets/common/spinner.svg'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    width: 42px;
    height: 42px;
  }
`

const LoadingSpinner = () => (
  <Container>
    <SpinnerSvg />
  </Container>
)

export default LoadingSpinner

import { useEffect, useRef, useState } from 'react'

// hold pin input state

const usePinInput = () => {
  const [pin, setPin] = useState('')
  const [pinError, setPinError] = useState(false)
  const errorTimer = useRef(false)

  const onSetPin = (newPin) => {
    setPin(newPin)
    setPinError(false)
    clearTimeout(errorTimer.current)
  }

  const resetPin = () => {
    setPin('')
    setPinError(false)
    clearTimeout(errorTimer.current)
  }

  useEffect(() => {
    if (pinError) {
      clearTimeout(errorTimer.current)
      errorTimer.current = setTimeout(() => {
        setPin('')
      }, 500)
    } else {
      clearTimeout(errorTimer.current)
    }
    return () => clearTimeout(errorTimer.current)
  }, [pinError])

  return {
    pin,
    setPin: onSetPin,
    resetPin,
    pinError,
    setPinError,
  }
}

export default usePinInput

import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import DivitFastAPI from '@/services/apiFast'

export function* fetchPayNowOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`/paynow/orders/${orderId}`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
  return data.data
}

export function* fetchFastPayNowOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitFastAPI.get(`/paynow/fast/orders/${orderId}`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
  return data.data
}

export function* fetchBuyMilesOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitFastAPI.get(`/paynow/fast/orders/${orderId}/topup`)
  )
  yield put({ type: 'order/getOrderSuccess', payload: data.data })
  return data.data
}
export default 0

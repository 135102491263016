import React from 'react'
import styled from 'styled-components'

const Message = styled.div`
  flex: 1;
  font-size: 0.77em;
  color: #e6171a;
  padding: 0.6rem 1rem;
  background-color: rgba(230, 23, 26, 0.1);
  border-radius: 8px;
`

const ListItem = styled.div`
  margin-bottom: 0.2rem;
`

const ErrorMessageLine = ({ errorMessage, defaultMessage, ...props }) => {
  if (!errorMessage) return <></>

  let message = ''

  if (Array.isArray(errorMessage)) {
    message = errorMessage.map((e, i) => <ListItem key={i}>{e}</ListItem>)
  } else if (typeof errorMessage === 'string') {
    message = errorMessage
  } else {
    message = errorMessage?.message || defaultMessage || 'error'
  }

  return <Message {...props}>{message}</Message>
}

export default ErrorMessageLine

import base from './base'
import asiamiles from './extends/asiamiles'
import cathay from './extends/cathay'
import divit from './extends/divit'
import hkexpress from './extends/hkexpress'

const themes = {
  divit: { ...base, ...divit },
  hkexpress: { ...base, ...hkexpress },
  cathay: { ...base, ...cathay },
  asiamiles: { ...base, ...asiamiles },
}

export default themes

import { useMemoizedFn } from 'ahooks'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import InputError from './InputError'
import { Input } from './InputField'

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Toggle = styled.div`
  position: absolute;
  right: 1rem;
  font-size: 0.66rem;
  cursor: pointer;
`

const PasswordInputField = React.forwardRef(
  ({ style, className, title, hasError, error, disabled, ...props }, ref) => {
    const intl = useIntl()

    const inputRef = useRef(null)
    const [showPassword, setShowPassword] = useState(false)

    const onClickField = useMemoizedFn(() => inputRef.current?.focus())

    return (
      <Field
        style={style}
        className={className}
        disabled={disabled}
        onClick={!disabled ? onClickField : null}
      >
        <Title>{title}</Title>
        <Wrapper>
          <Input
            ref={(node) => {
              ref?.(node)
              inputRef.current = node
            }}
            {...props}
            type={showPassword ? 'text' : 'password'}
            error={hasError || error}
          />
          <Toggle onClick={() => setShowPassword(!showPassword)}>
            {showPassword
              ? intl.formatMessage({ id: 'login.password.hide' })
              : intl.formatMessage({ id: 'login.password.show' })}
          </Toggle>
        </Wrapper>
        <InputError error={error} />
      </Field>
    )
  }
)

export default PasswordInputField

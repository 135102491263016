import { useKeyPress } from 'ahooks'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as NumPadDelSvg } from '@/assets/pin/num-pad-del.svg'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import FastClickButton from '@/components/ui/FastClickButton'
import { tt } from '@/locales/format'

const Container = styled.div`
  width: 100%;
  max-width: 15.333rem;
  margin: 0 auto;
`

const DotContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Dot = styled.div`
  width: 1.7778rem;
  height: 1.7778rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DotCircle = styled.div`
  width: 0.6667rem;
  height: 0.6667rem;
  background-color: ${({ isFilled }) => (isFilled ? '#FFCC33' : 'transparent')};
  border: 1px solid #ffcc33;
  border-radius: 50%;
`

const DotNumber = styled.div`
  font-size: 1.111rem;
  font-weight: 600;
`

const NumberContainter = styled.div`
  transform: scale(${({ zoomRate }) => zoomRate});
  transform-origin: top center;
`

const NumberRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NumberField = styled(FastClickButton)`
  width: 4.444rem;
  height: 4.444rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #dedede;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid #ffcc33;
  }
`

const NumberFieldEmpty = styled.div`
  width: 4.444rem;
  height: 4.444rem;
`

const NumberFieldText = styled.div`
  font-weight: 700;
  font-size: 1.333rem;
  text-align: center;
`

const NumberFieldForgotPin = styled.div`
  width: 4.444rem;
  height: 4.444rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const NumberFieldForgotPinText = styled.div`
  font-size: 0.667rem;
  font-weight: 500;
  text-decoration: underline;
`

const NumberFieldDel = styled.div`
  width: 4.444rem;
  height: 4.444rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ErrorMessage = styled.div`
  height: 1rem;
  font-size: 0.667rem;
  color: #e6171a;
  text-align: center;
`

export const PIN_LENGTH = 6

const DotField = ({ isInPos, value }) => {
  const timer = useRef(null)
  const [isShowNumber, setIsShowNumber] = useState(false)

  useEffect(() => {
    clearTimeout(timer.current)
    setIsShowNumber(true)
    timer.current = setTimeout(() => {
      setIsShowNumber(false)
    }, 1000)
    return () => clearTimeout(timer.current)
  }, [value])

  return (
    <Dot>
      {isInPos && isShowNumber ? (
        <DotNumber>{value}</DotNumber>
      ) : (
        <DotCircle isFilled={!!value} />
      )}
    </Dot>
  )
}

const keyPressCodes = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  'Backspace',
]

const PinInput = ({
  pin = '',
  disabled,
  onChangePin,
  onFinishPin,
  onForgotPin,
  hasError,
}) => {
  const intl = useIntl()

  const [numPadZoomRate, setNumPadZoomRate] = useState(1)
  const numPadRef = useRef(null)

  useLayoutEffect(() => {
    const max = window.innerHeight * 0.5
    const numPadHeight = numPadRef.current.clientHeight
    if (numPadHeight > max) {
      setNumPadZoomRate(Math.max(0.75, max / numPadHeight))
    }
  }, [])

  const curPos = pin.length

  const onDeleteNum = () => {
    if (disabled) return
    const newPin = pin.substring(0, pin.length - 1)
    onChangePin(newPin)
  }

  const onEnterPinNumber = (num) => () => {
    if (disabled) return
    const newPin = pin + String(num)
    if (newPin.length <= PIN_LENGTH) {
      onChangePin(newPin)
    }
    if (newPin.length === PIN_LENGTH) {
      setTimeout(() => {
        onFinishPin(newPin)
      }, 250)
    }
  }

  useKeyPress(keyPressCodes, (event) => {
    const { key } = event
    if (/[0-9]/.test(key)) {
      onEnterPinNumber(key)()
    } else if (key === 'Backspace') {
      onDeleteNum()
    }
  })

  return (
    <Container>
      <DotContainer>
        {new Array(6).fill('x').map((x, i) => (
          <DotField key={i} isInPos={i === curPos - 1} value={pin[i] || ''} />
        ))}
      </DotContainer>
      <Spacer height="0.444rem" />
      {hasError ? (
        <>
          <ErrorMessage>{tt(intl, 'profile.pin.error.tryagain')}</ErrorMessage>
          <Spacer height="1rem" />
        </>
      ) : (
        <Spacer height="2rem" />
      )}
      <NumberContainter ref={numPadRef} zoomRate={numPadZoomRate}>
        <NumberRow>
          <NumberField onPress={onEnterPinNumber(1)}>
            <NumberFieldText>1</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(2)}>
            <NumberFieldText>2</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(3)}>
            <NumberFieldText>3</NumberFieldText>
          </NumberField>
        </NumberRow>
        <Spacer height="1.333rem" />
        <NumberRow>
          <NumberField onPress={onEnterPinNumber(4)}>
            <NumberFieldText>4</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(5)}>
            <NumberFieldText>5</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(6)}>
            <NumberFieldText>6</NumberFieldText>
          </NumberField>
        </NumberRow>
        <Spacer height="1.333rem" />
        <NumberRow>
          <NumberField onPress={onEnterPinNumber(7)}>
            <NumberFieldText>7</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(8)}>
            <NumberFieldText>8</NumberFieldText>
          </NumberField>
          <NumberField onPress={onEnterPinNumber(9)}>
            <NumberFieldText>9</NumberFieldText>
          </NumberField>
        </NumberRow>
        <Spacer height="1.333rem" />
        <NumberRow>
          {onForgotPin ? (
            <NumberFieldForgotPin onClick={onForgotPin}>
              <NumberFieldForgotPinText>
                {tt(intl, 'common.forgotpin')}
              </NumberFieldForgotPinText>
            </NumberFieldForgotPin>
          ) : (
            <NumberFieldEmpty />
          )}
          <NumberField onClick={onEnterPinNumber(0)}>
            <NumberFieldText>0</NumberFieldText>
          </NumberField>
          <NumberFieldDel onClick={onDeleteNum}>
            <Icon renderImage={() => <NumPadDelSvg />} />
          </NumberFieldDel>
        </NumberRow>
      </NumberContainter>
    </Container>
  )
}

export default PinInput

import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { ReactComponent as DownArrowSvg } from '@/assets/common/down-arrow.svg'
import Icon from '@/components/Icon'

import InputError from './InputError'

const getRandomId = () => parseInt(Math.random() * 1000000, 10)

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const ValueContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 2.5rem;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 8px;
  padding: 0.78rem 1.33rem;
  font-size: 0.78rem;
  background: white;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const Value = styled.div`
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#989898' : 'inherit')};
`

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.16)',
  }),
  option: (provided, state) => ({
    ...provided,
    height: 48,
    backgroundColor: state.isSelected ? '#FFFAEA' : 'white',
    color: '#1c1c1c',
    padding: '12px 12px',
    cursor: 'pointer',
  }),
}

const InputField = React.forwardRef(
  (
    {
      style,
      className,
      title,
      placeholder,
      hasError,
      error,
      options = [],
      value,
      disabled,
      onChange,
    },
    ref
  ) => {
    const [id] = useState(() => getRandomId())
    const [isExpanded, setIsExpanded] = useState(false)

    const valueLabel =
      options.find((o) => o.value === value)?.label || placeholder

    useEffect(() => {
      const click = (e) => {
        if (!e.target.closest(`.select-field-${id}`)) {
          setIsExpanded(false)
        }
      }
      document.body.addEventListener('click', click)
      return () => document.body.removeEventListener('click', click)
    }, [])

    const onClickField = () => {
      setIsExpanded((s) => !s)
    }

    const onChangeSelect = (e) => {
      setIsExpanded(false)
      onChange(e.value)
    }

    const onBlurSelect = () => {
      setIsExpanded(false)
    }

    return (
      <Field style={style} className={className} disabled={disabled}>
        <Title>{title}</Title>
        <ValueContainer
          className={`select-field-${id}`}
          onClick={!disabled ? onClickField : null}
          error={hasError || error}
        >
          <Value isPlaceholder={value === ''}>{valueLabel}</Value>
          <Icon
            renderImage={() => <DownArrowSvg />}
            width="1.11rem"
            height="1.11rem"
          />
        </ValueContainer>
        <Select
          ref={ref}
          components={{ Control: () => null }}
          styles={selectStyles}
          isLoading={false}
          isSearchable={false}
          cacheOptions
          defaultOptions
          onChange={onChangeSelect}
          onBlur={onBlurSelect}
          options={options}
          menuIsOpen={isExpanded}
        />
        <InputError error={error} />
      </Field>
    )
  }
)

export default InputField

import { push, replace } from 'connected-react-router'
import { call, delay, put, select } from 'redux-saga/effects'

import { setupAccount } from '@/redux/auth/authActions'
import * as lookupSagas from '@/redux/lookup/lookupSaga'
import { fetchProfile } from '@/redux/profile/profileSaga'
import DivitAPI, { setAuthHeader, unsetAuthHeader } from '@/services/api'
import { isRoleAvailable } from '@/utils/Jwt'
import {
  getOrderIdParam,
  getRouteByPathname,
  isCheckoutPath,
  isClaimMiles,
  IsOrder,
  IsPaynow,
} from '@/utils/Route'
import * as SessionStorage from '@/utils/sessionStorage'

import { routes } from '../../Router'
import { getErrorMessage } from '../utils/error'

function* setLoginData(data) {
  const { token, expire } = data
  if (token) {
    setAuthHeader(token)
    localStorage.setItem('token', token)
    localStorage.setItem('expire', expire)
    localStorage.setItem('last_login_ts', Date.now())
    yield put({ type: 'profile/setToken', payload: { token } })
    yield put({
      type: 'profile/setLastLoginTs',
      payload: { lastLoginTs: Date.now() },
    })
  }
}

export function* redirectReturnUrlOrHome() {
  const returnUrl = sessionStorage.getItem('return_url') || '/home'
  sessionStorage.removeItem('return_url')
  yield put(replace(returnUrl, { isAfterAuth: true }))
}

export function* loginRedirect() {
  const { profile } = yield select((s) => s.profile)
  const pathname = yield select((s) => s.router.location.pathname)
  const isCheckout = isCheckoutPath(pathname)

  // only checkout will show welcome page
  if (isCheckout) {
    if (!profile.email_validated || !profile.tel_validated) {
      const path = isCheckout ? '/sign-up/welcome' : '/profile/welcome'
      yield put(replace(path))
      return
    }
  } else if (!profile.email_validated) {
    yield put(
      replace('/profile/verify-email', {
        mode: 'verify',
        email: profile.email,
      })
    )
    return
  }

  // any other return url or home
  yield redirectReturnUrlOrHome()
}

// verify profile loop
// 1. check validations in profile from server
// 2. redirect to verify page if missing
export function* verifyProfile(action) {
  const { curStep } = action?.payload || {}
  const { data } = yield call(() => DivitAPI.get('users/v2/profiles'))
  const { data: profiles } = data

  // update profile
  yield put({ type: 'profile/getProfileSuccess', payload: data.data })

  const pathname = yield select((s) => s.router.location.pathname)
  const isCheckout = isCheckoutPath(pathname)
  const isClaimMilesRoute = isClaimMiles()

  // if miles token verifyClaimerBy = tel,
  // then requires tel number verification
  const { verifyClaimerBy } = SessionStorage.getJSON('claim_token', {})
  const isClaimMilesByTel = verifyClaimerBy === 'phone'

  // bypass some steps already did
  let step = 0
  if (curStep === 'email') {
    if (isClaimMilesRoute && !isClaimMilesByTel) {
      step = 2
    } else {
      step = 1
    }
  } else if (curStep === 'mobile') {
    step = 2
  }

  // force go to verify email
  if (step <= 0 && !profiles.email_validated) {
    const verifyPath = isCheckout
      ? `/sign-up/verify-email`
      : '/profile/verify-email'
    yield put(
      push(verifyPath, {
        mode: 'verify',
        email: profiles.email,
      })
    )
    return
  }

  // force go to verify mobile
  if (step <= 1 && !profiles.tel_validated) {
    const verifyPath = isCheckout
      ? `/sign-up/verify-mobile`
      : '/profile/verify-mobile'
    yield put(
      push(verifyPath, {
        mode: 'verify',
      })
    )
    return
  }

  // any other return url or home
  yield redirectReturnUrlOrHome()
}

export function* login({ payload }) {
  try {
    const { email, password, recapResp } = payload
    yield put({ type: 'auth/loginStart' })
    const formData = new FormData()
    formData.append('client_id', email)
    formData.append('client_secret', password)
    formData.append('recapResp', recapResp)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const { data } = yield call(() =>
      DivitAPI.post('admin/login', formData, config)
    )
    // check role of enduser
    if (!isRoleAvailable(data.token)) {
      yield put({
        type: 'auth/loginFailed',
        payload: 'forbidden',
      })
      return
    }
    yield setLoginData(data)
    yield put({ type: 'auth/loginSuccess', payload: data })
    const profile = yield fetchProfile()
    yield lookupSagas.fetchLookups()
    yield put({
      type: 'gtm/sendEvent',
      payload: {
        event: 'login',
        userId: profile.customerID,
        accountProvider: 'email',
      },
    })
    yield put(setupAccount({ isPrompt: true }))
  } catch (e) {
    yield put({
      type: 'auth/loginFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* logout({ payload }) {
  const { isNormalLogout = true } = payload || {}
  if (isNormalLogout) {
    DivitAPI.post('auth/logout')
  }
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('created_order')
  sessionStorage.removeItem('created_order_customer')
  sessionStorage.removeItem('created_order_promo_code')
  localStorage.removeItem('token')
  localStorage.removeItem('expire')
  localStorage.removeItem('sumsub-access-token')
  unsetAuthHeader()
  yield put({ type: 'order/reset' })
  yield put({ type: 'profile/reset' })
  yield put({ type: 'auth/logout' })
}

export function* merchantSignup({ payload }) {
  try {
    const {
      email,
      company,
      brNumber,
      accNumber,
      contact,
      tel,
      brDoc,
      recapResp,
    } = payload
    yield put({ type: 'auth/merchantSignupStart', payload })
    const { data } = yield call(async () => {
      const body = new FormData()
      body.append('email', email)
      body.append('contact', contact)
      body.append('company', company)
      body.append('accNumber', accNumber)
      body.append('brNumber', brNumber)
      body.append('recapResp', recapResp)
      body.append('source', 'business')
      if (brDoc && brDoc?.[0]) {
        body.append('file', brDoc?.[0])
      }
      if (tel?.trim()) {
        body.append('tel', tel?.trim())
      }
      return DivitAPI.post('partners/signup', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    })
    yield put({ type: 'auth/merchantSignupSuccess', payload: data?.data })
  } catch (e) {
    yield put({
      type: 'auth/merchantSignupFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* signUp({ payload }) {
  try {
    const {
      firstName,
      lastName,
      email,
      tel, // optional
      password,
      language,
      recapResp,
    } = payload
    const mRef = sessionStorage.getItem('mRef') || ''
    yield put({ type: 'auth/signUpStart', payload })
    const { data } = yield call(async () => {
      const body = {
        firstName,
        lastName,
        email,
        tel,
        password,
        language,
        recapResp,
        source: mRef,
      }
      if (!body.tel?.trim()) {
        delete body.tel
      }
      return DivitAPI.post('profiles', body)
    })
    const loginData = data.data.token_data
    yield setLoginData(loginData)
    yield put({ type: 'auth/loginSuccess', payload: loginData })
    yield put({ type: 'auth/signUpSuccess' })
    const profile = yield fetchProfile()
    yield lookupSagas.fetchLookups()
    yield put({
      type: 'gtm/sendEvent',
      payload: {
        event: 'signup',
        userId: profile.customerID,
        accountProvider: 'email',
      },
    })
    yield loginRedirect()
  } catch (e) {
    yield put({
      type: 'auth/signUpFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* clearSignUp() {
  yield put({ type: 'auth/clearSignUp' })
}

// trigger after refresh token
// TOFIX: too easy to forget if enter private route without login
// save return_url if current route is private
export function* tokenExpiredRedirect() {
  const { location } = yield select((s) => s.router)
  const { pathname, search } = location
  const currentRoute = getRouteByPathname(routes, pathname)

  const isPrivateRoute = currentRoute && currentRoute.private
  if (isPrivateRoute) {
    sessionStorage.setItem('return_url', `${pathname}${search}`)
  }

  yield put({ type: 'logout', payload: { isNormalLogout: false } })

  yield delay(100)

  const isCheckoutStart = IsOrder(pathname)
  const isClaimMilesRoute = isClaimMiles()
  const isPaynow = IsPaynow(pathname)

  if (isPaynow) {
    // no need to redirect to login for fast checkout
    return
  }

  if (isCheckoutStart) {
    const orderId = getOrderIdParam(pathname)
    yield put(replace(`/orderpreview/${orderId}`))
  } else if (isClaimMilesRoute) {
    // return to claim miles
    sessionStorage.setItem('return_url', `${pathname}${search}`)
    yield put(replace('/'))
  } else {
    yield put(replace('/'))
  }
}

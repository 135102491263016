import React, { useState } from 'react'
import styled from 'styled-components'

import Icon from '@/components/Icon'

const Wrapper = styled.div`
  border-radius: 28px;
  background-color: #fffaea;
  padding: 4px;
`

const Container = styled.div`
  position: relative;
  display: flex;
`

const Bg = styled.div`
  position: absolute;
  width: ${({ total }) => `${(1 / total) * 100}%`};
  height: 100%;
  top: 0;
  left: ${({ total, activeIndex }) => `${(activeIndex / total) * 100}%`};
  transition: left 0.3s;
  background-color: rgba(255, 204, 51, 1);
  border-radius: 20px;
`

const SelectorTab = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 0px;

  &:hover {
    cursor: pointer;
  }
`

const TabLabel = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #1c1c1c;
  margin-left: 0.44rem;
`

const SelectorTabs = ({ defaultTab, tabs, onChange }) => {
  const defaultTabIndex = tabs.findIndex((t) => t.key === defaultTab)
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex)

  const onSelectTab = (index) => {
    setActiveTabIndex(index)
    onChange?.(tabs[index])
  }

  return (
    <Wrapper>
      <Container>
        <Bg total={tabs.length} activeIndex={activeTabIndex} />
        {tabs.map((tab, i) => (
          <SelectorTab key={i} onClick={() => onSelectTab(i)}>
            <Icon
              renderImage={() => <tab.Icon />}
              width="0.88rem"
              height="0.88rem"
            />
            <TabLabel>{tab.label}</TabLabel>
          </SelectorTab>
        ))}
      </Container>
    </Wrapper>
  )
}

export default SelectorTabs

import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import PaddingContent from '@/components/layout/PaddingContent'
import LoadingModal from '@/components/LoadingModal'
import OTPInputBox from '@/components/OTPInputBox'
import Spacer from '@/components/Spacer'
import Title from '@/components/ui/Title'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import useCountDown from '@/hooks/useCountdown'
import { tt } from '@/locales/format'
import { profileIdSelector } from '@/redux/profile/profileSelector'

const Container = styled(PaddingContent)``

const SubTitle = styled.div`
  max-width: 375px;
  font-size: 0.778rem;
  text-align: center;
  margin: 0 auto;
`

const ClientID = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
  text-align: center;
`

const ActionButtons = styled.div`
  text-align: center;
`

const ResendButton = styled(Button)`
  width: 10rem;
  font-size: 0.89rem;
`

const COUNT_DOWN_IN_SECOND = 60

const VerifyPinOTPModal = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { executeRecaptcha } = useCaptcha()

  const profileId = useSelector(profileIdSelector)
  const { verifyPinOTPModal, forgotPin, validatePinOTP } = useSelector(
    (s) => s.pin
  )
  const { isOpen } = verifyPinOTPModal

  const { countDownInSec, setCountDownInSec } = useCountDown({
    timeoutInSec: COUNT_DOWN_IN_SECOND,
  })

  const otpInputBoxRef = useRef()
  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const [errorMessage, setErrorMessage] = useState('')

  const resetOTP = () => {
    setTimeout(() => {
      setOtp(['', '', '', '', '', ''])
      otpInputBoxRef.current?.resetFocus()
    }, 200)
  }

  const onCallback = (result) => {
    setTimeout(() => {
      resetOTP()
      verifyPinOTPModal.callback?.(result)
      dispatch({ type: 'pin/resetVerifyPinOTPModal' })
    }, 500)
  }

  const onClose = () => {
    dispatch({ type: 'pin/closeVerifyPinOTPModal' })
    onCallback({ isSuccess: false })
  }

  const onConfirm = () => {
    dispatch({ type: 'pin/closeVerifyPinOTPModal' })
    onCallback({ isSuccess: true })
  }

  const resendOTP = async () => {
    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('SEND_PIN_OTP')
    } catch (err) {
      console.error(err)
    }

    dispatch({
      type: 'pin/forgotPin',
      payload: {
        recapResp,
      },
    })
  }

  const doVerifyOTP = (newOTP) => {
    dispatch({
      type: 'pin/validatePinOTP',
      payload: {
        token: newOTP.join(''),
      },
    })
  }

  useEffect(() => {
    if (verifyPinOTPModal.isOpen) {
      dispatch({ type: 'pin/forgotPin' })
    } else {
      dispatch({ type: 'pin/reset' })
    }
  }, [verifyPinOTPModal?.isOpen])

  useEffect(() => () => dispatch({ type: 'pin/reset' }), [])

  useEffect(() => {
    if (forgotPin.isSuccess) {
      setCountDownInSec(COUNT_DOWN_IN_SECOND)
      resetOTP()
    }
    if (forgotPin.isError) {
      setErrorMessage('send otp failed, try again')
    } else {
      setErrorMessage('')
    }
  }, [forgotPin])

  useEffect(() => {
    if (validatePinOTP.isSuccess) {
      onConfirm()
      resetOTP()
    }
    if (validatePinOTP.isError) {
      setErrorMessage(tt(intl, 'profile.verify.send.error'))
    } else {
      setErrorMessage('')
    }
  }, [validatePinOTP])

  const onChangeOTP = (newOTP) => {
    setOtp(newOTP)

    if (newOTP.length === 6 && newOTP.every((digit) => digit.length === 1)) {
      doVerifyOTP(newOTP)
    }
  }

  const onModalLeave = () => {}

  return (
    <BottomSheet
      fullscreen
      open={isOpen}
      onDismiss={onClose}
      onLeave={onModalLeave}
    >
      <Container>
        <Spacer height="2.222rem" />
        <Title onClickBack={onClose}>{tt(intl, 'profile.verification')}</Title>
        <Spacer height="1rem" />
        <SubTitle>{tt(intl, 'profile.resetpin.msg1')}</SubTitle>
        <Spacer height="0.889rem" />
        <ClientID>{profileId}</ClientID>
        <Spacer height="1.111rem" />
        <ErrorMessageLine errorMessage={errorMessage} />
        <Spacer height="1.111rem" />
        <OTPInputBox ref={otpInputBoxRef} otp={otp} onChangeOTP={onChangeOTP} />
        <Spacer height="2.22rem" />
        <ActionButtons>
          <ResendButton disabled={countDownInSec > 0} onClick={resendOTP}>
            <span>{tt(intl, 'common.resend')} </span>
            {countDownInSec > 0 && <span>{`(${countDownInSec})`}</span>}
          </ResendButton>
        </ActionButtons>
        <Spacer height="2.22rem" />
        <LoadingModal
          loading={forgotPin.isLoading || validatePinOTP.isLoading}
        />
      </Container>
    </BottomSheet>
  )
}

export default (props) => (
  <CaptchaProvider>
    <VerifyPinOTPModal {...props} />
  </CaptchaProvider>
)

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  title: '',
  content: '',
  actions: [],
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    reset: () => initialState,
    openModal: (state, { payload }) => {
      state.isOpen = true
      state.title = payload.title
      state.content = payload.content
      state.actions = payload.actions
    },
  },
})

export const { actions } = modalSlice

export default modalSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  msgs: [
    // { type: 'success', content: 'verified' },
    // { type: 'error', content: 'token expired' },
  ],
}

const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState,
  reducers: {
    reset: () => initialState,
    addMessage: (state, { payload }) => {
      const { type, content } = payload
      state.msgs.push({ type, content })
    },
    replaceMessage: (state, { payload }) => {
      const { type, content } = payload
      state.msgs = [{ type, content }]
    },
  },
})

export const { actions } = flashMessageSlice

export default flashMessageSlice.reducer

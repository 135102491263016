import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import Button from '@/components/common/Button'
import InputField from '@/components/common/InputField'
import Row from '@/components/common/Row'
import SelectField from '@/components/common/SelectField'
import UploadFileInputField from '@/components/common/UploadFileInputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import LoadingModal from '@/components/LoadingModal'
import MilesValue from '@/components/miles/MilesValue'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import {
  promptActionModal,
  promptOptionsModal,
} from '@/redux/modal/modalActions'
import { verifyIdentity } from '@/redux/pin/pinActions'
import {
  profileSelector,
  roleSelector,
  tokenBooksSelector,
} from '@/redux/profile/profileSelector'
import scrollToTop from '@/utils/scrollToTop'

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
`

const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 2rem;
  text-align: right;
  border: 0px none transparent;
  border-bottom: 1px solid #ddd;
  padding: 5px 0px;
  margin: 10px 10px;
`
const AmountSuffix = styled.div`
  font-size: 0.688rem;
  max-width: 55px;
`
const BookOptionItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
const BookOptionItemTitle = styled.div`
  margin-left: 10px;
  color: #888;
`
const Total = styled(CurrencyInput)`
  background: transparent;
  font-size: 2rem;
  text-align: right;
  border: 0px none transparent;
  padding: 5px 0px;
  margin: 10px 10px;
`
const Currency = styled.div``
const GenerateButton = styled(Button)`
  width: 100%;
  font-size: 0.89rem;
`
const RecordList = styled.div`
  background-color: white;
  padding: 1rem 1.333rem;
`
const Record = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;

  &:first-child {
    border-top: 1px solid #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }
`
const BatchDate = styled.div`
  font-size: 0.889rem;
  font-weight: 600;
`
const Download = styled.div`
  font-size: 0.8rem;
  cursor: pointer;
  color: #fc3;
`
const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`

const DownloadTemplate = styled.a`
  cursor: pointer;
  color: #fc3;
`
const UploadButton = styled(Button)`
  width: 100%;
  font-size: 0.89rem;
`

const schema = Yup.object().shape({
  files: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      'FILE_SIZE',
      'uploaded file is too big',
      (value) => !value || !value?.[0] || value?.[0]?.size <= 4096 * 1024
    )
    .test(
      'FILE_FORMAT',
      'uploaded file has unsupported format',
      (value) =>
        !value ||
        !value?.[0] ||
        value?.[0]?.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        value?.[0]?.type === 'application/vnd.ms-excel'
    ),
  merchantID: Yup.string().required(
    'unable to load profile merchantID, pls reload the page and retry'
  ),
  bookID: Yup.string().required(
    'unable to load token bookID, pls reload the page and retry'
  ),
  miles: Yup.string(),
  count: Yup.string(),
  total: Yup.string(),
  remarks: Yup.string(),
})

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const ActiveStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #ffcc33;
  background: transparent;
  padding: 0.2rem 1rem;
  border-radius: 16px;
  color: #ffcc33;
  flex: 0;
`
const UnknownStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #ddd;
  background: transparent;
  padding: 0.2rem 1rem;
  border-radius: 16px;
  color: #ddd;
  flex: 0;
`
const CompleteStatus = styled.div`
  font-size: 0.66rem;
  border: 2px solid #00cc00;
  background: transparent;
  padding: 0.2rem 1rem;
  border-radius: 16px;
  color: #00cc00;
  flex: 0;
`
const getStatus = (status) => {
  if (status === 'new' || status === 'processing') {
    return <ActiveStatus>{status}</ActiveStatus>
  }
  if (status === 'done') {
    return <CompleteStatus>complete</CompleteStatus>
  }
  return <UnknownStatus>{status}</UnknownStatus>
}

const BatchRecordList = ({ items, downloadBatchResult }) =>
  items && (
    <RecordList>
      {items.map((rec) => (
        <Record key={rec.processID}>
          <Row>
            <BatchDate>
              {moment(rec.submittedTime).format('MM-DD HH:mm')}
            </BatchDate>
            <Download onClick={() => downloadBatchResult(rec)}>
              {`complete rec (${rec.numberCompleted}/${rec.numberToBeProcessed})`}
            </Download>
            {getStatus(rec.status)}
          </Row>
        </Record>
      ))}
    </RecordList>
  )

const SendMilesInBatch = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [directConvertMode, setDirectConvertMode] = useState(false)
  const [activeTab, setActiveTab] = useState('token')
  const [currentBook, setCurrentBook] = useState({})
  const [insufficientBalance, setInsufficientBalance] = useState(true)

  const { batchRequests } = useSelector((s) => s.batchSendMiles)
  const { data } = useSelector((s) => s.batchSendMiles?.batchResult)
  const profile = useSelector(profileSelector)
  const role = useSelector(roleSelector)
  const { books } = useSelector(tokenBooksSelector)
  const { error, isError, isLoading, isSuccess } = batchRequests

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      miles: '',
      count: '',
      total: '',
    },
  })

  const checkSufficientBalance = () => {
    const totalMiles = parseInt(getValues('total'), 10) || 0
    if (currentBook && currentBook.balance) {
      setInsufficientBalance(
        currentBook.balance.amount <= 0 ||
          totalMiles > currentBook.balance.amount / 100 ||
          totalMiles === 0
      )
    } else {
      setInsufficientBalance(true)
    }
  }

  useEffect(() => {
    if (role && role !== 'admin') {
      history.replace('/home')
    }
    if (role) {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
  }, [role])

  useEffect(() => {
    if (!profile) return

    // set direct convert mode
    setDirectConvertMode(
      profile?.subscribedServices.indexOf('directconversion') >= 0
    )
    // load batch result data
    dispatch({
      type: 'batchSendMiles/fetchBatchResults',
      payload: {
        merchantID: profile.merchantID,
      },
    })
  }, [profile])

  useEffect(() => {
    if (error) {
      setErrorMessage(error)
    } else {
      setErrorMessage(null)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.filter((d) => d.status !== 'done').length > 0) {
      setTimeout(() => {
        dispatch({
          type: 'batchSendMiles/fetchBatchResults',
          payload: {
            merchantID: profile.merchantID,
          },
        })
      }, 3000)
    }
  }, [data])

  useEffect(() => {
    if (books && profile) {
      if (books.length > 0) {
        setValue('bookID', books[0].bookID)
        setCurrentBook(books[0])
      }
      setValue('merchantID', profile.merchantID)
      checkSufficientBalance()
    }
  }, [books, profile])

  useEffect(() => {
    if (isSuccess) {
      // save promo code for default next one
      dispatch(
        promptActionModal({
          title: 'sent',
          content:
            'job submitted, please check the process status below and download the file when it is done',
          closeText: 'close',
          closable: true,
        })
      )
      dispatch({
        type: 'batchSendMiles/fetchBatchResults',
        payload: {
          merchantID: profile.merchantID,
        },
      })
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
      reset()
      setValue('merchantID', profile.merchantID)
      setValue('bookID', currentBook.bookID)
      dispatch({ type: 'batchSendMiles/reset' })
    }
    if (isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [isError, isSuccess])

  const setTokenBook = (bookID) => {
    setCurrentBook(books.filter((b) => b.bookID === bookID)[0])
    setValue('bookID', bookID)
  }

  const onChangeTotal = (field, value) => {
    const m = parseInt(field === 'miles' ? value : getValues('miles'), 10) || 0
    const t = parseInt(field === 'count' ? value : getValues('count'), 10) || 0
    setValue(field, value)
    setValue('total', m * t)
    checkSufficientBalance()
  }

  const onClickGenerate = handleSubmit(async (param) => {
    setErrorMessage('')

    const m = parseInt(getValues('miles'), 10) || 0
    const t = parseInt(getValues('count'), 10) || 0
    if (m <= 0 || t <= 0) return

    const result = await dispatch(verifyIdentity())
    if (result.isSuccess) {
      dispatch({
        type: 'batchSendMiles/batchGenerateTokens',
        payload: {
          merchantID: param.merchantID,
          bookID: param.bookID,
          milesPerToken: m,
          numOfTokens: t,
          remarks: getValues('remarks'),
        },
      })
    }
  })

  const onClickUpload = handleSubmit(async (param) => {
    setErrorMessage('')

    const payload = new FormData()
    payload.append('file', param.files[0])
    payload.append('merchantID', param.merchantID)
    payload.append('bookID', param.bookID)

    const result = await dispatch(verifyIdentity())
    if (result.isSuccess) {
      dispatch({
        type: 'batchSendMiles/batchSendMiles',
        payload,
      })
    }
  })

  const onClickDownload = async () => {
    if (!directConvertMode) {
      dispatch({
        type: 'batchSendMiles/downloadBatchSendMilesTemplate',
        payload: {},
      })
    } else {
      dispatch(
        promptOptionsModal({
          title: 'batch issue miles templates',
          content: 'download and fill the template to issue miles in batch',
          actionFns: [
            {
              key: 'action1',
              title: 'batch issue miles',
              action: () =>
                dispatch({
                  type: 'batchSendMiles/downloadBatchSendMilesTemplate',
                  payload: {},
                }),
            },
            {
              key: 'action2',
              title: 'batch issue and convert miles',
              action: () =>
                dispatch({
                  type: 'batchSendMiles/downloadBatchSendMilesTemplate',
                  payload: {
                    directConvertMode,
                  },
                }),
            },
          ],
          closable: true,
        })
      )
    }
  }

  const onClickDownloadResult = async (rec) => {
    dispatch({
      type: 'batchSendMiles/downloadBatchSendMilesResult',
      payload: {
        ...rec,
      },
    })
  }

  return (
    <PageContainer>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>batch reward miles</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>
          {books && books.length > 1 && (
            <>
              from miles account:&nbsp;&nbsp;
              <SelectField
                name="bookID"
                value={currentBook.bookID}
                options={books.map((b) => ({
                  label: (
                    <BookOptionItem>
                      <MilesValue
                        fontSize="1rem"
                        miles={b.balance.amount}
                        isSigned={false}
                      />
                      <BookOptionItemTitle>{b.bookTitle}</BookOptionItemTitle>
                    </BookOptionItem>
                  ),
                  value: b.bookID,
                }))}
                onChange={(v) => setTokenBook(v)}
              />
            </>
          )}
          {books && books.length === 1 && currentBook?.balance && (
            <>
              balance:&nbsp;&nbsp;
              <MilesValue
                fontSize="1rem"
                miles={currentBook?.balance?.amount}
                isSigned={false}
              />
            </>
          )}
        </SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {errorMessage && (
          <>
            <ErrorMessageLine errorMessage={errorMessage} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <SelectorTabs
          defaultTab={activeTab}
          tabs={[
            { key: 'token', label: 'by token' },
            { key: 'email', label: 'by email' },
          ]}
          onChange={(tab) => setActiveTab(tab.key)}
        />
        <Form>
          <Tab isActive={activeTab === 'token'}>
            <Spacer height={globalStyles.px.xs} />
            <SubTitle>generate claim miles tokens in csv file</SubTitle>
            <Spacer height={globalStyles.px.xs} />
            <Row>
              <Controller
                name="miles"
                control={control}
                render={({ field }) => (
                  <Amount
                    allowNegativeValue={false}
                    decimalScale={0}
                    disableAbbreviations
                    placeholder="0"
                    defaultValue="0"
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value)
                      onChangeTotal('miles', value)
                    }}
                    inputMode="numeric"
                    maxLength={7}
                    style={{ width: '200px' }}
                  />
                )}
              />
              <AmountSuffix>
                <Currency>miles</Currency>
              </AmountSuffix>
              x
              <Controller
                name="count"
                control={control}
                render={({ field }) => (
                  <Amount
                    allowNegativeValue={false}
                    decimalScale={0}
                    disableAbbreviations
                    placeholder="0"
                    value={field.value}
                    onValueChange={(value) => {
                      field.onChange(value)
                      onChangeTotal('count', value)
                    }}
                    inputMode="numeric"
                    maxLength={5}
                    style={{ width: '110px' }}
                  />
                )}
              />
              <AmountSuffix>
                <Currency>tokens</Currency>
              </AmountSuffix>
            </Row>
            <Row>
              <Controller
                name="total"
                control={control}
                render={({ field }) => (
                  <Total
                    allowNegativeValue={false}
                    decimalScale={0}
                    disableAbbreviations
                    placeholder="0"
                    value={field.value}
                    inputMode="numeric"
                    disabled
                  />
                )}
              />
              <AmountSuffix>
                <Currency>total miles</Currency>
              </AmountSuffix>
            </Row>
            <Row>
              <InputField
                title=""
                placeholder="remarks"
                {...register('remarks')}
                inputMode="text"
              />
            </Row>
            <Spacer height={globalStyles.px.xs} />
            <GenerateButton
              onClick={onClickGenerate}
              disabled={isLoading || !profile || insufficientBalance}
            >
              {intl.formatMessage({ id: 'common.generate' })}
            </GenerateButton>
            <Spacer height={globalStyles.px.xs} />
          </Tab>
          <Tab isActive={activeTab === 'email'}>
            <Spacer height={globalStyles.px.xs} />
            <SubTitle>
              to issue miles directly to member&apos;s email, fill in the
              information with this{' '}
              <DownloadTemplate onClick={onClickDownload}>
                template file
              </DownloadTemplate>
            </SubTitle>
            <Spacer height={globalStyles.px.xs} />

            <FormRow>
              <FormColumn>
                <UploadFileInputField
                  id="files"
                  {...register('files')}
                  placeholder={intl.formatMessage({
                    id: 'upload.excel',
                  })}
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx,.xls"'
                  error={getErrorMessage(errors.files)}
                />
              </FormColumn>
            </FormRow>
            <Spacer height={globalStyles.px.xs} />
            <UploadButton
              type="bw"
              onClick={onClickUpload}
              disabled={isLoading || !profile}
            >
              {intl.formatMessage({ id: 'common.upload' })}
            </UploadButton>
            <Spacer height={globalStyles.px.xs} />
          </Tab>
          <LoadingModal loading={isLoading || false} />
          <Spacer height={globalStyles.px.lg2} />
          <Title>batch process records</Title>
          <Spacer height={globalStyles.px.xs} />
          <SubTitle>result of the batch process (latest 100)</SubTitle>
          <Spacer height={globalStyles.px.xs} />
          <BatchRecordList
            items={data}
            downloadBatchResult={onClickDownloadResult}
          />
        </Form>
      </PageContent>
    </PageContainer>
  )
}

export default SendMilesInBatch

import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@/components/common/Button'
import Spacer from '@/components/Spacer'

const Container = styled.div``

const Title = styled.div`
  font-size: 1.2rem;
  text-align: center;
`

const SubTitle = styled.div`
  max-width: 375px;
  margin: 0 auto;
  font-size: 0.778rem;
  line-height: 1.5;
  text-align: center;
`

const ButtonContainer = styled.div`
  text-align: center;
`

const BackButton = styled(Button)`
  padding: 7px 16px;
`

const PaynowFpsUnavailableScreen = ({ periodEnd }) => {
  const history = useHistory()

  const onClickBack = () => {
    history.push('/create-payment')
  }

  let content =
    'your purchase could not be completed due to FPS system maintenance'
  if (periodEnd && periodEnd > 0) {
    content += `, please try again after ${moment
      .unix(periodEnd)
      .format('DD MMM YYYY h:mm a')}`
  } else {
    content += ', please try again later'
  }

  return (
    <Container>
      <Spacer height="2.222rem" />
      <Title>system maintenance</Title>
      <Spacer height="0.889rem" />
      <SubTitle>{content}</SubTitle>
      <Spacer height="1.333rem" />
      <ButtonContainer>
        <BackButton type="bw" onClick={onClickBack}>
          back
        </BackButton>
      </ButtonContainer>
    </Container>
  )
}

export default PaynowFpsUnavailableScreen

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import DateSelectField, {
  getDefaultDateValue,
} from '@/components/common/DateSelectField'
import SelectField from '@/components/common/SelectField'
import EmptyResult from '@/components/EmptyResult'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import InfinityScroll from '@/components/InfinityScroll'
import LoadingSpinner from '@/components/LoadingSpinner'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import * as milesTransactionsActions from '@/redux/pages/milesTransactionsActions'
import { profileSelector } from '@/redux/profile/profileSelector'
import * as Pagination from '@/utils/pagination'

const Container = styled.div``

const Content = styled.div`
  background-color: white;
`

const ErrorContainer = styled.div`
  padding: 0 1.333rem;
`

const Filters = styled.div`
  padding: 0.889rem 1.333rem;
`

const LoadingContainer = styled.div``

const Footer = styled.div`
  width: 100%;
  height: 1.333rem;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

const RecordList = styled.div`
  background-color: white;
  padding: 1rem 1.333rem;
`
const Record = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;

  &:first-child {
    border-top: 1px solid #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
  }
`
const ItemLeft = styled.div`
  flex: 1;
  margin-right: 0.444rem;
`
const ItemTitle = styled.div`
  font-size: 0.667rem;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const ItemCreatedDate = styled.div`
  margin-top: 0.222rem;
  font-size: 0.667rem;
  color: #979797;
`
const ItemMilesValue = styled(MilesValue)`
  justify-content: flex-end;
  font-weight: 700;
`
const MilesTransactionItems = ({ items, ownerID }) => {
  const getByTitle = (rec) => {
    switch (rec.txnType) {
      case 'claim':
      case 'reserve':
        return `sent to ${rec.metadata?.metadata.by ?? rec.targetType}`
      case 'transfer':
        if (rec.sourceID === ownerID) {
          return `transfer to ${rec.metadata?.metadata.to ?? rec.targetType}`
        }
        return `transfer from ${rec.metadata?.metadata?.from ?? rec.sourceType}`
      case 'earn':
        if (rec.reason.includes('REWARD REFERRER')) {
          return `referral bonus`
        }
        if (rec.targetID === ownerID) {
          return `buy miles on order ${
            rec.metadata?.metadata?.merchantRef ?? ''
          }`
        }
        return `purchase reward on order ${
          rec.metadata?.metadata?.merchantRef ?? ''
        }`
      default:
    }
    return `${rec.txnType} transaction`
  }

  const getMiles = (rec) =>
    rec.targetAmount.amount * (rec.sourceID === ownerID ? -1 : 1)

  return (
    items && (
      <RecordList>
        {items.map((rec) => (
          <Record key={rec.txnID}>
            <ItemLeft>
              <ItemTitle>{getByTitle(rec)}</ItemTitle>
              <ItemCreatedDate>
                {moment(rec.createdAt).format('YYYY-MM-DD HH:mm')}
              </ItemCreatedDate>
            </ItemLeft>
            <ItemMilesValue miles={getMiles(rec)} isSigned />
          </Record>
        ))}
      </RecordList>
    )
  )
}

const MilesTransactionsList = () => {
  const dispatch = useDispatch()

  const { milesTransactions, getMilesTransactions } = useSelector(
    (s) => s.milesTransactions
  )
  const { isLoading } = getMilesTransactions
  const { data, total } = milesTransactions
  const profile = useSelector(profileSelector)
  const books = useSelector((s) => s.divitMiles?.merchantBooks)
  const [bookID, setBookID] = useState('')
  const [dateValue, setDateValue] = useState(() => getDefaultDateValue())

  const getParams = () => ({
    fromDate: dateValue.fromDate,
    toDate: dateValue.toDate,
    merchantID: profile.merchantID,
    bookID,
  })

  useEffect(() => {
    if (profile) {
      dispatch({
        type: 'milesTransactions/getMilesTransactions',
        payload: getParams(),
      })
    }
  }, [dateValue, profile, bookID])

  const isEmptyResult = !isLoading && data?.length === 0
  const hasMore = Pagination.hasMore(data, total)

  const loadMore = () =>
    dispatch(
      milesTransactionsActions.fetchMilesTransactionsNextPage(getParams())
    )

  return (
    <Container>
      <Content>
        {getMilesTransactions.error && (
          <ErrorContainer>
            <Spacer height={globalStyles.px.xs} />
            <ErrorMessageLine errorMessage={getMilesTransactions.error} />
          </ErrorContainer>
        )}
        <Filters>
          <DateSelectField value={dateValue} onChange={setDateValue} />
          <Spacer height={globalStyles.px.xs} />
          <SelectField
            value={bookID}
            options={[
              { label: `select a book`, value: '' },
              ...books.map((b) => ({ label: b.bookTitle, value: b.bookID })),
            ]}
            onChange={(v) => setBookID(v)}
          />
        </Filters>
        {isLoading && (
          <LoadingContainer>
            <Spacer height={globalStyles.px.xs} />
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {isEmptyResult && <EmptyResult />}
        {!isLoading && (
          <>
            <MilesTransactionItems items={data} ownerID={profile.merchantID} />
            <InfinityScroll hasMore={hasMore} loadMore={loadMore}>
              <LoadingSpinner />
            </InfinityScroll>
          </>
        )}
      </Content>
      <Footer />
    </Container>
  )
}

export default MilesTransactionsList

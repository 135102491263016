import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

export function* fetchDivitMilesPreviewClaim({ token }) {
  const { data } = yield call(async () =>
    DivitAPI.get('miles/preview/claim', {
      headers: {
        'X-Divit-Data': `token=${token}`,
      },
    })
  )

  return data
}

export function* claimDivitMiles({ token }) {
  const { data } = yield call(async () =>
    DivitAPI.post('miles/profiles/claim', { token })
  )

  return data
}

export function* fetchDivitMiles() {
  const { data } = yield call(async () => DivitAPI.get('miles/profiles/books'))

  yield put({
    type: 'profile/getDivitMilesSuccess',
    payload: data.data,
  })

  return data
}

export function* fetchDivitMilesTransactions() {
  const { data } = yield call(async () =>
    DivitAPI.get('miles/profiles/books/transactions', {
      params: {
        fromDate: '2021-01-01',
      },
    })
  )
  yield put({
    type: 'profile/getDivitMilesTransactionsSuccess',
    payload: data.data,
  })

  return data
}

export function* fetchDivitMilesMembership() {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/membership`)
  )
  yield put({
    type: 'profile/getDivitMilesMembershipSuccess',
    payload: data.data,
  })

  return data
}

export function* addDivitMilesMembership(params) {
  const { data } = yield call(async () =>
    DivitAPI.post(`miles/profiles/membership`, params)
  )

  yield fetchDivitMilesMembership()

  return data
}

export function* convertDivitMiles(params) {
  const { data } = yield call(async () =>
    DivitAPI.post(`miles/profiles/convert`, params)
  )

  yield fetchDivitMiles()

  return data
}

export function* fetchDivitMilesByOrder({ orderId }) {
  const { data } = yield call(async () =>
    DivitAPI.get(`miles/profiles/books/transactions/${orderId}`)
  )
  yield put({ type: 'order/getOrderMilesSuccess', payload: data.data })

  return data
}

export function* fetchMerchantMilesBooks() {
  const { data } = yield call(async () => 
    DivitAPI.get(`miles/books`, {
      params: {
        ownerType: 'merchant',
      }})
  )
  yield put({ 
    type: 'divitMiles/getMerchantBooksSuccess',
    payload: data.data,
  })
}
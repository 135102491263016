import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoadingSpinner from './LoadingSpinner'

const Container = styled.div`
  background-color: transparent;
  opacity: 0.5;
  width: 100%;
  height: 100vh;
`

const LoadingScreen = () => {
  const [showLoading, setShowLoading] = useState(false)

  // show after short period of time to avoid spark
  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), 500)
    return () => clearTimeout(timer)
  }, [])

  return (
    showLoading && (
      <Container>
        <LoadingSpinner />
      </Container>
    )
  )
}

export default LoadingScreen

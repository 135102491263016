export const getPendingCampaigns = (missions = []) =>
  missions.filter((m) => m.status === 'active')

export const getPendingCampaign = (missions = [], missionCode) =>
  getPendingCampaigns(missions).find((m) => m.missionCode === missionCode)

export const executeRewardStatement = (statement, amount) => {
  /* eslint-disable */
  const InstalmentAmount = amount * 100
  const TotalAmount = InstalmentAmount
  const ServiceFee = 0
  const RoundDown = (amt, digit) => {
    amt = amt / Math.pow(10, digit)
    amt = Math.floor(amt) * Math.pow(10, digit)
    return amt
  }
  const Round = (amt, digit) => {
    amt = amt / Math.pow(10, digit)
    amt = Math.round(amt) * Math.pow(10, digit)
    return amt
  }
  const RoundUp = (amt, digit) => {
    amt = amt / Math.pow(10, digit)
    amt = Math.ceil(amt) * Math.pow(10, digit)
    return amt
  }
  return eval(statement) / 100
  /* eslint-enable */
}

export const getRewardMiles = (campaign, amount = 0) => {
  const { rewardStatement } = campaign
  try {
    return executeRewardStatement(rewardStatement, amount)
  } catch (err) {
    return 0
  }
}

export default 0

import PropTypes from 'prop-types'
import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { IS_SAFARI } from '@/utils/platform'

const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID

const LoginFacebook = ({ render, onSuccess, onFailure }) => {
  const onResponse = (resp) => {
    if (!resp || !resp.accessToken) {
      onFailure(resp)
      return
    }
    onSuccess(resp)
  }

  return (
    <FacebookLogin
      disableMobileRedirect={IS_SAFARI}
      appId={fbAppId}
      fields="name,email,picture"
      callback={onResponse}
      redirectUri={`${document.location.origin}${document.location.pathname}`}
      render={({ onClick }) => render({ onClick })}
    />
  )
}

LoginFacebook.propTypes = {
  render: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

export default LoginFacebook

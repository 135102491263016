import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    // global loading
    loading: false,
    // side bar
    isShowSideBar: false,
    // force update count
    forceUpdate: 0,
  },
  reducers: {
    startLoading: (state) => {
      state.loading = true
    },
    endLoading: (state) => {
      state.loading = false
    },
    showSideBar: (state) => {
      state.isShowSideBar = true
    },
    hideSideBar: (state) => {
      state.isShowSideBar = false
    },
    forceUpdate: (state) => {
      state.forceUpdate += 1
    },
  },
})

export const { actions } = appSlice

export default appSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  merchants: [],
  rateHKDDVM: null,
}

const lookupSlice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    getMerchantListSuccess: (state, action) => {
      state.merchants = action.payload
    },
    getRateHKDDVMSuccess: (state, action) => {
      state.rateHKDDVM = action.payload
    },
    getTokenRateHKDDVMSuccess: (state, action) => {
      state.tokenRateHKDDVM = action.payload
    },
  },
})

export const { actions } = lookupSlice

export default lookupSlice.reducer

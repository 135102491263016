import classNames from 'classnames'
import QueryString from 'query-string'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Head from '@/components/Head'
import Header from '@/components/Header'
import HeaderSpacer from '@/components/HeaderSpacer'
import LoadingScreen from '@/components/LoadingScreen'
import MessageModal from '@/components/MessageModal'
import ScrollToTop from '@/components/ScrollToTop'
import SideBar from '@/components/SideBar'
import GlobalLoadingModal from '@/containers/GlobalLoadingModal'
import { useAuth } from '@/contexts/authContext'
import usePage from '@/hooks/usePage'
import useScreenView from '@/hooks/useScreenView'
import PinRequestModal from '@/pages/pin/PinRequestModal'
import PinSetupModal from '@/pages/pin/PinSetupModal'
import VerifyPinOTPModal from '@/pages/pin/VerifyPinOTPModal'
import { setPartnerInfo } from '@/utils/Partner'
import {
  IsFps,
  IsHistory,
  IsHome,
  IsLogin,
  IsOrder,
  IsPaynow,
  IsRoot,
  IsSuccessPage,
} from '@/utils/Route'

import Breadcrumbs from './components/Breadcrumbs'
import SwipeableTemporaryDrawer from './components/layout/BottomBar'
import { StoreKeys } from './constants/storage'
import Router from './Router'

const ViewportContainer = styled.div`
  position: relative;
  min-height: var(--app-height);
  width: 100%;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 1px 5px #eee;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  flex: 1;
  position: relative;

  display: flex;

  margin-left: ${({ margin }) => margin && 'calc(100% / 12)'};
  margin-right: ${({ margin }) => margin && 'calc(100% / 12)'};

  padding-bottom: ${({ padding }) => padding && 'calc(72px + 0.125rem)'};

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    padding-bottom: ${({ padding }) => padding && 'calc(80px + 0.125rem)'};
  }
`

const AppState = ({ children }) => {
  const { token } = useAuth()
  const { pathname } = useLocation()

  const isPaynow = IsPaynow(pathname)

  // check token is valid and get user profile
  const { isInitLoading } = usePage({
    skipInit: isPaynow || !token,
    initAction: { type: 'pageInit/initApp' },
  })

  if (isInitLoading) return <LoadingScreen />

  return children
}

const App = () => {
  const location = useLocation()
  const { pathname } = location
  const hasToken = !!localStorage.getItem(StoreKeys.TOKEN)

  const isLogin = IsLogin(pathname)
  const isPaynow = IsPaynow(pathname)
  const isHistory = IsHistory(pathname)
  const isHome = IsHome(pathname)
  const isOrder = IsOrder(pathname)
  const isLanding = IsRoot(pathname) && !hasToken
  const isFps = IsFps(pathname)
  const isSuccessPage = IsSuccessPage(pathname)

  // listen to force update
  useSelector((s) => s.app.forceUpdate)

  // listen to history change to log event screen_view
  useScreenView()

  // merchant reference id generated by server, send when signup
  useEffect(() => {
    if (!sessionStorage.getItem('mRef')) {
      const { mRef = '' } = QueryString.parse(document.location.search)
      sessionStorage.setItem('mRef', mRef)
    }
  }, [])

  // get partner data from query string
  useEffect(() => {
    if (isOrder) {
      const { merchant, expiredAt } = QueryString.parse(
        document.location.search
      )
      const { params } =
        matchPath(pathname, '/orderpreview/:orderId') ||
        matchPath(pathname, '/order/:orderId')
      const { orderId } = params || {}
      if (orderId && merchant) {
        setPartnerInfo(orderId, { merchant, expiredAt })
      }
    }
  }, [isOrder])

  const showBreadscrumb =
    !isLogin && !isHome && !isLanding && !isFps && !isSuccessPage
  const HeaderNavBar = classNames({
    'lg:hidden': !isLanding,
  })

  return (
    <div className="App">
      <Head />
      <ScrollToTop />
      <ViewportContainer white={isHome || isHistory || isPaynow}>
        {!isLogin && (
          <div className={HeaderNavBar}>
            <Header />
            <HeaderSpacer />
          </div>
        )}
        <Body>
          <SideBar />
          <SwipeableTemporaryDrawer />
          {isLanding ? (
            <AppState>
              <Router />
            </AppState>
          ) : (
            <div className="w-full">
              {showBreadscrumb && <Breadcrumbs />}
              <AppState>
                <Router />
              </AppState>
            </div>
          )}
        </Body>

        <PinRequestModal />
        <VerifyPinOTPModal />
        <PinSetupModal />
        <MessageModal />
        <GlobalLoadingModal />
      </ViewportContainer>
    </div>
  )
}

export default App

import { isAndroid } from 'react-device-detect'

export const canBrowserShare = !!navigator.share

export const canBrowserShareFile = (() => {
  const files = [
    new File([], '', {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    }),
  ]
  return canBrowserShare && navigator.canShare({ files })
})()

export const getShareFileMedias = ({ email }) => {
  let medias = []
  if (email) {
    medias = medias.concat(['email'])
  }
  if (canBrowserShareFile) {
    medias = medias.concat(['browser'])
  }
  return medias
}

export const getShareMedias = ({ tel, email }) => {
  let medias = []
  if (tel) {
    medias = medias.concat(['sms', 'whatsapp'])
  }
  if (email) {
    medias = medias.concat(['email'])
  }
  if (canBrowserShare) {
    medias = medias.concat(['browser'])
  }
  return medias
}

export const smsShare = ({ tel, text }) => {
  if (isAndroid) {
    window.open(`sms:${tel}?body=${text}`)
  }
  window.open(`sms:${tel}/&body=${text}`)
}

export const emailShare = ({ email, title, text }) => {
  window.open(`mailto:${email}?subject=${title}&body=${text}`)
}

export const whatsappShare = ({ tel, text }) => {
  window.open(
    `https://api.whatsapp.com/send?text=${text}&phone=${tel}`,
    '_blank'
  )
}

export const browserShareFile = async (dataURI, filename) => {
  const response = await fetch(dataURI)
  const blob = await response.blob()
  const file = new File([blob], filename, {
    type: blob.type,
    lastModified: new Date().getTime(),
  })
  const shareData = { files: [file] }
  if (navigator.canShare(shareData)) {
    return navigator.share(shareData)
  }
  return Promise.reject(new Error('not supported'))
}

export const browserShare = ({ title, text, url }) =>
  navigator.share?.({ title, text, url })

import { useLockFn, useMemoizedFn } from 'ahooks'
import React, { useEffect, useRef } from 'react'

function isElement(node) {
  const ELEMENT_NODE_TYPE = 1
  return node.nodeType === ELEMENT_NODE_TYPE
}

const overflowScrollReg = /scroll|auto|overlay/i

const getScrollParent = (el, root = window) => {
  let node = el

  while (node && node !== root && isElement(node)) {
    const { overflowY } = window.getComputedStyle(node)
    if (overflowScrollReg.test(overflowY)) {
      return node
    }
    node = node.parentNode
  }

  return root
}

const isWindow = (el) => el === window

const InfinityScroll = ({ threshold = 50, hasMore, loadMore, children }) => {
  const doLoadMore = useLockFn(() => loadMore())

  const elementRef = useRef(null)
  const checkTimer = useRef(0)

  const check = useMemoizedFn(() => {
    clearTimeout(checkTimer.current)
    checkTimer.current = setTimeout(() => {
      const element = elementRef.current
      if (!element) return
      if (!element.offsetParent) return
      const parent = getScrollParent(element)
      if (!parent) return
      const rect = element.getBoundingClientRect()
      const elementTop = rect.top
      const current = isWindow(parent)
        ? window.innerHeight
        : parent.getBoundingClientRect().bottom
      if (current >= elementTop - threshold) {
        doLoadMore()
      }
    })
  })

  useEffect(() => check())

  useEffect(() => {
    const element = elementRef.current
    if (!element) return null
    const parent = getScrollParent(element)
    if (!parent) return null
    function onScroll() {
      check()
    }
    parent.addEventListener('scroll', onScroll)
    return () => parent.removeEventListener('scroll', onScroll)
  }, [])

  return <div ref={elementRef}>{hasMore && (children || 'loading...')}</div>
}

export default InfinityScroll

import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as DivitSvg } from '@/assets/divit-black.svg'
import { BackButtonContainer } from '@/components/common/BackButton'
import Button from '@/components/common/Button'
import Expander from '@/components/common/Expander'
import InputField from '@/components/common/InputField'
import Link from '@/components/common/Link'
import PasswordInputField from '@/components/common/PasswordInputField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SocialMediaAuth from '@/components/SocialMediaAuthNew'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { useAuth } from '@/contexts/authContext'
import { CaptchaProvider, useCaptcha } from '@/contexts/captchaContext'
import { useLocale } from '@/contexts/localeContext'
import { LineHR } from '@/pages/auth/shared/style'

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: center;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: center;
`

const Form = styled.form``

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const FormColumn = styled.div`
  flex: ${({ flex }) => flex || 1};
  ${({ position }) => position && `text-align: ${position};`}

  &:not(:last-child) {
    margin-right: ${({ theme, noGutter }) =>
      noGutter ? '0' : `${theme.gutter}px`};
  }
`

const LoginButton = styled(Button)`
  width: 100%;
  font-size: 0.89rem;
`

const HRRow = styled.div`
  width: 8.78rem;
  margin: 0 auto;
`

const ForgotPassword = styled(Link)``

const schema = Yup.object().shape({
  email: Yup.string().email('invalid email format').required('required'),
  password: Yup.string().required('required'),
})

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const Login = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentLocale } = useLocale()
  const { login, token } = useAuth()
  const { executeRecaptcha } = useCaptcha()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const [errorMessage, setErrorMessage] = useState('')

  const { loading, error } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch({ type: 'loginClear' })
  }, [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (token) {
      history.replace('/home')
    }
  }, [])

  useEffect(() => () => dispatch({ type: 'fastSignup/reset' }), [])

  useEffect(() => {
    if (error) {
      setErrorMessage(intl.formatMessage({ id: 'error.action.login' }))
    } else {
      setErrorMessage(null)
    }
  }, [error])

  const onClickBack = (e) => {
    e.preventDefault()
    history.goBack()
  }

  const onClickLogin = handleSubmit(async (data) => {
    setErrorMessage('')

    const { email, password } = data

    let recapResp = ''

    try {
      recapResp = await executeRecaptcha('LOGIN')
    } catch (err) {
      console.error(err)
    }

    login({ email, password, recapResp }, true)
  })

  const onClickForgotPassword = (e) => {
    e.preventDefault()
    const email = getValues('email')
    history.push('/forgot-password', { email })
  }

  return (
    <PageContainer>
      <PageContent hasPadding>
        <BackButtonContainer onClick={onClickBack} />
        <Icon
          renderImage={() => <DivitSvg />}
          width="6.23rem"
          height="2.22rem"
        />
        <Spacer height={globalStyles.px.lg} />
        <Title>welcome back</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>login to access business portal</SubTitle>
        <Spacer height={globalStyles.px.lg2} />
        {errorMessage && (
          <>
            <ErrorMessageLine errorMessage={errorMessage} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Form>
          <FormRow>
            <FormColumn>
              <InputField
                type="email"
                id="email"
                data-testid="login.email"
                title="email"
                {...register('email')}
                placeholder="divit@gmail.com"
                error={getErrorMessage(errors.email)}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <PasswordInputField
                id="password"
                data-testid="login.password"
                title="password"
                {...register('password')}
                placeholder="enter password"
                error={getErrorMessage(errors.password)}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <Expander />
            <ForgotPassword onClick={onClickForgotPassword}>
              forgot password?
            </ForgotPassword>
          </FormRow>
          <Spacer height={globalStyles.px.lg} />
          <LoginButton onClick={onClickLogin}>login</LoginButton>
        </Form>
        <Spacer height="2.22rem" />
      </PageContent>
      <LoadingModal loading={loading} />
    </PageContainer>
  )
}

export default (props) => (
  <CaptchaProvider>
    <Login {...props} />
  </CaptchaProvider>
)

import React from 'react'
import styled from 'styled-components'

// container > content +  sticky footer

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const CSContainer = ({ className, children }) => (
  <Container className={className}>
    <ContainerInner>{children}</ContainerInner>
  </Container>
)

export const CSContent = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  padding-bottom: 4.444rem;
`

export const CSFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1.778rem 0;
  background: rgb(255, 255, 255);

  ${(p) =>
    p.isRounded &&
    `
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  `};

  ${(p) =>
    !p.hasShadow &&
    'background: linear-gradient( 0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%)'};

  ${(p) => p.hasShadow && 'box-shadow: 0 -3px 6px rgb(0 0 0 / 4%)'};
`

export default 0

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const pinSlice = createSlice({
  name: 'pin',
  initialState: {
    verifyPinOTPModal: {
      isOpen: false,
      callback: null,
    },
    requestModal: {
      isOpen: false,
      isAuth: false,
      callback: null,
    },
    setupModal: {
      isOpen: false,
      isReset: false,
      callback: null,
    },
    authPin: AsyncState.create(),
    setupPin: AsyncState.create(),
    validatePin: AsyncState.create(),
    forgotPin: AsyncState.create(),
    validatePinOTP: AsyncState.create(),
  },
  reducers: {
    openVerifyPinOTPModal: (state, action) => {
      state.verifyPinOTPModal.isOpen = true
      state.verifyPinOTPModal.callback = action.payload.callback
    },
    closeVerifyPinOTPModal: (state) => {
      state.verifyPinOTPModal.isOpen = false
    },
    resetVerifyPinOTPModal: (state) => {
      state.verifyPinOTPModal.isOpen = false
      state.verifyPinOTPModal.callback = null
    },
    openRequestModal: (state, action) => {
      state.requestModal.isOpen = true
      state.requestModal.isAuth = action.payload.isAuth
      state.requestModal.callback = action.payload.callback
    },
    closeRequestModal: (state) => {
      state.requestModal.isOpen = false
    },
    resetRequestModal: (state) => {
      state.requestModal.isOpen = false
      state.requestModal.isAuth = false
      state.requestModal.callback = null
    },
    openSetupModal: (state, action) => {
      state.setupModal.isOpen = true
      state.setupModal.isReset = action.payload.isReset
      state.setupModal.callback = action.payload.callback
    },
    closeSetupModal: (state) => {
      state.setupModal.isOpen = false
    },
    resetSetupModal: (state) => {
      state.setupModal.isOpen = false
      state.setupModal.isReset = false
      state.setupModal.callback = null
    },
    reset: (state) => {
      state.authPin = AsyncState.reset()
      state.setupPin = AsyncState.reset()
      state.validatePin = AsyncState.reset()
    },
    authPinStart: (state) => {
      state.authPin = AsyncState.start()
    },
    authPinSuccess: (state, action) => {
      state.authPin = AsyncState.success(action.payload)
    },
    authPinFailed: (state, action) => {
      state.authPin = AsyncState.failed(action.payload)
    },
    setupPinStart: (state) => {
      state.setupPin = AsyncState.start()
    },
    setupPinSuccess: (state, action) => {
      state.setupPin = AsyncState.success(action.payload)
    },
    setupPinFailed: (state, action) => {
      state.setupPin = AsyncState.failed(action.payload)
    },
    validatePinStart: (state) => {
      state.validatePin = AsyncState.start()
    },
    validatePinSuccess: (state, action) => {
      state.validatePin = AsyncState.success(action.payload)
    },
    validatePinFailed: (state, action) => {
      state.validatePin = AsyncState.failed(action.payload)
    },
    forgotPinStart: (state) => {
      state.forgotPin = AsyncState.start()
    },
    forgotPinSuccess: (state, action) => {
      state.forgotPin = AsyncState.success(action.payload)
    },
    forgotPinFailed: (state, action) => {
      state.forgotPin = AsyncState.failed(action.payload)
    },
    validatePinOTPStart: (state) => {
      state.validatePinOTP = AsyncState.start()
    },
    validatePinOTPSuccess: (state, action) => {
      state.validatePinOTP = AsyncState.success(action.payload)
    },
    validatePinOTPFailed: (state, action) => {
      state.validatePinOTP = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = pinSlice

export default pinSlice.reducer

import React from 'react'

import { i18n } from '@/contexts/localeContext'

export const defaultFormatValues = {
  b: (chunks) => <b>{chunks}</b>,
  br: <br />,
}

export const t = (id = 'undefined', values = {}) =>
  i18n.intl.formatMessage({ id }, { ...defaultFormatValues, ...values })

export const formatMessage = (intl, id = 'undefined', values = {}) =>
  intl.formatMessage({ id }, { ...defaultFormatValues, ...values })

export const tt = formatMessage

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import DivitPng from '@/assets/divit.png'
import HeaderSpacer from '@/components/HeaderSpacer'

const ViewportContainer = styled.div`
  position: relative;
  min-height: 100vh;
  max-width: 600px;
  min-width: 360px;
  margin: 0 auto;
  box-shadow: 0px 5px 5px #ccc;
  overflow: hidden;
  background: white;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: calc(100% / 12);
  margin-right: calc(100% / 12);
  margin-top: 2.5rem;
  margin-bottom: 1.85rem;
  color: ${({ theme }) => theme.text};
`

const DivitLogo = styled.img`
  width: 6.23rem;
  height: 2.22rem;
`

const Body = styled.div`
  position: relative;
`

const BaseAppLayout = ({ children }) => (
  <div className="App">
    <ViewportContainer>
      <Header>
        <DivitLogo src={DivitPng} alt="Divit Logo" />
      </Header>
      <HeaderSpacer />
      <Body>{children}</Body>
    </ViewportContainer>
  </div>
)

BaseAppLayout.propTypes = {
  children: PropTypes.element,
}

BaseAppLayout.defaultProps = {
  children: <></>,
}

export default BaseAppLayout

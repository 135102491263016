import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const InvoiceRecordSlice = createSlice({
  name: 'invoiceRecord',
  initialState: {
    invoiceOrder: AsyncState.create(),
    getInvoiceRecordList: AsyncState.create(),
    totalCount: 0,
    currentPage: 0,
  },
  reducers: {
    reset: (state) => {
      state.getInvoiceRecordList = AsyncState.reset()
      state.totalCount =  0
      state.currentPage = 0
    },
    updateInvoiceOrderStart: (state, action) => {
      state.invoiceOrder = AsyncState.start()
    },
    updateInvoiceOrderSuccess: (state, action) => {
      state.invoiceOrder = AsyncState.success(action.payload)
    },
    updateInvoiceOrderFailed: (state, action) => {
      state.invoiceOrder = AsyncState.failed(action.payload)
    },
    getInvoiceRecordListStart: (state, action) => {
      state.getInvoiceRecordList = {
        ...state.getInvoiceRecordList,
        status: 'loading',
        isLoading: true,
        isSuccess: false,
        isError: false,
      }
      state.totalCount = 0
      state.currentPage = action.payload
    },
    getInvoiceRecordListSuccess: (state, action) => {
      if (state.currentPage > 0) {
        action.payload.data = [...state.getInvoiceRecordList.data, ...action.payload.data]
      }
      state.getInvoiceRecordList = AsyncState.success(action.payload.data)
      state.totalCount = action.payload.count
    },
    getInvoiceRecordListFailed: (state, action) => {
      state.getInvoiceRecordList = AsyncState.failed(action.payload)
      state.totalCount =  0
    },
  },
})

export const { actions } = InvoiceRecordSlice

export default InvoiceRecordSlice.reducer

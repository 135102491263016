import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const batchOrderSlice = createSlice({
  name: 'batchOrder',
  initialState: {
    batchOrder: AsyncState.create(),
    templateFile: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.batchOrder = AsyncState.reset()
    },
    batchOrderUploadStart: (state) => {
      state.batchOrder = AsyncState.start()
    },
    batchOrderUploadSuccess: (state, action) => {
      state.batchOrder = AsyncState.success(action.payload.data)
    },
    batchOrderUploadFailed: (state, action) => {
      state.batchOrder = AsyncState.failed(action.payload)
    },
    downloadBatchInvoiceTemplateStart: (state) => {
      state.templateFile = AsyncState.start()
    },
    downloadBatchInvoiceTemplateSuccess: (state, action) => {
      state.templateFile =  AsyncState.success(action.payload)
    },
    downloadBatchInvoiceTemplateFailed: (state, action) => {
      state.templateFile =  AsyncState.success(action.payload)
    },
  },
})

export const { actions } = batchOrderSlice

export default batchOrderSlice.reducer

import moment from 'moment'

export const getPartnerRef = (order) => `#${order.orderID.substring(0, 8)}`

export const getOrderAmount = (order) => {
  const { orderAmount, serviceFee } = order
  return {
    currency: orderAmount.currency,
    amount: orderAmount.amount + serviceFee.amount,
  }
}

export const getStatus = (order) => {
  if (order.status.toLowerCase() === 'completed') return 'completed'
  // TODO: handle paynow other status
  return ''
}

export const getLatestTransaction = (order) => {
  const txns = order.financialTransactions?.slice() || []
  txns.sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
  return txns.length > 0 ? txns[0] : undefined
}

export const isFailed = (order) => {
  const txn = getLatestTransaction(order)
  return txn?.transactionStatus === 'failed'
}

export default 0

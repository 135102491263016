import 'yup-phone-lite'

import { yupResolver } from '@hookform/resolvers/yup'
import { Decimal } from 'decimal.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as BackSpaceSvg } from '@/assets/common/backspace.svg'
import Button from '@/components/common/Button'
import ExpandableInputField from '@/components/common/ExpandableInputField'
import InputError from '@/components/common/InputError'
import Row from '@/components/common/Row'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { profileSelector } from '@/redux/profile/profileSelector'
import { FormattedMiles, FormattedPrice, Round } from '@/utils/Price'
import scrollToTop from '@/utils/scrollToTop'
import { invalidNameValue } from '@/utils/validation'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 3rem;
  text-align: right;
  border: none;
  width: 100%;
  flex: unset;
`
const AmountSuffix = styled.div`
  max-width: 45px;
`
const BackSpace = styled(Icon)`
  fill: #979797;
`
const Currency = styled.div``
const RowRight = styled(Row)`
  text-align: right;
  padding-bottom: 0.5rem;
`
const ReferenceNumber = styled(ExpandableInputField)`
  & > div:first-child {
    & div {
      font-weight: 500;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const RecipientEmails = styled(ReactMultiEmail)`
  display: ${(props) => props.shown};
  margin-top: 0.5rem;
  font-size: 0.78rem;
  background: transparent;
  padding: 0.778rem 1.333rem;
  &.empty > span[data-placeholder] {
    color: #989898;
  }
  & > span[data-placeholder] {
    position: unset;
    padding: 0;
  }
  & > input {
    background: transparent;
    padding: 0 0rem !important;
  }
`

const Label = styled.div`
  font-weight: 500;
  font-size: 0.778rem;
`
const OrderTotal = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;
`

const RemoveEmail = styled.span``

const ResetButton = styled(Button)``

const CreateButton = styled(Button)``

const defaultValues = {
  firstName: '',
  lastName: '',
  miles: 0,
  email: '',
  recipients: [],
  amount: '',
  milesForReward: 0,
  reference: '',
}

const nameSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .when('lastName', {
        is: (lastName) => !lastName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    lastName: Yup.string()
      .when('firstName', {
        is: (firstName) => !firstName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  },
  ['firstName', 'lastName']
)

const schema = Yup.object()
  .shape({
    miles: Yup.number()
      .typeError('miles is required')
      .positive('miles must be positive')
      .required('miles is required'),
    email: Yup.string()
      .trim('')
      .when('tel', {
        is: (tel) => !tel,
        then: Yup.string()
          .email('invalid email format')
          .required('either mobile or email is required'),
      }),
    recipients: Yup.array()
      .of(Yup.string().email())
      .test({
        message: 'recipient is required',
        test: (arr) => arr.length > 0,
      }),
    amount: Yup.number()
      .typeError('amount must be number')
      .positive('amount must be positive')
      .required('amount is required'),
    reference: Yup.string()
      .required('merchant ref# is required')
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  })
  .concat(nameSchema)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const DirectMilesPayment = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [emails, setEmails] = useState([])
  const [sellRate, setSellRate] = useState(0)

  const profile = useSelector(profileSelector)
  const { createPayment } = useSelector((s) => s.createPayment)
  const rateHKDDVM = useSelector((s) => s.lookup?.rateHKDDVM)
  const tokenRateHKDDVM = useSelector((s) => s.lookup?.tokenRateHKDDVM)

  const {
    reset,
    register,
    // trigger,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      firstName: `customer_${moment().unix()}`,
      reference: `${moment().unix()}`,
    },
    resolver: yupResolver(schema),
  })

  const orderAmount = watch('amount')
  const reference = watch('reference')

  const onClickReset = () => {
    setEmails([])
    reset()
  }

  const onClickCreate = handleSubmit((data) => {
    dispatch({
      type: 'createPayment/createDirectMilesPayment',
      payload: data,
    })
  })

  useEffect(() => {
    onClickReset()
  }, [])

  useEffect(() => {
    if (!profile) return
    if (profile?.abbreviation !== 'DIVITMARKET') {
      history.push('/')
    }
    dispatch({
      type: 'divitMiles/fetchMerchantMilesBooks',
      payload: {},
    })
  }, [profile])

  useEffect(() => {
    if (createPayment.isSuccess) {
      window.location.href = `${createPayment.data?.redirectURI}&token=${createPayment.data?.token}`
    }
    if (createPayment.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [createPayment])

  useEffect(() => () => dispatch({ type: 'createPayment/reset' }), [])

  useEffect(() => {
    if (!rateHKDDVM && !tokenRateHKDDVM) return
    if (!tokenRateHKDDVM || tokenRateHKDDVM?.sell === 0) {
      setSellRate(rateHKDDVM?.sell)
    } else {
      setSellRate(tokenRateHKDDVM?.sell)
    }
  }, [tokenRateHKDDVM, rateHKDDVM])

  const onChangeMiles = (recip, val) => {
    setValue('email', recip?.[0] || '')
    setValue('recipients', recip || [])
    setValue(
      'amount',
      Round(Decimal(val).mul(sellRate).toNumber(), 2) * recip.length
    )
  }

  const loading = createPayment.isLoading

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>direct miles</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>please enter information below</SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {createPayment.error && (
          <>
            <ErrorMessageLine errorMessage={createPayment.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Row>
          <Controller
            name="miles"
            control={control}
            render={({ field }) => (
              <Amount
                allowNegativeValue={false}
                decimalScale={0}
                disableAbbreviations
                placeholder="0"
                value={field.value}
                onValueChange={(value, name) => {
                  field.onChange(value)
                  onChangeMiles(emails, value)
                }}
                inputMode="numeric"
                maxLength={10}
              />
            )}
          />
          <AmountSuffix>
            <BackSpace
              renderImage={() => <BackSpaceSvg />}
              width="1.111rem"
              height="1.111rem"
              onClick={() => {
                setValue('miles', '')
              }}
            />
            <Currency>miles</Currency>
          </AmountSuffix>
        </Row>
        {errors.miles && (
          <RowRight>
            <InputError error={getErrorMessage(errors.miles)} />
          </RowRight>
        )}
        <Row>
          <ReferenceNumber
            title={`merchant ref: ${reference}`}
            {...register('reference')}
            placeholder="merchant reference #"
            error={getErrorMessage(errors.reference)}
            maxLength={10}
          />
        </Row>
        <Spacer height={globalStyles.px.xs} />
        <Label>recipient email(s)</Label>
        <Row>
          <RecipientEmails
            placeholder="recipient(s)"
            emails={emails}
            shown="flex"
            onChange={(_emails) => {
              setEmails(_emails)
              onChangeMiles(_emails, getValues('miles'))
            }}
            getLabel={(email, index, removeEmail) => (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <RemoveEmail data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </RemoveEmail>
              </div>
            )}
          />
        </Row>
        {errors.recipients && (
          <Row>
            <InputError error={getErrorMessage(errors.recipients)} />
          </Row>
        )}
        <Spacer height={globalStyles.px.xs} />
        <Row>
          <Label>number of recipient(s)</Label>
          <OrderTotal>{emails.length}</OrderTotal>
        </Row>
        <Row>
          <Label>total reward miles</Label>
          <OrderTotal>
            {FormattedMiles(getValues('miles') * 100 * emails.length)}
          </OrderTotal>
        </Row>
        <Spacer height={globalStyles.px.xs} />
        <hr />
        <Spacer height={globalStyles.px.xs} />
        <Row>
          <Label>total amount</Label>
          <OrderTotal>{FormattedPrice('HKD', orderAmount * 100)}</OrderTotal>
        </Row>
        {errors.amount && (
          <RowRight>
            <InputError error={getErrorMessage(errors.amount)} />
          </RowRight>
        )}
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
        <Buttons>
          <ResetButton type="bw" bordered onClick={onClickReset}>
            reset
          </ResetButton>
          <CreateButton type="main" onClick={onClickCreate}>
            create
          </CreateButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default DirectMilesPayment

/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import CitiSvg from '@/assets/landing/bw_fps_divit_citi.png'
import SlideSvg from '@/assets/landing/landing_kv.png'
import LandingSvg from '@/assets/landing/landing-bg-min.jpg'
import Button from '@/components/common/Button'
import LoadingScreen from '@/components/LoadingScreen'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { useAuth } from '@/contexts/authContext'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: url(${LandingSvg});
  background-repeat: no-repeat;
  background-size: 1000px, 100%, contain;
  background-position: -200px 0px;

  @media (min-width: 480px) {
    background-size: 1280px, 100%, contain;
    background-position: -100px 0px;
  }

  @media (min-width: 640px) {
    background-size: 1280px, 100%, contain;
    background-position: 0px 0px;
  }

  @media (min-width: 1280px) {
    background-size: 100%, 100%;
    background-position: 0px 0px;
  }
`

const PageContentLanding = styled(PageContent)`
  max-width: 1280px;
`

const Content = styled.div``

const Title = styled.div`
  font-size: 36px;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.xs}px`}) {
    font-size: 48px;
  }
`

// const SubTitle = styled.div`
//   font-size: 1.18rem;
//   font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
// `

const HeroImageContainer = styled.div`
  /* position: absolute; */
  @media (min-width: 480px) {
    padding-top: 82px;
  }
`

const CitiImageContainer = styled.div``

const Buttons = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  gap: 8px;
`

const LoginButton = styled(Button)`
  font-size: 0.8rem;
  color: #1c1c1c;
  padding: 10px 36px;
  border: 0px none transparent;
  background-color: white;
`

const SignupButton = styled(Button)`
  font-size: 0.8rem;
  padding: 10px 36px;
`

const SubLogo = styled.img`
  max-width: 279px !important;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.xs}px`}) {
    max-width: 379px !important;
  }
`

const Landing = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useAuth()

  useEffect(() => {
    dispatch({ type: 'loginClear' })
  }, [])

  // if user is loggedin, should not see signup, go home
  useEffect(() => {
    if (token) {
      history.replace('/home')
    }
  }, [])

  const onClickCreateAccount = () => {
    history.push('/signup')
  }

  const onClickLogin = () => {
    history.push('/login')
  }

  return (
    <>
      {!token ? (
        <Container>
          <Content>
            <PageContentLanding
              hasPadding
              className="flex justify-center flex-wrap gap-y-[130px] lg:flex-nowrap xs:gap-y-20 md:gap-y-10 lg:gap-0 lg:justify-between"
            >
              <div>
                <Spacer height={globalStyles.px.lg2} />
                <CitiImageContainer>
                  <SubLogo src={CitiSvg} alt="" />
                </CitiImageContainer>
                <Title>divit business portal</Title>
                <Spacer height="0.5rem" />
                <Buttons>
                  <SignupButton onClick={onClickCreateAccount}>
                    create account
                  </SignupButton>
                  <LoginButton bordered onClick={onClickLogin}>
                    login
                  </LoginButton>
                </Buttons>
              </div>
              <HeroImageContainer>
                <img src={SlideSvg} alt="" />
              </HeroImageContainer>
            </PageContentLanding>
          </Content>
        </Container>
      ) : (
        <LoadingScreen />
      )}
    </>
  )
}

export default Landing

import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'
import { actions as setupTeamActions } from './setupTeamSlice'

export function* getUserList() {
  try {
    yield put(setupTeamActions.getUserListStart())
    const { data } = yield call(() => DivitAPI.get('/users/v2', {}))
    yield put(setupTeamActions.getUserListSuccess(data.data))
  } catch (e) {
    yield put(setupTeamActions.getUserListFailed(getErrorMessage(e)))
  }
}

export function* inviteUser({ payload }) {
  try {
    yield put(setupTeamActions.inviteUserStart())
    const { data } = yield call(() =>
      DivitAPI.post('/users/v2/invite', payload)
    )
    yield put(setupTeamActions.inviteUserSuccess(data))

    yield getUserList()
  } catch (e) {
    yield put(setupTeamActions.inviteUserFailed(getErrorMessage(e)))
  }
}

export function* updateUser({ payload }) {
  try {
    yield put(setupTeamActions.editUserStart())
    const { data } = yield call(() =>
      DivitAPI.patch(`/users/v2/profile/${payload.contactID}`, payload)
    )
    yield put(setupTeamActions.editUserSuccess(data))

    yield getUserList()
  } catch (e) {
    yield put(setupTeamActions.editUserFailed(getErrorMessage(e)))
  }
}

export default null

import React from 'react'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY

export const CaptchaProvider = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} {...props} />
)

export const useCaptcha = useGoogleReCaptcha

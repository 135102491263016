import React from 'react'
import { Helmet } from 'react-helmet'

const Head = () => (
  <Helmet>
    <title>divit | business portal</title>
    <meta name="description" content="divit, pay now, pay later, earn miles" />
    <link rel="icon" type="image/png" href="/icons/logo32.png" sizes="32x32" />
    <link
      rel="icon"
      type="image/png"
      href="/icons/logo192.png"
      sizes="192x192"
    />
    <link rel="apple-touch-icon" href="/icons/logo180.png" />
    <link rel="manifest" href="/manifest.json" />
  </Helmet>
)

export default Head

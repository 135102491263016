import { useEffect, useState } from 'react'

import { validatePassword } from '@/utils/validation'

const usePasswordValiation = (password) => {
  const [passwordValidation, setPasswordValidation] = useState(() =>
    validatePassword(password)
  )

  const checkPasswordValidation = (pass) => {
    setPasswordValidation(validatePassword(pass))
  }

  useEffect(() => {
    checkPasswordValidation(password)
  }, [password])

  return {
    checkPasswordValidation,
    passwordValidation,
  }
}

export default usePasswordValiation

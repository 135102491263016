import { push } from 'connected-react-router'
import { all, put } from 'redux-saga/effects'

import { setupAccount } from '@/redux/auth/authActions'
import { fetchLookups } from '@/redux/lookup/lookupSaga'
import { fetchProfile } from '@/redux/profile/profileSaga'
import { isRoleAvailable } from '@/utils/Jwt'

import { getErrorMessage } from '../utils/error'
import { actions as pageInitActions } from './pageInitSlice'

// TODO: now grouped all page init into one, should be separate into page
// page initialize order, profile, then get it from order / profile

function* checkJWTValid() {
  const token = localStorage.getItem('token')
  if (token && !isRoleAvailable(token)) {
    yield put({ type: 'logout', payload: { isNormalLogout: false } })
    yield put(push('/'))
    throw new Error('forbidden')
  }
}

export function* initApp() {
  try {
    yield put(pageInitActions.initAppStart())
    yield all([checkJWTValid(), fetchLookups(), fetchProfile()])
    yield put(pageInitActions.initAppSuccess())
    yield put(setupAccount({ isPrompt: true, isRedirect: false }))
  } catch (e) {
    yield put(pageInitActions.initAppFailed(getErrorMessage(e)))
  }
}

export default null

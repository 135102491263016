import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: {
    forgotPassword: AsyncState.create(),
    verifyToken: AsyncState.create(),
    resetPassword: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.forgotPassword = AsyncState.reset()
    },
    forgotPasswordStart: (state) => {
      state.forgotPassword = AsyncState.start()
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPassword = AsyncState.success(action.payload)
    },
    forgotPasswordFailed: (state, action) => {
      state.forgotPassword = AsyncState.failed(action.payload)
    },
    verifyTokenStart: (state) => {
      state.verifyToken = AsyncState.start()
    },
    verifyTokenSuccess: (state, action) => {
      state.verifyToken = AsyncState.success(action.payload)
    },
    verifyTokenFailed: (state, action) => {
      state.verifyToken = AsyncState.failed(action.payload)
    },
    resetPasswordStart: (state) => {
      state.resetPassword = AsyncState.start()
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPassword = AsyncState.success(action.payload)
    },
    resetPasswordFailed: (state, action) => {
      state.resetPassword = AsyncState.failed(action.payload)
    },
  },
})

export const { actions } = resetPasswordSlice

export default resetPasswordSlice.reducer

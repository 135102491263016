import { createSelector } from 'reselect'

import * as DivitMiles from '@/utils/DivitMiles'
import * as Jwt from '@/utils/Jwt'

export const storeSelector = (s) => s.profile
export const milesSelector = (s) => s.divitMiles

export const roleSelector = createSelector(storeSelector, (s) => s.role || '')

export const profileSelector = createSelector(
  storeSelector,
  (s) => s.profile || {}
)

export const profileMerchantSelector = createSelector(
  profileSelector,
  (profile) => profile
)

export const profileConfigSettings = createSelector(
  profileSelector,
  (profile) => profile?.settings?.configSettings || {}
)

// merchant email must be validated
export const profileIdSelector = createSelector(profileSelector, (s) => s.email)

export const sessionSelector = createSelector(
  storeSelector,
  profileSelector,
  (data, profile) => {
    const { token, lastLoginTs } = data
    return {
      token,
      lastLoginTs,
      hasPin: profile.hasUserPin,
      hasPassword: !profile.systemGenPwd,
      hasEmail: !!profile.email,
      hasTel: !!profile.phone,
      loginType: Jwt.getLoginType(token),
      isOAuthLogin: Jwt.getLoginType(token) !== 'pwd',
    }
  }
)

export const divitMilesSelector = createSelector(storeSelector, (s) => ({
  balance: DivitMiles.getBalance(s.divitMiles),
  memberships: s.divitMilesMemberships,
  pendingBalance: DivitMiles.getPendingBalance(s.divitMiles),
  transcations: DivitMiles.getAllTransactions(s.divitMilesTranscations),
  pendingEvents: DivitMiles.getPendingEvents(s.divitMilesTranscations),
}))

export const tokenBookBalanceSelector = createSelector(milesSelector, (s) => ({
  bookID: DivitMiles.getTokenBookID(s.merchantBooks),
  balance: DivitMiles.getTokenBookBalance(s.merchantBooks),
  reserved: DivitMiles.getTokenBookReservedTotal(s.merchantBooks),
}))

export const tokenBooksSelector = createSelector(milesSelector, (s) => ({
  books: DivitMiles.getTokenBooks(s.merchantBooks),
}))

export const campaignSelector = createSelector(storeSelector, (s) => ({
  missions: s.campaignMissions,
}))

export const milesLinkageSelector = createSelector(storeSelector, (s) => ({
  milesLinkage: s.milesLinkage,
}))

export const getFpsServiceSelector = createSelector(storeSelector, (profile) =>
  profile.services.find((s) => s.serviceName === 'fps_citi')
)

export const getFpsQRServiceSelector = createSelector(
  storeSelector,
  (profile) => profile.services.find((s) => s.serviceName === 'fps_qrcode')
)

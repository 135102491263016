import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as resetPasswordActions } from './resetPasswordSlice'

export function* forgotPassword({ payload }) {
  try {
    yield put(resetPasswordActions.forgotPasswordStart())
    const { data } = yield call(() =>
      DivitAPI.post('/users/v2/pwd/reset/request', payload)
    )
    yield put(resetPasswordActions.forgotPasswordSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.forgotPasswordFailed(getError(e)))
  }
}

export function* verifyToken({ payload }) {
  try {
    yield put(resetPasswordActions.verifyTokenStart())
    const { data } = yield call(() =>
      DivitAPI.post('/users/v2/pwd/reset/verify', payload)
    )
    yield put(resetPasswordActions.verifyTokenSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.verifyTokenFailed(getError(e)))
  }
}

export function* resetPassword({ payload }) {
  try {
    yield put(resetPasswordActions.resetPasswordStart())
    const { data } = yield call(() =>
      DivitAPI.patch('/users/v2/pwd/reset', payload)
    )
    yield put(resetPasswordActions.resetPasswordSuccess(data))
  } catch (e) {
    yield put(resetPasswordActions.resetPasswordFailed(getError(e)))
  }
}

export default null

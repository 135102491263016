import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  merchantBooks: [],
  loading: false,
  error: null,
}

const divitMilesSlice = createSlice({
  name: 'divitMiles',
  initialState,
  reducers: {
    reset: () => initialState,
    getMerchantBooksStart: (state) => {
      state.loading = true
      state.error = null
    },
    getMerchantBooksSuccess: (state, action) => {
      state.merchantBooks = action.payload
      state.loading = false
      state.error = null
    },
    getMerchantBooksFailed: (state, action) => {
      state.merchantBooks = []
      state.error = action.payload
    },
  },
})

export const { actions } = divitMilesSlice

export default divitMilesSlice.reducer

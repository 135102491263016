import colors from '../colors'

const hkexpress = {
  text: colors.hkexpressPurple,
  bubble: {
    background: colors.divitDarkPurple,
    text: colors.white,
  },
  ticket: {
    primary: colors.hkexpressPurple,
    secondary: colors.hkexpressRed,
  },
  backButton: {
    text: colors.hkexpressPurple,
  },
}

export default hkexpress

import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getErrorMessage } from '../utils/error'
import { actions as changePasswordActions } from './changePasswordSlice'

export function* changePassword({ payload }) {
  try {
    yield put(changePasswordActions.changePasswordStart())
    const { data } = yield call(() => DivitAPI.patch('/users/v2/pwd', payload))
    yield put(changePasswordActions.changePasswordSuccess(data))
  } catch (e) {
    yield put(changePasswordActions.changePasswordFailed(getErrorMessage(e)))
  }
}

export default null

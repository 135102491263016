import * as math from './math'
import numToDollar from './numberToWords/numToDollar'
import numToPercentage from './numberToWords/numToPercentage'

/**
 * Round to specific decimal places
 * @param {number} amount. Amount of price
 * @param {number} decimalPlaces. Decimal places
 * @param {string} mode. 'off', 'up', 'down'
 * @returns {number} roundedValue. The rounded price
 */
export const Round = (amount, decimalPlaces = 0, mode = 'off') => {
  const factor = 10 ** decimalPlaces
  if (mode === 'up')
    return Math.ceil((amount + Number.EPSILON) * factor) / factor
  if (mode === 'down')
    return Math.floor((amount + Number.EPSILON) * factor) / factor
  return Math.round((amount + Number.EPSILON) * factor) / factor
}

/**
 * Split dollar and cent in string from amount
 * @param {number} amount - Amount of price
 * @returns {Object} dollarAndCent
 * @returns {string} dollarAndCent.dollar. Dollar part of price
 * @returns {string} dollarAndCent.cent. Cent part of price
 */
export const DollarAndCent = (amount) => ({
  dollar: `${parseInt(amount / 100, 10)}`,
  cent: `${Round(amount % 100)}`.padStart(2, '0'),
})

/**
 * Format a string with given currency and amount
 * @param {string} currency. Currency of price
 * @param {number} amount. Amount of price
 * @param {boolean} showCents. Show cents or not. Default true
 * @returns {string} formattedPrice. The formatted price
 */
export const FormattedPrice = (currency, amount, showCents = true) => {
  const isNegative = amount < 0
  const { dollar, cent } = DollarAndCent(Math.abs(amount))
  const formattedDollar = dollar.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  const ret = showCents
    ? `${currency} ${formattedDollar}.${cent}`.trim()
    : `${currency} ${formattedDollar}`.trim()
  return isNegative ? `-${ret}` : ret
}

export const FormattedMiles = (amount) => FormattedPrice('', amount, false)

export const FormattedPercentage = (value) => Round(value, 1).toFixed(1)

// TODO: HKID only
export const TermsWordings = (curreny, amount, locale) =>
  `${numToDollar(Round(math.div(amount, 100), 2), {
    locale,
  })} (${FormattedPrice(curreny, amount)})`

export const TermsWordingsPercent = (percent, locale) =>
  `${numToPercentage(percent, { locale })} (${percent}%)`

import { createSlice } from '@reduxjs/toolkit'

import AsyncState from '../utils/AsyncState'

const batchSendMilesSlice = createSlice({
  name: 'batchSendMiles',
  initialState: {
    batchRequests: AsyncState.create(),
    templateFile: AsyncState.create(),
    batchResult: AsyncState.create(),
    batchResultFile: AsyncState.create(),
  },
  reducers: {
    reset: (state) => {
      state.batchRequests = AsyncState.reset()
    },
    batchSendMilesStart: (state) => {
      state.batchRequests = AsyncState.start()
    },
    batchSendMilesSuccess: (state, action) => {
      state.batchRequests = AsyncState.success(action.payload.data)
    },
    batchSendMilesFailed: (state, action) => {
      state.batchRequests = AsyncState.failed(action.payload)
    },
    downloadBatchSendMilesTemplateStart: (state) => {
      state.templateFile = AsyncState.start()
    },
    downloadBatchSendMilesTemplateSuccess: (state, action) => {
      state.templateFile =  AsyncState.success(action.payload)
    },
    downloadBatchSendMilesTemplateFailed: (state, action) => {
      state.templateFile =  AsyncState.success(action.payload)
    },
    fetchBatchSendMilesStart: (state) => {
      state.batchResult = AsyncState.start()
    },
    fetchBatchSendMilesSuccess: (state, action) => {
      state.batchResult = AsyncState.success(action.payload.data)
    },
    fetchBatchSendMilesFailed: (state, action) => {
      state.batchResult = AsyncState.failed(action.payload)
    },
    downloadBatchSendMilesResultStart: (state) => {
      state.batchResultFile = AsyncState.start()
    },
    downloadBatchSendMilesResultSuccess: (state, action) => {
      state.batchResultFile =  AsyncState.success(action.payload)
    },
    downloadBatchSendMilesResultFailed: (state, action) => {
      state.batchResultFile =  AsyncState.success(action.payload)
    },
  },
})

export const { actions } = batchSendMilesSlice

export default batchSendMilesSlice.reducer

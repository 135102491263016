import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as SpinnerSvg } from '@/assets/common/spinner.svg'

import Modal from './Modal'

const LoadingBackground = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`

const LoadingWrapper = styled.div`
  width: 11.652rem;
  height: 7.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background-color: white;
`

const LoadingIcon = styled.div``

const LoadingText = styled.div`
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #333333;
`

const LoadingModal = ({ loadingText, loading }) => {
  const intl = useIntl()

  return (
    <Modal isOpen={loading} style={{ overlay: { zIndex: 99999 } }}>
      <LoadingBackground>
        <LoadingWrapper>
          <LoadingIcon>
            <SpinnerSvg />
          </LoadingIcon>
          <LoadingText>
            {loadingText || intl.formatMessage({ id: 'spinner.loading' })}
          </LoadingText>
        </LoadingWrapper>
      </LoadingBackground>
    </Modal>
  )
}

LoadingModal.defaultProps = {
  loadingText: null,
}

LoadingModal.propTypes = {
  loadingText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
}

export default LoadingModal

import moment from 'moment'
import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'

import { getError } from '../utils/error'
import { actions as sendMilesActions } from './sendMilesSlice'

export function* sendMiles({ payload }) {
  try {
    yield put(sendMilesActions.sendMilesStart())
    const requestRespData = yield call(() =>
      DivitAPI.post(`/miles/merchants/${payload.merchantID}/issue`, {
        merchantRefID: `${moment().unix()}`,
        bookID: payload.bookID,
        customerEmail: (payload.email || '').toLowerCase(),
        customerPhone: payload.tel
          ? `${payload.countryCode}${payload.tel}`
          : null,
        miles: payload.miles,
        issuingMethod: 'email',
        claimExpiry: 0,
        remarks: 'DIRECT ISSUE MILES BY MERCHANT',
      })
    )
    yield put(sendMilesActions.sendMilesSuccess(requestRespData.data))
  } catch (err) {
    yield put(sendMilesActions.sendMilesFailed(getError(err)))
  }
}

export default null

import 'yup-phone-lite'

import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as BackSpaceSvg } from '@/assets/common/backspace.svg'
import { ReactComponent as EmailSvg } from '@/assets/common/email.svg'
import { ReactComponent as PhoneSvg } from '@/assets/common/mobile.svg'
import Button from '@/components/common/Button'
import InputError from '@/components/common/InputError'
import InputField from '@/components/common/InputField'
import Row from '@/components/common/Row'
import SelectField from '@/components/common/SelectField'
import Icon from '@/components/Icon'
import MilesValue from '@/components/miles/MilesValue'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import { verifyIdentity } from '@/redux/pin/pinActions'
import {
  profileSelector,
  roleSelector,
  tokenBooksSelector,
} from '@/redux/profile/profileSelector'
import scrollToTop from '@/utils/scrollToTop'

const Container = styled(PageContainer)`
  background-color: #fff;
`
const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`
const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
  display: flex;
  align-items: center;
`
const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 3rem;
  text-align: right;
  border: none;
  width: 100%;
  flex: unset;
`
const AmountSuffix = styled.div`
  max-width: 45px;
`
const BackSpace = styled(Icon)`
  fill: #979797;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`
const BookOptionItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
const BookOptionItemTitle = styled.div`
  margin-left: 10px;
  color: #888;
`
const Currency = styled.div``
const Form = styled.div`
  background: white;
  padding: 1.33rem;
  padding-top: 0.5rem;
  border-radius: 16px;
`
const RowRight = styled(Row)`
  text-align: right;
  padding-bottom: 0.5rem;
`
const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`
const ResetButton = styled(Button)``
const SendButton = styled(Button)``

const defaultValues = {
  countryCode: '852',
  tel: '',
  email: '',
  miles: '',
  merchantID: '',
  bookID: '',
}

const contactSchema = Yup.object().shape(
  {
    email: Yup.string()
      .trim('')
      .when('tel', {
        is: (tel) => !tel,
        then: Yup.string()
          .email('invalid email format')
          .required('either mobile or email is required'),
      }),
    countryCode: Yup.string().required(),
    tel: Yup.string().when('email', {
      is: (email) => !email,
      then: Yup.string()
        .phone('HK', 'invalid phone number format')
        .required('either mobile or email is required'),
    }),
  },
  ['tel', 'email']
)

const schema = Yup.object()
  .shape({
    miles: Yup.number()
      .typeError('miles is required')
      .positive('must be positive')
      .required('miles is required'),
    merchantID: Yup.string().required(
      'unable to load profile merchantID, pls reload the page and retry'
    ),
    bookID: Yup.string().required(
      'unable to load token bookID, pls reload the page and retry'
    ),
  })
  .concat(contactSchema)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const SendMiles = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState('email')
  const [currentBook, setCurrentBook] = useState({})
  const role = useSelector(roleSelector)
  const profile = useSelector(profileSelector)
  const { sendMiles } = useSelector((s) => s.sendMiles)
  const { books } = useSelector(tokenBooksSelector)
  const [insufficientBalance, setInsufficientBalance] = useState(true)

  const {
    reset,
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(schema),
  })

  const checkSufficientBalance = () => {
    const totalMiles = parseInt(getValues('miles'), 10) || 0
    if (currentBook && currentBook.balance) {
      setInsufficientBalance(
        currentBook.balance.amount <= 0 ||
          totalMiles > currentBook.balance.amount / 100 ||
          totalMiles === 0
      )
    } else {
      setInsufficientBalance(true)
    }
  }

  useEffect(() => () => dispatch({ type: 'sendMiles/reset' }), [])
  useEffect(() => {
    if (role && role !== 'admin') {
      history.replace('/home')
    }
    if (role) {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
  }, [role])

  useEffect(() => {
    if (books && profile) {
      if (books.length > 0) {
        setValue('bookID', books[0].bookID)
        setCurrentBook(books[0])
        checkSufficientBalance()
      }
      setValue('merchantID', profile.merchantID)
    }
  }, [books, profile])

  useEffect(() => {
    if (sendMiles.isSuccess) {
      // update the miles balance
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
      // save promo code for default next one
      dispatch(
        promptConfirmModal({
          title: 'sent',
          content: 'miles have been rewarded successfully',
          okText: 'done',
          closable: false,
        })
      )
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
      reset()
      setValue('tel', '')
      setValue('email', '')
      setValue('miles', '')
      setValue('merchantID', profile.merchantID)
      setValue('bookID', currentBook.bookID)
    }
    if (sendMiles.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [sendMiles])

  const onClickReset = () => {
    reset()
  }

  const setTokenBook = (bookID) => {
    setCurrentBook(books.filter((b) => b.bookID === bookID)[0])
    setValue('bookID', bookID)
  }

  const onClickSend = handleSubmit(async (data) => {
    if (data.miles > currentBook.balance.amount / 100) {
      setError('miles', { message: 'not enough miles' })
      return
    }
    const { isSuccess } = await dispatch(verifyIdentity())
    if (isSuccess) {
      dispatch({ type: 'sendMiles/sendMiles', payload: data })
    }
  })

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>reward divit miles</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>
          {books && books.length > 1 && (
            <>
              from miles account:&nbsp;&nbsp;
              <SelectField
                name="bookID"
                value={currentBook?.bookID}
                options={books.map((b) => ({
                  label: (
                    <BookOptionItem>
                      <MilesValue
                        fontSize="1rem"
                        miles={b.balance.amount}
                        isSigned={false}
                      />
                      <BookOptionItemTitle>{b.bookTitle}</BookOptionItemTitle>
                    </BookOptionItem>
                  ),
                  value: b.bookID,
                }))}
                onChange={(v) => setTokenBook(v)}
              />
            </>
          )}
          {books && books.length === 1 && currentBook?.balance && (
            <>
              balance:&nbsp;&nbsp;
              <MilesValue
                fontSize="1rem"
                miles={currentBook?.balance?.amount}
                isSigned={false}
              />
            </>
          )}
        </SubTitle>
        <Spacer height={globalStyles.px.xs} />
        <Row>
          <Controller
            name="miles"
            control={control}
            render={({ field }) => (
              <Amount
                allowNegativeValue={false}
                decimalScale={0}
                disableAbbreviations
                placeholder="0"
                value={field.value}
                onValueChange={(value, name) => {
                  field.onChange(value)
                  checkSufficientBalance()
                }}
                inputMode="numeric"
                maxLength={10}
              />
            )}
          />
          <AmountSuffix>
            <BackSpace
              renderImage={() => <BackSpaceSvg />}
              width="1.111rem"
              height="1.111rem"
              onClick={() => {
                setValue('miles', '')
              }}
            />
            <Currency>miles</Currency>
          </AmountSuffix>
        </Row>
        {errors.miles && (
          <RowRight>
            <InputError error={getErrorMessage(errors.miles)} />
          </RowRight>
        )}
        {errors.merchantID && (
          <RowRight>
            <InputError error={getErrorMessage(errors.merchantID)} />
          </RowRight>
        )}
        {errors.bookID && (
          <RowRight>
            <InputError error={getErrorMessage(errors.bookID)} />
          </RowRight>
        )}
        <Form>
          <SelectorTabs
            defaultTab={activeTab}
            tabs={[
              { key: 'email', label: 'email', Icon: EmailSvg },
              { key: 'mobile', label: 'mobile', Icon: PhoneSvg },
            ]}
            onChange={(tab) => setActiveTab(tab.key)}
          />
          <Tab isActive={activeTab === 'email'}>
            <InputField
              title=""
              placeholder="email"
              {...register('email')}
              error={getErrorMessage(errors.email)}
              inputMode="email"
            />
          </Tab>
          <Tab isActive={activeTab === 'mobile'}>
            <Row>
              <InputField
                style={{ width: 90, flex: 'none' }}
                onChange={() => null}
                value="852"
                hasError={getErrorMessage(errors.tel)}
                tabIndex="-1"
              />
              <InputField
                placeholder="mobile number"
                {...register('tel')}
                hasError={getErrorMessage(errors.tel)}
                inputMode="tel"
              />
            </Row>
            <Row>
              <InputError error={getErrorMessage(errors.tel)} />
            </Row>
          </Tab>
        </Form>
        <Spacer height={globalStyles.px.xs} />
        <Buttons>
          <ResetButton type="bw" bordered onClick={onClickReset}>
            reset
          </ResetButton>
          <SendButton
            type="main"
            onClick={onClickSend}
            disabled={
              !role || !profile || insufficientBalance || sendMiles.isLoading
            }
          >
            send
          </SendButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
    </Container>
  )
}

export default SendMiles

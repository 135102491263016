import React from 'react'

const PersonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={20}
      height={21}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="#fff" d="M19.988.5H0v20h19.988V.5Z" />
    </mask>
    <g stroke="#1C1C1C" strokeMiterlimit={10} mask="url(#a)">
      <path d="M9.994 10.493a3.85 3.85 0 1 0 0-7.7 3.85 3.85 0 0 0 0 7.7Z" />
      <path
        strokeLinecap="round"
        d="M2.294 18.21a5.133 5.133 0 0 1 5.134-5.138h5.133a5.134 5.134 0 0 1 5.133 5.138"
      />
    </g>
  </svg>
)
export default PersonIcon

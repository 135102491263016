import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Modal from '@/components/Modal'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.5);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 17.77rem;
  margin: 0 auto;
  margin-top: 200px;
  border-radius: 32px;
  background-color: white;
  transition: transform 0.2s ease-in-out;
`

const Title = styled.div`
  font-size: 1.11rem;
  font-weight: 600;
  padding: 0 1.38rem;
  text-align: center;
`

const Content = styled.div`
  margin-top: 8px;
  padding: 0 1.38rem;
  text-align: center;
  font-size: 0.88rem;
  line-height: 1.3rem;
`

const Item = styled.div`
  padding: 1.33rem 1.38rem;
  font-size: 0.88rem;
  font-weight: 600;
  border-top: 1px solid #eee;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #606060;
  }
`

const MessageModal = () => {
  const dispatch = useDispatch()

  const { isOpen, title, content, actions = [] } = useSelector((s) => s.modal)

  const onClose = () => dispatch({ type: 'modal/reset' })

  const onClickAction = (action) => () => {
    action.action?.()
    onClose()
  }

  const xActions =
    actions.length > 0
      ? actions
      : [{ key: 'ok', title: 'ok', action: () => onClose() }]

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999999,
        },
      }}
      openAnimationSelector=".message-modal"
      openAnimationClassName="message-modal--on-open"
    >
      <Background onClick={onClose} />
      <Container className="message-modal">
        <Spacer height={globalStyles.px.lg} />
        {title && <Title>{title}</Title>}
        {content && <Content>{content}</Content>}
        <Spacer height={globalStyles.px.md} />
        {xActions.map((action) => (
          <Item key={action.key} onClick={onClickAction(action)}>
            {action.title}
          </Item>
        ))}
      </Container>
    </Modal>
  )
}

export default MessageModal

import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  align-items: center;

  & > * {
    flex: 1;
    margin-right: 0.44rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

export default Row

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as TabReportSvg } from '@/assets/history/tab-report.svg'
import { ReactComponent as TabTransactionSvg } from '@/assets/history/tab-transaction.svg'
import MilesValue from '@/components/miles/MilesValue'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs2'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import ReportList from '@/pages/milestransactions/ReportList'
import {
  roleSelector,
  tokenBookBalanceSelector,
} from '@/redux/profile/profileSelector'

import MilesTransactionsList from './milestransactions/MilesTransactionsList'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`
const SubTitle = styled.div``
const Balance = styled.div`
  display: flex;
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Tab = styled.div`
  width: 100%;
  height: ${({ isActive }) => (isActive ? 'auto' : 0)};
  overflow: hidden;
`

const MilesTransactions = () => {
  const dispatch = useDispatch()

  const role = useSelector(roleSelector)
  const balance = useSelector(tokenBookBalanceSelector)
  const [activeTab, setActiveTab] = useState('transactions')

  useEffect(() => () => dispatch({ type: 'milesTransactions/reset' }), [])
  useEffect(() => {
    if (role === 'admin') {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
  }, [])

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>miles transactions</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>
          <Balance>
            divit miles balance:&nbsp;
            <MilesValue miles={balance.balance * 100} isSigned={false} />
          </Balance>
        </SubTitle>
        <Spacer height={globalStyles.px.lg1} />
        <SelectorTabs
          defaultTab={activeTab}
          tabs={[
            {
              key: 'transactions',
              label: 'transactions',
              Icon: TabTransactionSvg,
            },
            { key: 'report', label: 'report', Icon: TabReportSvg },
          ]}
          onChange={(tab) => setActiveTab(tab.key)}
        />
        <Tab isActive={activeTab === 'transactions'}>
          <MilesTransactionsList />
        </Tab>
        <Tab isActive={activeTab === 'report'}>
          <ReportList />
        </Tab>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
    </Container>
  )
}

export default MilesTransactions

import 'yup-phone-lite'
import 'react-multi-email/dist/style.css'

import { yupResolver } from '@hookform/resolvers/yup'
import { Decimal } from 'decimal.js'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller, useForm } from 'react-hook-form'
import { ReactMultiEmail } from 'react-multi-email'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

import { ReactComponent as BackSpaceSvg } from '@/assets/common/backspace.svg'
import Button from '@/components/common/Button'
import InputError from '@/components/common/InputError'
import InputField from '@/components/common/InputField'
import Row from '@/components/common/Row'
import SingleDateSelectField, {
  getDateValue,
  getDefaultDateValue,
} from '@/components/common/SingleDateSelectField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import MilesSlider from '@/components/miles/MilesSlider'
import MilesEarnLabel from '@/components/MilesEarnLabel'
import PageContainer from '@/components/Page/PageContainer'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { promptConfirmModal } from '@/redux/modal/modalActions'
import {
  profileConfigSettings,
  roleSelector,
} from '@/redux/profile/profileSelector'
import * as CampaignUtils from '@/utils/Campaign'
import * as LocalStorage from '@/utils/localstorage'
import { FormattedPercentage, FormattedPrice, Round } from '@/utils/Price'
import scrollToTop from '@/utils/scrollToTop'
import { invalidNameValue } from '@/utils/validation'

const Container = styled(PageContainer)`
  background-color: #fff;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const SubTitle = styled.div`
  font-size: 0.88rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  text-align: left;
`

const Amount = styled(CurrencyInput)`
  background: transparent;
  font-size: 3rem;
  text-align: right;
  border: none;
  width: 100%;
  flex: unset;
`
const AmountSuffix = styled.div`
  max-width: 45px;
`
const BackSpace = styled(Icon)`
  fill: #979797;
`
const Currency = styled.div``
const AmountError = styled.div`
  font-size: 0.77rem;
  text-align: right;
  margin-top: 0.44rem;
  color: #e6171a;
`

const FieldWrapper = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const FieldTitle = styled.div`
  font-size: 0.77rem;
  margin-bottom: 0.44rem;
`
const MessageContent = styled.textarea`
  background-color: transparent;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 8px;
  padding: 0.778rem 1.333rem;
  outline: none;
  margin-bottom: 0.44rem;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const BlockGroup = styled.div`
  display: grid;
  grid-template-columns: 50% 0.5rem 50%;
`

const EarnMilesContainer = styled.div``

const EarnMilesButton = styled.div`
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  margin-bottom: 0.33rem;
  width: fit-content;
`
const EarnMilesContent = styled(MilesEarnLabel)``

const MilesCostText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.778rem;
  color: #979797;
  padding: 0 1.333rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`
const EmailRow = styled(Row)`
  & > * {
    flex: 1;
    margin-right: 0.44rem;
  }

  & > *:last-child {
    flex: ${(props) => props.flex};
    margin-right: 0;
  }
`
const CCWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`
const CcButton = styled(Button)`
  margin-right: 0;
  margin-top: 0.44rem;
  border-radius: 8px;
  padding: 0.7rem 0.778rem;
`
const CcEmail = styled(ReactMultiEmail)`
  display: ${(props) => props.shown};
  margin-top: 0.5rem;
  font-size: 0.78rem;
  background: transparent;
  padding: 0.778rem 1.333rem;
  &.empty > span[data-placeholder] {
    color: #989898;
  }
  & > span[data-placeholder] {
    position: unset;
    padding: 0;
  }
  & > input {
    background: transparent;
    padding: 0 0rem !important;
  }
`

const ResetButton = styled(Button)``

const CreateButton = styled(Button)``

const RemoveEmail = styled.span``

const defaultValues = {
  firstName: '',
  lastName: '',
  countryCode: '852',
  tel: '',
  email: '',
  amount: '',
  milesForReward: 0,
  promoCode: '',
  reference: '',
  messageContent: '',
  notifyWhenPaid: true,
  ccEmail: '',
}

const nameSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .when('lastName', {
        is: (lastName) => !lastName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    lastName: Yup.string()
      .when('firstName', {
        is: (firstName) => !firstName,
        then: Yup.string().required('either first or last name is required'),
      })
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
  },
  ['firstName', 'lastName']
)

const contactSchema = Yup.object().shape(
  {
    email: Yup.string()
      .trim('')
      .when('tel', {
        is: (tel) => !tel,
        then: Yup.string()
          .email('invalid email format')
          .required('either mobile or email is required'),
      })
      .nullable()
      .optional()
      .email('invalid email format'),
    countryCode: Yup.string().required(),
    tel: Yup.string()
      .when('email', {
        is: (email) => !email,
        then: Yup.string()
          .phone('HK', 'invalid phone number format')
          .required('either mobile or email is required'),
      })
      .nullable()
      .optional()
      .phone('HK', 'invalid phone number format'),
    ccEmail: Yup.string(),
  },
  ['tel', 'email', 'ccEmail']
)

const schema = Yup.object()
  .shape({
    amount: Yup.number()
      .typeError('must be number')
      .positive('must be positive')
      .required('invalid invoice amount'),
    reference: Yup.string()
      .required('invoice/ref # is required')
      .test(
        'should not contain special character(s)',
        'should not contain special character(s)',
        (v) => !invalidNameValue(v)
      ),
    promoCode: Yup.string().test(
      'should not contain special character(s)',
      'should not contain special character(s)',
      (v) => !invalidNameValue(v)
    ),
    messageContent: Yup.string(),
  })
  .concat(nameSchema)
  .concat(contactSchema)

const getErrorMessage = (error) => {
  if (!error) return ''
  return error.message || 'invalid'
}

const RequestPayment = () => {
  const dispatch = useDispatch()

  // const [campaignOptions, setCampaignOptions] = useState([])
  // const [campaignOptionIndex, setCampaignOptionIndex] = useState(0)
  const [dateValue, setDateValue] = useState(() => getDefaultDateValue())
  const [showCcEmail, setShowCcEmail] = useState(false)
  const [emails, setEmails] = useState([])

  // const campaigns = useSelector((s) => s.profile.campaigns)
  const { requestPayment } = useSelector((s) => s.requestPayment)
  // const payNowRate = useSelector((s) => s.profile.payNowRate)
  // const rateHKDDVM = useSelector((s) => s.lookup.rateHKDDVM)
  // const role = useSelector(roleSelector)
  const configs = useSelector(profileConfigSettings)

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...defaultValues,
      firstName: `customer_${moment().unix()}`,
      reference: `${moment().unix()}`,
    },
    resolver: yupResolver(schema),
  })

  const orderAmount = watch('amount')
  const promoCode = watch('promoCode')

  // const campaign = campaigns.find((o) => o.promoCode === promoCode)

  // const setDefaultCampaign = () => {
  //   if (!configs || !campaigns) return
  //   const options = campaigns
  //     .filter(
  //       (c) =>
  //         c.status === 'active' &&
  //         (configs.campaignList?.indexOf(c.promoCode) >= 0 ||
  //           !configs.campaignList)
  //     )
  //     .map((c) => ({ label: c.title, value: c.promoCode }))
  //   setCampaignOptions(options)

  //   if (configs.defaultCampaign) {
  //     setValue('promoCode', configs.defaultCampaign)
  //     setCampaignOptionIndex(
  //       options.length -
  //         1 -
  //         options.findIndex((c) => c.value === configs.defaultCampaign)
  //     )
  //   } else {
  //     const code = options[options.length - 1]?.value || ''
  //     setValue('promoCode', code)
  //   }
  // }
  useEffect(() => () => dispatch({ type: 'requestPayment/reset' }), [])
  useEffect(() => {
    if (configs && configs.invoiceExpiry) {
      const v = getDateValue(configs.invoiceExpiry)
      if (v.key) {
        setDateValue(v)
      }
    }
    if (configs && configs.defaultCcList && configs.defaultCcList.length > 0) {
      setEmails(configs.defaultCcList)
      setShowCcEmail(true)
    }
    // setDefaultCampaign()
  }, [configs]) // [campaigns, configs])

  useEffect(() => {
    if (requestPayment.isSuccess) {
      // save promo code for default next one
      LocalStorage.saveString('default_promo_code', promoCode)
      dispatch(
        promptConfirmModal({
          title: 'sent',
          content: 'invoice has been delivered successfully',
          okText: 'done',
          closable: false,
        })
      )
      reset()
      // setDefaultCampaign()
    }
    if (requestPayment.isError) {
      setTimeout(() => {
        scrollToTop(true)
      }, 200)
    }
  }, [requestPayment])

  // useEffect(() => {
  //   if (campaign) {
  //     const milesForReward = CampaignUtils.getRewardMiles(
  //       campaign,
  //       parseFloat(orderAmount)
  //     )
  //     setValue('milesForReward', milesForReward)
  //   }
  // }, [orderAmount, campaign])

  useEffect(() => () => dispatch({ type: 'requestPayment/reset' }), [])

  const onClickReset = () => {
    reset()
    // setDefaultCampaign()
  }

  const onClickSend = handleSubmit((data) => {
    data = {
      ...data,
      expiredAt: moment(dateValue?.selectedDate).unix(),
      ccEmail: emails,
    }
    dispatch({ type: 'requestPayment/requestPayment', payload: data })
  })

  const onClickCc = () => {
    setShowCcEmail(!showCcEmail || emails.length > 0)
  }

  // const onChangeMilesSlider = (value) => {
  //   let index = campaignOptions.length - value - 1
  //   if (index < 0) index = 0
  //   if (index >= campaignOptions.length) index = campaignOptions.length - 1

  //   setCampaignOptionIndex(value)
  //   setValue('promoCode', campaignOptions[index].value)
  // }

  // const milesReward = campaign
  //   ? CampaignUtils.getRewardMiles(campaign, parseFloat(orderAmount))
  //   : 0

  // TODO: uncomment the following code
  // const mileCost = campaign
  //   ? Round(Decimal(milesReward).mul(rateHKDDVM?.sell).toNumber(), 2)
  //   : 0
  // const mileCost = 0

  // const mileCostPercentage = campaign
  //   ? FormattedPercentage((mileCost / parseFloat(orderAmount)) * 100)
  //   : 0
  // const totalCost = campaign
  //   ? Decimal(payNowRate.feeFixedAmount)
  //       .add(
  //         Decimal(parseFloat(orderAmount)).mul(payNowRate.feeInPercentage / 100)
  //       )
  //       .add(mileCost)
  //       .toNumber()
  //   : 0
  // const totalCostPercentage = campaign
  //   ? FormattedPercentage((totalCost / parseFloat(orderAmount)) * 100)
  //   : 0

  // const isDisplayMilesCost = !!campaign && orderAmount > 0
  const loading = requestPayment.isLoading

  return (
    <Container>
      <PageContent hasPadding>
        <Spacer height={globalStyles.px.lg2} />
        <Title>send payment request</Title>
        <Spacer height={globalStyles.px.xs} />
        <SubTitle>please enter information below</SubTitle>
        <Spacer height={globalStyles.px.xs} />
        {requestPayment.error && (
          <>
            <ErrorMessageLine errorMessage={requestPayment.error} />
            <Spacer height={globalStyles.px.xs} />
          </>
        )}
        <Row>
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Amount
                allowNegativeValue={false}
                decimalScale={2}
                disableAbbreviations
                placeholder="0.00"
                value={field.value}
                onValueChange={(value, name) => {
                  field.onChange(value)
                }}
                inputMode="numeric"
                maxLength={10}
              />
            )}
          />
          <AmountSuffix>
            <BackSpace
              renderImage={() => <BackSpaceSvg />}
              width="1.111rem"
              height="1.111rem"
              onClick={() => {
                setValue('amount', '')
              }}
            />
            <Currency>hkd</Currency>
          </AmountSuffix>
        </Row>
        <Row>
          {errors.amount && (
            <AmountError>{getErrorMessage(errors.amount)}</AmountError>
          )}
        </Row>
        <Spacer height={globalStyles.px.xs} />
        <BlockGroup>
          <FieldWrapper>
            <InputField
              {...register('reference')}
              title="invoice/ref #"
              placeholder="invoice/ref #"
              onChange={() => null}
              hasError={getErrorMessage(errors.reference)}
              maxLength={20}
            />
            <Row>
              <InputError error={getErrorMessage(errors.reference)} />
            </Row>
          </FieldWrapper>
          <Spacer height={globalStyles.px.xs} />
          <FieldWrapper>
            <FieldTitle>expire at</FieldTitle>
            <SingleDateSelectField value={dateValue} onChange={setDateValue} />
          </FieldWrapper>
        </BlockGroup>
        <Spacer height={globalStyles.px.xs} />
        <Row>
          <FieldWrapper>
            <FieldTitle>message</FieldTitle>
            <MessageContent {...register('messageContent')} rows={4} />
          </FieldWrapper>
        </Row>
        <EmailRow flex={0}>
          <FieldWrapper>
            <FieldTitle>customer</FieldTitle>
            <InputField
              title=""
              placeholder="email"
              {...register('email')}
              error={getErrorMessage(errors.email)}
              inputMode="email"
            />
          </FieldWrapper>
          <CCWrapper>
            <CcButton type="bw" bordered onClick={onClickCc}>
              cc
            </CcButton>
          </CCWrapper>
        </EmailRow>
        <Row>
          <CcEmail
            placeholder="cc email to"
            emails={emails}
            shown={showCcEmail ? 'flex' : 'none'}
            onChange={(_emails) => {
              setEmails(_emails)
            }}
            getLabel={(email, index, removeEmail) => (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <RemoveEmail data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </RemoveEmail>
              </div>
            )}
          />
        </Row>
        <Row>
          <InputField
            style={{ width: 90, flex: 'none' }}
            onChange={() => null}
            value="852"
            hasError={getErrorMessage(errors.tel)}
            tabIndex="-1"
          />
          <InputField
            placeholder="mobile number"
            {...register('tel')}
            hasError={getErrorMessage(errors.tel)}
            inputMode="tel"
          />
        </Row>
        <Row>
          <InputError error={getErrorMessage(errors.tel)} />
        </Row>
        {/* TODO: uncomment the following code */}

        {/* <Spacer height={globalStyles.px.xs} />
        <EarnMilesContainer>
          <FieldTitle>miles reward</FieldTitle>
          <center>
            <EarnMilesButton type="bw" bordered>
              <EarnMilesContent miles={(milesReward || 0) * 100} />
            </EarnMilesButton>
          </center>
          <Spacer height="0.889rem" />
          {campaignOptions.length > 1 && (
            <MilesSlider
              defaultValue={campaignOptionIndex}
              max={campaignOptions.length - 1}
              value={campaignOptionIndex}
              onChange={onChangeMilesSlider}
            />
          )}
        </EarnMilesContainer> */}
        <Spacer height={globalStyles.px.xs} />

        {/* TODO: uncomment the following code */}
        {/* {(role === 'admin' || !configs.hideCost) && isDisplayMilesCost && (
          <>
            <Spacer height={globalStyles.px.xs} />
            <MilesCostText>
              <div>miles cost</div>
              <div>
                {FormattedPrice('HKD', mileCost * 100)} ({mileCostPercentage}
                %)
              </div>
            </MilesCostText>
            <Spacer height={globalStyles.px.xs} />
            <MilesCostText>
              <div>total cost</div>
              <div>
                {FormattedPrice('HKD', totalCost * 100)} ({totalCostPercentage}
                %)
              </div>
            </MilesCostText>
          </>
        )} */}
        <Spacer height={globalStyles.px.xs} />
        <Buttons>
          <ResetButton type="bw" bordered onClick={onClickReset}>
            reset
          </ResetButton>
          <CreateButton type="main" onClick={onClickSend}>
            send
          </CreateButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
        <Spacer height={globalStyles.px.lg} />
      </PageContent>
      <LoadingModal loading={loading} />
    </Container>
  )
}

export default RequestPayment

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import CitiBlue from '@/assets/landing/color_fps_divit_citi.png'
import HomePoster from '@/assets/landing/home-poster.png'
import { ReactComponent as CreatePaymentIconSvg } from '@/assets/menu/action-create-payment.svg'
import { ReactComponent as DivitPromotionSvg } from '@/assets/menu/action-divit-promotion.svg'
import { ReactComponent as SendPaymentRequestIconSvg } from '@/assets/menu/action-reward.svg'
import { ReactComponent as BuyMiles } from '@/assets/menu/menu-buy-miles.svg'
import Icon from '@/components/Icon'
import LoadingScreen from '@/components/LoadingScreen'
import MilesValue from '@/components/miles/MilesValue'
import PageContent from '@/components/Page/PageContent'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import {
  profileMerchantSelector,
  roleSelector,
  tokenBookBalanceSelector,
} from '@/redux/profile/profileSelector'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const PosterContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: #fff7e8;
  padding: 32px 32px 16px 32px;

  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.lg}px`}) {
    position: relative;
    /* height: 235px; */
    flex-direction: column;
  }

  & #poster_main {
    object-fit: contain;
    max-height: 100%;
    margin: auto;
    @media (min-width: ${({ theme }) => `${theme.breakpointsV2.sm}px`}) {
      width: 278.623px;
    }

    @media (min-width: ${({ theme }) => `${theme.breakpointsV2.lg}px`}) {
      position: absolute;
      top: -56px;
      right: 20px;
      max-height: unset;
      height: auto;
      margin: 0;
    }
  }
`

const ActionDotContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.sm}px`}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.lg}px`}) {
    gap: 0px;
    width: 540px;
  }
`

const ActionItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.sm}px`}) {
    max-width: 80px;
  }

  @media (min-width: ${({ theme }) => `${theme.breakpointsV2.lg}px`}) {
    margin-left: 0;
    margin-right: 0;
  }
`

const ActionItemSubTitle = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
`

const ActionDot = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
`

const Title = styled.div`
  font-size: 1.2rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
`

const Subtitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.2rem;
  font-size: 0.8rem;
  line-height: 1.1rem;
`

const BlockGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const BlockIcon = styled(Icon)`
  & > svg,
  & > img {
    width: 2.5rem;
    height: 2.5rem;
  }

  & > svg path {
    fill: #ffcc33;
  }
`

const BlockIconYellow = styled(Icon)`
  & > svg,
  & > img {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const BlockSubtitle = styled.div`
  color: #696c70;
  font-size: 0.8rem;
`

const SmallBlock = styled.div`
  background: white;
  padding: 1.33rem;
  border-radius: 16px;
  border: 1px solid #eee;
  margin: 0.5rem;
  cursor: pointer;
  display: flex;

  &:hover {
    background: #fffef8;
  }

  & > * {
    align-self: center;
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
`

const SmallBlockTitle = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
`

const FieldWrapper = styled.div``

const PosterFooterLogo = styled.img`
  float: right;
  width: 240px;
`

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const role = useSelector(roleSelector)
  const balance = useSelector(tokenBookBalanceSelector)
  const profile = useSelector(profileMerchantSelector)
  const [hasMilesService, setHasMilesSerivce] = useState(false)
  const [hasPaynowService, setHasPaynowService] = useState(false)
  const [isDivitMarket, setIsDivitMarket] = useState(false)

  useEffect(() => {
    if (role === 'admin') {
      dispatch({
        type: 'divitMiles/fetchMerchantMilesBooks',
        payload: {},
      })
    }
    // dispatch({
    //   type: 'fetchReferralLink',
    //   payload: { merchantID: profile?.merchantID },
    // })

    setHasMilesSerivce(
      (profile?.subscribedServices || '').indexOf('miles') >= 0
    )
    setHasPaynowService(
      (profile?.subscribedServices || '').indexOf('paynow') >= 0
    )
    setIsDivitMarket(profile?.merchantID === 'DIVITMARKET')
  }, [profile])

  const onClickCreatePayment = () => {
    history.push('/payment/create-payment')
  }

  const onClickRequestPayment = () => {
    history.push('/payment/request-payment')
  }

  const onClickSendInvoices = () => {
    history.push('/payment/batch-invoices')
  }

  const onClickInvoiceRecords = () => {
    history.push('/payment/invoices')
  }

  const onClickTransactionHistory = () => {
    history.push('/payment/transactions')
  }

  const onClickDirectMiles = () => {
    history.push('/divit-promotions')
  }

  const onClickTopupMiles = () => {
    history.push('/miles/buy-miles')
  }

  const onClickSendMiles = () => {
    history.push('/miles/send-miles')
  }

  const onClickBatchSendMiles = () => {
    history.push('/miles/batch-send-miles')
  }

  const onClickMilesTransactionHistory = () => {
    history.push('/miles/miles-transactions')
  }

  if (!profile) return <LoadingScreen />

  return (
    <Container>
      <Content>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.xs} />
          <Subtitle>divit miles balance</Subtitle>
          <Spacer height="0.2rem" />
          <MilesValue
            fontSize="1.2rem"
            miles={balance.balance * 100}
            isSigned={false}
          />
          <Spacer height={globalStyles.px.xs} />

          <PosterContainer>
            <div>
              <Title>quick actions</Title>
              <ActionDotContainer>
                {hasPaynowService && (
                  <ActionItem onClick={onClickCreatePayment}>
                    <ActionDot>
                      <BlockIcon renderImage={() => <CreatePaymentIconSvg />} />
                    </ActionDot>
                    <ActionItemSubTitle>create payment</ActionItemSubTitle>
                  </ActionItem>
                )}
                {/* TODO: uncomment the following code */}
                {/* {hasMilesService && (
                  <>
                    <ActionItem onClick={onClickTopupMiles}>
                      <ActionDot>
                        <BlockIconYellow renderImage={() => <BuyMiles />} />
                      </ActionDot>
                      <ActionItemSubTitle>buy divit miles</ActionItemSubTitle>
                    </ActionItem>

                    <ActionItem onClick={onClickSendMiles}>
                      <ActionDot>
                        <BlockIconYellow
                          className="w-[39.04px] h-[39.04px]"
                          renderImage={() => <SendPaymentRequestIconSvg />}
                        />
                      </ActionDot>
                      <ActionItemSubTitle>
                        reward divit miles
                      </ActionItemSubTitle>
                    </ActionItem>
                  </>
                )} */}
                {isDivitMarket && (
                  <ActionItem onClick={onClickDirectMiles}>
                    <ActionDot>
                      <BlockIconYellow
                        renderImage={() => <DivitPromotionSvg />}
                      />
                    </ActionDot>
                    <ActionItemSubTitle>divit promotions</ActionItemSubTitle>
                  </ActionItem>
                )}
              </ActionDotContainer>
            </div>
            <img src={HomePoster} id="poster_main" alt="" />
            <div>
              <PosterFooterLogo src={CitiBlue} alt="" />
            </div>
          </PosterContainer>
          <Spacer height={globalStyles.px.md} />
          <Title>payment</Title>
          <Spacer height="0.3rem" />

          <BlockGroup>
            {hasPaynowService && (
              <>
                <SmallBlock onClick={onClickCreatePayment}>
                  <FieldWrapper>
                    <SmallBlockTitle>create payment</SmallBlockTitle>
                    <BlockSubtitle>generate payment QR code</BlockSubtitle>
                  </FieldWrapper>
                </SmallBlock>
                <SmallBlock onClick={onClickRequestPayment}>
                  <FieldWrapper>
                    <SmallBlockTitle>send invoice</SmallBlockTitle>
                    <BlockSubtitle>send payment request</BlockSubtitle>
                  </FieldWrapper>
                </SmallBlock>
                <SmallBlock onClick={onClickSendInvoices}>
                  <FieldWrapper>
                    <SmallBlockTitle>send batch invoices</SmallBlockTitle>
                    <BlockSubtitle>
                      send multiple payment requests
                    </BlockSubtitle>
                  </FieldWrapper>
                </SmallBlock>
              </>
            )}
            <SmallBlock onClick={onClickInvoiceRecords}>
              <FieldWrapper>
                <SmallBlockTitle>manage invoices</SmallBlockTitle>
                <BlockSubtitle>records of payment requests</BlockSubtitle>
              </FieldWrapper>
            </SmallBlock>
            <SmallBlock onClick={onClickTransactionHistory}>
              <FieldWrapper>
                <SmallBlockTitle>payment history</SmallBlockTitle>
                <BlockSubtitle>transactions and daily reports</BlockSubtitle>
              </FieldWrapper>
            </SmallBlock>
          </BlockGroup>

          {/* TODO: uncomment the following code */}
          {/* {role === 'admin' && (
            <>
              <Spacer height={globalStyles.px.md} />
              <Title>miles</Title>
              <Spacer height="0.3rem" />
              <BlockGroup>
                {hasMilesService && (
                  <>
                    <SmallBlock onClick={onClickTopupMiles}>
                      <FieldWrapper>
                        <SmallBlockTitle>buy divit miles</SmallBlockTitle>
                        <BlockSubtitle>top up miles balance</BlockSubtitle>
                      </FieldWrapper>
                    </SmallBlock>
                    <SmallBlock onClick={onClickSendMiles}>
                      <FieldWrapper>
                        <SmallBlockTitle>reward miles</SmallBlockTitle>
                        <BlockSubtitle>send miles to recipient</BlockSubtitle>
                      </FieldWrapper>
                    </SmallBlock>
                    <SmallBlock onClick={onClickBatchSendMiles}>
                      <FieldWrapper>
                        <SmallBlockTitle>reward batch miles</SmallBlockTitle>
                        <BlockSubtitle>
                          send miles to multiple recipients
                        </BlockSubtitle>
                      </FieldWrapper>
                    </SmallBlock>
                  </>
                )}
                <SmallBlock onClick={onClickMilesTransactionHistory}>
                  <FieldWrapper>
                    <SmallBlockTitle>miles history</SmallBlockTitle>
                    <BlockSubtitle>
                      transactions and daily reports
                    </BlockSubtitle>
                  </FieldWrapper>
                </SmallBlock>
              </BlockGroup>
            </>
          )} */}
        </PageContent>
        <Spacer height="30px" />
      </Content>
    </Container>
  )
}

export default Home

import FileSaver from 'file-saver'
import JSZip from 'jszip'

// file: { filename, url }
export const downloadFilesAsZip = async ({ customFetch, files = [] }) => {
  if (files.length === 0) return

  const getFn = (url) =>
    customFetch(url) || fetch(url).then((resp) => resp.blob())

  //  not work in iOS, will download as filename.xlsx.xls,
  //  and cannot open
  // if (files.length === 1) {
  //   const { filename, url } = files[0]
  //   const blob = await getFn(url)
  //   FileSaver.saveAs(blob, filename)
  //   return
  // }

  const zip = new JSZip()

  const ps = files.map(({ filename, url }) =>
    getFn(url).then((data) => zip.file(filename, data, { binary: true }))
  )

  await Promise.all(ps)

  const content = await zip.generateAsync({ type: 'blob' })
  const randId = String(Date.now()).substring(0, 8)
  FileSaver.saveAs(content, `reports-${randId}.zip`)
}

export const downloadFile = async({ customFetch, url, filename }) => {
  const getFn = (u) =>
    customFetch(u) || fetch(u).then((resp) => resp.blob())

  const blob = await getFn(url)
  FileSaver.saveAs(blob, filename)
}

export default null

import { createSlice } from '@reduxjs/toolkit'

import * as SessionStorage from '@/utils/sessionStorage'

const initialState = {
  order: null,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    reset: () => {
      SessionStorage.remove('created_order')
      return initialState
    },
    getOrderSuccess: (state, action) => {
      state.order = action.payload
      SessionStorage.saveJSON('created_order', action.payload)
    },
  },
})

export const { actions } = orderSlice

export default orderSlice.reducer

import styled from 'styled-components'

// app body flex: column

const Container = styled.div`
  flex: 1;
  position: relative;
`

export default Container

import 'rc-slider/assets/index.css'

import Slider from 'rc-slider'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as MilesSliderMinusSvg } from '@/assets/miles/slider/slider-minus.svg'
import { ReactComponent as MilesSliderPlusSvg } from '@/assets/miles/slider/slider-plus.svg'
import MilesSliderTrackSvg from '@/assets/miles/slider/slider-track.svg'
import Icon from '@/components/Icon'

import Spacer from '../Spacer'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const MilesSlider = ({
  defaultValue,
  min = 0,
  max = 0,
  value,
  onChange,
  adjustControls = false,
}) => {
  const isMinusEnabled = value > 0
  const isPlusEnabled = value < parseInt(max, 10)

  return (
    <Container>
      {adjustControls && (
        <>
          <Icon
            style={{ opacity: isMinusEnabled ? 1 : 0.3 }}
            renderImage={() => <MilesSliderMinusSvg />}
            width="1.77rem"
            height="1.77rem"
            onClick={() => onChange(Math.max(min, value - 1))}
          />
          <Spacer width="1rem" />
        </>
      )}
      <Slider
        defaultValue={defaultValue}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        railStyle={{
          backgroundColor: '#333333',
          height: 2,
        }}
        trackStyle={{
          backgroundColor: '#FFCC33',
          height: 2,
        }}
        handleStyle={{
          backgroundImage: `url("${MilesSliderTrackSvg}")`,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
          border: 0,
          width: 40,
          height: 40,
          marginTop: -16,
          boxShadow: 'unset',
          opacity: 1,
        }}
      />
      {adjustControls && (
        <>
          <Spacer width="1rem" />
          <Icon
            style={{ opacity: isPlusEnabled ? 1 : 0.3 }}
            renderImage={() => <MilesSliderPlusSvg />}
            width="1.77rem"
            height="1.77rem"
            onClick={() => onChange(Math.min(max, value + 1))}
          />
        </>
      )}
    </Container>
  )
}

export default MilesSlider

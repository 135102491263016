import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 3.111rem;
  height: 1.778rem;
  border-radius: 3rem;
  background-color: ${(p) => (p.checked ? '#FFCC33' : '#DEDEDE')};
  cursor: pointer;
  transition: background-color 0.3s;
`

const Ball = styled.div`
  position: absolute;
  top: 0;
  left: ${(p) => (p.checked ? '43%' : '2%')};
  width: 1.778rem;
  height: 1.778rem;
  padding: 0.111rem;
  box-sizing: border-box;
  transition: left 0.3s;

  & div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(28, 28, 28, 0.16);
  }
`

const Toggle = ({ checked, onChange }) => (
  <Container checked={checked} onClick={() => onChange(!checked)}>
    <Ball checked={checked}>
      <div />
    </Ball>
  </Container>
)

export default Toggle

import React from 'react'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  font-size: 0.77rem;
  margin-top: 0.44rem;
  color: #e6171a;
`

const InputError = ({ error }) =>
  error ? <ErrorMessage>{error}</ErrorMessage> : null

export default InputError

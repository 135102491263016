import { createSelector } from 'reselect'

// selectors

export const lookupSelector = (s) => s.lookup

export const merchantsSelector = createSelector(
  lookupSelector,
  (lookup) => lookup.merchants
)

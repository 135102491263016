import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'
import * as React from 'react'
import { Provider } from 'react-redux'

import store, { history } from '@/redux/store'

import { AuthProvider } from './authContext'
import { LocaleProvider } from './localeContext'
import { ThemeProvider } from './themeContext'

const Providers = ({ children }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider>
        <LocaleProvider>
          <AuthProvider>{children}</AuthProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
)

Providers.propTypes = {
  children: PropTypes.element,
}

Providers.defaultProps = {
  children: <></>,
}

export default Providers

import React, { createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const AuthContext = createContext({
  login: () => {},
  logout: () => {},
  merchantSignup: () => {},
  token: null,
  isLoggedIn: false,
  loading: false,
  error: null,
})

const AuthProvider = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, error, token } = useSelector((state) => state.auth)

  const login = (credential, isByPassVerification) => {
    const payload = { ...credential, isByPassVerification }
    dispatch({ type: 'login', payload })
  }

  const logout = () => {
    dispatch({ type: 'logout' })
    setTimeout(() => history.replace('/'), 500)
  }

  const merchantSignup = (payload) => {
    dispatch({ type: 'merchantSignup', payload })
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        merchantSignup,
        token,
        isLoggedIn: !!token,
        loading,
        error,
      }}
      {...props}
    />
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }

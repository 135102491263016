const statusMap = {
  idle: {
    status: 'idle',
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  loading: {
    status: 'loading',
    isLoading: true,
    isSuccess: false,
    isError: false,
  },
  success: {
    status: 'success',
    isLoading: false,
    isSuccess: true,
    isError: false,
  },
  failed: {
    status: 'failed',
    isLoading: false,
    isSuccess: false,
    isError: true,
  },
}

const AsyncState = {
  create: () => ({
    data: null,
    error: null,
    ...statusMap.idle,
  }),
  start: () => ({
    data: null,
    error: null,
    ...statusMap.loading,
  }),
  success: (data) => ({
    data,
    error: null,
    ...statusMap.success,
  }),
  failed: (error) => ({
    data: null,
    error,
    ...statusMap.failed,
  }),
  reset: () => ({
    data: null,
    error: null,
    ...statusMap.idle,
  }),
}

export default AsyncState

import moment from 'moment'
import { call, put } from 'redux-saga/effects'

import DivitAPI from '@/services/api'
import { downloadFilesAsZip } from '@/utils/download'
import { arrayToQs } from '@/utils/request'

import { getErrorMessage } from '../utils/error'
import { actions as milesTransactionsActions } from './milesTransactionsSlice'

const REPORT_DATE_FORMAT = 'YYYY-MM-DD'

// include today
export const fetchMilesTransactionsAsync = async ({
  merchantID,
  bookID,
  fromDate,
  toDate,
  page = 0,
  pageSize = 25,
}) => {
  const params = {
    bookID: bookID !== '' ? bookID : undefined,
    fromDate: moment(fromDate).format(REPORT_DATE_FORMAT),
    toDate: moment(toDate).add(1, 'days').format(REPORT_DATE_FORMAT),
    page,
    pageSize,
  }

  const txnTypes = ['reserve', 'earn', 'transfer', 'topup']
  const txnTypesStr = arrayToQs('txnTypes', txnTypes)

  const result = await DivitAPI.get(
    `/miles/merchants/${merchantID}/transactions?${txnTypesStr}`,
    { params }
  )

  return result
}

export function* getMilesTransactions({ payload }) {
  try {
    const { merchantID, fromDate, toDate, bookID } = payload
    // const url = `/miles/merchants/${payload.merchantID}/transactions?txnTypes=claim&txnTypes=earn&txnTypes=transfer&txnTypes=topup`
    yield put(milesTransactionsActions.getMilesTransactionsStart())
    const { data } = yield call(() =>
      fetchMilesTransactionsAsync({ merchantID, fromDate, toDate, bookID })
    )
    const result = { data: data.data, total: data.count }
    yield put(milesTransactionsActions.getMilesTransactionsSuccess(result))
  } catch (e) {
    yield put(
      milesTransactionsActions.getMilesTransactionsFailed(getErrorMessage(e))
    )
  }
}


export function* getMilesTransactionWeeklyReport({ payload }) {
  try {
    const { fromDate, toDate } = payload
    const params = {
      fromDate: moment(fromDate).format(REPORT_DATE_FORMAT),
      toDate: moment(toDate).add(1, 'days').format(REPORT_DATE_FORMAT),
      page: 0,
      pageSize: 90,
    }
    yield put(milesTransactionsActions.getMilesTransactionWeeklyReportStart())
    const { data } = yield call(() =>
      DivitAPI.get(`/reporting/miles/weekly`, { params })
    )
    yield put(
      milesTransactionsActions.getMilesTransactionWeeklyReportSuccess(data.data)
    )
  } catch (e) {
    yield put(
      milesTransactionsActions.getMilesTransactionWeeklyReportFailed(
        getErrorMessage(e)
      )
    )
  }
}

export function* downloadMilesTransactionWeeklyReport({ payload }) {
  try {
    const { filenames } = payload
    yield put(milesTransactionsActions.downloadMilesTransactionWeeklyReportStart())
    const files = filenames.map(({ sourceFilename, distFilename }) => ({
      filename: distFilename,
      url: `/reporting/miles/weekly/${sourceFilename}`,
    }))
    yield call(() =>
      downloadFilesAsZip({
        customFetch: (url) =>
          DivitAPI.get(url, { responseType: 'blob' }).then((resp) => resp.data),
        files,
      })
    )
    yield put(
      milesTransactionsActions.downloadMilesTransactionWeeklyReportSuccess({})
    )
  } catch (e) {
    yield put(
      milesTransactionsActions.downloadMilesTransactionWeeklyReportFailed(
        getErrorMessage(e)
      )
    )
  }
}

export default null

import React from 'react'
import styled from 'styled-components'

import AsiamilesValue from '@/components/miles/AsiamilesValue'
import MilesValue from '@/components/miles/MilesValue'
import Spacer from '@/components/Spacer'

const Container = styled.div`
  display: flex;
  align-items: center;
`
const Equal = styled.div`
  font-weight: 600;
`

const MilesEarnLabel = ({ miles }) => (
  <Container>
    <MilesValue miles={miles} fontSize="1rem" isSigned={false} />
    <Spacer width="8px" />
    <Equal>=</Equal>
    <Spacer width="8px" />
    <AsiamilesValue miles={miles} fontSize='1rem' isSigned={false} />
  </Container>
)

export default MilesEarnLabel

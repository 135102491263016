import { Autocomplete, TextField } from '@mui/material'
import { isUndefined } from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'

import { Regex } from '@/constants/validation'

const TagLabel = ({
  options,
  control,
  label,
  name,
  defaultValue = [],
  placeholder,
  type = 'email',
  onChange,
}) => {
  const onChangeSelect = (e, field, values = []) => {
    switch (type) {
      case 'email':
        if (
          Regex.email.test(e.target.value) ||
          (!Regex.email.test(e.target.value) && isUndefined(e.target.value))
        ) {
          field.onChange(values)
          if (onChange) {
            onChange(values)
          }
        }
        break
      default:
        field.onChange(values)
        if (onChange) {
          onChange(values)
        }
        break
    }


  }

  // TODO: keep the value if incorrect data
  const onPressEnter = (e, params) => {
    if (
      e.key === 'Enter' &&
      e.target.value
      //   Regex.email.test(e.target.value)
    ) {
      // params.inputProps.onChange(e.target.value)
      //   params.field.onChange('')
    }
    e.preventDefault()
    // params.field.onChange(e.target.value)
  }

  return (
    <Controller
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          id="combo-box-demo"
          options={options}
          onChange={(e, values) => onChangeSelect(e, field, values)}
          value={field.value || []}
          defaultValue={defaultValue}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              //   inputProps={{
              //     onKeyPress: (e) => {
              //       onPressEnter(e, params)
              //     },
              //   }}
            />
          )}
        />
      )}
      name={name}
      control={control}
    />
  )
}

export default TagLabel

import PropTypes from 'prop-types'
import React from 'react'
import GoogleLogin from 'react-google-login'

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const LoginGoogle = ({ render, onSuccess, onFailure }) => (
  <GoogleLogin
    isSignedIn={false}
    clientId={googleClientId}
    cookiePolicy="single_host_origin"
    onSuccess={onSuccess}
    onFailure={onFailure}
    render={({ onClick }) => render({ onClick })}
  />
)

LoginGoogle.propTypes = {
  render: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

export default LoginGoogle

import 'react-spring-bottom-sheet/dist/style.css'

import React, { useEffect } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'

const BottomSheetStyled = styled(BottomSheet)`
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.5);
  --rsbs-max-w: 600px;
  --rsbs-ml: auto;
  --rsbs-mr: auto;

  & [data-rsbs-footer] {
    box-shadow: none;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  height: ${({ fullscreen, top }) => {
    if (fullscreen) return 'var(--app-height)'
    if (top) return 'var(--app-height-s)'
    return 'auto'
  }};
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
`

export default ({
  fullscreen,
  top,
  header,
  open,
  onLeave,
  onDismiss,
  closeable = true,
  children,
  ...props
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <BottomSheetStyled
      open={open}
      header={false}
      expandOnContentDrag={!(fullscreen || top)}
      onDismiss={closeable ? onDismiss : () => {}}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave?.()}
      initialFocusRef={false}
      scrollLocking={false}
      {...props}
    >
      <Container fullscreen={fullscreen} top={top}>
        {children}
      </Container>
    </BottomSheetStyled>
  )
}

// src/constants/paths.ts

export const MenuItemPaths = [
  {
    id: 'Home',
    label: 'menu.home',
    path: '',
  },
  {
    id: 'Orders',
    label: 'menu.orders',
    path: '',
    children: [
      {
        id: 'PayLater',
        label: 'menu.orders.payLater',
        path: '',
      },
      {
        id: 'PayNow',
        label: 'menu.orders.payNow',
        path: '',
      },
      {
        id: 'BatchRefund',
        label: 'menu.orders.batchRefund',
        path: '',
      },
    ],
  },

]

export const MenuMoreItemPaths = [
  {
    id: 'PersonalInformation',
    label: 'user_profile.personal_info.label',
    path: '',
  },
  {
    id: 'Settings',
    label: 'menu.settings',
    path: '',
  },
]

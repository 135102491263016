import 'react-day-picker/dist/style.css'

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import styled from 'styled-components'

import { ReactComponent as YellowTickSvg } from '@/assets/common/yellow-tick.svg'
import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'

const Content = styled.div`
  width: 100%;
  max-width: 480px;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 1.111rem;
  text-align: center;
`

const List = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#FFFAEA' : 'transparent')};
  padding: 0.888rem 1.333rem;
  cursor: pointer;
`

const ItemText = styled.div`
  font-size: 0.888rem;
  font-weight: 500;
`

const DayPickerContainer = styled.div``

const DayPickerLabel = styled.div`
  margin: 0 auto;
  font-size: 0.778rem;
  padding: 0 1.333rem;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > button {
    flex: 1;
  }

  & > button:first-child {
    margin-right: 0.5rem;
  }
`

const CancelButton = styled(Button)``

const ApplyButton = styled(Button)``

export const OPTIONS = [
  {
    key: 'last_7_days',
    title: 'last 7 days',
    getValue: () => ({
      fromDate: moment().subtract(6, 'days').toDate(),
      toDate: moment().toDate(),
    }),
  },
  {
    key: 'last_14_days',
    title: 'last 14 days',
    getValue: () => ({
      fromDate: moment().subtract(13, 'days').toDate(),
      toDate: moment().toDate(),
    }),
  },
  {
    key: 'last_30_days',
    title: 'last 30 days',
    getValue: () => ({
      fromDate: moment().subtract(29, 'days').toDate(),
      toDate: moment().toDate(),
    }),
  },
  {
    key: 'last_60_days',
    title: 'last 60 days',
    getValue: () => ({
      fromDate: moment().subtract(59, 'days').toDate(),
      toDate: moment().toDate(),
    }),
  },
  {
    key: 'last_90_days',
    title: 'last 90 days',
    getValue: () => ({
      fromDate: moment().subtract(89, 'days').toDate(),
      toDate: moment().toDate(),
    }),
  },
  {
    key: 'custom_range',
    title: 'custom range',
    getValue: () => false,
  },
]

const getDefaultCustomRange = () => ({
  from: moment().subtract(1, 'days').toDate(),
  to: moment().toDate(),
})

const getOption = (dateValue) => OPTIONS.find((o) => o.key === dateValue.key)

const formatWeekdayName = (weekDay) => {
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  return days[moment(weekDay).day()]
}

const DateSelectModal = ({ isOpen, dateValue, onChange, onClose }) => {
  const [activeOption, setActiveOption] = useState(() => getOption(dateValue))
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  const [customRange, setCustomRange] = useState(() => getDefaultCustomRange())

  useEffect(() => {
    if (isOpen) {
      setActiveOption(getOption(dateValue))
    }
  }, [isOpen, dateValue])

  useEffect(() => {
    if (activeOption.key === 'custom_range') {
      setIsShowCalendar(true)
    } else {
      setIsShowCalendar(false)
    }
  }, [activeOption])

  const onClickOption = (option) => {
    setActiveOption(option)
  }

  const onClickApply = () => {
    const range = activeOption.getValue()
    if (isShowCalendar) {
      const defaultDateRange = getDefaultCustomRange()
      const fromDate = customRange
        ? customRange.from
        : defaultDateRange.fromDate
      let toDate = customRange ? customRange.to : defaultDateRange
      if (!toDate) toDate = moment(fromDate).add(1, 'days')
      onChange({
        key: 'custom_range',
        fromDate,
        toDate,
      })
    } else if (range) {
      onChange({ key: activeOption.key, ...range })
    }
  }

  const toD = (v) => (v ? moment(v).format('LLL') : '')
  const customRangeLabel = customRange
    ? `${toD(customRange.from)} - ${toD(customRange.to)}`
    : 'please select date range'

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>specify range of dates</Title>
        <Spacer height={globalStyles.px.md} />
        <List>
          {OPTIONS.map((option) => (
            <Item
              key={option.key}
              isActive={option.key === activeOption.key}
              onClick={() => onClickOption(option)}
            >
              <ItemText>{option.title}</ItemText>
              {option.key === activeOption.key && (
                <Icon
                  renderImage={() => <YellowTickSvg />}
                  width="0.833rem"
                  height="0.656rem"
                />
              )}
            </Item>
          ))}
        </List>
        {isShowCalendar && (
          <DayPickerContainer>
            <Spacer height={globalStyles.px.md} />
            <DayPickerLabel>{customRangeLabel}</DayPickerLabel>
            <Spacer height={globalStyles.px.md} />
            <DayPicker
              mode="range"
              max={90}
              toDate={new Date()}
              selected={customRange}
              onSelect={setCustomRange}
              formatters={{ formatWeekdayName }}
            />
          </DayPickerContainer>
        )}
        <Spacer height={globalStyles.px.lg2} />
        <Buttons>
          <CancelButton type="bw" bordered onClick={onClose}>
            cancel
          </CancelButton>
          <ApplyButton type="bw" onClick={onClickApply}>
            apply
          </ApplyButton>
        </Buttons>
        <Spacer height={globalStyles.px.lg} />
      </Content>
    </BottomSheet>
  )
}

export default DateSelectModal

import QueryString from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import DSvg from '@/assets/payment/D.svg'
import { ReactComponent as TabManualSvg } from '@/assets/payment/tab-manual.svg'
import { ReactComponent as TabQRCodeSvg } from '@/assets/payment/tab-qrcode.svg'
import { ReactComponent as DownloadSvg } from '@/assets/share/download-icon.svg'
import { ReactComponent as ShareSvg } from '@/assets/share/share-icon.svg'
import Button from '@/components/common/Button'
import LineHR from '@/components/common/LineHR'
import PoweredByDivit from '@/components/common/PoweredByDivit'
import Icon from '@/components/Icon'
import LoadingModal from '@/components/LoadingModal'
import LoadingScreen from '@/components/LoadingScreen'
import MerchantLogo from '@/components/merchants/MerchantLogo'
import MerchantName, {
  getMerchant,
  getMerchantName,
} from '@/components/merchants/MerchantName'
import MilesValue from '@/components/miles/MilesValue'
import PageContent from '@/components/Page/PageContent'
import SelectorTabs from '@/components/SelectorTabs'
import ShareManualPaymentModal from '@/components/ShareManualPaymentModal'
import ShareQRCodeModal from '@/components/ShareQRCodeModal'
import Spacer from '@/components/Spacer'
import { ERROR_CODE_FPS_UNAVAILABLE } from '@/constants/errors'
import globalStyles from '@/constants/globalStyles'
import useModal from '@/hooks/useModal'
import PaynowFpsUnavailableScreen from '@/pages/paynow/PaynowFpsUnavailableScreen'
import { merchantsSelector } from '@/redux/lookup/lookupSelector'
import { getFpsQRServiceSelector } from '@/redux/profile/profileSelector'
import * as CampaignUtils from '@/utils/Campaign'
import * as Instalments from '@/utils/Instalments'
import * as Order from '@/utils/Order'
import * as OrderPaynow from '@/utils/OrderPaynow'
import * as PaymentUtils from '@/utils/Payment'
import { FormattedPrice } from '@/utils/Price'
import { downloadImage } from '@/utils/qrcode'
import * as SessionStorage from '@/utils/sessionStorage'
import * as Share from '@/utils/share'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffcc33;
`

const Header = styled.div`
  background-image: url(${DSvg});
  background-repeat: no-repeat;
  background-position: top 101% right -108px;
  background-size: 400px 400px;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    background-position: top 87% right -100px;
    background-size: 600px 600px;
  }
`

const MerchantInfo = styled.div`
  display: flex;
  align-items: center;
`

const MerchantLogoContainer = styled.div`
  width: 4.44rem;
  height: 4.44rem;
`

const MerchantDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.88rem;
  font-size: 0.77rem;
`

const MerchantNameStyled = styled(MerchantName)`
  font-weight: 600;
`

const TotalAmount = styled.div`
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
  font-weight: 700;
  font-size: 1.77rem;
  font-weight: 600;
  margin-top: 5px;
`

const MilesReward = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-size: 0.778rem;
    margin-right: 0.444rem;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`

const TitleContainer = styled.div`
  width: 12rem;
  text-align: center;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => `${theme.breakpoints.xs}px`}) {
    width: 100%;
  }
`

const Tab = styled.div`
  width: 100%;
  height: ${({ isActive, isHide }) => {
    if (isHide) return 0
    if (isActive) return 'auto'
    return 0
  }};
  overflow: hidden;
`

const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
`

const SubTitle = styled.div`
  margin-top: 0.44rem;
  font-size: 0.66rem;
  color: #979797;
`

const QRRow = styled.div`
  display: flex;
  align-items: center;
`

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0rem;
  background-color: white;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 1.77rem;
`

const QRCodeImage = styled.img``

const FpsDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FpsDetailsTitle = styled.div`
  font-size: 0.77rem;
`

const FpsDetailsValue = styled.div`
  font-size: 1.11rem;
  font-weight: 600;
`

const FpsDetailsRemarks = styled.div`
  margin-top: 8px;
  font-size: 0.66rem;
  color: #979797;
`

const ShareButton = styled(Button)`
  width: 100%;
  height: 2.66rem;
  background-color: white;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: #c2c2c2;
  }

  & span {
    font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
    font-weight: 700;
    font-size: 0.88rem;
    margin-left: 8px;
  }
`

const Footer = styled.div`
  background-color: white;
`

const PaynowFastFps = () => {
  const location = useLocation()
  const { state } = location
  const history = useHistory()
  const dispatch = useDispatch()

  const { purchaseMiles = 0 } = state || {}

  // find created order in session
  const { orderID: orderId } = SessionStorage.getJSON('created_order')
  const customer = SessionStorage.getJSON('created_order_customer')
  const promoCode = SessionStorage.getString('created_order_promo_code')

  const checkOrderStatusTimer = useRef()
  const qrCodeContainerRef = useRef()

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('qrcode')
  const [fpsDetails, setFpsDetails] = useState()
  const [isBuyMiles] = useState(
    () => QueryString.parse(document.location.search)?.buymiles
  )
  const { appLink: payLink = '', webLink } = fpsDetails || {}
  const shareQRCodeModal = useModal()
  const shareManualPaymentModal = useModal()

  const merchants = useSelector(merchantsSelector)
  const campaigns = useSelector((s) => s.profile.campaigns)
  const {
    fetchOrder,
    generateFpsLink,
    refreshOrder,
    requestShareEmail,
  } = useSelector((s) => s.paynowFastFps)

  const fpsQRService = useSelector(getFpsQRServiceSelector)
  const isFpsQRAvailable = !fpsQRService

  const { data: order } = fetchOrder
  const instalmentId = order?.instalments[0].instalmentID

  useEffect(() => () => dispatch({ type: 'paynowFastFps/reset' }), [])

  // get order when mount
  useEffect(() => {
    // check only have token in session storage can access
    if (!orderId || !SessionStorage.getString('token')) {
      history.replace('/home')
      return
    }

    dispatch({
      type: 'paynowFastFps/fetchOrder',
      payload: { orderId },
    })
  }, [])

  useEffect(() => {
    if (!isFpsQRAvailable) {
      setActiveTab('manual')
    }
  }, [isFpsQRAvailable])

  const generateFpsPayment = () => {
    dispatch({
      type: 'paynowFastFps/generateFpsLink',
      payload: { orderId, instalmentId },
    })
  }

  const isOrderSuccess = (o) => {
    if (!o) return false
    const newInstalment = o.instalments.find(
      (i) => i.instalmentID === instalmentId
    )
    const status = Instalments.getPaymentStatus(newInstalment)
    return ['completed'].indexOf(status.toLowerCase()) >= 0
  }

  const isOrderFail = (o) => {
    if (!o) return false
    return Order.isExpired(o) || Order.isCancelled(o)
  }

  const isPaymentFailed = (o) => {
    if (!o) return false
    return OrderPaynow.isFailed(o)
  }

  const showOrderSuccess = () => {
    if (isBuyMiles) {
      history.push('/buy-miles-success')
    } else {
      history.push('/claim-miles')
    }
  }

  const showOrderError = (o) => {
    const content =
      o.status === 'expired' ? 'order is expired' : 'order is cancelled'
    dispatch({
      type: 'modal/openModal',
      payload: {
        content: `${content}, please create a new payment again.`,
        actions: [
          {
            key: 'back',
            title: 'back',
            action: () => history.replace('/home'),
          },
        ],
      },
    })
  }

  const showOrderRetry = () => {
    dispatch({
      type: 'modal/openModal',
      payload: {
        content: 'payment is failed, please retry fps payment.',
        actions: [
          {
            key: 'retry',
            title: 'retry',
            action: () => generateFpsPayment(),
          },
        ],
      },
    })
  }

  // generate fps only valid order
  useEffect(() => {
    if (fetchOrder.isSuccess) {
      // check & set default fps type
      const { instalments } = fetchOrder.data
      const currentInstalment = instalments.find(
        (instalment) => instalment.instalmentID === instalmentId
      )
      const instalmentTotalAmount = Instalments.getTotalOutstandingAmount(
        currentInstalment
      )
      if (instalmentTotalAmount.amount > 10000 * 100) {
        setActiveTab('manual')
      }

      if (Order.isNew(fetchOrder.data)) {
        generateFpsPayment()
      }
      if (isOrderSuccess(fetchOrder.data)) {
        showOrderSuccess()
      }
      if (isOrderFail(fetchOrder.data)) {
        showOrderError(fetchOrder.data)
      }
      if (isPaymentFailed(fetchOrder.data)) {
        showOrderRetry()
      }
    }
    if (fetchOrder.isError) {
      showOrderError()
    }
  }, [fetchOrder])

  useEffect(() => {
    if (generateFpsLink.isSuccess) {
      const { data } = generateFpsLink
      setFpsDetails(PaymentUtils.getFpsDetails(data))
    }
  }, [generateFpsLink])

  // if paylink is set, refresh long polling order status
  useEffect(() => {
    clearInterval(checkOrderStatusTimer.current)
    checkOrderStatusTimer.current = setInterval(() => {
      if (payLink) {
        dispatch({
          type: 'paynowFastFps/fpsRefreshOrder',
          payload: { orderId },
        })
      }
    }, 5 * 1000)
    return () => clearInterval(checkOrderStatusTimer.current)
  }, [payLink])

  // check current order status is invalid
  useEffect(() => {
    if (refreshOrder.isSuccess) {
      if (isOrderSuccess(refreshOrder.data)) {
        showOrderSuccess()
      }
      if (isOrderFail(refreshOrder.data)) {
        showOrderError(refreshOrder.data)
      }
      if (isPaymentFailed(refreshOrder.data)) {
        showOrderRetry()
      }
    }
    if (refreshOrder.isError) {
      showOrderError()
    }
  }, [refreshOrder])

  useEffect(() => {
    if (requestShareEmail.isSuccess) {
      dispatch({
        type: 'modal/openModal',
        payload: {
          content: 'sent email',
        },
      })
    } else if (requestShareEmail.isError) {
      dispatch({
        type: 'modal/openModal',
        payload: {
          content: 'fail to send email',
        },
      })
    }
  }, [requestShareEmail])

  if (!order) return <LoadingScreen loading />
  if (order.orderID !== orderId) return <></>

  // fps maintenance screen
  if (
    generateFpsLink.isError &&
    generateFpsLink.error?.code === ERROR_CODE_FPS_UNAVAILABLE
  ) {
    const { periodEnd = 0 } = generateFpsLink.error?.data || {}
    return <PaynowFpsUnavailableScreen order={order} periodEnd={periodEnd} />
  }

  if (generateFpsLink.isLoading || !payLink) return <LoadingModal loading />

  const { instalments } = order
  const currentInstalment = instalments.find(
    (instalment) => instalment.instalmentID === instalmentId
  )
  const instalmentTotalAmount = Instalments.getTotalOutstandingAmount(
    currentInstalment
  )

  const campaign = campaigns.find((c) => c.promoCode === promoCode)
  const milesReward = campaign
    ? CampaignUtils.getRewardMiles(campaign, instalmentTotalAmount.amount / 100)
    : 0
  const merchantName = getMerchantName(merchants, order.merchantID)
  const merchant = getMerchant(merchants, order.merchantID)
  const merchantRef = `#${order.merchantRef}`
  const paymentAmount = FormattedPrice(
    instalmentTotalAmount.currency,
    instalmentTotalAmount.amount
  )
  console.log('merchant', merchant)

  const getShareInfo = () => {
    const { tel, email } = customer
    const { fpsID, payee, paymentRefID } = fpsDetails
    const amount = FormattedPrice(
      instalmentTotalAmount.currency,
      instalmentTotalAmount.amount
    )

    return {
      tel,
      email,
      title: `fps payment request from ${merchantName}`,
      text: `fps payment request from ${merchantName}, amount: ${amount}, fps id: ${fpsID}, payee: ${payee}, code: ${paymentRefID} (must enter in remarks)`,
    }
  }

  const onClickDownloadQRCode = async () => {
    setLoading(true)
    // await sleep(100)
    // await downloadHtmlToImage(qrCodeContainerRef.current, {
    //   merchantName,
    //   merchantRef,
    //   paymentAmount,
    // })
    downloadImage(webLink)
    setLoading(false)
  }

  const onClickShareQRCode = async () => {
    const shareInfo = getShareInfo()
    const medias = Share.getShareFileMedias({ email: shareInfo.email })
    if (medias.length === 0) {
      // popup empty share
      shareQRCodeModal.openModal({ shareInfo })
      return
    }
    shareQRCodeModal.openModal({ ...shareInfo, dataURI: webLink })
  }

  const onClickShareQRCodeByEmail = () => {
    const { requestID } = generateFpsLink.data
    const milesForReward = milesReward
    dispatch({
      type: 'paynowFastFps/requestShareEmail',
      payload: { requestID, milesForReward },
    })
  }

  const onClickShareManualPayment = () => {
    const shareInfo = getShareInfo()
    shareManualPaymentModal.openModal(shareInfo)
  }

  const isRemoteLoading = requestShareEmail.isLoading

  return (
    <Container>
      <Header>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.lg2} />
          <MerchantInfo>
            <MerchantLogoContainer>
              <MerchantLogo
                merchantID={order.merchantID}
                width="4.44rem"
                height="4.44rem"
              />
            </MerchantLogoContainer>
            <MerchantDetails>
              <MerchantNameStyled merchantID={order.merchantID} />
              <Spacer height="0.2rem" />
              <TotalAmount>{paymentAmount}</TotalAmount>
              <Spacer height="0.2rem" />
              <MilesReward>
                <span>{isBuyMiles ? 'purchase miles' : 'miles to earn'}</span>
                <MilesValue
                  miles={isBuyMiles ? purchaseMiles * 100 : milesReward * 100}
                  isBW
                  isSigned={false}
                  fontSize="1.11rem"
                />
              </MilesReward>
            </MerchantDetails>
          </MerchantInfo>
          <Spacer height={globalStyles.px.lg2} />
        </PageContent>
      </Header>
      <Content>
        <PageContent hasPadding>
          <Spacer height={globalStyles.px.md} />
          {(merchant?.subscribedServices || '').indexOf('disablemanualfps') <
            0 && (
            <>
              <SelectorTabs
                defaultTab={activeTab}
                tabs={[
                  isFpsQRAvailable && {
                    key: 'qrcode',
                    label: 'qr code',
                    Icon: TabQRCodeSvg,
                  },
                  { key: 'manual', label: 'manual', Icon: TabManualSvg },
                ].filter((t) => !!t)}
                onChange={(tab) => setActiveTab(tab.key)}
              />
              <Spacer height={globalStyles.px.md} />
            </>
          )}
          <Tab isActive={activeTab === 'qrcode'} isHide={!isFpsQRAvailable}>
            <TitleContainer>
              <Title>scan to pay with mobile banking app</Title>
              <SubTitle>merchant reference: {merchantRef}</SubTitle>
            </TitleContainer>
            <Spacer height={globalStyles.px.md} />
            <QRRow>
              <QRCodeContainer ref={qrCodeContainerRef}>
                <QRCodeImage src={webLink} alt="fps" width={220} />
              </QRCodeContainer>
            </QRRow>
            {isIOS && (
              <TitleContainer>
                <SubTitle>press and hold on the QR code to download</SubTitle>
              </TitleContainer>
            )}
            <Spacer height={globalStyles.px.md} />
            <LineHR>or</LineHR>
            {!isIOS && (
              <>
                <Spacer height={globalStyles.px.md} />
                <ShareButton onClick={onClickDownloadQRCode}>
                  <Icon
                    renderImage={() => <DownloadSvg />}
                    width="1.33rem"
                    height="1.33rem"
                  />
                  <span>download qr code</span>
                </ShareButton>
              </>
            )}
            <Spacer height={globalStyles.px.md} />
            <ShareButton onClick={onClickShareQRCode}>
              <Icon
                renderImage={() => <ShareSvg />}
                width="1.33rem"
                height="1.33rem"
              />
              <span>share</span>
            </ShareButton>
          </Tab>
          <Tab isActive={activeTab === 'manual'}>
            <TitleContainer>
              <Title>manual pay with mobile banking app</Title>
              <SubTitle>merchant reference: {merchantRef}</SubTitle>
            </TitleContainer>
            <Spacer height={globalStyles.px.md} />
            <FpsDetailsRow>
              <FpsDetailsTitle>divit fps id:</FpsDetailsTitle>
              <FpsDetailsValue>{fpsDetails.fpsID}</FpsDetailsValue>
            </FpsDetailsRow>
            <Spacer height={globalStyles.px.xs} />
            <FpsDetailsRow>
              <FpsDetailsTitle>payee:</FpsDetailsTitle>
              <FpsDetailsValue>{fpsDetails.payee}</FpsDetailsValue>
            </FpsDetailsRow>
            <Spacer height={globalStyles.px.xs} />
            <FpsDetailsRow>
              <FpsDetailsTitle>your 6 characters code*:</FpsDetailsTitle>
              <FpsDetailsValue>{fpsDetails.paymentRefID}</FpsDetailsValue>
            </FpsDetailsRow>
            <FpsDetailsRemarks>
              enter at “message to payee.” This is required to identify your
              transaction
            </FpsDetailsRemarks>
            <Spacer height={globalStyles.px.md} />
            <LineHR>or</LineHR>
            <Spacer height={globalStyles.px.md} />
            <ShareButton onClick={onClickShareManualPayment}>
              <Icon
                renderImage={() => <ShareSvg />}
                width="1.33rem"
                height="1.33rem"
              />
              <span>share</span>
            </ShareButton>
          </Tab>
          <Spacer height={globalStyles.px.md} />
        </PageContent>
      </Content>
      <Footer>
        <Spacer height={globalStyles.px.lg2} />
        <PoweredByDivit />
        <Spacer height={globalStyles.px.lg2} />
      </Footer>
      <ShareQRCodeModal
        isOpen={shareQRCodeModal.isOpen}
        shareInfo={shareQRCodeModal.modalInfo}
        onClickShareEmail={onClickShareQRCodeByEmail}
        onClose={shareQRCodeModal.onCloseModal}
        onCleanup={shareQRCodeModal.onCleanUpModal}
      />
      <ShareManualPaymentModal
        isOpen={shareManualPaymentModal.isOpen}
        shareInfo={shareManualPaymentModal.modalInfo}
        onClose={shareManualPaymentModal.onCloseModal}
        onCleanup={shareManualPaymentModal.onCleanUpModal}
      />
      <LoadingModal loading={loading || isRemoteLoading} />
    </Container>
  )
}

export default PaynowFastFps

import { useMemoizedFn } from 'ahooks'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import InputError from './InputError'
import { Input } from './InputField'

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Upload = styled.div`
  position: absolute;
  right: 1rem;
  font-size: 0.66rem;
  cursor: pointer;
`

const HiddenFileInput = styled.input`
  display: none;
`

const UploadFileInputField = React.forwardRef(
  (
    { style, className, title, hasError, error, disabled, accept, ...props },
    ref
  ) => {
    const intl = useIntl()

    const inputRef = useRef(null)
    const displayRef = useRef(null)
    const { onChange, ...restProps } = props

    const onClickField = useMemoizedFn(() => inputRef.current?.click())
    const onSelectFile = useMemoizedFn((e) => {
      displayRef.current.value = e.currentTarget?.files?.[0]?.name || ''
      onChange({
        type: 'file',
        target: e.currentTarget,
        value: e.currentTarget?.files,
      })
    })

    return (
      <Field style={style} className={className} disabled={disabled}>
        <Title>{title}</Title>
        <Wrapper>
          <Input ref={displayRef} disabled placeholder={props.placeholder} />
          <HiddenFileInput
            type="file"
            ref={(node) => {
              ref?.(node)
              inputRef.current = node
            }}
            {...restProps}
            accept={accept}
            onChange={onSelectFile}
          />
          <Upload onClick={() => onClickField()}>
            {intl.formatMessage({ id: 'common.upload' })}
          </Upload>
        </Wrapper>
        <InputError error={error} />
      </Field>
    )
  }
)

export default UploadFileInputField

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as LogoutSvg } from '@/assets/menu/menu-logout-icon.svg'
import Button from '@/components/common/Button'
import Icon from '@/components/Icon'
import MerchantName from '@/components/merchants/MerchantName'
import { MENU_ITEMS, MORE_MENU_ITEMS } from '@/constants/menuItem'
import { useAuth } from '@/contexts/authContext'
import { profileSelector } from '@/redux/profile/profileSelector'

import AccordionMenu from '../Sidebar/AccordionMenu'
import MenuItem from '../Sidebar/MenuItem'

export const RenderMenuItem = (props) => {
  const {
    icon,
    title,
    route,
    childrenArr,
    narrowed,
    keyItem,
    selectedItem,
    setSelectedItem,
    handleClose,
  } = props

  const location = useLocation()

  if (childrenArr && childrenArr.length > 0) {
    return (
      <AccordionMenu
        icon={icon}
        name={title}
        childrenArr={childrenArr}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        expanded={selectedItem === keyItem}
        active={location.pathname.startsWith(route)}
        isNarrowed={narrowed}
        keyItem={keyItem}
        onCloseMenu={handleClose}
      />
    )
  }

  return (
    <Link to={route} onClick={handleClose}>
      <MenuItem
        icon={icon}
        active={location.pathname === route}
        name={title}
        isNarrowed={narrowed}
      />
    </Link>
  )
}

const ProfileContainer = styled.div`
  width: 100%;
`

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileName = styled.div`
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 6px;
`

const ProfileRole = styled.div`
  font-size: 12px;
  color: black;
  font-weight: bold;
`
const LogoutButton = styled(Button)`
  border: ${(props) => (props.isNarrowed ? '' : '1px solid black')};
  background: white;
  padding: 10px 8px;
  border-radius: 8px;
  width: 100%;
  height: 42px;
  position: relative;
`
const ListMenuContainer = styled(List)`
  padding: 16px !important;
`

const SwipeableTemporaryDrawer = () => {
  const dispatch = useDispatch()
  const { logout } = useAuth()
  const [selectedItem, setSelectedItem] = useState('')
  const profile = useSelector(profileSelector)
  const { firstName, lastName, merchantID } = profile

  const name = `${firstName || ''} ${lastName || ''}`.trim()

  const onClickLogout = () => {
    dispatch({ type: 'app/hideSideBar' })
    logout()
  }

  const onHandleClose = () => {
    dispatch({ type: 'app/hideSideBar' })
    setSelectedItem('')
  }

  const list = () => (
    <div>
      <ListMenuContainer>
        <ProfileContainer className="flex justify-between">
          <ProfileDetails>
            <>
              <ProfileName>{name}</ProfileName>
              <ProfileRole>
                <MerchantName merchantID={merchantID} />
              </ProfileRole>
            </>
          </ProfileDetails>
          <KeyboardArrowDownIcon
            className="self-center cursor-pointer"
            onClick={onHandleClose}
          />
        </ProfileContainer>
      </ListMenuContainer>
      <Divider />
      <List>
        {MENU_ITEMS.map((item, index) => (
          <RenderMenuItem
            icon={() => <item.Icon />}
            key={item.key}
            keyItem={item.key}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            title={item.title}
            route={item.path}
            childrenArr={item.children}
            narrowed={false}
            handleClose={onHandleClose}
          />
        ))}
      </List>
      <Divider />
      <List>
        {MORE_MENU_ITEMS.map((item, index) => (
          <RenderMenuItem
            icon={() => <item.Icon />}
            key={item.key}
            title={item.title}
            keyItem={item.key}
            route={item.path}
            childrenArr={item.children}
            narrowed={false}
            handleClose={onHandleClose}
          />
        ))}
      </List>
      <Divider />
      <ListMenuContainer>
        <ProfileContainer>
          <ProfileDetails>
            <LogoutButton isNarrowed={false} onClick={onClickLogout}>
              <div className="absolute top-1">
                <Icon renderImage={() => <LogoutSvg />} />
              </div>{' '}
              log out
            </LogoutButton>
          </ProfileDetails>
        </ProfileContainer>
      </ListMenuContainer>
    </div>
  )

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={useSelector((s) => s.app.isShowSideBar)}
        onClose={onHandleClose}
        onOpen={() => {
          dispatch({ type: 'app/showSideBar' })
        }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  )
}

export default SwipeableTemporaryDrawer

import React, { useState } from 'react'

import { ReactComponent as CloseIconSvg } from '@/assets/common/close-icon.svg'
import { ReactComponent as SearchIconSvg } from '@/assets/common/search-icon.svg'
import Icon from '@/components/Icon'

import InputField from './InputField'

const Prefix = () => (
  <Icon
    renderImage={() => <SearchIconSvg />}
    width="1.111rem"
    height="1.111rem"
  />
)

const Suffix = () => (
  <Icon
    renderImage={() => <CloseIconSvg />}
    width="1.111rem"
    height="1.111rem"
  />
)

const SearchInputField = React.forwardRef((props, ref) => {
  const [value, setValue] = useState('')

  const onClickClear = () => {
    setValue('')
    props.onClickClear()
  }

  const onChange = (e) => {
    setValue(e.target.value)
    props.onChange(e)
  }

  return (
    <InputField
      ref={ref}
      {...props}
      hasTitle={false}
      prefix={<Prefix />}
      suffix={!!value && <Suffix />}
      onClickSuffix={onClickClear}
      onChange={onChange}
    />
  )
})

export default SearchInputField

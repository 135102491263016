import { matchPath } from 'react-router-dom'

export const matchExactPath = (pathname, path) =>
  matchPath(pathname, { path, exact: true })

export const IsShowTab = (pathname) =>
  !['/profile/miles/convert'].some((item) => matchExactPath(pathname, item))

export const IsCustomerSignUp = (pathname) =>
  [
    '/orderpreview/:orderId',
    '/order/:orderId',
    '/sign-up/overdue/:orderId',
    '/sign-up/profile/:orderId',
    '/sign-up/schedule/:orderId',
    '/sign-up/summary/:orderId',
    '/sign-up/payment/:orderId/:instalmentId',
    '/sign-up/payment/check/:orderId/:instalmentId',
    '/sign-up/payme/:orderId/:instalmentId',
    '/sign-up/fps/:orderId/:instalmentId',
    '/sign-up/payment/status/:orderId/:instalmentId',
    '/sign-up/payment/success/:orderId/:instalmentId',
    '/sign-up/payment/failure/:orderId/:instalmentId',
    '/sign-up/welcome',
    '/sign-up/verify-mobile',
    '/sign-up/verify-email',
    '/sign-up/kyc',
  ].some((item) => matchExactPath(pathname, item))

// check current is checkout process
export const isCheckoutPath = (pathname) => {
  const returnUrl = sessionStorage.getItem('return_url')
  return (
    matchExactPath(returnUrl, '/order/:orderId') || IsCustomerSignUp(pathname)
  )
}

export const isByPassRedirection = (pathname) =>
  [
    '/sign-up/welcome',
    '/profile/welcome',
    '/sign-up/verify-mobile',
    '/sign-up/verify-email',
    '/profile/verify-mobile',
    '/profile/verify-email',
  ].some((item) => matchExactPath(pathname, item))

export const IsCustomerSignUpEnd = (pathname) =>
  [
    '/sign-up/payment/check/:orderId/:instalmentId',
    '/sign-up/payment/status/:orderId/:instalmentId',
    '/sign-up/payment/success/:orderId/:instalmentId',
    '/sign-up/payment/failure/:orderId/:instalmentId',
  ].some((item) => matchExactPath(pathname, item))

export const IsRoot = (pathname) => matchExactPath(pathname, '/')
export const IsLogin = (pathname) => matchExactPath(pathname, '/login')
export const IsSignUp = (pathname) => matchExactPath(pathname, '/sign-up')
export const IsFps = (pathname) => matchExactPath(pathname, '/fps')
export const IsMerchantSignup = (pathname) =>
  matchExactPath(pathname, '/signup')
export const IsOrderPreview = (pathname) =>
  matchExactPath(pathname, '/orderpreview/:orderId')
export const IsHome = (pathname) => matchExactPath(pathname, '/home')
export const IsHistory = (pathname) =>
  matchExactPath(pathname, '/history') ||
  matchExactPath(pathname, '/history/*') ||
  matchExactPath(pathname, '/schedule/review/:id') ||
  matchExactPath(pathname, '/schedule/review/paynow/:id')
export const IsError = (pathname) => matchExactPath(pathname, '/error')
export const IsOrder = (pathname) =>
  matchExactPath(pathname, '/orderpreview/:orderId') ||
  matchExactPath(pathname, '/order/:orderId')
export const IsMilesLogin = (pathname) =>
  matchExactPath(pathname, '/miles/signin') ||
  matchExactPath(pathname, '/miles/signup')
export const IsMilesHome = (pathname) =>
  matchExactPath(pathname, '/profile/miles/convert') ||
  matchExactPath(pathname, '/profile/miles')
export const IsClaimMiles = (pathname) =>
  matchExactPath(pathname, '/miles/claim/:token')

export const getPaymentCallbackUri = ({ pathname, orderId, instalmentId }) =>
  IsCustomerSignUp(pathname)
    ? `${window.location.origin}/sign-up/payment/status/${orderId}/${instalmentId}`
    : `${window.location.origin}/payment/status/${orderId}/${instalmentId}`

export const getRouteByPathname = (routes, pathname) =>
  Object.values(routes).find((r) => matchExactPath(pathname, r.path))

export const getRouteName = (route) => {
  if (!route) {
    return 'Not found'
  }
  if (route.name) {
    return route.name
  }
  if (route.path === '/') {
    return 'Root'
  }
  return route.path
    .split('/')
    .filter((r) => r[0] !== ':')
    .join(' ')
}

export const isCheckout = () => sessionStorage.getItem('is_checkout') === 'true'

export const getOrderIdParam = (pathname) => {
  const { params } =
    matchExactPath(pathname, '/orderpreview/:orderId') ||
    matchExactPath(pathname, '/order/:orderId')
  return params.orderId
}

export const isClaimMiles = () =>
  sessionStorage.getItem('is_claim_divit_miles') === 'true'

export const IsPaynow = (pathname) =>
  matchExactPath(pathname, '/orders/:orderId/fast') ||
  pathname.indexOf('paynow/fast') >= 0

export const IsSuccessPage = (pathname) =>
  matchExactPath(pathname, '/buy-miles-success') ||
  matchExactPath(pathname, '/claim-miles')

import { call, delay, put, select } from 'redux-saga/effects'

import { ERROR_CODE_FPS_UNAVAILABLE } from '@/constants/errors'
import { fetchMerchantList } from '@/redux/lookup/lookupSaga'
import DivitAPI, { getAuthToken } from '@/services/api'
import * as Jwt from '@/utils/Jwt'
import * as Role from '@/utils/role'

import { getErrorMessage } from '../utils/error'
import { getFpsServiceSelector } from './profileSelector'

function* fetchCampaigns({ merchantId }) {
  const { data: campaigns } = yield call(() =>
    DivitAPI.get(
      `campaign?page=0&pageSize=50&merchantId=${merchantId}&status=active`
    )
  )
  // campaigns.data
  // .sort((a, b) => (a.title > b.title ? 1 : -1))
  // .sort((a, b) => a.title.length - b.title.length)
  yield put({ type: 'profile/getCampaignsSuccess', payload: campaigns.data })
}

export function* fetchPayNowRate({ merchantId }) {
  const { data: rates } = yield call(async () =>
    DivitAPI.get(`/paynow/merchants/${merchantId}/rates`)
  )
  yield put({ type: 'profile/getPayNowRateSuccess', payload: rates.data[0] })
}

export function* fetchProfile() {
  const { data: profile } = yield call(async () =>
    DivitAPI.get('users/v2/profile')
  )
  yield put({ type: 'profile/getProfileSuccess', payload: profile.data })

  const token = getAuthToken()
  const roleName = Role.getRole(Jwt.getRole(token))
  yield put({ type: 'profile/getRoleSuccess', payload: roleName })

  const merchantId = profile.data.merchantID
  yield delay(200)
  yield fetchCampaigns({ merchantId })
  // yield fetchPayNowRate({ merchantId })
  yield fetchMerchantList({ merchantId })

  return profile.data
}

export function* patchProfile({ payload }) {
  try {
    yield put({ type: 'profile/patchProfileStart' })
    const { data } = yield call(async () => DivitAPI.patch('profiles', payload))
    yield put({ type: 'profile/getProfileSuccess', payload: data.data })
    yield put({ type: 'profile/patchProfileSuccess' })
  } catch (e) {
    yield put({
      type: 'profile/patchProfileFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* patchProfileConfig({ payload }) {
  try {
    yield put({ type: 'profile/patchProfileConfigStart' })
    yield call(async () =>
      DivitAPI.patch(
        `/merchants/v2/${payload.merchantID}/branches/${payload.branchID}/appsettings`,
        payload
      )
    )
    yield put({ type: 'profile/patchProfileConfigSuccess' })
    yield fetchProfile()
  } catch (e) {
    yield put({
      type: 'profile/patchProfileConfigFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* fetchProfileServices() {
  const { data } = yield call(async () => DivitAPI.get('profiles/services'))
  yield put({ type: 'profile/getProfileServicesSuccess', payload: data.data })
  return data.data
}

export function* fetchCorporateReferralLink({ payload }) {
  const { merchantID } = payload
  try {
    yield put({ type: 'profile/getCorporateReferralLinkStart' })
    const { data } = yield call(async () =>
      DivitAPI.get(`partners/${merchantID}/referrallink`)
    )
    yield put({
      type: 'profile/getCorporateReferralLinkSuccess',
      payload: data.data,
    })
  } catch (e) {
    yield put({
      type: 'profile/getCorporateReferralLinkFailed',
      payload: getErrorMessage(e),
    })
  }
}

export function* checkFpsIsAvailable() {
  const fpsService = yield select(getFpsServiceSelector)
  if (fpsService) {
    const err = new Error('fps is unavailable')
    err.code = ERROR_CODE_FPS_UNAVAILABLE
    err.data = { periodEnd: fpsService.periodEnd }
    throw err
  }
}

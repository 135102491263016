import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import DivitLogo from '@/assets/merchants/divit-placeholder.png'
import Icon from '@/components/Icon'
import { merchantsSelector } from '@/redux/lookup/lookupSelector'

const MerchantIcon = styled(Icon)`
  background-color: white;
  border: 1px solid rgba(28, 28, 28, 0.2);
`

const MerchantLogo = ({ merchantID, width, height }) => {
  const images = useSelector(merchantsSelector)

  const getImage = () => {
    const merchant = images.find((m) => m.merchantID === merchantID)
    if (merchant && merchant.logoFile) {
      return <img alt="" src={`${merchant.logoFile}`} />
    }

    return <img alt="" src={DivitLogo} />
  }

  return (
    <MerchantIcon
      renderImage={() => getImage()}
      width={width}
      height={height}
      isCircle
    />
  )
}

export default MerchantLogo

import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactComponent as ErrorPageSvg } from '@/assets/common/error-page-icon.svg'
import colors from '@/themes/colors'

import { formatMessage } from '../locales/format'

const Container = styled.div`
  padding: 0 1.875rem;
  text-align: center;
`

const ErrorPageIcon = styled(ErrorPageSvg)`
  width: 8.675rem;
  height: auto;
`

const Title = styled.div`
  margin-top: 1.875rem;
  color: ${colors.divitLightBlue};
  font-size: 1.625rem;
  font-family: 'Red Hat Display', 'Noto Sans', sans-serif;
`

const Message = styled.div`
  margin-top: 1.875rem;
  font-size: 0.875rem;

  &:hover {
    cursor: pointer;
  }

  span {
    color: ${colors.divitOrange};
    text-decoration: underline;
  }
`

// it is outside all provider, if you need any provider,
// you need to add it to wrap this component
const Error = () => {
  const intl = useIntl()

  const goHome = (e) => {
    e.preventDefault()

    document.location.href = '/'
  }

  return (
    <Container>
      <ErrorPageIcon />
      <Title>{formatMessage(intl, 'error.page.title')}</Title>
      <Message onClick={goHome}>
        {intl.formatMessage(
          {
            id: 'notfound.message.homepage',
          },
          {
            highlight: (
              <span>
                {intl.formatMessage({
                  id: 'notfound.message.homepage.highlight',
                })}
              </span>
            ),
          }
        )}
      </Message>
    </Container>
  )
}

export default Error

import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as CalendarIconSvg } from '@/assets/common/calendar-icon.svg'
import { ReactComponent as DownArrowSvg } from '@/assets/common/down-arrow.svg'
import Icon from '@/components/Icon'
import SingleDateSelectModal, { OPTIONS } from '@/components/SingleDateSelectModal'

import InputError from './InputError'

const Field = styled.div`
  font-size: 0.77rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const Title = styled.div`
  margin-bottom: 0.44rem;
`

const ValueContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 2.5rem;
  border: 1px solid ${({ error }) => (error ? 'red' : '#DDDDDD')};
  border-radius: 8px;
  padding: 0.78rem 1.33rem;
  font-size: 0.78rem;
  background: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-color: #f7ce55;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #f7ce55;
  }
`

const Value = styled.div`
  flex: 1;
  margin-left: 0.444rem;
  color: ${({ isPlaceholder }) => (isPlaceholder ? '#989898' : 'inherit')};
`

const defaultOption = OPTIONS.find((o) => o.key === '3_days')

export const getDefaultDateValue = () => ({
  key: defaultOption.key,
  ...defaultOption.getValue(),
})

export const getDateValue = (key) => {
  if (!key) return null
  const opt = OPTIONS.find((o) => o.key === key)
  return {
    key,
    ...opt.getValue(),
  }
}

const InputField = React.forwardRef(
  (
    {
      style,
      className,
      hasTitle = true,
      title,
      placeholder,
      hasError,
      error,
      value,
      disabled,
      onChange,
    },
    ref
  ) => {
    const [isShowModal, setIsShowModal] = useState(false)

    const optionTitle = OPTIONS.find((o) => o.key === value.key)?.title
    const toD = (v) => moment(v).format('LLL')
    const customDateLabel = `${toD(value.selectedDate)}`
    const valueLabel =
      value.key === 'custom_date' ? customDateLabel : optionTitle

    const onClickField = () => {
      setIsShowModal(true)
    }

    const onChangeSelect = (selectedDay) => {
      onChange(selectedDay)
      setIsShowModal(false)
    }

    return (
      <Field style={style} className={className} disabled={disabled}>
        {hasTitle && <Title>{title}</Title>}
        <ValueContainer
          onClick={!disabled ? onClickField : null}
          error={hasError || error}
        >
          <Icon
            renderImage={() => <CalendarIconSvg />}
            width="1.11rem"
            height="1.11rem"
          />
          <Value isPlaceholder={value === ''}>{valueLabel}</Value>
          <Icon
            renderImage={() => <DownArrowSvg />}
            width="1.11rem"
            height="1.11rem"
          />
        </ValueContainer>
        <InputError error={error} />
        <SingleDateSelectModal
          isOpen={isShowModal}
          dateValue={value}
          onChange={onChangeSelect}
          onClose={() => setIsShowModal(false)}
        />
      </Field>
    )
  }
)

export default InputField

import React from 'react'

import Spacer from '@/components/Spacer'

// 1.25 + [2.68]

const HeaderSpacer = () => {
  // get height from store, but at least set min height

  const defaultHeight = '3.55rem'
  const headerHeight = defaultHeight

  return <Spacer height={headerHeight} />
}

export default HeaderSpacer

/* eslint-disable */
import { Decimal } from 'decimal.js'

import convertEn from './numToWordsEn'
import convertZh from './numToWordsZh'

const t = {
  en: {
    point: 'Point',
    percent: 'Percent',
  },
  'zh-hk': {
    point: '點',
    percent: '百份之',
  },
  'zh-cn': {
    point: '点',
    percent: '百分之',
  },
}

// 12.34 -> 12.34 precent
function convertPercentage(number, { locale }) {
  const d = parseInt(number, 10)
  const f = parseInt(Decimal(number).minus(d).mul(100).valueOf(), 10)
  const fs = f > 10 ? '' + f : '0' + f

  if (locale === 'en') {
    let ret = ''
    ret += convertEn(d)
    if (f > 0) {
      ret += ' ' + t[locale]['point'] + ' '
      ret += fs
        .split('')
        .map((c) => convertEn(parseInt(c, 10)))
        .join(' ')
    }
    ret += ' ' + t[locale]['percent']
    return ret
  } else if (locale === 'zh-hk' || locale === 'zh-cn') {
    let ret = t[locale]['percent']
    ret += convertZh(d, locale)
    if (f > 0) {
      ret += t[locale]['point']
      ret += fs
        .split('')
        .map((c) => convertZh(parseInt(c, 10), locale))
        .join('')
    }
    return ret
  }
  return ''
}

export default convertPercentage

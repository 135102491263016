import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import BottomSheet from '@/components/BottomSheet'
import Button from '@/components/common/Button'
import SingleDateSelectField, {
  getDateValue,
  getDefaultDateValue,
} from '@/components/common/SingleDateSelectField'
import ErrorMessageLine from '@/components/ErrorMessageLine'
import Spacer from '@/components/Spacer'
import globalStyles from '@/constants/globalStyles'
import { profileConfigSettings } from '@/redux/profile/profileSelector'

const Content = styled.div`
  width: 100%;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 1.33rem;
  border-top-right-radius: 1.33rem;
  margin: 0 auto;
  padding: 0 1.74rem;
  box-sizing: border-box;
`
const Title = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
  text-align: center;
`
const SubTitle = styled.div`
  margin-top: 0.44rem;
  font-size: 0.667rem;
  color: #979797;
  text-align: center;
`
const Form = styled.div`
  padding: 0 1.33rem;
`
const ExtendButton = styled(Button)`
  width: 100%;
  font-size: 0.88rem;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
`
const CancelButton = styled(Button)`
  width: 100%;
  font-size: 0.88rem;
  font-weight: 600;
  background-color: red;

  display: flex;
  justify-content: center;
  align-items: center;
`
const EditInvoiceOrderModal = ({
  orderID,
  expiredAt,
  isOpen,
  onClose,
  onCleanup,
  onClickCancel,
  onClickExtend,
  error,
}) => {
  const configs = useSelector(profileConfigSettings)
  const [newExpiryDate, setNewExpiryDate] = useState(() =>
    getDefaultDateValue()
  )

  useEffect(() => {
    if (configs && configs.invoiceExpiry) {
      const v = getDateValue(configs.invoiceExpiry)
      if (v.key) {
        setNewExpiryDate(v)
      }
    }
  }, [configs])

  const onLeave = () => {
    onCleanup()
  }

  const onClickCancelOrder = () => {
    onClickCancel({ orderID })
  }

  const onClickExtendOrder = () => {
    let extendDay = 0
    if (expiredAt > 0) {
      extendDay = Math.floor((expiredAt - moment().unix()) / 86400) * 86400
    }
    onClickExtend({
      orderID,
      expiryDate: extendDay + moment(newExpiryDate.selectedDate).unix(),
    })
  }

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={onClose}
      onSpringEnd={(e) => e.type === 'CLOSE' && onLeave()}
    >
      <Content>
        <Spacer height={globalStyles.px.lg2} />
        <Title>order actions</Title>
        <SubTitle>to extend or cancel order</SubTitle>
        <Spacer height={globalStyles.px.md} />
        <Form>
          {error && (
            <>
              <ErrorMessageLine errorMessage={error} />
              <Spacer height={globalStyles.px.xs} />
            </>
          )}
          <Spacer height={globalStyles.px.xs} />
          <SingleDateSelectField
            value={newExpiryDate}
            onChange={setNewExpiryDate}
          />
          <Spacer height={globalStyles.px.xs} />
          <ExtendButton type="bw" onClick={onClickExtendOrder}>
            <span>extend expiry date</span>
          </ExtendButton>
          <Spacer height={globalStyles.px.xs} />
          <CancelButton type="bw" onClick={onClickCancelOrder}>
            <span>cancel order</span>
          </CancelButton>
          <Spacer height={globalStyles.px.lg} />
          <Spacer height={globalStyles.px.lg} />
        </Form>
      </Content>
    </BottomSheet>
  )
}

export default EditInvoiceOrderModal
